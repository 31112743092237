import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  FormControl,
  InputGroup,
  Tab,
  Nav,
  Row,
} from "react-bootstrap";
import "../App.css";
import { tooltip } from "../Common/Tooltip";
import { BrowserRouter as Router } from "react-router-dom";
import { useBreakPoints, useViewport } from "../HistoricalChart/Hooks";
import * as com from "../Common.js"
import NumericalInput from "../NumericalInput";
import BreakDownChart from "./BreakdownChart";
import AmortizationChart from "./AmortizationChart";

const DEFAULT_HOME_PRICE = 800000;
const DEFAULT_DOWN_PRICE = 200000;
const DEFAULT_LOAN_TERM = 30; //year
const DEFAULT_INTEREST_RATE = 3.875; //year
const DEFAULT_INSURANCE = 213;
const DEFAULT_PROPERTY_TAXES_RATE = 0.72;

const oneToNMonth = [...Array(360).keys()];
const USstate = {
  CA: ["California", 0.76],
  AL: ["Alabama", 0.41],
  AK: ["Alaska", 1.19],
  AZ: ["Arizona", 0.66],
  AR: ["Arkansas", 0.62],
  CO: ["Colorado", 0.51],
  CT: ["Connecticut", 2.14],
  DE: ["Delaware", 0.57],
  DC: ["District Of Columbia", 0.56],
  FL: ["Florida", 0.89],
  GA: ["Georgia", 0.92],
  HI: ["Hawaii", 0.28],
  ID: ["Idaho", 0.69],
  IL: ["Illinois", 2.27],
  IN: ["Indiana", 0.85],
  IA: ["Iowa", 1.57],
  KS: ["Kansas", 1.41],
  KY: ["Kentucky", 0.86],
  LA: ["Louisiana", 0.55],
  ME: ["Maine", 1.36],
  MD: ["Maryland", 1.09],
  MA: ["Massachusetts", 1.23],
  MI: ["Michigan", 1.54],
  MN: ["Minnesota", 1.12],
  MS: ["Mississippi", 0.81],
  MO: ["Missouri", 0.97],
  MT: ["Montana", 0.84],
  NE: ["Nebraska", 1.73],
  NV: ["Nevada", 0.6],
  NH: ["New Hampshire", 2.18],
  NJ: ["New Jersey", 2.49],
  NM: ["New Mexico", 0.8],
  NY: ["New York", 1.72],
  NC: ["North Carolina", 0.84],
  ND: ["North Dakota", 0.98],
  OH: ["Ohio", 1.56],
  OK: ["Oklahoma", 0.9],
  OR: ["Oregon", 0.97],
  PA: ["Pennsylvania", 1.58],
  RI: ["Rhode Island", 1.63],
  SC: ["South Carolina", 0.57],
  SD: ["South Dakota", 1.31],
  TN: ["Tennessee", 0.71],
  TX: ["Texas", 1.8],
  UT: ["Utah", 0.63],
  VT: ["Vermont", 1.9],
  VA: ["Virginia", 0.82],
  WA: ["Washington", 0.98],
  WV: ["West Virginia", 0.58],
  WI: ["Wisconsin", 1.85],
  WY: ["Wyoming", 0.61],
};

function ControlledTabs(props) {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="beakdown" >
     
      <Nav variant="tabs" className="flex-column " style={{position: 'relative', bottom: '-1px'}}>
        <Row>
          <Nav.Item>
            <Nav.Link eventKey="beakdown">Breakdown</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="amortization">Amortization</Nav.Link>
          </Nav.Item>
        </Row>
      </Nav>

      <Tab.Content className="calcChart mb-5" style={{minHeight:'300px', border: '1px solid #dddddd'}}>
        <Tab.Pane eventKey="beakdown">
          <BreakDownChart series={props.series} />
        </Tab.Pane>
        <Tab.Pane eventKey="amortization">
          <AmortizationChart
            monthlyInterest={props.monthlyInterest}
            monthlyPrinciple={props.monthlyPrinciple}
            remaining={props.remaining}
            n={props.n}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

const monthly_payment = (loanAmt, baseRate, term) => {
	let rate = baseRate * 0.01 / 12
	let n = term * 12
	return Math.round(loanAmt * (rate * Math.pow((1+rate), n)) / (Math.pow((1+rate), n) - 1))
}

const DoMortgageCalculate = () => {
  const breakPoints = useBreakPoints();
  const viewport = useViewport();

  const { width, height } = calculateDimensions(breakPoints, viewport);

  let p = DEFAULT_HOME_PRICE - DEFAULT_DOWN_PRICE;
  let base = 1 + DEFAULT_INTEREST_RATE / 1200;
  let months = 12 * DEFAULT_LOAN_TERM;
  const [homePrice, setHomePrice] = useState(DEFAULT_HOME_PRICE);
  const [downPrice, setDownPrice] = useState(DEFAULT_DOWN_PRICE);
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [interestRate, setInterestRate] = useState(DEFAULT_INTEREST_RATE);
  let temp = base ** months;
  const [monthlyPayments, setMonthlyPayments] = useState(
    monthly_payment(DEFAULT_HOME_PRICE - DEFAULT_DOWN_PRICE, DEFAULT_INTEREST_RATE, DEFAULT_LOAN_TERM)
  );
  const [percentage, setPercentage] = useState(
    (DEFAULT_DOWN_PRICE / DEFAULT_HOME_PRICE) * 100
  );
  const [isOpen, setIsOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [taxes, setTaxes] = useState(DEFAULT_PROPERTY_TAXES_RATE / 1200);
  const [insurance, setInsurance] = useState(DEFAULT_INSURANCE);
  const [hoa, setHOA] = useState(0);
  const [pmi, setPMI] = useState(0);

  let denominator = 1 - base ** -months;
  let remaining = oneToNMonth
    .slice(0, 12 * loanTerm)
    .map((i) => (p * (1 - base ** (-months + i))) / denominator);
  let monthlyPrincipleCul = remaining.map((i) => p - i);
  let monthlyInterestCul = monthlyPrincipleCul
    .map((i) => (p - i) * (interestRate / 1200))
    .map(((sum) => (value) => (sum += value))(0));
  const [remain, setRemain] = useState(remaining);
  const [monthlyInterest, setMonthlyInterest] = useState(monthlyInterestCul);
  const [monthlyPrinciple, setMonthlyPrinciple] = useState(monthlyPrincipleCul);

  const doCalculate = () => {
    let p = homePrice - downPrice;
    let n = loanTerm * 12;
    let ir = interestRate / 1200;


    //PMI
    if (downPrice / homePrice < 0.2) {
      setPMI(0.25 * ir * homePrice);
    } else {
      setPMI(0);
    }

    temp = (1 + ir) ** n;
    setMonthlyPayments(monthly_payment(p, interestRate, loanTerm))
    console.log(
      "homePrice:" + homePrice,
      "downPrice:" + downPrice,
      "principal amount:" + p,
      "n:" + n,
      "monthly interest rate:" + ir,
      "monthly:" + monthlyPayments
    );

    denominator = 1 - (1 + ir) ** -n;
    remaining = oneToNMonth.slice(0, n).map(function (i, index) {
      let remain = (p * (1 - (1 + ir) ** (-n + i))) / denominator;
      monthlyPrincipleCul[index] = p - remain;
      monthlyInterestCul[index] = (p - monthlyPrincipleCul[index]) * ir;
      return remain;
    });
    let init = 0;
    setRemain(remaining);
    setMonthlyPrinciple(monthlyPrincipleCul);
    setMonthlyInterest(
      monthlyInterestCul.map(function (v) {
        return (init += v);
      })
    );
  };

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const toogleOpenMap = (e) => {
    e.preventDefault();
    setOpenMap(!openMap);
  };

  const myObserver = new ResizeObserver((entries) => {
    if (entries.length > 0) {
      let height = entries[0].contentRect.height;
      let width = entries[0].contentRect.width;
      window.parent.postMessage(
        { source: "tools", payload: [width, height] },
        "*"
      );
      console.log("width:" + width + ", height: " + height);
    }
  });

  const ref = useRef();

  useEffect(() => {
    setPercentage((downPrice / homePrice) * 100);
    doCalculate();
    // eslint-disable-next-line 
  }, [homePrice, downPrice]);
  useEffect(() => {
    doCalculate();
    // eslint-disable-next-line 
  }, [loanTerm, interestRate, taxes]);
  useEffect(() => {
    if (ref.current) myObserver.observe(ref.current);
  });
  return (
    <div id="contain" className="mx-3 " ref={ref} width={width} height={height}>
      <Form>
        <Form.Row>
          <Col xs style={{minWidth:'200px'}}>
            <Form.Group className="inputSection">
              <Form.Label style={{ marginBottom: "0" }}>
                {tooltip(
                  "Home Price",
                  <div>
                    What you pay for the home (if buying) or your home's current
                    value (if refinancing).
                  </div>,
                  "bottom",
                  "p"
                )}
              </Form.Label>
              <NumericalInput
                size="sm"
                value={homePrice}
                required
                type="number"
                step={10000}
                onChange={(e) => {
                  setHomePrice(e.target.value);
                }}
                min={0}
              />
              
            </Form.Group>
          </Col>
         
          <Col xs style={{minWidth:'200px'}}>
            <Form.Group className="inputSection">
              <Form.Label style={{ marginBottom: "0" }}>
                {tooltip(
                  "Down Payment",
                  <div>
                    The cash you pay upfront when buying a home as a percentage
                    of your full loan amount.
                  </div>,
                  "bottom",
                  "p"
                )}
              </Form.Label>
              <Form.Row>
                <Col xs>
                  <NumericalInput
                    size="sm"
                    value={downPrice}
                    required
                    type="number"
                    onChange={(e) => {
                      setDownPrice(e.target.value);
                    }}
                    min={0}
                    step={1000}
                  />
                </Col>
                or
                <Col xs={4}>
                  <InputGroup>
                    <Form.Control
                      size="sm"
                      value={Math.round(percentage * 100) / 100}
                      required
                      type="number"
                      onChange={(e) => {
                        setDownPrice(
                          Math.round(homePrice * (e.target.value / 100))
                        );
                      }}
                      min={0}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        className="pl-3"
                        style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                        size="sm"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group className="inputSection">
              <Form.Label style={{ marginBottom: "0" }}>
                {tooltip(
                  "Loan Term",
                  <div>
                    The amount of time you have to pay back the loan. Usually 15
                    or 30 years for common loan types.
                  </div>,
                  "bottom",
                  "p"
                )}
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => {
                  setLoanTerm(e.target.value);
                }}
                required
              >
                <option value="30">30 year fixed</option>
                <option value="25">25 year fixed</option>
                <option value="20">20 year fixed</option>
                <option value="15">15 year fixed</option>
                <option value="10">10 year fixed</option>
              </Form.Control>
              
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="inputSection">
              {/* <div className="err"></div> */}
              <Form.Label style={{ marginBottom: "0" }}>
                {tooltip(
                  "Interest Rate",
                  <div>
                    The cost of borrowing money that’s typically expressed as an
                    annual percentage of the loan.
                  </div>,
                  "bottom",
                  "p"
                )}
              </Form.Label>
              <InputGroup>
                <FormControl
                  size="sm"
                  type="number"
                  step="0.001"
                  defaultValue={DEFAULT_INTEREST_RATE}
                  min="0"
                  max="50"
                  onChange={(e) => {
                    setInterestRate(e.target.value);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className="pl-3"
                    style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                    size="sm"
                  >
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <button className="lpbutton"
            id="more"
            onClick={(e) => {
              toggle(e);
            }}
          >
            {isOpen ? `Less  ▲` : `Advanced  ▼`}
          </button>
        </Form.Row>
        {isOpen ? (
          <div>
            <Form.Row>
              <Col>
                <Form.Group className="inputSection">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {tooltip(
                      "Monthly homeowners association (HOA) fees",
                      <div>
                        Typically, owners of condos or townhomes are required to
                        pay homeowners association dues (known as HOA fees), to
                        cover common amenities or services within the property
                        such as garbage collection, landscaping, snow removal,
                        pool maintenance, and hazard insurance.
                      </div>,
                      "bottom",
                      "p"
                    )}
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    type="number"
                    onChange={(e) => {
                      setHOA(Number(e.target.value));
                    }}
                    min={0}
                    defaultValue={0}
                  />
                  
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="inputSection">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {tooltip(
                      "Monthly homeowner's insurance",
                      <div>
                        Commonly known as hazard insurance, most lenders require
                        insurance to provide damage protection for your home and
                        personal property from a variety of events, including
                        fire, lightning, burglary, vandalism, storms,
                        explosions, and more. All homeowner's insurance policies
                        contain personal liability coverage, which protects
                        against lawsuits involving injuries that occur on and
                        off your property.
                      </div>,
                      "bottom",
                      "p"
                    )}
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    required
                    type="number"
                    onChange={(e) => {
                      setInsurance(Number(e.target.value));
                    }}
                    min={0}
                    defaultValue={insurance}
                  />
                  
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group className="inputSection">
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Label style={{ marginBottom: "0" }}>
                        {tooltip(
                          "State",
                          <div>State affects the property tax you will pay. </div>,
                          "bottom",
                          "p"
                        )}
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={(e) => {
                          if (e.target.value !== "")
                            setTaxes(e.target.value / 1200);
                        }}
                      >
                        {Object.keys(USstate).map((key) => (
                          <option value={USstate[key][1]} key={key}>
                            {USstate[key][0]}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    {/* <Col>
                      <Form.Label style={{ marginBottom: "0" }}>
                        {tooltip(
                          "County",
                          <div>Coming soon ...</div>,
                          "bottom",
                          "p"
                        )}
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                        disabled
                      >
                        <option value=""> Choose One</option>
                        <option value="Albany">Albany</option>
                        <option value="Broome">Broome</option>
                        <option value="Orange">Orange</option>
                        <option value="New-York">New York</option>
                      </Form.Control>
                    </Col> */}
                  </Form.Row>
                  
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={{ marginBottom: "0" }}>
                    {tooltip(
                      "State Tax Rate Rank Map",
                      <div>
                        Check the rank of 50 state tax rates in the map
                      </div>,
                      "bottom",
                      "p"
                    )}
                  </Form.Label>
                  <br></br>
                  <button className="lpbutton"
                    id="tax-map"
                    onClick={(e) => {
                      toogleOpenMap(e);
                    }}
                  >
                    {openMap ? `Close ▲` : `Open ▼`}
                    {openMap ? (
                      <div>
                        <iframe
                          title="tax-map"
                          src="https://cdn.wallethub.com/wallethub/embed/11585/property-geochart1.html"
                          width="556"
                          height="347"
                          frameBorder="0"
                          scrolling="no"
                        ></iframe>
                        <div>
                          Source:{" "}
                          <a href="https://wallethub.com/edu/states-with-the-highest-and-lowest-property-taxes/11585">
                            WalletHub
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </button>
                  {/* <Form.Control
                    name="zipcode"
                    size="sm"
                    // onChange={}
                    onBlur={(e) => {}}
                    type="text"
                    pattern="^\d{5}$"
                  />*/}
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
        ) : (
          <Form.Row></Form.Row>
        )}
      </Form>
      <div className="text-center sectionquestion mb-3 mt-2" style={{fontSize: '1.2em'}}>
        Estimated Monthly Payment:$
        { com.commaizeFloat(Math.round(
          (monthlyPayments + homePrice * taxes + insurance + hoa + pmi)
        ))}
        <br/>
        Total Interest Over Loan Lifetime: ${ com.commaizeFloat(Math.ceil(monthlyInterest[monthlyInterest.length - 1]))}
      </div>
      
      <ControlledTabs
        series={[
          Math.round(monthlyPayments * 100) / 100,
          Math.round(homePrice * taxes * 100) / 100,
          Math.round(insurance * 100) / 100,
          Math.round(hoa * 100) / 100,
          Math.round(pmi * 100) / 100,
        ]}
        monthlyInterest={monthlyInterest}
        monthlyPrinciple={monthlyPrinciple}
        remaining={remain}
        n={12 * loanTerm}
      />
  
    </div>
  );
};
function MortgageCalculator() {
  window.drift.hide();

  return (
    //GA tracking needs to be wrapped by BrowserRouter; the reverse cannot be true, so BrowserRouter is removed from AuthenticatedApp.js
    <Router>
      <div
        className=" py-0 my-0 mx-0 px-0 text-center pb-10"
        
      >

        <DoMortgageCalculate />
       
      </div>
    </Router>
  );
}

export default MortgageCalculator;
