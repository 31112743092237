import React, { Component } from "react"
import dayjs from 'dayjs'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import './PrequalInterview.css'
import DateOfBirth from "../DateOfBirth"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common"
import { getCurrentState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { tooltip, Tips } from '../Common/Tooltip'
import InputGroupComponent from "../Common/InputGroup"
import Collapsible from "../Common/Collapsible"
import CheckGroup from "../Common/components/CheckGroup"
import AddressAutocomplete from "../Common/AddressAutocomplete"
import { Switch, Checkbox, InputNumber, Select, DatePicker } from 'antd'
import { isEmpty } from 'lodash'
import eventBus from '../Bus'
const visas = [
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
]

const mapStateToProps = (state) => {
    let getOccupation = (employed, selfemployed) => {
        if (employed === "employed" && !selfemployed) return "employed"
        if (employed === "employed" && selfemployed) return "selfemployed"
        return "unemployed"

    }
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
        property: state.application.property,
        cohabiting: state.application.cohabiting,
        financingsameproperty: state.application.financingsameproperty,
        borroweroccupation: getOccupation(state.application.borrower.occupation.hasoccupation,
            state.application.borrower.occupation.selfemployed),
        coborroweroccupation: "co" + getOccupation(state.application.coborrower.occupation.hasoccupation,
            state.application.coborrower.occupation.selfemployed),
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    updateCurrentResidence: (event) => {
        dispatch(act.UpdateCurrentResidence(event.target.value))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    updateCohabiting: (event) => {
        dispatch(act.UpdateCohabiting(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },

    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },

    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event.target.value))
    },

    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationCheck: (check, who, verb) => {
        dispatch(act.ChangeOccupationInfo(check, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },

    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    addBorrowerOwnedProperty: (t) => {
        dispatch(act.AddBorrowerOwnedProperty(t))
    },
    prependBorrowerOwnedProperty: () => {
        dispatch(act.PrependBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    clearBorrowerOwnedProperty: (t) => {
        dispatch(act.ClearBorrowerOwnedProperty(t))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event, who, verb))
    },
    updateBorrowerAttribute: (who, value, verb) => {
        dispatch(act.UpdateBorrowerAttribute(who, value, verb))
    },
})

class Applicant extends Component {

    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.cform = React.createRef()
        let has = props.hascoborrower ? "" : "selected"
        let doenothave = props.hascoborrower ? "selected" : ""
        this.frompreapp = props.from === "preapp" ? true : false
        this.state = {
            selectedalone: has,
            withcoborrower: doenothave,
            show: false,
            showSelf: false,
            showCashout: false,
            qualify: "",
            additional: "",
            residence: "",
            validated: false,
            loanLimits: {},
            sameAddress: false,
            who: "borrower",
        }
        this.radiocoborrower = React.createRef()
        this.phoneref = React.createRef()
        this.dob = React.createRef()
        let needsReset = false
        if (this.props.progress.lasttouch == null) {
            needsReset = true
        } else {
            let now = new Date()
            let then = new Date(this.props.progress.lasttouch)
            let days = (now - then) / (3600 * 24 * 1000)
            if (days > 7)
                needsReset = true
        }
        let resettableStates = ['interviewstarted', 'productselected', 'interviewfinished']

        if (needsReset && resettableStates.includes(this.props.application.status)) {
            //   this.props.updateChangeProgress(0, "max")
            this.props.updateChangeProgress(0, "interview")
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.props.changeReviewedLoans(0)
            getCurrentState()
            this.forceUpdate()
        }
        this.fetchLoanLimits()
        if (sessionStorage.getItem("ZeitroA") !== null) {
            if (
                this.props.application.borrower.firstname === "" &&
                this.props.application.borrower.lastname === ""
            ) {
                this.getName()
            }
        }

    }

    onRef = (ref) => {
        this.dob = ref
    }

    getName = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/auth/getprofile", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        this.props.changeBorrowerInfo(
                            { target: { value: js.FirstName } },
                            "borrower",
                            "firstname"
                        )
                        this.props.changeBorrowerInfo(
                            { target: { value: js.LastName } },
                            "borrower",
                            "lastname"
                        )
                        this.props.changeBorrowerInfo(
                            { target: { value: js.Email } },
                            "borrower",
                            "email"
                        )
                    }
                })
            })
            .catch((err) => {
                console.log("Fetch Error :", err)
            })
    };
    updateBorrower = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let bo = this.props.application.borrower
        let cb = this.props.application.coborrower
        fetch("/auth/updateborrower", {
            body: JSON.stringify({
                Email: bo.email,
                FirstName: bo.firstname,
                LastName: bo.lastname,
                CoborrowerFirstName: cb.firstname,
                CoborrowerLastName: cb.lastname,
                CoborrowerEmail: cb.email,
                CoborrowerPhone: cb.homephone,
            }),
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
            })
            .catch((err) => {
                console.log("Fetch Error :", err)
            })
    };

    fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    
                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    }

    getStates = () => {
        let options = []
        let keys = Object.keys(this.state.loanLimits)
        options.push(<option value="">Select your state</option>)
        keys.forEach((x) => {
            options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
        })
        return options
    };

    getCounties = () => {
        let options = []
        options.push(<option value="">Select your county</option>)
        let st = this.props.application.borrower.presentaddressstate
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    };
    getNewCounties = () => {
        let options = []
        options.push(<option value="">Select your county</option>)
        let st = this.props.application.coborrower.presentaddressstate
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    };
    componentDidMount() {
        if (this.props.application.hascoborrower === null) {
            this.updateCoborrower({ target: { value: st.HC_Alone } })
        }
        this.completion()
        this.setState({ sameAddress: this.hasSameAddress() })



        var inputs = document.querySelectorAll('input')
        // 遍历每一个输入框
        inputs.forEach(function (input) {
            // 设置autocomplete属性为off或new-password
            input.setAttribute('autocomplete', 'off')
            if (input.type === 'password') {
                input.setAttribute('autocomplete', 'new-password')
            }
        })

    }

    componentDidUpdate() {
        this.completion()
    }

    componentWillUnmount() { }
    completion = () => {
        const form = this.form.current
        const formElements = form.elements
        if (formElements.length === 0) {
            sessionStorage.setItem("progressValue", 100)
            return
        }
        let totalCount = 0
        let fillNum = 0
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i]
            if (element.type !== 'button' && element.type !== 'checkbox') {
                totalCount++
                if (!isEmpty(element.value)) {
                    fillNum++
                }
            }
        }
        const progressValue = Math.round((fillNum / totalCount) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    hasSameAddress = () => {
        const application = this.props.application
        const borrower = application.borrower
        const coBorrower = application.coborrower

        return (
            borrower.presentaddressstreet === coBorrower.presentaddressstreet &&
            borrower.presentaddressstate === coBorrower.presentaddressstate &&
            borrower.presentaddresscounty === coBorrower.presentaddresscounty &&
            borrower.presentaddresscity === coBorrower.presentaddresscity &&
            borrower.presentaddresszip === coBorrower.presentaddresszip
        )
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    handleSubmit = e => {
        if (this.props.application.borrower.homephone.length !== 14) {
            this.phoneref.current.setCustomValidity("Incorrect phone number format.")
            this.setState({ validated: true })
            return false
        } else {
            this.phoneref.current.setCustomValidity("")
        }
        if (this.form.current.reportValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ validated: true })
            return false
        }
        console.log()
        if (this.props.application.hascoborrower === 'withcoborrower') {
            if (this.cform.current.reportValidity() === false) {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ validated: true })
                return false
            }
        }

        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo({ target: { value: st.O_Own } }, com.borrower, "presentaddressownership")

        this.updateBorrower()

        return true
    }
    chooseBorrower = (event) => {
        com.touch()
        this.radioself.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Alone } }) // this is a hack
    }
    chooseCoborrower = (event) => {
        com.touch()
        this.radiocoborrower.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
    }
    updateCoborrower = (event) => {
        com.touch()
        if (event.target.id === "multiple") {
            if (this.props.application.remotecoborrowers.length === 0)
                this.props.addRemoteCoborrower()
        }

        if (event.target.id === "withcoborrower") {
            this.updateMarried({ target: { value: "true" } })
        }
        if (event.target.id === "alone") {
            this.updateMarried({ target: { value: "false" } })
        }
        this.props.updateCoborrower(event)
    }
    updateCohabiting = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateCohabiting({ target: { checked: val } })
    }
    updateMarried = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateApplicationAttribute(val, st.MS_Married)
    }
    changeEmployed = e => {
        switch (e.target.value) {
            case "employed":
                this.props.changeOccupationCheck(true, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.borrower, "selfemployed")
                break
            case "selfemployed":
                this.props.changeOccupationCheck(true, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(true, com.borrower, "selfemployed")
                break
            case "unemployed":
            default:
                this.props.changeOccupationCheck(false, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.borrower, "selfemployed")
                break
        }
    }
    changeCoEmployed = e => {
        com.touch()
        switch (e.target.value) {
            case "coemployed":
                this.props.changeOccupationCheck(true, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.coborrower, "selfemployed")
                break
            case "coselfemployed":
                this.props.changeOccupationCheck(true, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(true, com.coborrower, "selfemployed")
                break
            case "counemployed":
            default:
                this.props.changeOccupationCheck(false, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.coborrower, "selfemployed")
                break
        }
    }
    selfEmployedAsessment = () => {

    }

    changeMainPropertyAppraisal = e => {
        com.touch()
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, com.borrower, "marketvalue")
        this.props.changeMainPropertyAppraisal(e)
    }
    renderCoborrowerEmployment() {
        if (this.props.hascoborrower !== st.HC_Alone) {
            return (
                <Form.Row className="pt-0  my-0 py-0">

                    <Col className="text-left text-nowrap" >
                        <Form.Label className="text-left" >Additional borrower employment status:</Form.Label>
                        <Form.Group controlId="employment" >

                            <Form.Control name="employment" as="select" size="md"
                                defaultValue={this.props.coborroweroccupation}
                                onChange={this.changeCoEmployed}
                            >
                                <option value="coemployed">Additional borrower currently employed</option>
                                <option value="coselfemployed">Additional borrower currently self-employed</option>
                                <option value="counemployed">Additional borrower not employed</option>

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="text-left ">
                        <div hidden={this.props.coborroweroccupation !== "coselfemployed"} className="pt-2"> <br /><span style={{ fontSize: '0.8em' }} className="divlink mt-4 bluish" onClick={this.selfEmployedAsessment}> <b>&lt;-</b><i className="fas fa-question-circle text-info mr-1"></i> See if you qualify</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Form.Row>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
    onFirst = e => {
        com.touch()
        if (true === this.props.application.firsttimebuyer) {
            this.props.updateApplicationAttribute(false, "firsttimebuyer")
        } else {
            this.props.updateApplicationAttribute(true, "firsttimebuyer")
        }
    }

    onMarried = e => {
        com.touch()
        if (true === this.props.application.cohabiting) {
            this.props.updateCohabiting({ target: { checked: false } })
            this.props.updateApplicationAttribute(false, st.MS_Married)
        } else {
            this.props.updateCohabiting({ target: { checked: true } })
            this.props.updateApplicationAttribute(true, st.MS_Married)
        }
    }

    firstTime = () => {
        if (this.props.property.purpose !== st.POL_Purchase || this.props.application.borrower.presentaddressownership === st.O_Own)
            return ""
        return (
            <div className="mx-1 mt-4 text-wrap text-left">

                <div className="d-flex sectionquestion text-left"><span>{tooltip("Are you a first time home buyer?",
                    <div>
                        A first time home buyer is someone who meets any of the following conditions:
                        <ul>
                            <li>
                                An individual who has not owned a principal residence for three years. If you’ve owned a home but your spouse has not, then you can purchase a place together as first-time homebuyers.
                            </li><li>
                                A single parent who has only owned a home with a former spouse while married.
                            </li><li>
                                A displaced homemaker who has only owned with a spouse.
                            </li><li>
                                An individual who has only owned a principal residence not permanently affixed to a permanent foundation in accordance with applicable regulations.
                            </li><li>
                                An individual who has only owned a property that was not in compliance with state, local, or model building codes—and that cannot be brought into compliance for less than the cost of constructing a permanent structure.
                            </li>
                        </ul>
                    </div>, "auto"

                )}</span></div>

                <Form.Group>
                    <fieldset>
                        <div className="d-flex  ">
                            <div className="mr-3">
                                <input required onChange={this.onFirst} value={true} checked={true === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyer" name="freeandclear"></input>
                                <label id="firsttimebuyerlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyer">Yes, I am</label></div>
                            <div><input required onChange={this.onFirst} value={false} checked={false === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyernot" name="freeandclear"></input>
                                <label id="firsttimebuyernotlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyernot">No, I'm not</label></div>
                        </div>
                    </fieldset>

                    <Form.Control.Feedback type="invalid" >
                        Please specify if you are are a first time buyer.
                    </Form.Control.Feedback>
                </Form.Group>

            </div>

        )
    }

    sameProperty = () => {
        /*
        let updateProgram = e => {
            this.props.changeMainPropertyRefinanceAttribute(e.target.value, "currentprogram")
        }
        let updateCurrentRate = e => {
            this.props.changeMainPropertyRefinanceAttribute(e.target.value, "currentrate")

        }
        */
        let onSame = e => {
            com.touch()
            let out = e.target.value === "true"
            this.props.updateSameProperty({ target: { checked: out } })
            if (out)
                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
        }
        if (this.props.property.purpose !== st.POL_Refinance && this.props.property.purpose !== st.POL_Cashoutrefinance)
            return ""
        else
            return (
                <div>
                    <div className="d-flex mt-4 sectionquestion text-left">Are you refinancing your primary residence? </div>

                    <Form.Group controlId="financesamesame" className="text-left" >
                        <fieldset>
                            <div className="d-flex  ">
                                <div className="mr-3">
                                    <input required onChange={onSame} value={true} checked={true === this.props.financingsameproperty} type="radio" id="financingsameproperty" name="financingsameproperty"></input>
                                    <label id="financingsamepropertylabel" className="zeitro-radio divlink" htmlFor="financingsameproperty">Yes, I am</label>
                                </div>
                                <div>
                                    <input required onChange={onSame} value={false} checked={false === this.props.financingsameproperty} type="radio" id="financingsamepropertynot" name="financingsameproperty"></input>
                                    <label id="financingsamepropertynotlabel" className="zeitro-radio divlink" htmlFor="financingsamepropertynot">No, I'm not</label>
                                </div>
                            </div>
                        </fieldset>

                        <Form.Control.Feedback type="invalid" >
                            Please specify if you are refiancing primary residence.
                        </Form.Control.Feedback>
                    </Form.Group>



                </div>
            )
    }

    updateCoborrowerAssetsUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: "withcoborrower" } })  // this is a hack
        }
        this.props.updateApplicationCheck(e, "assetsofotherpersonused")
    }
    updateCoborrowerLiabilitiesUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
        }

        this.props.updateApplicationCheck(e, "liabilitiesofotherpersonrequired")
    }

    figureOut = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    selfEmployedAsessment = () => {
        this.setState({ showSelf: true })

    }
    qualifyForCashout = () => {
        this.setState({ showCashout: true })
    }
    handleCloseCashout = () => {
        this.setState({ showCashout: false })
    }
    changeMainPropertyPurpose = e => {
        com.touch()
        this.props.updateSelectField(new st.ChosenLoan(), "selectedloan")
        this.props.changeReviewedLoans(0)

        if (e.target.value === "cashoutrefinance") {
            this.setState({ showCashout: true })
        }
        if (this.props.property.purpose !== "purchase") { // if change from purpose, clear gifts/insurance
            this.props.clearBorrowerGift()
            this.props.clearBorrowerInsurance()
        }
        this.props.changeMainPropertyPurpose(e)
    }
    onStreetAddressCompletion = (address, who) => {
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let state = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "short_name"
        )
        let zip = com.getSubaddress(address, "postal_code", "long_name")

        let county = com.getSubaddress(
            address,
            "administrative_area_level_2",
            "long_name"
        )
        let longstate = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "long_name"
        )

        this.props.changePhoneInfo(city, who, "presentaddresscity")
        this.props.changePhoneInfo(longstate, who, "presentaddressstate")
        this.props.changePhoneInfo(zip, who, "presentaddresszip")
        this.props.changePhoneInfo(county, who, "presentaddresscounty")
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")

        let stradd = num + " " + short
        this.props.changeBorrowerInfo(
            { target: { value: stradd } },
            who,
            "presentaddressstreet"
        )
        if (who === "borrower" && this.props.application[who].presentaddressownership === st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length >= 1) {
            this.props.changeOwnedProperty(stradd, 0, "address")
            this.props.changeOwnedProperty(longstate, 0, "state")
            this.props.changeOwnedProperty(county, 0, "county")
            this.props.changeOwnedProperty(city, 0, "city")
            this.props.changeOwnedProperty(zip, 0, "zipcode")
        }
        let shorts = short.split(" ")
        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(" ")
        }
        let prefix = ""
        let s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {
            prefix = shorts[0]
            shorts = short.split(" ")
            short = shorts.slice(1, shorts.length).join(" ")
        }

    };

    render() {

        let changeBorrowerDOB = (who, verb) => {
            return (event) => {
                com.touch()
                this.dob.current.setCustomValidity("")
                let date = com.fixDateInput(event.target.value)
                return this.props.updateBorrowerAttribute(who, date, verb)
            }
        }

        let achangeBorrowerInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo({
                    target: {
                        value: event
                    }
                }, who, verb)
            }
        }
        let changeBorrowerInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
            }
        }
        let changeCoBorrower = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
                this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })
                if (this.props.application.hascoborrower === st.HC_Withcoborrower && this.props.application.cohabiting === null) {
                    this.props.updateCohabiting({ target: { checked: true } })
                }
            }
        }
        let changePhoneInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.phoneref.current.setCustomValidity("")
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changePhoneInfo(phone, who, verb)
            }
        }
        let appraisalVerbage = () => {
            switch (this.props.property.purpose) {
                case st.POL_Purchase:
                case st.POL_Refinance:
                case st.POL_Cashoutrefinance:
                    return "Estimated home value"
                default:
                    return "You should not see this line!"
            }
        }

        let onQualify = e => {
            this.setState({ qualify: e.target.value })
        }
        let onAdditional = e => {
            this.setState({ additional: e.target.value })
        }
        let removeCoborrower = e => {
            com.touch()
            let who = "coborrower"
            this.updateCoborrower({ target: { value: st.HC_Alone } })
            this.props.updateCohabiting({ target: { checked: false } })
            this.props.updateApplicationAttribute(false, st.MS_Married)

            this.props.changeBorrowerInfoByValue("", who, "firstname")
            this.props.changeBorrowerInfoByValue("", who, "lastname")
            this.props.changeBorrowerInfoByValue("", who, "email")
            this.props.changeBorrowerInfoByValue("", who, "homephone")
            this.props.changeBorrowerInfoByValue("", who, "citizenship")
        }
        let onAddressChange = (e, who) => {
            com.touch()
            let addr = e.target.value
            if (addr === "") {
                this.props.changeMainProperty(false, "lookupsuccessful")
            }
            // TBD
            this.props.changeBorrowerInfoByValue("3", who, "presentaddressyears")
            // console.log(this.props.changeBorrowerInfo(
            //     e,
            //     who,
            //     "presentaddressstreet"
            // ), 789);
            return this.props.changeBorrowerInfo(
                e,
                who,
                "presentaddressstreet"
            )
        }
        let defDate = new Date()
        defDate.setFullYear(defDate.getFullYear() + 10)

        let changeExpiration = (event, who) => {
            com.touch()
            this.expiration.current.setCustomValidity("")

            let date = com.fixDateInput(event.target.value)
            return this.props.changePhoneInfo(date, who, "visaexpiration")
        }
        let displayCitizenship = (who) => {
            return (

                <Row className="citizenship-wrap ">
                    <Col xs={12} lg={4} className="mt-4">
                        <Form.Group controlId="citizenship">
                            <Form.Label className="text-left">What's your citizenship?</Form.Label>
                            <div>
                                <Select
                                    onChange={achangeBorrowerInfo(who, "citizenship")}
                                    value={this.props.application[who].citizenship}
                                    style={{
                                        width: '100%',
                                        height: 44

                                    }}
                                >
                                    <option value="citizen">US Citizen</option>
                                    <option value="permanent">US Permanent Resident</option>
                                    <option value="alien">Non-US Citizen</option>

                                </Select>

                            </div>
                            {/* <Form.Control
                                required={who === "borrower" ? true : false}
                                name="citizenship"
                                as="select"
                                size="md"
                                defaultValue={this.props.application[who].citizenship}
                                onChange={changeBorrowerInfo(who, "citizenship")}
                            >
                                <option value="">...</option>
                                <option value="citizen">US Citizen</option>
                                <option value="permanent">US Permanent Resident</option>
                                <option value="alien">Non-US Citizen</option>
                            </Form.Control>
                            
                            <Form.Control.Feedback type="invalid">
                                Please provide your citizenship status.
                            </Form.Control.Feedback> */}
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={4} className="mt-4">
                        {this.props.application[who].citizenship === "alien" ? (
                            <Form.Group controlId="visa">
                                <Form.Label className="text-left">What visa type do you currently have?</Form.Label>
                                <div>
                                <Select
                                    onChange={changeBorrowerInfo(who, "visa")}
                                    value={this.props.application[who].visa}
                                    style={{
                                        width: '100%',
                                        height: 44

                                    }}
                                >
                                     {massage(visas, who).map(displayvisa)}

                                </Select>

                                </div>
                                {/* <Form.Control
                                    name="visa"
                                    as="select"
                                    size="md"
                                    defaultValue={this.props.application[who].visa}
                                    onChange={changeBorrowerInfo(who, "visa")}
                                >
                                    {massage(visas, who).map(displayvisa)}
                                </Form.Control> */}

                                <Form.Control.Feedback type="invalid">
                                    Please provide your citizenship.
                                </Form.Control.Feedback>
                            </Form.Group>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col xs={12} lg={4} className="mt-4">
                        {this.props.application[who].citizenship === "alien" ? (
                            <Form.Group controlId="visa" className="text-left d-flex flex-column">
                                <Form.Label className="text-left">Expires at</Form.Label>
                                <DatePicker
                                    name="expireat"
                                    style={{ width: '100%' }}
                                    required
                                    size="sm"
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    value={this.props.application[who].visaexpiration ? dayjs(this.props.application[who].visaexpiration, "MM/DD/YYYY") : ''}
                                    onChange={e => {
                                        let dat = new Date(e)
                                        this.props.changePhoneInfo(e ? dayjs(dat).format('MM/DD/YYYY') : '', who, "visaexpiration")
                                    }}
                                />
                            </Form.Group>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>

            )
        }
        let massage = (visas, who) => {
            if (who === "coborrower") return visas
            return visas.filter((x) => {
                if ([st.VT_H_4, st.VT_L_2, st.VT_O_3, st.VT_P_4].includes(x))
                    return false
                return true
            })
        }
        let displayvisa = (value, index) => {
            return (
                <option key={"visa" + index} value={value}>
                    {value}
                </option>
            )
        }
        let setLoanState = (v, who) => {
            this.props.changePhoneInfo(v, who, "presentaddressstate")
        }
        let setLoanCounty = (v, who) => {
            this.props.changePhoneInfo(v, who, "presentaddresscounty")
        }
        let setLoanCity = (v, who) => {
            this.props.changePhoneInfo(v, who, "presentaddresscity")
        }
        let setZip = (v, who) => {
            v = v.toString().replace(/\D/g, '')
            this.props.changePhoneInfo(v, who, "presentaddresszip")
        }
        let onChangeSwitch = (checked) => {
            let value = checked ? 'withcoborrower' : 'alone'
            this.updateCoborrower({ target: { value } })
            if (!checked) {
                let who = "coborrower"
                this.props.updateCohabiting({ target: { checked: false } })
                this.props.updateApplicationAttribute(false, st.MS_Married)

                this.props.changeBorrowerInfoByValue("", who, "firstname")
                this.props.changeBorrowerInfoByValue("", who, "lastname")
                this.props.changeBorrowerInfoByValue("", who, "email")
                this.props.changeBorrowerInfoByValue("", who, "homephone")
                this.props.changeBorrowerInfoByValue("", who, "citizenship")
            }
        }
        let onChangeCheckbox = (e) => {
            this.setState({ sameAddress: e.target.checked }, () => { eventBus.emit('changeProgressValue') })
            if (e.target.checked) {
                this.props.changeBorrowerInfo(
                    { target: { value: this.props.application.borrower.presentaddressstreet } },
                    'coborrower',
                    "presentaddressstreet"
                )
                setLoanState(this.props.application.borrower.presentaddressstate, 'coborrower')
                setLoanCounty(this.props.application.borrower.presentaddresscounty, 'coborrower')
                setLoanCity(this.props.application.borrower.presentaddresscity, 'coborrower')
                setZip(this.props.application.borrower.presentaddresszip, 'coborrower')
                this.props.changeBorrowerInfoByValue(this.props.application.borrower.presentaddressownership, "coborrower", "presentaddressownership")
            }
        }
        return (
            <div className=" text-left mb-3">

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCashout} onHide={this.handleCloseCashout} >
                    <Modal.Header closeButton>
                        <Modal.Title>Do you qualify for cash out refinance?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">

                        <div className="my-4 ">The borrower must be on the property title for at least 6 months in order to qualify for cash out refinance.
                        </div>
                        <div>Have you been on the title for more than 6 months?</div>
                        <div className="mt-4 mb-3">
                            <input type="radio" checked={this.state.qualify === "yes6month"} onChange={onQualify} value="yes6month" name="forcashout" id="yes6month" />
                            <label id="yes6monthlabel" className="divlink" htmlFor="yes6month">Yes, I have</label>
                            <input className="ml-3" type="radio" checked={this.state.qualify === "no6month"} onChange={onQualify} value="no6month" name="forcashout" id="no6month" />
                            <label id="no6monthlabel" className="divlink" htmlFor="no6month">No, I have not</label>
                        </div>
                        {
                            this.state.qualify === "yes6month" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} > Yes! Please continue filling out the application </div> : ""
                        }
                        {
                            this.state.qualify === "no6month" ?
                                <div  >
                                    Does the following apply to you?
                                    <ul>
                                        <li>You acquired the property through inheritance, divorce, separation or dissolution of domestic partnership.</li>
                                        <li>Purchased the property using all cash.</li>
                                        <li>Property was under an LLC or under a Trust for a minimum of six months.</li>
                                    </ul>
                                    <div className="mt-4 mb-3">
                                        <input type="radio" checked={this.state.additional === "yesapplies"} onChange={onAdditional} value="yesapplies"
                                            name="yesapplies" id="yesapplies" />
                                        <label className="divlink" id="yesapplieslabel"
                                            htmlFor="yesapplies">Yes, it does</label>
                                        <input className="ml-3" checked={this.state.additional === "doesnotapply"} type="radio" onChange={onAdditional} value="doesnotapply"
                                            name="yesapplies" id="doesnotapply" />
                                        <label id="noapplieslabel" className="divlink"
                                            htmlFor="doesnotapply">No, it doesn't</label>
                                    </div>
                                    {
                                        this.state.additional === "yesapplies" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} >Yes! Please continue filling out the application </div>
                                            :
                                            (this.state.additional === "doesnotapply" ? <div style={{ fontSize: '1.2em', color: '#770000', fontWeight: 'bold' }} >Sorry, you can't apply for a cash out loan at this point in time.</div> : "")
                                    }
                                </div> : ""

                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseCashout}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>When you need to apply with a coborrower:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <Form id="modal" noValidate validated={false} onSubmit={this.handleSubmitModal}>

                            <div className="my-4 ">Applicants should complete this form as “Borrower” or “Additional Borrower,” as applicable.
                            </div>
                            <div className="mb-4">Additional Borrower information must also be provided (and the appropriate box checked) when </div>
                            <Row>
                                <Col>
                                    <input type="checkbox" id="assetsofotherpersonused" name="assetsofotherpersonused" defaultChecked={this.props.application.assetsofotherpersonused} onChange={this.updateCoborrowerAssetsUsed} ></input>
                                    <label id="assetsofotherpersonusedlabel" style={{ textDecoration: "underline" }} className="divlink" htmlFor="assetsofotherpersonused">The INCOME or ASSETS of a person other than the Borrower (including the Borrower’s spouse) will be used as a basis for loan qualification </label>
                                </Col>
                            </Row>
                            or
                            <Row>
                                <Col>
                                    <Form.Group controlId="liabilitiesofotherpersonrequired" className="text-left form-inline " >
                                        <Form.Check className="divlink" type="checkbox" id="liabilitiesofotherpersonrequired" name="liabilitiesofotherpersonrequired" defaultChecked={this.props.application.liabilitiesofotherpersonrequired} onChange={this.updateCoborrowerLiabilitiesUsed} label="The income or assets of the Borrower’s spouse or other person who has community property rights pursuant to state law will NOT be used as a basis for loan qualification, but his or her LIABILITIES must be considered because the spouse or other person has community property rights pursuant to applicable law and Borrower resides in a community property state, the security property is located in a community property state, or the Borrower is relying on other property located in a community property state as a basis for repayment of the loan."></Form.Check></Form.Group>
                                </Col> </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showSelf} onHide={this.handleSelfClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you self employed?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                        <div> Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSelfClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Form ref={this.form}>

                    {!this.frompreapp && <Row className="prequalInterviewSubTitle pt-3">
                        <Col style={{ color: '#000', fontWeight: 600 }}>Basic</Col>
                    </Row>}

                    <Row className="pt-4  " >
                        <Col md={6} xs={12}>
                            <Form.Group className="paddingRightFix" >
                                <Form.Label>First name</Form.Label>
                                <InputGroupComponent
                                    name="firstname"
                                    placeholderValue='Enter your first name'
                                    value={this.props.application["borrower"].firstname}
                                    onChange={changeBorrowerInfo("borrower", "firstname")}
                                    required={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Group >
                                <Form.Label>Last name</Form.Label>
                                <InputGroupComponent
                                    name="lastname"
                                    placeholderValue='Enter your last name'
                                    value={this.props.application["borrower"].lastname}
                                    onChange={changeBorrowerInfo("borrower", "lastname")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {!this.frompreapp && <Row className="mt-1">
                        <Col>
                            <AddressAutocomplete
                                id="Address"
                                label="What's your current residential address?"
                                required={true}
                                name="addessline"
                                defaultValue={
                                    this.props.application["borrower"]
                                        .presentaddressstreet
                                }
                                onChange={(e) => {
                                    onAddressChange(e, 'borrower')
                                }}
                                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                placeholder="Address 1"
                                goodfeedback=""
                                badfeedback="Please provide your street address."
                                onCompletion={e => this.onStreetAddressCompletion(e, "borrower")}
                                size="md"
                            />
                        </Col>
                    </Row>}
                    {!this.frompreapp && <Row className=" ">
                        <Col className="mt-4" md={3} xs={12}>
                            <Form.Group >
                                <div>
                                    <Select style={{
                                        width: '100%',
                                        height: 44
                                    }} value={this.props.application.borrower.presentaddressstate} onChange={(e) => {
                                        setLoanState(e, 'borrower')
                                    }}>    {this.getStates()}</Select>

                                </div>
                                {/* <Form.Control
                                    required
                                    size="sm"
                                    as="select"
                                    placeholder="State"
                                    onChange={(e) => {
                                        setLoanState(e.target.value, 'borrower')
                                    }}
                                    value={this.props.application.borrower.presentaddressstate}
                                >
                                    {this.getStates()}
                                </Form.Control> */}
                            </Form.Group>
                        </Col>
                        <Col className="mt-4" md={3} xs={12}>
                            <Form.Group >
                                <div>
                                    <Select style={{
                                        width: '100%',
                                        height: 44
                                    }} value={this.props.application.borrower.presentaddresscounty} onChange={(e) => {
                                        setLoanCounty(e, 'borrower')
                                    }}>     {this.getCounties()}</Select>

                                </div>
                                {/* <Form.Control
                                    required
                                    size="sm"
                                    as="select"
                                    onChange={(e) => {
                                        setLoanCounty(e.target.value, 'borrower')
                                    }}
                                    value={this.props.application.borrower.presentaddresscounty}
                                >
                                    {this.getCounties()}
                                </Form.Control> */}
                            </Form.Group>
                        </Col>
                        <Col className="mt-4" md={3} xs={12}>
                            <Form.Group >
                                <Form.Control
                                    required
                                    size="sm"
                                    onChange={(e) => {
                                        setLoanCity(e.target.value, 'borrower')
                                    }}
                                    value={this.props.application["borrower"].presentaddresscity}
                                    placeholder="City"
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="mt-4" md={3} xs={12} >
                            <Form.Group >
                                <div>
                                    <InputNumber size="large" style={{ width: '100%' }} value={this.props.application["borrower"].presentaddresszip}
                                        placeholder="Zip Code" onChange={(e) => {
                                            console.log(e)
                                            setZip(e, 'borrower')
                                        }}></InputNumber>

                                </div>
                                {/* <Form.Control
                                    required
                                    size="sm"
                                    type="number"
                                    onChange={(e) => {
                                        setZip(e.target.value, 'borrower')
                                    }}
                                    value={this.props.application["borrower"].presentaddresszip}
                                    placeholder="Zip Code"
                                >
                                </Form.Control> */}
                            </Form.Group>
                        </Col>
                    </Row>}

                    {!this.frompreapp && <Row className="mt-4">
                        <Col className="" md={12}>
                            <Form.Group className="inputSection" style={{ width: "100%" }}>
                                <Form.Label>
                                    How about your current residence?
                                </Form.Label>
                                <CheckGroup
                                    data={[{
                                        value: st.O_Own,
                                        label: "I own it",
                                    },
                                    {
                                        value: st.O_Rent,
                                        label: 'I rent it',
                                    },
                                    {
                                        value: st.O_Rentfree,
                                        label: 'No primary housing expense',
                                    }
                                    ]}
                                    onChangeSelect={e => {
                                        this.props.changeBorrowerInfoByValue(e, this.state.who, "presentaddressownership")

                                        let properties = this.props.application.assetsandliabilities.ownedproperties
                                        if (e === st.O_Own) {
                                            if (this.state.who === "borrower" && (properties.length === 0 ||
                                                (properties.length > 0 && properties[0].address !== this.props.application[this.state.who].presentaddressstreet))) {
                                                this.props.prependBorrowerOwnedProperty()

                                                this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddressstreet, 0, "address")
                                                this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddressstate, 0, "state")
                                                this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresscounty, 0, "county")
                                                this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresscity, 0, "city")
                                                this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresszip, 0, "zipcode")
                                            }
                                        } else {
                                            if (properties.length > 0) {
                                                this.props.removeBorrowerOwnedProperty(0)
                                            }
                                        }
                                    }}
                                    value={this.props.application[this.state.who].presentaddressownership}
                                    type='radio'
                                    direction="horizontal"
                                />
                                {this.props.application.borrower.presentaddressownership === st.O_Rent && <div className="mt-4">

                                    <div className="formLabel">
                                        Monthly rent
                                    </div>
                                    <InputNumber
                                        min={1}
                                        prefix="$"
                                        className="w-100"
                                        defaultValue={this.props.application[this.state.who].expenses.currentrent}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={e => this.props.updateExpenses(e, this.state.who, "currentrent")}
                                        size="large"
                                    />
                                </div>}
                            </Form.Group>
                        </Col>
                    </Row>}

                    {
                        !this.frompreapp && this.state.who === "borrower" && this.props.application[this.state.who].presentaddressownership === st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length > 0 && <div><Row>
                            <Col  className="mt-4">
                                <div className="formLabel">
                                    Property value
                                </div>
                                <InputNumber
                                    min={1}
                                    prefix="$"
                                    className="w-100"
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].marketvalue}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={e => this.props.changeOwnedProperty(e, 0, "marketvalue")}
                                    size="large"
                                />
                            </Col>
                            <Col  className="mt-4">
                                <div className="formLabel">
                                    Property status
                                </div>
                                <Select
                                    className="w-100"
                                    style={{ height: 44 }}
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].status}
                                    options={[
                                        { value: "sold", label: "Sold" },
                                        { value: "pending", label: "Pending sale" },
                                        { value: "rental", label: "Rental" },
                                        { value: "second", label: "Second" },

                                    ]}
                                    onChange={e => this.props.changeOwnedProperty(e, 0, "status")}
                                />

                            </Col>
                            <Col  className="mt-4">
                                <div className="formLabel">
                                    Property intended occupancy
                                </div>
                                <Select
                                    className="w-100"
                                    style={{ height: 44 }}
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].occupancy}
                                    options={[
                                        { value: "principal", label: "Primary Residence" },
                                        { value: "secondhome", label: "Second Home" },
                                        { value: "investment", label: "Investment" },
                                    ]}
                                    onChange={e => this.props.changeOwnedProperty(e, 0, "occupancy")}
                                />

                            </Col>
                        </Row>
                            <Row className="mb-3 mt-4">
                                <Col md={8} xs={12}>
                                    <div className="formLabel">
                                        Monthly insurance, taxes, association dues, etc.
                                    </div>
                                    <InputNumber
                                        min={1}
                                        prefix="$"
                                        className="w-100"
                                        defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].insurancemaintenancetaxes}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={e => this.props.changeOwnedProperty(e, 0, "insurancemaintenancetaxes")}
                                        size="large"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Checkbox
                                        checked={
                                            this.props.application.assetsandliabilities.ownedproperties[0].propertytype === "twotofour"
                                        }
                                        className="formLabel"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.props.changeOwnedProperty("twotofour", 0, "propertytype")
                                            } else {
                                                this.props.changeOwnedProperty(null, 0, "propertytype")
                                            }
                                        }}
                                    >
                                        This is a 2-4 unit property
                                    </Checkbox>
                                </Col>
                            </Row>
                            {(
                                this.props.application.assetsandliabilities.ownedproperties[0].occupancy === "investment" ||
                                this.props.application.assetsandliabilities.ownedproperties[0].propertytype === "twotofour"
                            ) && <Row className="">
                                    <Col md={8} xs={12}>
                                        <div className="formLabel">
                                            Expected Monthly Rental Income
                                        </div>
                                        <InputNumber
                                            min={1}
                                            prefix="$"
                                            className="w-100"
                                            defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].netrentalincome}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={e => this.props.changeOwnedProperty(String(e), 0, "netrentalincome")}
                                            size="large"
                                        />
                                    </Col>
                                </Row>}


                        </div>
                    }

                    <Row >
                        <Col md={6} xs={12} className="mt-4">
                            <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                <Form.Label className="text-left  text-nowrap">
                                    Date of birth
                                </Form.Label>

                                <DatePicker
                                    name="dateofbirth"
                                    style={{ width: '100%' }}
                                    required
                                    size="sm"
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    value={this.props.application.borrower.dateofbirth ? dayjs(this.props.application.borrower.dateofbirth, "MM/DD/YYYY") : ''}
                                    onChange={e => {
                                        let dat = new Date(e)
                                        this.props.changeBorrowerInfoByValue(e ? dayjs(dat).format('MM/DD/YYYY') : '', "borrower", "dateofbirth")
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} className="mt-4">
                            <Form.Group controlId="MaritalStatus" className="text-left">
                                <Form.Label className="text-left">Marital status<Tips
                                    content={<div>
                                        If you are in a domestic partnership, single or
                                        divorced, select Unmarried
                                    </div>}
                                />

                                </Form.Label>
                                <div>
                                    <Select
                                        style={{
                                            width: "100%",
                                            height: 44

                                        }}
                                        value={
                                            this.props.application["borrower"].maritalstatus

                                        }
                                        onChange={achangeBorrowerInfo(
                                            "borrower",
                                            "maritalstatus"
                                        )}
                                    >
                                        <option value="married">Married</option>
                                        <option value="separated">Separated</option>
                                        <option value="unmarried">Unmarried</option>
                                    </Select>
                                </div>
                                {/* <Form.Control
                                    required
                                    name="maritalstatus"
                                    as="select"
                                    size="sm"
                                    defaultValue={
                                        this.props.application["borrower"].maritalstatus
                                    }
                                    onChange={changeBorrowerInfo(
                                        "borrower",
                                        "maritalstatus"
                                    )}
                                >
                                    <option required value="">
                                        ...
                                    </option>
                                    <option value="married">Married</option>
                                    <option value="separated">Separated</option>
                                    <option value="unmarried">Unmarried</option>
                                </Form.Control>
                                
                                <Form.Control.Feedback type="invalid">
                                    Please provide your marital status
                                </Form.Control.Feedback> */}
                            </Form.Group>
                        </Col>
                    </Row>

                    {!this.frompreapp && displayCitizenship("borrower")}
                    <Row><Col>
                        <div className="mt-4">
                            <Form.Group >
                                <Form.Label>Contact Phone</Form.Label>
                                <Form.Control
                                    isValid={false}
                                    ref={this.phoneref}
                                    name="phone"
                                    placeholderValue='Enter your phone number'
                                    value={this.props.application.borrower.homephone}
                                    onChange={changePhoneInfo("borrower", "homephone")}
                                    required
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please enter a valid phone number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="subheading" style={{ marginTop: "5px" }}>We hate spam just as much as you do. You won't receive any unwanted calls or texts from us.</div>
                    </Col></Row>
                    {!this.frompreapp && <Row className="mt-4"><Col style={{ display: 'flex' }}><Switch defaultChecked={this.props.application.hascoborrower === "withcoborrower"} onChange={onChangeSwitch} /><span style={{ marginLeft: 18, fontSize: 14, fontWeight: 500, color: '#222' }}>I have a co-borrower</span></Col></Row>}
                    {
                        !this.frompreapp && this.props.application.hascoborrower === "withcoborrower" && (
                            <>
                                <Row className="prequalInterviewSubTitle mt-4"><Col style={{ color: '#000', fontWeight: 600 }}>Co-borrower</Col></Row>
                                <Row className="pt-4">
                                    <Col>
                                        <Form.Group ><Checkbox checked={this.state.sameAddress} onChange={onChangeCheckbox}><span style={{ fontSize: 14, fontWeight: 500, color: '#222',whiteSpace:"normal" }}>Co-borrower has the same address as the primary borrower.</span></Checkbox></Form.Group>
                                    </Col>
                                </Row>
                                <Form ref={this.cform}>
                                    <Row className="pt-4">
                                        <Col md={6} xs={12}>
                                            <Form.Group >
                                                <Form.Label>First name</Form.Label>
                                                <InputGroupComponent
                                                    required={true}
                                                    name="coborrowerfirstname"
                                                    placeholderValue="Co-borrower first name"
                                                    value={this.props.application["coborrower"].firstname}
                                                    onChange={changeCoBorrower("coborrower", "firstname")}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Form.Group>
                                                <Form.Label>Last name</Form.Label>
                                                <InputGroupComponent
                                                    required={true}
                                                    name="coborrowerlastname"
                                                    placeholderValue="Co-borrower last name"
                                                    value={this.props.application["coborrower"].lastname}
                                                    onChange={changeBorrowerInfo("coborrower", "lastname")}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className=""  >
                                    <Col md={6} xs={12} className="mt-2">
                                        <Form.Group >
                                            <Form.Label>Email</Form.Label>
                                            <InputGroupComponent
                                                placeholderValue="Enter your co-applicant's email address"
                                                value={this.props.application["coborrower"].email}
                                                onChange={changeBorrowerInfo("coborrower", "email")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12} className="mt-2">
                                        <Form.Group>
                                            <Form.Label>Phone</Form.Label>
                                            <InputGroupComponent
                                                placeholderValue="Enter your co-applicant's phone number"
                                                value={this.props.application["coborrower"].homephone}
                                                onChange={changePhoneInfo("coborrower", "homephone")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    !this.state.sameAddress && (
                                        <>
                                            <Row className="mt-4">
                                                <Col>
                                                    <AddressAutocomplete
                                                        id="coAddress"
                                                        label="What's your current residential address?"

                                                        name="addessline"
                                                        defaultValue={
                                                            this.props.application["coborrower"]
                                                                .presentaddressstreet
                                                        }
                                                        onChange={(e) => {
                                                            onAddressChange(e, 'coborrower')
                                                        }}
                                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                                        placeholder="Address 1"
                                                        goodfeedback=""
                                                        badfeedback="Please provide your street address."
                                                        onCompletion={e => this.onStreetAddressCompletion(e, "coborrower")}
                                                        size="md"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="">
                                                <Col className="mt-4" md={3} xs={12}>
                                                    <Form.Group >
                                                        <div>
                                                            <Select style={{
                                                                width: '100%',
                                                                height: 44
                                                            }} value={this.props.application.coborrower.presentaddressstate} onChange={(e) => {
                                                                setLoanState(e, 'coborrower')
                                                            }}>    {this.getStates()}</Select>

                                                        </div>
                                                        {/* <Form.Control
                                                            size="sm"
                                                            as="select"
                                                            placeholder="State"
                                                            onChange={(e) => {
                                                                setLoanState(e.target.value, 'coborrower')
                                                            }}
                                                            value={this.props.application.coborrower.presentaddressstate}
                                                        >
                                                            {this.getStates()}
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                </Col>
                                                <Col className="mt-4" md={3} xs={12}>
                                                    <Form.Group >
                                                        <div>
                                                            <Select style={{
                                                                width: '100%',
                                                                height: 44
                                                            }} value={this.props.application.coborrower.presentaddresscounty} onChange={(e) => {
                                                                setLoanState(e, 'coborrower')
                                                            }}>    {this.getNewCounties()}</Select>

                                                        </div>
                                                        {/* <Form.Control
                                                            size="sm"
                                                            as="select"
                                                            onChange={(e) => {
                                                                setLoanCounty(e.target.value, 'coborrower')
                                                            }}
                                                            value={this.props.application.coborrower.presentaddresscounty}
                                                        >
                                                            {this.getNewCounties()}
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                </Col>
                                                <Col className="mt-4" md={3} xs={12}>
                                                    <Form.Group >
                                                        <Form.Control
                                                            size="sm"
                                                            onChange={(e) => {
                                                                setLoanCity(e.target.value, 'coborrower')
                                                            }}
                                                            value={this.props.application["coborrower"].presentaddresscity}
                                                            placeholder="City"
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="mt-4" md={3} xs={12} >
                                                    <Form.Group >
                                                        <div>
                                                            <InputNumber size="large" style={{ width: '100%' }} value={this.props.application["borrower"].presentaddresszip}
                                                                placeholder="Zip Code" onChange={(e) => {
                                                                    console.log(e)
                                                                    setZip(e, 'borrower')
                                                                }}></InputNumber>

                                                        </div>
                                                        {/* <Form.Control
                                                            size="sm"
                                                            type="number"
                                                            onChange={(e) => {
                                                                setZip(e.target.value, 'coborrower')
                                                            }}
                                                            value={this.props.application["coborrower"].presentaddresszip}
                                                            placeholder="Zip Code"
                                                        >
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mt-4">
                                                <Col className="" >
                                                    <Form.Group className="inputSection" style={{ width: "100%" }}>
                                                        <Form.Label>
                                                            How about your current residence?
                                                        </Form.Label>
                                                        <CheckGroup
                                                            data={[{
                                                                value: st.O_Own,
                                                                label: "I own it",
                                                            },
                                                            {
                                                                value: st.O_Rent,
                                                                label: 'I rent it',
                                                            },
                                                            {
                                                                value: st.O_Rentfree,
                                                                label: 'No primary housing expense',
                                                            }
                                                            ]}
                                                            onChangeSelect={e => {
                                                                this.props.changeBorrowerInfoByValue(e, "coborrower", "presentaddressownership")
                                                            }}
                                                            value={this.props.application.coborrower.presentaddressownership}
                                                            type='radio'
                                                            direction="horizontal"
                                                        />
                                                        {this.props.application.coborrower.presentaddressownership === st.O_Rent && <div className="mb-3">

                                                            <div className="formLabel mt-4">
                                                                Monthly rent
                                                            </div>
                                                            <InputNumber
                                                                min={1}
                                                                prefix="$"
                                                                className="w-100"
                                                                defaultValue={this.props.application.coborrower.expenses.currentrent}
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={e => this.props.updateExpenses(e, "coborrower", "currentrent")}
                                                                size="large"
                                                            />
                                                        </div>}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }

                                <Row >
                                    <Col md={6} xs={12} className="mt-2">
                                        <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                            <Form.Label className="text-left  text-nowrap">
                                                Date of birth
                                            </Form.Label>

                                            <DatePicker
                                                name="dateofbirth"
                                                style={{ width: '100%' }}
                                                required
                                                size="sm"
                                                placeholder="MM/DD/YYYY"
                                                format="MM/DD/YYYY"
                                                value={this.props.application.coborrower.dateofbirth ? dayjs(this.props.application.coborrower.dateofbirth, "MM/DD/YYYY") : ''}
                                                onChange={e => {
                                                    let dat = new Date(e)
                                                    this.props.changeBorrowerInfoByValue(e ? dayjs(dat).format('MM/DD/YYYY') : '', "coborrower", "dateofbirth")
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12} className="mt-2">
                                        <Form.Group controlId="MaritalStatus" className="text-left">
                                            <Form.Label className="text-left">Marital status<Tips
                                                content={<div>
                                                    If you are in a domestic partnership, single or
                                                    divorced, select Unmarried
                                                </div>}
                                            />

                                            </Form.Label>
                                            <Form.Control
                                                required
                                                name="maritalstatus"
                                                as="select"
                                                size="sm"
                                                defaultValue={
                                                    this.props.application["coborrower"].maritalstatus
                                                }
                                                onChange={changeBorrowerInfo(
                                                    "coborrower",
                                                    "maritalstatus"
                                                )}
                                            >
                                                <option required value="">
                                                    ...
                                                </option>
                                                <option value="married">Married</option>
                                                <option value="separated">Separated</option>
                                                <option value="unmarried">Unmarried</option>
                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please provide your marital status
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {this.props.application["coborrower"].maritalstatus === "married" &&
                                    <Row className="mt-2">
                                        <Col className="d-flex">
                                            <Form.Check
                                                label="1"
                                                name="group1"
                                                type="checkbox"
                                                id="checkbox1"
                                            >
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    onChange={this.onMarried}
                                                    checked={true === this.props.application.cohabiting}
                                                    isValid />
                                            </Form.Check>
                                            <Form.Label className="ml-2">I am married to this co-applicant.
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                }

                                <div >
                                    {displayCitizenship("coborrower")}
                                </div>
                            </>
                        )
                    }
                </Form>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Applicant)
