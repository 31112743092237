import React, { useState, useEffect, useRef } from "react"
import { Form, Col, Button, Row, InputGroup, FormControl } from "react-bootstrap"
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import HorizontalSlider from '../Common/HorizontalSlider'
import "../App.css"
import { tooltip, MonhtlyChange } from '../Common/Tooltip'
import * as com from "../Common.js"
import NumericalInput from "../NumericalInput"
import { withRouter } from "react-router-dom"
import { Select } from 'antd'
const DEFAULT_LOAN_TERM = 30 //year

let fixed = []
let arms = []
const Refinance = (props) => {
    window.drift.hide()
    let customerid = props.match.params["customerid"]

    const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM)
    const [propertyType, setPropertyType] = useState("singlefamilydetached")
    const [propertyValue, setPropertyValue] = useState(false)
    const [cashout, setCashout] = useState(0)
    const [occupancy, onOccupancy] = useState("principal")
    const [validated, setValidated] = useState(false)
    const [remaining, setRemaining] = useState(false)
    const [county, setCounty] = useState("")
    const [second, setSecond] = useState(0)
    const [heloc, setHeloc] = useState(0)
    const [units, setUnits] = useState(2)
    const [loading, setLoading] = useState(false)
    const [amortization, setAmortization] = useState("fixed")
    const [loans, setLoans] = useState(fixed)
    // eslint-disable-next-line 
    const [fico, setFico] = useState('A')
    const [index, setIndex] = useState(1)
    const [monthsToPay, setMonthsToPay] = useState('')
    const [interestRate, setInterestRate] = useState('')
    const [message, setMessage] = useState("")

    const form = useRef()



    let getRates = () => {

        let request = {
            loan_purpose: "RateTermRefi",
            loan_amount: parseInt(remaining),
            property_price: parseInt(propertyValue),
            property_type: com.getPropertyType(propertyType),
            state: "CALIFORNIA",
            county: county,

        }
        setLoading(true)


        fetch('/data/getprequal/' + customerid, {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        }).then(
            response => {
                if (response.status !== 200) {
                    setLoading(false)
                    return
                }
                response.json().then(pr => {
                    getProducts(pr.fees, pr.pmi, pr.zeitro_fee)

                })
            }

        ).catch((err) => {

            setLoading(false)

        })
    }
    let getFico = () => {
        switch (fico) {
            case 'A':
                return 770
            case 'B':
                return 750
            case 'C':
                return 730
            case 'D':
                return 710
            case 'E':
                return 690
            case 'F':
                return 670
            case 'G':
                return 650
            case 'H':
                return 630
            case 'I':
                return 600
            case 'J':
            default:
                return 570

        }
    }
    let getLtv = () => {

        let ltv = Math.ceil(getLoanAmount() / parseInt(propertyValue) * 100)
        let cltv = Math.ceil((getLoanAmount() + parseInt(second)) / parseInt(propertyValue) * 100)
        let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloc)) / parseInt(propertyValue) * 100)
        return [ltv, cltv, hcltv]
    }
    let getType = () => {
        let cout = parseInt(cashout)
        if (0 === cout)
            return "RateTermRefi"
        return "CashOutRefi"
    }
    let getLoanAmount = () => {
        return parseInt(cashout) + parseInt(remaining)
    }
    let getProducts = (fees, pmi, zeitro_fee) => {
        let _units = 1
        if (propertyType === "twotofourfamily")
            _units = units
        setMessage("")

        arms = []
        fixed = []
        let [ltv, cltv, hcltv] = getLtv()
        let body = {
            "state": "CALIFORNIA",
            "county": county,
            "purpose": getType(),
            "loanAmount": getLoanAmount(),
            units: _units,
            "LTV": ltv,
            "CLTV": cltv,
            "HCLTV": hcltv,
            "FICO": getFico(),
            "occupancy": com.getOccupancy(occupancy),
            "property": com.getPropertyType(propertyType),
            "point": zeitro_fee,
            "estimatedClosingCosts": fees,
            "pmi": pmi
        }
        fetch('/data/getrefinance/' + customerid, {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    setLoading(false)
                    setMessage("An internal error has occured")
                    return
                }
                response.json().then(pr => {
                    let products = pr.result

                    if (pr.status !== "Ok")
                        setMessage(<div className="mt-5"><h4>{pr.status}</h4>{pr.message}</div>)
                    if (products === undefined) {
                        setLoading(false)
                        return
                    }
                    products.forEach(product => {
                        let lender = product.Lender


                        product.ProductRatesCosts.forEach(pr => {
                            let commonproduct = pr.product
                            if (commonproduct.Term !== loanTerm)
                                return
                            let ausengine = pr.AUSEngine
                            pr.rateCosts.forEach(rate => {

                                let pdkt = {
                                    Lender: lender,
                                    ...commonproduct,
                                    Aus: ausengine,
                                    ...rate
                                }

                                if (rate.lockDay !== 30) {
                                    return
                                }
                                if (cashout !== 0)
                                    pdkt.refitype = "Cash Out Refinance"
                                else
                                    pdkt.refitype = "Rate/Term Refinance"
                                pdkt.loanAmount = getLoanAmount()
                                if (pdkt.Arm != null)
                                    arms.push(pdkt)
                                else {
                                    fixed.push(pdkt)
                                }
                            })
                        })
                    })
                    let minArmCC = 10000000
                    let maxArmCC = -1000000
                    arms.forEach(p => {
                        if (p.closing_cost > maxArmCC)
                            maxArmCC = p.closing_cost
                        if (p.closing_cost < minArmCC)
                            minArmCC = p.closing_cost

                    })
                    let minFixedCC = 10000000
                    let maxFixedCC = -1000000
                    fixed.forEach(p => {
                        if (p.closing_cost > maxFixedCC)
                            maxFixedCC = p.closing_cost
                        if (p.closing_cost < minFixedCC)
                            minFixedCC = p.closing_cost

                    })
                    let compareRate = (a, b) => {
                        if (a.base_rate !== b.base_rate)
                            return a.base_rate - b.base_rate

                        return (a.closing_cost - b.closing_cost) // take into account remaining credits
                    }

                    let cullRate = products => {
                        if (products.length === 0)
                            return products

                        let newproducts = []
                        newproducts.push(products[0])
                        let base_rate = products[0].base_rate
                        let closing_cost = products[0].closing_cost

                        for (let i = 1; i < products.length; i++) {
                            console.log(products[i])
                            if (products[i].base_rate !== base_rate) {
                                if (products[i].closing_cost < closing_cost) {
                                    newproducts.push(products[i])
                                    closing_cost = products[i].closing_cost
                                }
                                base_rate = products[i].base_rate
                            } else {
                            }
                        }
                        return newproducts
                    }
                    arms.sort(compareRate)
                    fixed.sort(compareRate)
                    arms = cullRate(arms)
                    fixed = cullRate(fixed)
                    ///getProducts(pr.fees, pr.pmi, pr.zeitro_fee) 
                    if (fixed.length !== 0) {
                        setLoans(fixed)
                    } else if (arms.length !== 0) {
                        setLoans(arms)
                    }
                    setLoading(false)
                })
            }

        ).catch((err) => {
            setLoading(false)

        })
    }
    const ref = useRef()
    useEffect(() => {
        window.drift.hide()
        const myObserver = new ResizeObserver((entries) => {
            if (entries.length > 0) {
                let height = entries[0].contentRect.height
                let width = entries[0].contentRect.width
                window.parent.postMessage(
                    { source: "tools", payload: ["100%", height + 39] },
                    "*"
                )
                console.log("width:" + width + ", height: " + height)
            }
        })
        if (ref.current) myObserver.observe(ref.current)
    })
    let showCounties = () => {
        let options = []
        let keys = Object.keys(com.counties)

        keys.forEach(x => {
            options.push(<option value={x}> {com.capitalize(x)}</option>)
        })
        return options
    }
    let handleSubmit = event => {
        event.preventDefault()
        event.stopPropagation()
        if (form.current.reportValidity() === false) {

            setValidated(true)
            return false
        }


        getRates()
        return false
    }
    let onFixed = () => {
        setLoans(fixed)
        setAmortization("fixed")
    }
    let onArm = () => {
        setLoans(arms)
        setAmortization("arm")
    }
    let changeBalance = (v) => {
        setIndex(v)
    }
    function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

        let monthlyrate = rate / 12.0
        let expn = Math.pow(1 + monthlyrate, termmonths)
        let payment = loansize * monthlyrate * expn / (expn - 1)
        payment = (Math.round(payment * 100) / 100)

        let accumulatedinterest = (loansize * monthlyrate - payment) *
            (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
            spanmonths * payment
        return accumulatedinterest
    }
    let getBreakPoint = (loan) => {
        let breakPoint = -1
        if (parseFloat(interestRate) <= loan.base_rate) {
            let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, parseInt(remaining), parseInt(monthsToPay), 1)
            let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, loan.loanAmount, loanTerm * 12, 1)
            return [accumulatedOld, accumulatedNew, breakPoint]
        }
        let oldm, newm
        for (let i = 1; i <= Math.min(loanTerm * 12, parseInt(monthsToPay)); i++) {
            let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, parseInt(remaining), parseInt(monthsToPay), i)
            let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, loan.loanAmount, loanTerm * 12, i)
            if (i === 1) {
                oldm = accumulatedOld
                newm = accumulatedNew
            }
            accumulatedNew += loan.closing_cost
            if (accumulatedNew < accumulatedOld) {
                breakPoint = i
                break
            }
        }
        return [oldm, newm, breakPoint]
    }

    let showCard = () => {
        if (loans.length === 0)
            return ""
        let i = Math.min(index, loans.length)
        let loan = loans[i - 1]

        let [oldm, newm, breakPoint] = getBreakPoint(loan)
        let improvement = Math.round(newm - oldm)
        let showSavings = () => {
            if (improvement === 0)
                return ""
            if (improvement > 0) {
                return <i className="ml-1 fas fa-arrow-up"></i>
            }
            return <i className="ml-1 fas fa-arrow-down"></i>
        }
        let mostyle = {}
        if (improvement > 0) {
            mostyle = { color: '#c33' }
        } else if (improvement < 0) {
            mostyle = { color: '#393' }
        }
        return <div>
            <div className="sectionquestion">{loan.refitype}</div>
            <div>Loan amount: {com.commaizeFloat(loan.loanAmount.toFixed(2))}</div>
            <div>Mortgage type: {com.capitalize(loan.Mtype.replace('_', ' '))}</div>
            {loan.Arm == null ?
                <div>{loan.Term} year fixed</div>
                : <div>{loan.Term} year ARM{loan.Arm.fixedperiod}/{loan.Arm.adjustmentperiod}</div>
            }
            <div>Rate: {loan.base_rate}%</div>
            <div>APR: {loan.APR}%</div>

            <div className="d-flex"> <span className="mr-1">Monthly payment:</span>  {MonhtlyChange(oldm, newm,

                <span style={mostyle} ><b>${com.commaizeFloat(loan.monthly_payment.toFixed(2))}</b>{showSavings()}</span>
            )} </div>
            {improvement < 0 ? <div>Monthly savings: ${com.commaizeFloat((-improvement).toFixed(2))}</div> : ""}
            {loan.closing_cost > 0 ?
                <div>Closing cost: ${com.commaizeFloat(loan.closing_cost.toFixed(2))}</div>
                : <div>
                    <div>Closing cost: $0</div>
                    <div>
                        Remaining credits: ${com.commaizeFloat((-loan.closing_cost).toFixed(2))}
                    </div>
                </div>
            }
            {loan.PMICost !== 0 ?
                <div>PMI premium: ${com.commaizeFloat(loan.PMICost.toFixed(2))}</div>
                : ""}
            <div>{breakPoint >= 0 ?

                <span style={mostyle}><b>Break even at {breakPoint} months</b></span>

                : <span style={mostyle}><b>No point to refinance</b></span>

            }</div>
        </div>
    }

    return (
        <div id="contain" ref={ref} style={{ color: "black" }} className="w-100 text-center ">
            <Row>
                <Col className="text-left" md>
                    <Form ref={form} noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Row>
                            <Col md>
                                <Form.Group className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Estimated Property Value",
                                            <div>
                                                {" "}
                                                Your property's current market value. Look it up on Zillow, or Redfin.
                                            </div>,
                                            "bottom",
                                            "p"
                                        )}
                                    </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        value={propertyValue}
                                        required

                                        onChange={e =>
                                            setPropertyValue(e.target.value)}
                                        min={0}
                                    />

                                </Form.Group>
                            </Col>

                            <Col xs >
                                <Form.Group className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Remaining Loan amount",
                                            <div>
                                                Remaining principal you need to pay on the mortgage
                                            </div>,
                                            "bottom",
                                            "p"
                                        )}
                                    </Form.Label>

                                    <NumericalInput
                                        size="sm"
                                        value={remaining}
                                        required
                                        type="number"
                                        onChange={e => setRemaining(e.target.value)}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md>
                                <Form.Group className=" p-0">
                                    {/* <div className="err"></div> */}
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Current Interest Rate",
                                            <div>
                                                The cost of borrowing money that’s typically expressed as an
                                                annual percentage of the loan.
                                            </div>,
                                            "bottom",
                                            "p"
                                        )}
                                    </Form.Label>
                                    <InputGroup className="p-0 m-0">
                                        <FormControl
                                            size="sm"
                                            type="number"
                                            step="0.001"
                                            defaultValue={interestRate}
                                            min="0"
                                            max="50"
                                            onChange={(e) => {
                                                setInterestRate(e.target.value)
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className=""
                                                style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                                size="sm"
                                            >%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col md>
                                <Form.Group className=" p-0 m-0 ">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip("Months left to pay", <div>We need this information to determine the breakeven point.</div>, "bottom", "p")}
                                    </Form.Label>
                                    <Form.Control className=""
                                        size="sm"
                                        type="number"
                                        min={1}
                                        max={360}
                                        defaultValue={monthsToPay}
                                        onChange={(e) => { setMonthsToPay(e.target.value) }}
                                        required
                                    >

                                    </Form.Control>

                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md>
                                <Form.Group className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "New Loan Term",
                                            <div>
                                                The amount of time you have to pay back the loan. Usually 15
                                                or 30 years for common loan types.
                                            </div>,
                                            "bottom",
                                            "p"
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setLoanTerm(parseInt(e.target.value))
                                        }}
                                        required
                                    >

                                        <option value={30} >30</option>
                                        <option value={25}>25</option>
                                        <option value={20}>20</option>
                                        <option value={15}>15</option>
                                        <option value={10}>10</option>
                                    </Form.Control>

                                </Form.Group>
                            </Col>

                            <Col md>

                                <Form.Group className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip("County", <div>We need the county information for determining the FHA, super conformind and high cost mortgage eligibility.</div>, "bottom", "p")}
                                    </Form.Label>
                                    <div>
                                        <Select onChange={e => setCounty(e)} value={propertyType} style={{ width: '100%', height: 44 }}>


                                            {showCounties()}
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => { setCounty(e.target.value) }}
                                        required
                                    >
                                        <option value="">...</option>
                                        {showCounties()}
                                    </Form.Control> */}

                                </Form.Group>
                            </Col>
                        </Form.Row>



                        <Form.Row>
                            <Col md>
                                <Form.Group className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Credit Score",
                                            <div>
                                                a number lenders use to help them decide how likely it
                                                is that they will be repaid on time if they give a
                                                person a loan or a credit card.
                                            </div>,
                                            "auto",
                                            ""
                                        )}
                                    </Form.Label>

                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => { }}
                                        required
                                    >
                                        <option value="A">Perfect(760+)</option>
                                        <option value="B">Excellent(740-759)</option>
                                        <option value="C">Very Good(720-739)</option>
                                        <option value="D">Good(700-719)</option>
                                        <option value="E">Above Average(680-699)</option>
                                        <option value="F">Average(660-679)</option>
                                        <option value="G">Fair(640-659)</option>
                                        <option value="H">Need Improvement(620-639)</option>
                                        <option value="I">Poor(580-619)</option>
                                        <option value="J">Very Poor(0-580)</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group controlId="propertytype" className="text-left " >
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip("Property Type", <div>
                                            <ul>
                                                <li>
                                                    <b>Detached</b> is when the property sits on its own lot without sharing any walls with another house or building. <br />
                                                </li><li className="mb-3">
                                                    <b>Attached</b> housing, on the other hand, shares walls on both sides with another home.
                                                </li>
                                                <li>
                                                    <b>Single Family Residence:</b>  A single dwelling unit that has no shared property and is built on its own parcel of land.
                                                </li><li>
                                                    <b>Planned Unit Development (PUD):</b>A community of homes comprised predominantly of single-family residences that could be attached or detached and it is surrounded by common areas jointly owned and managed by a homeowners association (HOA).
                                                </li><li>
                                                    <b>Condominium</b>:	Building structure divided into several units that are each individually and privately owned, surrounded by common areas jointly owned and managed by a homeowners association (HOA).
                                                </li></ul>
                                        </div>, "auto", "")}
                                    </Form.Label >
                                    <div>
                                        <Select onChange={e => setPropertyType(e)} value={propertyType} style={{ width: '100%', height: 44 }}>


                                            <option value="singlefamilydetached">Single Family Home, Detached</option>
                                            <option value="singlefamilyattached">Single Family Home, Attached</option>
                                            <option value="condominiumdetached">Condominium, Detached</option>
                                            <option value="condominium">Condominium, Attached</option>
                                            <option value="pudattached">Planned Unit Development, attached</option>
                                            <option value="puddetached">Planned Unit Development, detached</option>
                                            <option value="twotofourfamily">2 to 4 Unit Multifamily</option>
                                            <option value="manufacturedhome">Manufactured Home</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control name="propertytype" as="select" size="sm"
                                        type="text" required
                                        value={propertyType}
                                        onChange={e => setPropertyType(e.target.value)} >

                                        <option value="singlefamilydetached">Single Family Home, Detached</option>
                                        <option value="singlefamilyattached">Single Family Home, Attached</option>
                                        <option value="condominiumdetached">Condominium, Detached</option>
                                        <option value="condominium">Condominium, Attached</option>
                                        <option value="pudattached">Planned Unit Development, attached</option>
                                        <option value="puddetached">Planned Unit Development, detached</option>
                                        <option value="twotofourfamily">2 to 4 Unit Multifamily</option>
                                        <option value="manufacturedhome">Manufactured Home</option>
                                    </Form.Control>
                                </Form.Group> */}
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {propertyType === "twotofourfamily" ?
                            <Form.Row>
                                <Col md>
                                    <Form.Group controlId="propertytype" className="text-left " >
                                        {tooltip("Number of Units", <div>
                                            Select how many units in a multiunit house
                                        </div>, "auto", "")}
                                        <div>
                                            <Select onChange={e => setUnits(e)} value={units} style={{ width: '100%', height: 44 }}>


                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </Select>

                                        </div>
                                        {/* <Form.Control name="propertytype" as="select" size="sm"
                                            type="text" required
                                            value={units}
                                            onChange={e => setUnits(e.target.value)} >

                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>

                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Form.Row>
                            : ""}
                        <Form.Row>
                            <Col md>
                                <Form.Group id="cashout" className=" ">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Cash Out",
                                            <div>
                                                If you specify non-zero number, this becomes a cash out refinance loan. Your rates may be higher than for a straight rate/term refinance
                                            </div>,
                                            "auto",
                                            ""
                                        )}
                                    </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        value={cashout}
                                        required
                                        type="number"
                                        onChange={
                                            e => setCashout(e.target.value)}
                                        min={0}
                                    />

                                </Form.Group>
                            </Col>
                            <Col md  >
                                <Form.Group controlId="occupancy" className="text-left " >
                                    <Form.Label className="text-left " style={{ marginBottom: "0" }}>{tooltip("Occupancy",
                                        <div>
                                            There are three types of occupancy:
                                            <ul>
                                                <li>
                                                    Principal Residence: The subject property will be a Primary Residence inhabited by the borrower. This occupancy type represents the least amount of risk for the lender, and the morgage rates are the lowest.
                                                </li><li>
                                                    Second Home: The subject property will be occupied by the borrower for some portion of the year. Typically, this would be a used as a Summer or Vacation Home. This occupancy type represents a higher risk for the lender.
                                                </li><li>
                                                    Investment: An investment property is owned but not occupied by the borrower. These properties are usually rented out to others by the borrower or purchased for use by child attending university in another state. This occupancy type represents the highest level of risk.
                                                </li></ul>
                                            The interest rate on your mortgage is partially based on the level of risk associated with each occupancy type. This means that your interest rate will be lowest for Principal Residences and highest for Investment Properties.
                                        </div>,
                                        "auto", "")}</Form.Label>
                                    <div>
                                        <Select onChange={e => onOccupancy(e)} value={occupancy} style={{ width: '100%', height: 44 }}>


                                            <option value="principal">Primary Residence</option>
                                            <option value="secondhome">Second Home</option>
                                            <option value="investment">Investment</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control name="occupancy" as="select" size="sm"
                                        defaultValue={occupancy}
                                        onChange={e => { onOccupancy(e.target.value) }} >
                                        <option value="principal">Primary Residence</option>
                                        <option value="secondhome">Second Home</option>
                                        <option value="investment">Investment</option>
                                    </Form.Control> */}

                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md>
                                <Form.Group id="secondmo" >
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "Second Mortgage",
                                            <div>
                                                Keep zero if you don't have second mortgage. Otherwise enter the balance of your second mortgage.
                                            </div>,
                                            "auto",
                                            ""
                                        )}
                                    </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        value={second}
                                        required

                                        onChange={e =>
                                            setSecond(e.target.value)}
                                        min={0}
                                    />

                                </Form.Group>
                            </Col>

                            <Col md>
                                <Form.Group id="heloc" className="">
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        {tooltip(
                                            "HELOC",
                                            <div>
                                                Size on your home equity line of credit. Keep zero if no HELOC.
                                            </div>,
                                            "auto",
                                            ""
                                        )}
                                    </Form.Label>

                                    <NumericalInput
                                        size="sm"
                                        value={heloc}
                                        required
                                        type="number"
                                        onChange={e => setHeloc(e.target.value)}

                                        min={0}
                                    />


                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col xs className="text-center w-100">
                                <Button type="submit" variant="primary" className="mb-3">Click to get rates</Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </Col>
                <Col className="text-center" md="auto">
                    {loading ?
                        <div clasName="text-center w100 justify-content-center  align-content-center">
                            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">

                                <img alt="spinner" className="loader mt-5" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
                            </div>
                        </div> :
                        <div>
                            {
                                arms.length !== 0 || fixed.length !== 0 ?
                                    <div className="text-center mt-1 mb-3">
                                        <ToggleButtonGroup id="purpose" type="radio" name="purpose" defaultValue={amortization}>
                                            {fixed.length !== 0 ?
                                                <ToggleButton
                                                    onClick={onFixed}
                                                    value="fixed"
                                                    variant="info"
                                                >Fixed</ToggleButton> : ""}
                                            {arms.length !== 0 ?
                                                <ToggleButton
                                                    onClick={onArm}
                                                    value="arm"
                                                    variant="info"
                                                >ARM</ToggleButton> : ""}


                                        </ToggleButtonGroup>
                                        <div style={{ border: '1px solid #dddddd', minWidth: '300px' }} className="p-3  drop-shadow">
                                            <Row style={{ fontSize: '0.65em' }} className="p-0 m-0">
                                                <Col xs className="text-left pl-0">
                                                    Lowest payment
                                                </Col>
                                                <Col xs className="text-right pr-0">
                                                    Lowest closing cost
                                                </Col>
                                            </Row>
                                            <HorizontalSlider id="yearstohold" onUpdate={changeBalance} onChange={changeBalance} step={1} domain={[1, loans.length]} values={[Math.min(index, loans.length)]} />

                                            {showCard()}
                                        </div>

                                    </div>
                                    : <div className="text-wrap w-100">{message}</div>
                            }

                        </div>}
                </Col>
            </Row>

        </div>
    )
}
export default withRouter(Refinance)
