import React, { Component } from "react"
import "../App.css"

import StateSelector from "../StateSelector"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import NumericalInput from "../NumericalInput"
import InputGroup from "react-bootstrap/InputGroup"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import * as com from "../Common.js"
import DateOfBirth from "../DateOfBirth"
import { connect } from "react-redux"
import * as act from "../Store/actions"
import * as st from "../State"
import { Tips, tooltip } from "../Common/Tooltip"
import AddressAutocomplete from "../Common/AddressAutocomplete"
import Autocomplete from "../Autocomplete"
import { titles } from "../occupations.js"
import throttle from "lodash.throttle"
import { Select, DatePicker } from "antd"
import dayjs from 'dayjs'
const statesDate = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}
const mapStateToPropsMore2 = (state) => {
    let s = {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
    }

    return s
}

const mapDispatchToPropsMore2 = (dispatch) => ({
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },

    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    changeBorrowerCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    updateBorrowerAttribute: (who, value, verb) => {
        dispatch(act.UpdateBorrowerAttribute(who, value, verb))
    },

    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },

    setBorrowerHasAdditionalIncome: (value, who) => {
        dispatch(act.SetBorrowerHasAdditionalIncome(value, who))
    },
    updateIncomeMonthly: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb))
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(t, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },

    updateBorrowerAdditionalIncome: (event, who, verb, n) => {
        dispatch(
            act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
        )
    },
    changeIncomeCheck: (checked, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(checked, who, verb))
    },
    changeCurrentOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(!event.target.checked, who, verb))
    },
    changeCurrentOccupation: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (phone, who, verb) => {
        dispatch(act.ChangeOccupationInfo(phone, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event.target.value, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event.target.value, who))
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.value, who, verb, index)
        )
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.checked, who, verb, index)
        )
    },

    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    changeBorrowerOtherOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationIncome(t, i, who, verb))
    },
    changeMainProperty: (t, verb) => {
        dispatch(act.ChangeMainProperty(t, verb))
    },
})

export class OtherInfoPersonal2 extends Component {
    constructor(props) {
        super(props)
        this.refages = React.createRef()
        this.refdependents = React.createRef()

        this.state = {
            store: this.props.application,
            areagesrequired: {},
        }
        this.isValidated = this.isValidated.bind(this)

        if ("" !== this.props.application[this.props.who].numberofdependents)
            this.state.areagesrequired = { required: true }
        this.hideMailingAddress()

        let email = this.props.application.borrower.email
        if (email === "") {
            this.getEmail()
        }
        this.dob = React.createRef()
        this.relref = React.createRef()

        if (this.props.application.status === "interviewstarted") {
            this.props.updateApplicationAttribute("productselected", "status")
        }

        this.crefsfrom = []
        if (this.props.application[this.props.who].otheroccupations !== null) {
            for (
                let i = 0;
                i < this.props.application[this.props.who].otheroccupations.length;
                i++
            ) {
                this.crefsfrom.push(React.createRef())
            }
        }
    }


    getEmail = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/auth/getprofile", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    window.document.dispatchEvent(new Event("reauthenticate"), "")
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event("reauthenticate"), "")
                    } else {
                        js.Method = parseInt(js.Method)
                        this.props.changeBorrowerInfo(
                            { target: { value: js.Email } },
                            "borrower",
                            "email"
                        )
                    }
                })
            })
            .catch((err) => {
                console.log("Fetch Error :", err)
            })
    };

    processDependents = (element) => {
        let val = this.refdependents.current.value
        let num = parseInt(val)
        if (val === "" || num === 0) {
            this.setState({ areagesrequired: {} })
            this.refages.current.value = ""
            this.props.changeBorrowerInfo(
                { target: { value: "" } },
                this.props.who,
                "dependentsages"
            )
            this.refages.current.setCustomValidity("")
        } else {
            this.setState({ areagesrequired: { required: true } })
            // create a regexp
            let seed = "^[1-9][0-9]*"
            for (let i = 0; i < num - 1; i++) {
                seed += ",[1-9][0-9]*"
            }
            seed += "$"
            let r = new RegExp(seed)
            let res = element.value.match(r)
            if (null === res)
                element.setCustomValidity(
                    "Please fill out comma-separated list of ages"
                )
            else element.setCustomValidity("")
        }
    };
    componentDidMount() {
        this.processDependents(this.refages.current)
    }
    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }
    updateMail = (event) => {
        console.log(event)
        com.touch()
        let val = event.target.value === "true"
        this.props.changeBorrowerCheck(
            { target: { checked: val } },
            this.props.who,
            "samemailingaddress"
        )
    };

    updateDependents = (e) => {
        com.touch()
        let ret = this.props.changeBorrowerInfo(
            e,
            this.props.who,
            "numberofdependents"
        )
        this.processDependents(this.refages.current)
        return ret
    };
    updateAges = (e) => {
        com.touch()
        let ret = this.props.changeBorrowerInfo(
            e,
            this.props.who,
            "dependentsages"
        )
        this.validateAges(e)
        return ret
    };
    validateAges = (event) => {
        com.touch()
        this.processDependents(event.target)
    };
    hideMailingAddress = () => {
        return false
    };
    onMailingAddressCompletion = (address) => {
        let num = com.getSubaddress(address, "street_number", "long_name")
        let city = com.getSubaddress(address, "locality", "long_name")
        let state = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "short_name"
        )
        let zip = com.getSubaddress(address, "postal_code", "long_name")
        let zips = com.getSubaddress(address, "postal_code_suffix", "short_name")
        if (zips !== "") zip = zip + "-" + zips

        let short = com.getSubaddress(address, "route", "short_name")
        let stradd = num + " " + short
        this.props.changeBorrowerInfo(
            { target: { value: stradd } },
            this.props.who,
            "mailingaddressstreet"
        )

        this.props.changeBorrowerInfo(
            { target: { value: city } },
            this.props.who,
            "mailingaddresscity"
        )
        this.props.changeBorrowerInfo(
            { target: { value: state } },
            this.props.who,
            "mailingaddressstate"
        )
        this.props.changeBorrowerInfo(
            { target: { value: zip } },
            this.props.who,
            "mailingaddresszip"
        )
    };
    handleSubmit = (event) => {
        return true
    };
    onRef = (ref) => {
        this.dob = ref
    };
    renderOccupation = () => {
        let changeOccupationDate = (who, verb) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                this.props.changeOccupationInfo(
                    { target: { value: fixed } },
                    who,
                    verb
                )
            }
        }
        let changeOccupationInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeOccupationInfo(event, who, verb)
            }
        }
        let changeCurentOccupationPhone = (who, verb) => {
            return (event) => {
                com.touch()
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changePhoneInfo(phone, who, verb)
            }
        }
        let employment = this.props.application[this.props.who].occupation
        let index = null

        return (
            <div className="incard pt-0">
                <Row className="incardRow">
                    <Col className="mt-4" >
                        <Form.Group
                            controlId={"CEmployer1" + index}
                            className="text-left"
                        >
                            <Form.Label className="text-left text-nowrap">
                                Employer
                            </Form.Label>
                            <Form.Control
                                isValid={false}
                                size="sm"
                                required
                                name={"cemployer" + index}
                                type="text"
                                defaultValue={employment.employername}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col className="mt-4"  >
                        <Form.Group
                            controlId={"cfrom" + index}
                            className="text-left"
                        >
                            <Form.Label className="text-left text-nowrap">
                                Starting date
                            </Form.Label>
                            <div>
                                <DatePicker
                                    name="dateofbirth"
                                    style={{ width: '100%' }}
                                    onRef={(ref) => {
                                        this.expiration = ref
                                    }}
                                    required
                                    size="sm"
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    value={employment.from ? dayjs(employment.from, "MM/DD/YYYY") : ''}
                                    onChange={e => {
                                        this.props.changeOccupationInfo(
                                            { target: { value: dayjs(e).format('MM/DD/YYYY') } },
                                            this.props.who,
                                            'from'
                                        )

                                    }}
                                />
                            </div>
                            {/* <div style={{ opacity: 0 }}>
                                <DateOfBirth
                                    size="sm"
                                    isValid={false}
                                    //ref={this.crefsfrom[index]} ?
                                    onRef={(ref) => {
                                        this.crefsfrom[index] = ref
                                    }}
                                    //reff={this.crefsfrom[index]}
                                    required
                                    placeholder="MM/DD/YYYY"
                                    name={"ocfrom" + index}
                                    type="text"
                                    value={employment.from}
                                    onChange={changeOccupationDate(this.props.who, "from")}
                                    negativefeedback="Provide valid start"
                                    pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                />
                            </div> */}
                        </Form.Group>
                    </Col>
                    <Col className="mt-4" >
                        <Form.Group
                            controlId={"cjobphone" + index}
                            className="text-left"
                        >
                            <Form.Label className="text-left text-nowrap"> HR phone number <Tips
                                content={<div>
                                    Specify the HR/Personnel or hiring manager phone
                                    number to verify employment.
                                </div>}
                            />
                            </Form.Label>
                            <Form.Control
                                size="sm"
                                isValid={false}
                                required
                                name={"cjobphone" + index}
                                type="text"
                                placeholder="(###)-###-####"
                                pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                value={employment.phone}
                                onChange={changeCurentOccupationPhone(
                                    this.props.who,
                                    "phone"
                                )}
                            />

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12} className="mt-4" >
                        <AddressAutocomplete
                            id={"CEmployerAddr1" + index}
                            label="Address"
                            required={true}
                            name={"cemployeraddr" + index}
                            defaultValue={employment.employeraddress}
                            onChange={changeOccupationInfo(
                                this.props.who,
                                "employeraddress"
                            )}
                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                            placeholder="Street Address, No P.O.Box accepted"
                            goodfeedback=""
                            badfeedback="Please provide address."
                        />
                    </Col>
                </Row>
            </div>
        )
    };

    renderOtherOccupation = (o, index) => {
        let key = o.key
        index = parseInt(index)

        let changeOccupationDate = (who, verb) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                let from = this.crefsfrom[index].current.value

                let expr =
                    /^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$/gi

                if (from && from.match(expr)) {
                    let f = new Date(from)
                    this.crefsfrom[index].current.setCustomValidity("")

                    let old = new Date("01/01/1900")
                    let now = new Date()

                    if (f < old) {
                        this.refsfrom[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity(
                            "Too old to be true"
                        )
                    }
                    if (f >= now) {
                        this.refsto[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity(
                            "Can't be in the future"
                        )
                    }
                }
                return this.props.changeBorrowerOtherOccupationValue(
                    fixed,
                    index,
                    who,
                    verb
                )
            }
        }

        let changeCurentOccupationPhone = (who, verb) => {
            return (event) => {
                com.touch()
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changeBorrowerOtherOccupationValue(
                    phone,
                    index,
                    who,
                    verb
                )
            }
        }
        let changeOccupationInfo = (who, verb) => {
            return (e) => {
                com.touch()
                this.props.changeBorrowerOtherOccupationValue(
                    e.target.value,
                    index,
                    who,
                    verb
                )
            }
        }
        let employment =
            this.props.application[this.props.who].otheroccupations[index]

        return (
            <div className="incard m-0 mb-3 px-0">
                <Row>
                    <Col>
                        <Form.Row>
                            <Col>

                                <Row className="incardRow">
                                    <Col className="ml-0 pl-0 incardCol" xs={12} md={4}>
                                        <Form.Group
                                            controlId={"CEmployer1" + index}
                                            className="text-left"
                                        >
                                            <Form.Label className="text-left text-nowrap">
                                                Employer:
                                            </Form.Label>
                                            <Form.Control
                                                isValid={false}
                                                size="sm"
                                                required
                                                name={"cemployer" + index}
                                                type="text"
                                                defaultValue={employment.employername}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} className="incardField-mobileAdjuster">
                                        <Form.Group
                                            controlId={"cfrom" + index}
                                            className="text-left"
                                        >
                                            <Form.Label className="text-left text-nowrap">
                                                Starting date
                                            </Form.Label>
                                            {/* <div>
                                                <DatePicker
                                                    name="dateofbirth"
                                                    style={{ width: '100%' }}
                                                    onRef={(ref) => {
                                                        this.expiration = ref
                                                    }}
                                                    required
                                                    size="sm"
                                                    placeholder="MM/DD/YYYY"
                                                    format="MM/DD/YYYY"
                                                    value={employment.from ? dayjs(employment.from, "MM/DD/YYYY") : ''}
                                                    onChange={e => {

                                                        this.props.changeOccupationInfo(
                                                            { target: { value: dayjs(e).format('MM/DD/YYYY') } },
                                                            this.props.who,
                                                            'from'
                                                        )

                                                    }}
                                                />
                                            </div> */}
                                            <div style={{ opacity: 0 }}>

                                                <DateOfBirth
                                                    size="sm"
                                                    isValid={false}
                                                    //ref={this.crefsfrom[index]} ?
                                                    onRef={(ref) => {
                                                        this.crefsfrom[index] = ref
                                                    }}
                                                    //reff={this.crefsfrom[index]}
                                                    required
                                                    placeholder="MM/DD/YYYY"
                                                    name={"ocfrom" + index}
                                                    type="text"
                                                    value={employment.from}
                                                    onChange={changeOccupationDate(this.props.who, "from")}
                                                    negativefeedback="Provide valid start"
                                                    pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} className="incardField-mobileAdjuster">
                                        <Form.Group
                                            controlId={"cjobphone" + index}
                                            className="text-left"
                                        >
                                            <Form.Label className="text-left text-nowrap">HR phone number <Tips
                                                content={<div>
                                                    Specify the HR/Personnel or hiring manager phone
                                                    number to verify employment.
                                                </div>}
                                            />
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                isValid={false}
                                                required
                                                name={"cjobphone" + index}
                                                type="text"
                                                placeholder="(###)-###-####"
                                                pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                                value={employment.phone}
                                                onChange={changeCurentOccupationPhone(
                                                    this.props.who,
                                                    "phone"
                                                )}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col className="ml-0 pl-0 mt-4" xs={12} md={12}>
                                        <AddressAutocomplete
                                            id={"CEmployerAddr1" + index}
                                            label="Address:"
                                            required={true}
                                            name={"cemployeraddr" + index}
                                            defaultValue={employment.employeraddress}
                                            onChange={changeOccupationInfo(
                                                this.props.who,
                                                "employeraddress"
                                            )}
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            placeholder="Street Address, No P.O.Box accepted"
                                            goodfeedback=""
                                            badfeedback="Please provide address."
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
            </div>
        )
    };

    render() {

        let changeBorrowerInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
            }
        }
        let changeRelationshipState = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.updateBorrowerAttribute(who, event.target.value, verb)
            }
        }
        let relationshipDescriptionChange = (event) => {
            com.touch()

            this.props.updateBorrowerAttribute(
                this.props.who,
                event.target.value,
                "relationship"
            )
        }
        let relationshiSelect = (event) => {
            com.touch()
            this.props.updateBorrowerAttribute(
                this.props.who,
                event,
                "relationshiptype"
            )
            if (event === "other") {
                this.props.updateBorrowerAttribute(this.props.who, "", "relationship")
                this.relref.current.focus()
            } else
                this.props.updateBorrowerAttribute(
                    this.props.who,
                    com.relationships[event],
                    "relationship"
                )
        }
        let onHasPartner = (e) => {
            this.props.updateBorrowerAttribute(
                this.props.who,
                e.target.value === "true",
                "haspartner"
            )
        }
        return (
            <div className="text-left p-4">
                {this.props.who === com.borrower ? (
                    <div>
                        <div className="prequalInterviewTitle">
                            Please tell us more about{" "}
                            {this.props.application.borrower.firstname}{" "}
                            {this.props.application.borrower.lastname}
                        </div>
                        <div className="subheading mt-1">
                            We need those information to fill in Uniform Residential Loan
                            Application (Form 1003)
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="prequalInterviewTitle">
                            Please tell us more about{" "}
                            {this.props.application.coborrower.firstname}{" "}
                            {this.props.application.coborrower.lastname}
                        </div>
                        <div className="subheading mt-1">
                            We need those information to fill in Uniform Residential Loan
                            Application (Form 1003)
                        </div>
                    </div>
                )}

                <div className="text-left mt-4  ">
                    <div className="" hidden={this.hideMailingAddress()}>
                        <Form.Row>
                            <Col>
                                <Form.Label>
                                    Is your mailing address same as your residence?
                                </Form.Label>
                                <div className="d-flex mt-1">
                                    <div className="mr-3 ">

                                        <input
                                            onChange={this.updateMail}
                                            value={true}
                                            defaultChecked={
                                                this.props.application[this.props.who]
                                                    .samemailingaddress
                                            }
                                            type="radio"
                                            id="mailsame"
                                            name="mailingsame"
                                        ></input>{" "}
                                        <label className="divlink" htmlFor="mailsame">
                                            Yes
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={this.updateMail}
                                            value={false}
                                            defaultChecked={
                                                !this.props.application[this.props.who]
                                                    .samemailingaddress
                                            }
                                            type="radio"
                                            id="mailnotsame"
                                            name="mailingsame"
                                        ></input>{" "}
                                        <label className="divlink" htmlFor="mailnotsame">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        {this.props.application[this.props.who].samemailingaddress ? (
                            ""
                        ) : (
                            <div className="mt-4" key="qrwerqwe">
                                <Form.Row >
                                    <Col>
                                        <AddressAutocomplete
                                            size="sm"
                                            required
                                            id="mailaddessline"
                                            name="mailaddessline"
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            defaultValue={
                                                this.props.application[this.props.who]
                                                    .mailingaddressstreet
                                            }
                                            onChange={changeBorrowerInfo(
                                                this.props.who,
                                                "mailingaddressstreet"
                                            )}
                                            goodfeedback=""
                                            badfeedback="Please provide your mailing address."
                                            onCompletion={this.onMailingAddressCompletion}
                                        />

                                    </Col>
                                </Form.Row>
                                <Form.Row className="financesTypemobile">
                                    <Col className="mt-4">
                                        <Form.Group controlId="mailingState" className="text-left">
                                            <Form.Label className="text-left">State</Form.Label>
                                            <div>
                                                <Select
                                                    style={{ width: "100%", height: 44 }}
                                                    value={
                                                        this.props.application[this.props.who]
                                                            .mailingaddressstate
                                                    }
                                                    onChange={e =>
                                                        this.props.changeBorrowerInfo(
                                                            { target: { value: e } },
                                                            this.props.who,
                                                            "mailingaddressstate"
                                                        )
                                                    }
                                                >

                                                    {Object.keys(statesDate).map(st =>
                                                    (
                                                        <Select.Option value={st}>{statesDate[st]}</Select.Option>

                                                    )
                                                    )}

                                                </Select>
                                            </div>

                                            <Form.Control.Feedback type="invalid">
                                                Please provide state.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mt-4">
                                        <Form.Group controlId="mailingCity" className="text-left">
                                            <Form.Label className="text-left">City</Form.Label>
                                            <Form.Control
                                                isValid={false}
                                                size="sm"
                                                required
                                                name="mailingcity"
                                                type="text"
                                                autoComplete="locality"
                                                value={
                                                    this.props.application[this.props.who]
                                                        .mailingaddresscity
                                                }
                                                onChange={changeBorrowerInfo(
                                                    this.props.who,
                                                    "mailingaddresscity"
                                                )}
                                                pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide city.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col className="mt-4">
                                        <Form.Group controlId="mailingZip" className="text-left">
                                            <Form.Label className="text-left">ZIP code</Form.Label>
                                            <Form.Control
                                                isValid={false}
                                                size="sm"
                                                required
                                                name="mailingzip"
                                                type="text"
                                                value={
                                                    this.props.application[this.props.who]
                                                        .mailingaddresszip
                                                }
                                                onChange={changeBorrowerInfo(
                                                    this.props.who,
                                                    "mailingaddresszip"
                                                )}
                                                pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid zip code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </div>
                        )}
                    </div>
                    <div >
                        <Form.Row className="mt-4">

                            <Col xs={12}>
                                <Form.Group controlId="YeardOfSchool" className="text-left">
                                    <Form.Label className="text-left">Years of school(Optional) <Tips
                                        content={<div>
                                            Years of school should include all of your education
                                            starting with the elementary school.
                                        </div>}
                                    />
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        name="YeardOfSchool"
                                        type="number"
                                        defaultValue={
                                            this.props.application[this.props.who].yearsofschool
                                        }
                                        onChange={changeBorrowerInfo(
                                            this.props.who,
                                            "yearsofschool"
                                        )}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                            {/*
                              <Col>
                              <Form.Group controlId="StateId" className="text-left" >
                                  <Form.Label className="text-left  text-nowrap" >Driver License/State ID:</Form.Label>
                                  <Form.Control isValid={false} size="sm"
                                      required
                                      ref={this.stateid}
                                      name="stateid"
                                      type="text"
                                      defaultValue={this.props.application[this.props.who].stateid}
                                      onChange={changeStateID(this.props.who, "stateid")}
  
                                      pattern="^[\w]+$"
                                  />
                                  
                                  <Form.Control.Feedback type="invalid" >
                                      Please provide a valid state ID.
                                  </Form.Control.Feedback>
                              </Form.Group>
                              </Col>
                              */}
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Col xs={12}>
                                <Form.Group controlId="numofdependents" className="text-left">
                                    <Form.Label className="text-left text-nowrap">{this.props.who === "borrower"
                                        ? "# of dependents(Optional)"
                                        : "# of dependents not listed by borrower"} <Tips
                                            content={<div>
                                                Dependents are defined in the same way as on your tax
                                                return. Coborrower should not list the same dependents
                                                twice. <br />
                                                Keep empty if no dependents
                                            </div>}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        name="numofdependents"
                                        type="number"
                                        defaultValue={
                                            this.props.application[this.props.who].numberofdependents
                                        }
                                        min={0}
                                        ref={this.refdependents}
                                        onChange={this.updateDependents}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a number of dependents.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="mt-4">
                                <Form.Group controlId="agesofdependents" className="text-left">
                                    <Form.Label className="text-left">Their ages(Optional) <Tips
                                        content={<div>
                                            Comma separated list of ages. Keep empty if no
                                            dependents.
                                        </div>}
                                    />

                                    </Form.Label>

                                    <Form.Control

                                        ref={this.refages}

                                    />
                                    {/* <Form.Control
                                        isValid={false}
                                        size="sm"
                                        {...this.state.areagesrequired}
                                        name="agesofdependents"
                                        type="text"
                                        ref={this.refages}
                                        defaultValue={
                                            this.props.application[this.props.who].dependentsages
                                        }
                                        placeholder="#,##,##,..."
                                        onChange={this.updateAges}
                                        onBlur={this.validateAges}
                                    /> */}
                                    {/* <Form.Control.Feedback type="invalid">
                                        Please provide comma-separated ages.
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                            {/* <Col xs={12}>
                <Form.Group controlId="MaritalStatus" className="text-left">
                  <Form.Label className="text-left">Marital status<Tips 
                    content={<div>
                      If you are in a domestic partnership, single or
                      divorced, select Unmarried
                    </div>}
                  />
                  
                  </Form.Label>
                  <Form.Control
                    required
                    name="maritalstatus"
                    as="select"
                    size="sm"
                    defaultValue={
                      this.props.application[this.props.who].maritalstatus
                    }
                    onChange={changeBorrowerInfo(
                      this.props.who,
                      "maritalstatus"
                    )}
                  >
                    <option required value="">
                      ...
                    </option>
                    <option value="married">Married</option>
                    <option value="separated">Separated</option>
                    <option value="unmarried">Unmarried</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide your marital status
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
                        </Form.Row>
                        {this.props.application[this.props.who].maritalstatus ===
                            "unmarried" ? (
                            <>
                                <>
                                    <Form.Row className="mt-4">
                                        <Col xs={12}>
                                            Is there a person who's not your legal spouse but has
                                            similar property rights?
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mt-4">
                                        <Col>
                                            <Form.Group>
                                                <fieldset>
                                                    <div className="d-flex ">
                                                        <div className="mr-3 ">
                                                            <input
                                                                required
                                                                onChange={onHasPartner}
                                                                value="false"
                                                                checked={
                                                                    false ===
                                                                    this.props.application[this.props.who]
                                                                        .haspartner
                                                                }
                                                                type="radio"
                                                                id="haspartnernot"
                                                                name="haspartner"
                                                            ></input>
                                                            <label
                                                                className="zeitro-radio divlink"
                                                                htmlFor="haspartnernot"
                                                            >
                                                                No, there isn't
                                                            </label>
                                                        </div>
                                                        <div className="mr-3 ">
                                                            <input
                                                                required
                                                                onChange={onHasPartner}
                                                                value="true"
                                                                checked={
                                                                    true ===
                                                                    this.props.application[this.props.who]
                                                                        .haspartner
                                                                }
                                                                type="radio"
                                                                id="haspartner"
                                                                name="haspartner"
                                                            ></input>
                                                            <label
                                                                className="zeitro-radio divlink"
                                                                htmlFor="haspartner"
                                                            >
                                                                Yes, there is
                                                            </label>
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <Form.Control.Feedback type="invalid">
                                                    Please specify if you're in a relationship similar to
                                                    legal marriage.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </>
                                {this.props.application[this.props.who].haspartner === true ? (
                                    <Form.Row className="mt-4">
                                        <Col xs={12}>
                                            <Form.Group controlId="unmarriedtype">
                                                <Form.Label className="text-left">
                                                    Relationship Description:
                                                </Form.Label>
                                                <div>

                                                    <Select
                                                        value={
                                                            this.props.application[this.props.who]
                                                                .relationship
                                                        }
                                                        onChange={(e) => relationshiSelect(e)}
                                                    >
                                                        {Object.keys(com.relationships).map((key) => {
                                                            return (
                                                                <Select.Option
                                                                    value={key}
                                                                >
                                                                    {com.relationships[key]}
                                                                </Select.Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                {/* <InputGroup>
                                                    <Form.Control
                                                        required
                                                        size="sm"
                                                        ref={this.relref}
                                                        type="text"
                                                        value={
                                                            this.props.application[this.props.who]
                                                                .relationship
                                                        }
                                                        readOnly={
                                                            this.props.application[this.props.who]
                                                                .relationshiptype !== "other"
                                                        }
                                                        onChange={relationshipDescriptionChange}
                                                        pattern="^.+$"
                                                    />
                                                    <DropdownButton
                                                        as={InputGroup.Append}
                                                        variant="dropdown"
                                                        title=""
                                                        className="state-dropdown-menu"
                                                        id="dropdown-relationship"
                                                        size="sm"
                                                    >
                                                        {" "}
                                                        {Object.keys(com.relationships).map((key) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    onSelect={relationshiSelect}
                                                                    selected={key === "other"}
                                                                    key={key}
                                                                    eventKey={key}
                                                                    href="#"
                                                                >
                                                                    {com.relationships[key]}
                                                                </Dropdown.Item>
                                                            )
                                                        })}
                                                    </DropdownButton>
                                                  
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter marital status!
                                                    </Form.Control.Feedback>
                                                </InputGroup> */}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12}>
                                            <Form.Group controlId="State" className="text-left">
                                                <Form.Label className="text-left">
                                                    State in which the relationship was formed:
                                                </Form.Label>
                                                <StateSelector
                                                    isValid={false}
                                                    size="sm"
                                                    required
                                                    name="state"
                                                    autoComplete="state"
                                                    type="text"
                                                    placeholder="##"
                                                    value={
                                                        this.props.application[this.props.who]
                                                            .stateofrelationship
                                                    }
                                                    onChange={changeRelationshipState(
                                                        this.props.who,
                                                        "stateofrelationship"
                                                    )}
                                                    pattern="^[A-Z]{2,2}$"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please provide the State.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="mt-4">
                        {this.props.application[this.props.who].occupation.hasoccupation ===
                            "employed" && this.renderOccupation()}

                        {this.props.application[this.props.who].otheroccupations !== null &&
                            this.props.application[this.props.who].otheroccupations.map(
                                this.renderOtherOccupation
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

OtherInfoPersonal2 = connect(
    mapStateToPropsMore2,
    mapDispatchToPropsMore2,
    null,
    {
        forwardRef: true,
    }
)(OtherInfoPersonal2)
