import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Prequal.css";
import "./BorrowerApplication.css";
import { PrequalHeader } from "./Landing/LandingMenu.js";
import * as com from "./Common.js";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainSidebar from "./MainSidebar/MainSidebar";
import SidebarApplication from "./SidebarApplication/SidebarApplication";
import ApplicationInterview from "./Application/ApplicationInterview";
import HardMoneyLoanInterView from "./Application/HardMoneyLoanInterView";
import Documents from "./Documents/Documents";
import Tasks from "./Tasks";
import Status from "./Status";
import Profile from "./Profile";
import YourLoans from "./YourLoans";
import HardMoneyLoanDashboard from "./HardMoneyLoanDashboard";
import ContactUs from "./ContactUs";
import CreditCard from "./CreditCard";

import FaqPage from "./FaqPage";
import Glossary from "./Glossary";
import LogoutPage from "./LogoutPage";
import { UpdateChangeProgressType } from "./Store/progress";
import { Redirect, matchPath } from "react-router-dom";
import Howitworks from "./Howitworks";
import Sign from "./Common/Sign";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    hidden: "false",
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgressType: (step, stage) => {
    dispatch(UpdateChangeProgressType(step, stage));
  },
});

export default class HomePageApplication extends React.Component {
  state = {
    shrunk: false,
    smallScreen: false,
    name: com.getCompanyName(),
    fromiframe: "false",
  };
  shrink = () => {
    this.setState({ shrunk: !this.state.shrunk });
  };
  listenResizeEvent = (e) => {
    if (window.innerWidth < 800) {
      this.setState({ smallScreen: true });
      if (!this.state.hidden) {
        this.setState({ hidden: true, shrunk: true });
      }
    } else {
      this.setState({ smallScreen: false });
      if (this.state.hidden) {
        this.setState({ hidden: false, shrunk: false });
      }
    }
  };
  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.get('fromiframe') !== null && params.get('fromiframe') !== undefined) {
      if (params.get('fromiframe') === "true") {
        this.setState({ fromiframe: "true" });
      }
    }
    window.addEventListener("resize", this.listenResizeEvent);
    this.listenResizeEvent();
  }

  render = () => {
    let path = this.props.location;
    let isHardmoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
    const hardMoneyHomepageMatch = matchPath(path.pathname, {
      path: "/application/yourloans",
    });

    if (path.pathname.indexOf("/application/") >= 0) {
      document.body.style.marginBottom = "0px";
    } else {
      document.body.style.marginBottom = "209px";
    }

    const renderMainPane = () => {
      return (
        <Col className="text-center text-wrap p-0 bluebackground h-100">
          <Switch>
            <Route exact path="/application/home">
              <Helmet>
                <title> Welcome Back </title>
              </Helmet>

              <h1>Welcome back!</h1>
            </Route>
            <Route path="/application/sign/:taskid/:id">
              <Helmet>
                <title> Sign a Document </title>
              </Helmet>
              <Sign />
            </Route>
            <Route path="/application/interview/main/:section?">
              <Helmet>
                <title> Application Interview </title>
              </Helmet>
              <ApplicationInterview smallScreen={this.state.smallScreen} fromiframe={this.state.fromiframe} />
            </Route>
            <Route path="/application/hardMoneyLoan/interview/main/:section?">
              <Helmet>
                <title> Hard Money Loan Interview </title>
              </Helmet>
              <HardMoneyLoanInterView />
            </Route>
            <Route exact path="/resources/howitworks">
              <Helmet>
                <title> How It Works </title>
              </Helmet>
              <Howitworks />
            </Route>
            <Route exact path="/application/status">
              <Helmet>
                <title> Loan Status </title>
              </Helmet>

              <Status />
            </Route>
            <Route exact path="/application/tasks/:where?">
              <Helmet>
                <title> Action Requests </title>
              </Helmet>
              <div className="prequalPane" style={{border: 'none', marginLeft: 0, marginRight: 0}}>
                <Tasks />
              </div>
            </Route>

            <Route exact path="/application/docs">
              <Helmet>
                <title> Documents </title>
              </Helmet>
              <div className="prequalPane" >
                <Documents />
              </div>
            </Route>
            <Route exact path="/application/contact">
              <Helmet>
                <title> Contact Us </title>
              </Helmet>

              <ContactUs />
            </Route>

            <Route exact path="/application/profile">
              <Helmet>
                <title> Profile </title>
              </Helmet>
              <div className="prequalPane">
                <Profile />
              </div>
            </Route>

            <Route exact path="/application/yourloans">
              <Helmet>
                <title>My Loans </title>
              </Helmet>

              {isHardmoneyLoan ? <HardMoneyLoanDashboard /> : <YourLoans header={"yourloans"} />}
            </Route>

            <Route exact path="/application/creditcard">
              <CreditCard />
            </Route>

            <Route exact path="/resources/faq">
              <Helmet>
                <title>
                  {this.state.name} Information for government monitoring
                  purposes{" "}
                </title>
              </Helmet>

              <FaqPage />
            </Route>
            <Route exact path="/resources/glossary">
              <Helmet>
                <title>{this.state.name} Glossary </title>
              </Helmet>

              <Glossary />
            </Route>
            <Route exact path="/application/logout">
              <LogoutPage />
            </Route>

            <Route>
              <Helmet>
                <title>{this.state.name} File Not Found </title>
              </Helmet>

              <h1>Error 404! Can't render this URL!</h1>
            </Route>
          </Switch>
        </Col>
      );
    };

    if (this.state.smallScreen) {
      return (
        <div className="d-flex">
          <div id="myapp" className="p-0 m-0">
            {isHardmoneyLoan && hardMoneyHomepageMatch ? null : <PrequalHeader app={true} setid={false} />}

            <>
              <Row
                id="pane"
                className="w-100  mx-0 mb-0 px-0 pt-0  align-top d-flex backimage0"
              >
                <div className="siderbarpage">
                  <div id="nav-container" style={{ zIndex: 1001 }}>
                    <div className="bg"></div>
                    <div className="menubutton" tabindex="0">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </div>
                    <div id="nav-content" tabindex="0">
                      <SidebarApplication />
                    </div>
                  </div>
                  <main>
                    <div className="content">{renderMainPane()}</div>
                  </main>
                </div>
              </Row>
            </>
          </div>
        </div>
      );
    }


    return (
      <div className="d-flex" style={{ height: '100%' }}>
        <div className={this.state.fromiframe === "false" ? "mainSidebarWrap applicationSidebarWrap" : "hidemainSidebar"}>
          <MainSidebar />
        </div>
        <div id="myapp" style={{ backgroundColor: this.state.fromiframe === "true" ? "white" : "" }} className="p-0 m-0 application-content">
          {this.state.fromiframe === "false" && !(isHardmoneyLoan && hardMoneyHomepageMatch) && <PrequalHeader app={true} setid={false} />}
          <div className={this.state.fromiframe === "true"? "flex-grow-1 iframeHeightAdjuster":"flex-grow-1"}>{renderMainPane()}</div>
        </div>
      </div>
    );
  };
}

HomePageApplication = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageApplication)
);
