import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Prequal.css';
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Dropdown, Space, Progress } from 'antd';
import * as com from "../Common.js"
import './SidebarApplication.css';
import { UpdateChangeProgress } from "../Store/progress"
import { connect } from 'react-redux'
import { capitalize, isEmpty, isEqual } from 'lodash';
import eventBus from '../Bus';
const mapStateToProps = (state) => {
  return {
    progress: state.progress,
    application: state.application,
    property: state.application.property,
    borrower: state.application.borrower
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage))
  },
});



export default class SidebarApplication extends Component {

  constructor(props) {
    super(props);
    this.state = {
      latest: "",
      oldest: "",
      shownew: false,
      showalert: false,
      hideBell: true,
      hideExclamation: true,
      landingurl:"",
      percent: 0,
      menuExpand: true
    };
    this.lastCheck = 0
    this.icons = false

  }



  getLoanOfficerUrl = () => {
    let token = com.getUserToken()
    fetch('/borrower/getloanofficerlandingurl', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then(js => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Status)
          } else {
            this.setState({ landingurl: js.LandingUrl })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
      });
  }

  componentDidMount() {
    eventBus.addListener('changeProgressValue',this.getProgressValue)
    let dropdown = document.getElementsByClassName("side-dropdown-btn");
    let i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent) {
          if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
          } else {
            dropdownContent.style.display = "block";
          }
        }
      });
    }
    document.getElementById('btn-sub').click();

    if (com.getCustomerId()==="individualcustomers") {
      this.getLoanOfficerUrl()
    }
    setTimeout(() => {
      this.getProgressValue()
    },200)
  }
  componentWillUnmount() {
    eventBus.removeListener('changeProgressValue',this.getProgressValue)
  } 
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps !== this.props) || (prevState.percent !== this.state.percent)) {
      this.getProgressValue()
    }
  }
  getProgressValue = () => {
    let progressValue = sessionStorage.getItem("progressValue")
    if (progressValue) {
      this.setState({percent: progressValue})
    }
  }
  render() {
    let subMenuList = {
      personal: [
        {
          menu: 'You & co-borrower'
        },
        {
          menu: 'Current residence'
        }
      ],
      property: [
        {
          menu: 'Basic'
        },
        {
          menu: 'Detail'
        }
      ],
      income: [
        {
          menu: 'Employment'
        },
        {
          menu: 'Other income'
        }
      ]
    }
    let entry = (start, end, name, token) => {
      let unfinished = (this.props.progress.interview.step >= start && this.props.progress.interview.step < end) ?
        true : false
      let st = unfinished ? {backgroundColor:"#EAEFFD"} : {height: 41}
      if (this.props.progress.interview.max >= start || this.props.progress.interview.step >= start) {
        return (
          <Link className="navselection" to={'/application/interview/main/' + token}
          >
            <div className="prequalInterviewNav" style={st}>
              <div  className="selectsignWrap" >
                <img src={unfinished? "/images/navSignSelected.png":"/images/navSignFinished.png"} />
              </div>
              <div className="prequalInterviewNavText">
                <a style={{paddingBottom: 0}} className="title">{name}</a>
                {unfinished && (
                  <>
                    <Progress className='prequalInterviewNavProgress' percent={this.state.percent} showInfo={false} size="small" trailColor="#D6E2FB" />
                    {subMenuList[token] && subMenuList[token].map((item, index) => (
                      <div key={index} className='sidenav-sub'>
                        <Link to={'/application/interview/main/' + (com.stageToNumberApplication[token] + index)}>
                          <span className={subMenuList[token].length - (end - this.props.progress.interview.step) === index ? 'sub-acitve' : ''}>{ item.menu }</span>
                          <img className='link' src='/images/link.svg' />
                          {index !== subMenuList[token].length - 1 && <div className='line'></div>}
                        </Link>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Link>
        )
      }

      return (
        <Button style={{height: '41px', padding: 0, border: 'none'}} variant="link" className="m-0 selectionoffset w-100" disabled>
           <div style={{padding: '3px 15px'}} className="prequalInterviewNav unselected">
              <div  className="selectsignWrap" >
                <img src="/images/navSignUnselected.png" />
              </div>
              <div className="prequalInterviewNavText unselected">
                <a className="unselected">{name}</a>
                {/* <a className="etc">ETC: {sec} sec</a> */}
              </div>
            </div>
        </Button>
      )

    }
    let unfinished = (this.props.progress.interview.step >= 0 && this.props.progress.interview.step < com.stageToNumberApplication.property) ?
      true: false
    let isPreapproval = false === this.props.application.property.hascontract &&
      this.props.application.property.purpose === "purchase"
    let creditPulled = (this.props.application.scre !== 0)
    let newcustomer = sessionStorage.getItem("newcustomer")=== "true" ? true : false
    let onChangePurpose = () => {
      window.location.href = "/services/preapp?customerid=" + com.getCustomerId()
    }
    let items = [
      {
        key: '1',
        label: (
          <Button variant="plainText" onClick={e => { onChangePurpose() }} >
            Change loan purpose
          </Button>
        ),
      }
    ]
    let clickMenu = () => {
      this.setState({menuExpand: !this.state.menuExpand})
    }
    return (
      <div style={{ flexWrap: false, }} id="sidebar" className="sidenav-application">
        <button id="btn-sub"className={this.props.progress.interview.step < com.stageToNumberApplication.overview ? "sidebar-menu sidebar-menu-selected" :
            "sidebar-menu"} style={{padding: 0}} >
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 14px 0 15px'}} onClick={clickMenu}>
              <span className="sidebartext-app">MY APPLICATION</span>
              <img className={this.state.menuExpand ? 'menuExpandIcon expand' : 'menuExpandIcon'} src={this.state.menuExpand ? "/images/menuExpandIcon-active.svg" : "/images/menuExpandIcon.svg"} />
            </div>
        </button>
        <div className="prequal-dropdown-container sidebar-menu-item" style={{ height: this.state.menuExpand ? 'auto' : 0 }}>
          
          <Link className="navselection" to={newcustomer ? '/application/interview/main/0' : '/application/interview/main/applicant'}>
            <div style={{height: unfinished ? 'auto' : 41}} className="prequalInterviewNav">
              <div  className="selectsignWrap" >
                <img src={unfinished ?"/images/navSignSelected.png":"/images/navSignFinished.png"} />
              </div>
              <div className="prequalInterviewNavText">
                <a className="title">Personal</a>
                {unfinished && (
                  <>
                    <Progress className='prequalInterviewNavProgress' percent={this.state.percent} showInfo={false} size="small" trailColor="#D6E2FB" />
                    {subMenuList.personal.map((item, index) => (
                      <div key={index} className='sidenav-sub'>
                        <Link to={'/application/interview/main/' + (com.stageToNumberApplication.applicant + index)}>
                          <span className={subMenuList.personal.length - (com.stageToNumberApplication.property - this.props.progress.interview.step) === index ? 'sub-acitve' : ''}>{ item.menu }</span>
                          <img className='link' src='/images/link.svg' />
                          {index !== subMenuList.personal.length - 1 && <div className='line'></div>}
                        </Link>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Link>
          {entry(com.stageToNumberApplication.property, com.stageToNumberApplication.income, 'Property', 'property', 30)}
          {entry(com.stageToNumberApplication.income, com.stageToNumberApplication.finances, 'Income', 'income', 60)}
          {entry(com.stageToNumberApplication.finances, com.stageToNumberApplication.liabilities, 'Finances', 'finances', 60)}
          {entry(com.stageToNumberApplication.liabilities, com.stageToNumberApplication.overview, 'Liabilities', 'liabilities', 60)}

        </div>
        {/* <Link to='/application/yourloans' className="p-0">
          <button id="btn-sub" className={window.location.pathname.split("/").pop() === "yourloans" ? "sidebar-button-selected sidebar-app selectbar-text" :
            "sidebar-button sidebar-app"} ><span className="sidebartext-app">My loans</span>
          </button>
        </Link> */}
        {
          com.getNumericalStatus(this.props.application.status) > 0  ?
          <Link to='/application/interview/main/overview' className="p-0">
            <button id="btn-sub" className={this.props.progress.interview.step=== com.stageToNumberApplication.overview ? "sidebar-button-selected sidebar-app selectbar-text" :
            "sidebar-button sidebar-app"} ><span className="sidebartext-app">OVERVIEW</span>
        </button>
          </Link>: <a className='sidebar-app p-0'>
            <span className="sidebardisabletext-app">OVERVIEW</span>
            <img src="/images/sidebarLock.svg" style={{width: 32, height: 32}} />
          </a>
        }
        {
          creditPulled ?
          <Link to='/application/interview/main/rates' className="p-0">
            <button id="btn-sub" className={this.props.progress.interview.step=== com.stageToNumberApplication.rates ? "sidebar-button-selected sidebar-app selectbar-text" :
            "sidebar-button sidebar-app"} ><span className="sidebartext-app">MY RATES</span>
        </button>
          </Link>: <a className='sidebar-app p-0'>
            <span className="sidebardisabletext-app">MY RATES</span>
            <img src="/images/sidebarLock.svg" style={{width: 32, height: 32}} />
          </a>
        } 
        {
          creditPulled ?
          <Link to='/application/interview/main/declarations' className="p-0">
            <button id="btn-sub" className={this.props.progress.interview.step >= com.stageToNumberApplication.declarations ? "sidebar-button-selected sidebar-app selectbar-text" :
            "sidebar-button sidebar-app"} ><span className="sidebartext-app">DECLARATIONS</span>
        </button>
          </Link>: <a className='sidebar-app p-0'>
            <span className="sidebardisabletext-app">DECLARATIONS</span>
            <img src="/images/sidebarLock.svg" style={{width: 32, height: 32}} />
          </a>
        } 
      </div>
    );
  }
}
SidebarApplication = connect(mapStateToProps, mapDispatchToProps)(SidebarApplication)