import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Prequal.css';
import '../Application/ApplicationInterview.css';
import '../Application/SSN.css'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import { Tips } from "../Common/Tooltip";
import * as com from "../Common"
import { getCurrentState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Spinner  from "react-bootstrap/Spinner";
import Warning from "../Common/components/Warning";
import { mockUCS, mockUCSError, mockUCS2, mockUCS3, mockUCSError2 } from "./mock";
import { filterMortgages } from "./Liabilities";
import CheckGroup from "../Common/components/CheckGroup";
import NumericalInput, { CommaizedInput } from '../NumericalInput'
import { Spin, Checkbox, InputNumber, Slider } from 'antd';
const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb));
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },  
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    clearCRErrors: () => {
        dispatch(act.ClearCRErrors())
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb));
      },
});

class SSN extends Component {

    constructor(props) {
        super(props);
        this.state = {
            automatedCreditPull: false,
            pullCredit: this.props.application.assetsandliabilities.loans !== null && this.props.application.assetsandliabilities.loans.length === 1 && this.props.application.assetsandliabilities.loans[0].name==="Estimated" ? "false" : this.props.application.assetsandliabilities.loans !== null ? "true" : "",
            borrowerSSN : this.props.application["borrower"].socialsecurity,
            originalBorrowerSSN : this.props.application["borrower"].socialsecurity,
            coborrowerSSN : this.props.application["coborrower"].socialsecurity,
            originalCoborrowerSSN : this.props.application["coborrower"].socialsecurity,
            hasCoborrower: this.props.application.hascoborrower === "withcoborrower",
            authorized: this.props.application.scre !== 0 && this.props.application.assetsandliabilities.loans !== null && !(this.props.application.assetsandliabilities.loans.length === 1 && this.props.application.assetsandliabilities.loans[0].name==="Estimated") ? true : false ,
            expand: [],
            stepsandsubsteps: [],
            invalidPrimaryAddress: false,
            showContactModal: false,
            useEstimated: false,
            estimatedLoan: {
                openeddate: "01/01/2023",
                name: "Estimated",
                address: "",
                accountnumber: "",
                monthlypayment: "",
                monthlydefinedbycustomer: true,
                remainingbalance: "",
                highbalance: "",
                monthslefttopay: "",
                mannerofpaymentcode: "C",
                currentratingtype: "AsAgreed",
                creditloantype: "ConventionalRealEstateMortgage",
                creditliabilityaccounttype: "Mortgage",
                creditliabilityaccountownershiptype: "Individual",
                creditliabilityaccountstatustype: "Open",
                detailcreditbusinesstype: "FinanceMortgageCompanies",
                creditliabilityhighestadverserating: "NoDataAvailable",
                reportedby: ["Equifax", "Experian", "TransUnion"],
                deferredpaymentamount: "",
                deferredpaymentdate: "",
                comment: "CONVENTIONAL REAL ESTATE MORTGAGE",
                rate: "",
                correction: "none",
                explanation: "",
                attribution: 2,
                propertyaddress: "",
                key: "0",
              },
            validated: false,
            errorMessages: [],
            pwdType: 'text',
            coPwdType: 'text',
            showCreditModal: false,
            confirm: false,
            showCheckMsg: false,
            showStatusInfo: false,
            showCheckCredit: true,
            showFico: false,
            changeTips: false,
            scre: '',
            debt: ''
        }
        // TBD
        if (this.props.application.hascreditfreeze === null) {
            this.props.updateApplicationAttribute(false, "hascreditfreeze")
        }
        this.originalBorrowerSSN = this.state.borrowerSSN.slice()
        this.originalCoborrowerSSN = this.state.coborrowerSSN.slice()
    }
    componentDidMount() {
        this.getCurProgress()
        this.initStatus()
        if (this.props.application.assetsandliabilities.warnings !== null) {
            this.setState({errorMessages: this.props.application.assetsandliabilities.warnings})
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.pullCredit === 'true' && this.state.showCheckCredit) {
            this.props.changeContinueButtonText("Check Credit")
        } else {
            this.props.changeContinueButtonText("Next step")
        }
        this.getCurProgress()
    }
    initStatus = () => {
        setTimeout(() => {
            this.checkAutomatedCreditPull()
            this.setState({scre: this.props.application.scre === 0 ? '' : this.props.application.scre, debt: this.getEstimatedMonthlyPay()})
            if (this.checkStatus() === 'success') {
                this.setState({showStatusInfo: true, showCheckCredit: false, showFico: false})
            }
            if (this.checkStatus() === 'fail') {
                this.setState({showStatusInfo: true, showCheckCredit: false, showFico: true})
    
            }
        },0)
    }
    getCurProgress = () => {
        let obj = {
            borrowerSSN: this.state.borrowerSSN,
        }
        if (this.state.hasCoborrower) {
            obj.coborrowerSSN = this.state.coborrowerSSN
        }
        const emptyNum = Object.keys(obj).filter((key) => {
            const value = obj[key];
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((Object.keys(obj).length - emptyNum) / Object.keys(obj).length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    getEstimatedMonthlyPay = () => {
        let loans = this.props.application.assetsandliabilities.loans
        if (loans !== null && loans.length === 1 && loans[0].monthlydefinedbycustomer === true) {
           
            return loans[0].monthlypayment && loans[0].monthlypayment !== '' ? loans[0].monthlypayment : 0
        }
        return 0
    }

    checkAutomatedCreditPull = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/checkautomatedcreditpull', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log(response)
                    return;
                }
                response.json().then(js => {
                    this.setState({automatedCreditPull: js.UseAutomatedCreditPull})
                    
                    if (!js.UseAutomatedCreditPull)
                        this.setState({pullCredit: "false"})
                    }
                )
                return
            }

        ).catch((err) => {
            console.log('Fetch Error :', err);
        });

    }

    checkPulled = () => {
        if (this.props.application.scre !== 0 && this.props.application.assetsandliabilities.loans !== null && !(this.props.application.assetsandliabilities.loans.length === 1 && this.props.application.assetsandliabilities.loans[0].name==="Estimated")) {
            return true
        }
        return false
    }
    checkStatus = () => {
        if (this.checkPulled()) {
            return 'success'
        } else {
            if (this.props.application.assetsandliabilities.warnings !== null) {
                return 'fail'
            } else {
                return 'none'
            }
        }
    }
    reconcileSSN = (js, borrowers) => {
        let dashize = ssn => {
            return ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
        }
        for (let i = 0; i < borrowers.length; i++) {
            let outperson = js.personswithresidencies[i].person
            let inperson = borrowers[i].person
            let inssn = inperson.socialsecurity.replace(/-/g, '')

            let outssn = dashize(outperson.socialsecurity)

            // if (outperson.socialsecurity !== inssn) {

            //     if (window.confirm("Attention!\nCredit report returned a different SSN for " + outperson.firstname + " " + outperson.lastname + ": " + outssn +
            //         "\nYou entered: " + borrowers[i].person.socialsecurity + "\n\nWould you like to use the value from credit report (recommended)? ")) {

            //         this.props.changeBorrowerInfo(outssn, (i === 0) ? com.borrower : com.coborrower, "socialsecurity")
            //     }
            // }
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    fillPerson = who => {
        let a = this.props.application[who]
        return {
            firstname: a.firstname, middlename: a.middlename, lastname: a.lastname,
            suffix: a.suffix, email: a.email,
            socialsecurity: a.socialsecurity,
            dateofbirth: a.dateofbirth
        }
    }
    fillAddress = who => {
        let a = this.props.application[who]

        return {
            street: a.presentaddressstreet, city: a.presentaddresscity, state: a.presentaddressstate,
            zip: a.presentaddresszip, years: a.presentaddressyears,
            months: a.presentaddressmonths, ownership: a.presentaddressownership
        }
    }
    fillPreviouswAddress = (index, who) => {
        let a = this.props.application[who].previousaddresses[index]

        return {
            street: a.street, city: a.city, state: a.state,
            zip: a.zip, years: a.years,
            months: a.months, ownership: a.ownership
        }
    }
    fillApplicant = who => {
        let addresses = [this.fillAddress(who)]
        let a = this.props.application[who].previousaddresses

        for (let i = 0; i < a.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }
        if (addresses.length>0 && addresses[0].street==="") {
            addresses = [this.fillAddress("borrower")]
        }
        return { person: this.fillPerson(who), addresses: addresses }
    }
    fillRequestBody = () => {
        let borrowers = [this.fillApplicant("borrower")]
        if (this.props.application.hascoborrower === "withcoborrower" ||
            this.props.application.hascoborrower === "multiple"
            || (this.props.application.hascoborrower === "alone" && this.props.application.aloneMarriedWantsFHA)
        ) {
            borrowers.push(this.fillApplicant("coborrower"))
        }
        return {
            // true for LO, false for borrower
            force_ucs: false,
            debug: "just_request",
            app_data: borrowers,
            request: "credit_report"
        }
    }
    getCreditReport() {
        this.props.changeLoading(true)
        this.props.changeReviewedLoans(0)
        if (false &&  null !== this.props.application.assetsandliabilities.loans) {
            return
        }

        let token = window.sessionStorage.getItem("ZeitroA");
        let id = com.getUserId()

        if ("undefined" === typeof id) {
            id = this.props.borrowerid
        }
        // let body = this.fillRequestBody()
        // let borrowers = body.app_data

        let clearBorrowerCreditRecords = () => {
            this.props.clearCRErrors()
            this.props.updateFico("")
            this.props.clearBorrowerLoans(null, this.props.who)
            let s = this.state.borrowerSSN
            let cs = this.state.coborrowerSSN
            this.setState({originalBorrowerSSN: s, originalCoborrowerSSN: cs})
            this.props.changeContinueButtonText("Next step")
        }

        let processStatusNotOK = () => {
            clearBorrowerCreditRecords()
            let errorMessages = ["The credit report is currently unavailable due to an internal error."]
            this.props.updateCRErrors(errorMessages)
            this.setState({errorMessages: errorMessages})
            this.props.changeLoading(false)
            this.initStatus()
        }

        let processData = (js) => {
            clearBorrowerCreditRecords()

            if (!(typeof js.errorMessages === "undefined" || js.errorMessages === null)) {
                console.log(js.errorMessages)
                this.props.updateCRErrors(js.errorMessages)
                this.setState({errorMessages: js.errorMessages})
                this.props.changeLoading(false)
                if (js.errorMessages.includes("Invalid or missing Primary Applicant residential address")) {
                    this.setState({invalidPrimaryAddress: true})
                }
                this.initStatus()
                return
            }

            this.props.clearBorrowerLoans(null, this.props.who)
            this.props.updateFico(js.creditscore)
            if (js.creditscore=="0") {
                this.props.updateFico("750")
            }
            if (js.creditscore === "") {
                this.initStatus()
                return
            }
                

            // this.reconcileSSN(js, borrowers)

            let remap = o => {
                let l = { ...o }

                l.correction = "none"
                //l.attribution = attr

                let isstudent = l.creditliabilityaccounttype === "Educational"
                let islease = l.creditliabilityaccounttype === "AutoLease" || l.creditliabilityaccounttype === "Lease"
                let isinstallment = l.creditliabilityaccounttype === "Installment"

                if (l.creditloantype !== null && l.creditloantype.toLowerCase().indexOf("education") >= 0) {
                    if (l.monthlypayment === "")
                        l.monthlypayment = 0
                } else {
                    if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === "")) {
                        l.monthlydefinedbycustomer = true
                    }
                }

                // TBD: better ways to match addresses!
                if (l.address !== null && l.address.split(',')[0].toLowerCase() === this.props.application.borrower.presentaddressstreet.toLowerCase() &&
                l.creditliabilityaccountstatustype==="Open" &&
                filterMortgages(l)) {
                    l.forprimaryresidence = true
                }
                return l
            }
            let opened = js.liabilities
            let dupresolved = null
            let expand = []
            let steps = -1
            let substeps = 0
            let stepsandsubsteps = []
            for (let i = 0; i < opened.length; i++) {
                let remapped = remap(opened[i])
                if (opened[i].remainingbalance === "" || parseInt(opened[i].remainingbalance) === 0)
                   continue
                this.props.copyBorrowerLoan(remapped, this.props.who)

                expand.push(false)
                let st
                if (remapped.correction === "duplicate") {
                    substeps += 1
                    st = { steps, substeps, dupresolved }
                } else {
                    steps += 1
                    substeps = 0
                    st = { steps, substeps, dupresolved }
                }
                stepsandsubsteps.push(st)
            }

            this.props.addBorrowerAdditionalPropertiesFromCredit(null)
            getCurrentState()

            this.originalBorrowerSSN = this.state.borrowerSSN.slice()
            this.setState({loadingcr: false, expand, stepsandsubsteps })
            this.props.changeLoading(false)
            this.initStatus()
        }

        // if (window.location.hostname === "localhost") {
        //     processData(mockUCS)
        //     return
        // }

        fetch(window.location.origin + "/borrower/customercreditreport", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                InitiatedByLO: false,
                Type:"CustomerSoftCreditReport",
                BorrowerID: id, // should switch to loan ID?
            }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                processStatusNotOK()
                return;
            }

            response.text().then(text => {
                try {

                    let js = JSON.parse(text)
                    processData(js)
                } catch (xx) {
                    console.log(xx)
                    this.initStatus()
                    this.props.changeLoading(false)
                }
            })

        }).catch(error => {
            this.initStatus()
            this.props.changeLoading(false)
        })

    }

    handleSubmit = e => {
        if (this.state.pullCredit === 'false' || (this.state.pullCredit === 'true' && this.state.showFico)) {
            this.props.clearBorrowerLoans(null, "borrower")
            let c = {...this.state.estimatedLoan}
            this.props.copyBorrowerLoan(c, "borrower")
            this.props.updateFico(this.state.scre)
            this.updateEstimatedPayment(this.state.debt)
        }
        if (this.state.pullCredit === 'true' && !this.state.showFico) {
            this.props.updateFico(this.state.scre)
        }
        if (!this.state.hasCoborrower && this.state.borrowerSSN.length !== 11) {
            return false
        }
        if (this.state.hasCoborrower && (this.state.borrowerSSN.length !== 11 || this.state.coborrowerSSN.length !== 11)) {
            return false
        }
        if (this.state.pullCredit === 'true' && this.state.showCheckCredit) {
            this.creditCheckContinue()
            return false
        }
        if ((this.state.pullCredit === 'false' && this.state.scre < 500) || (this.state.showFico && this.state.scre < 500)) {
            this.setState({showCheckMsg: true})
            return false
        }
        return true
    }

    updateSSN = (who, e) => {
        com.touch();
        this.props.changePhoneInfo(e, who, "socialsecurity");
    };
    formatSSN = (value) => {
        const ssn = value.replace(/[^\d]/g, "")
        const ssnLength = ssn.length
        if (ssnLength < 4) return ssn
        if (ssnLength < 6) {
            return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
        }
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
    }
      enterSSN = () => {
        if (!this.state.hasCoborrower) {
            return (
                <div>
                    <Form.Label>What’s your Social Security Number</Form.Label>
                    <InputGroup>
                        <Form.Control isValid={this.state.borrowerSSN.length === 11} size="md" isInvalid={this.state.borrowerSSN !== '' && this.state.borrowerSSN.length !== 11}
                            className="ssn-input"
                            required
                            name="ssn"
                            type={this.state.pwdType}
                            value={this.state.borrowerSSN}
                            onChange={(e)=>{
                                const value = this.formatSSN(e.target.value)
                                this.updateSSN("borrower", value)
                                this.setState({borrowerSSN: value, changeTips: true, showCheckCredit: true, showFico: false, showCheckMsg: false})
                                if (this.checkStatus() === 'success') {
                                    this.setState({showStatusInfo: false})
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid" >
                            SSN must be 9 digits
                        </Form.Control.Feedback>
                        <img onClick={() => {this.setState({pwdType: this.state.pwdType === 'text' ? 'password' : 'text'})}} src="/images/show-password.png" style={{width: 24, height: 24, position: 'absolute', right: 36, top: 10, cursor: 'pointer', zIndex: 99}} />
                    </InputGroup>
                </div>
            )
        } else {
            let borrowerName = <a style={{color:"#325CEB"}}>{this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname}</a>
            let coborrowerName = <a style={{color:"#325CEB"}}>{this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname}</a>
            return (
                <div>
                    <Form.Label>What’s {borrowerName}'s Social Security Number</Form.Label>
                    <InputGroup>
                        <Form.Control isValid={this.state.borrowerSSN.length === 11} size="md" isInvalid={this.state.borrowerSSN !== '' && this.state.borrowerSSN.length !== 11}
                            className="ssn-input"
                            required
                            name="ssn"
                            type={this.state.pwdType}
                            value={this.state.borrowerSSN}
                            onChange={(e)=>{
                                const value = this.formatSSN(e.target.value)
                                this.updateSSN("borrower", value)
                                this.setState({borrowerSSN: value, changeTips: true, showCheckCredit: true, showFico: false, showCheckMsg: false})
                                if (this.checkStatus() === 'success') {
                                    this.setState({showStatusInfo: false})
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid" >
                            SSN must be 9 digits
                        </Form.Control.Feedback>
                        <img onClick={() => {this.setState({pwdType: this.state.pwdType === 'text' ? 'password' : 'text'})}} src="/images/show-password.png" style={{width: 24, height: 24, position: 'absolute', right: 36, top: 10, cursor: 'pointer', zIndex: 99}} />
                    </InputGroup>
                    <Form.Label className="mt-4">What’s {coborrowerName}'s Social Security Number</Form.Label>
                    <InputGroup>
                        <Form.Control isValid={this.state.coborrowerSSN.length === 11} size="md" isInvalid={this.state.coborrowerSSN !== '' && this.state.coborrowerSSN.length !== 11}
                            className="ssn-input"
                            required
                            name="ssn"
                            type={this.state.coPwdType}
                            value={this.state.coborrowerSSN}
                            onChange={(e)=>{
                                const value = this.formatSSN(e.target.value)
                                this.updateSSN("coborrower", value)
                                this.setState({coborrowerSSN: value, changeTips: true, showCheckCredit: true, showFico: false, showCheckMsg: false})
                                if (this.checkStatus() === 'success') {
                                    this.setState({showStatusInfo: false})
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid" >
                            SSN must be 9 digits
                        </Form.Control.Feedback>
                        <img onClick={() => {this.setState({coPwdType: this.state.coPwdType === 'text' ? 'password' : 'text'})}} src="/images/show-password.png" style={{width: 24, height: 24, position: 'absolute', right: 36, top: 10, cursor: 'pointer', zIndex: 99}} />
                    </InputGroup>
                </div>
            )
        }
    }

    contactLO = () => {
        this.setState({showContactModal: true})

        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/contactloaboutcreditpull', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log(response)
                    return;
                }
                return
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });

    }

    updateEstimatedPayment = e => {
        this.props.clearBorrowerLoans([], "borrower")
        let c = {...this.state.estimatedLoan}
        c.monthlypayment = e
        // console.log(c)
        this.setState({estimatedLoan: c})
        this.props.copyBorrowerLoan(c, "borrower")
    }
    creditCheckContinue = () => {
        this.getCreditReport()
    }
    checkBtnDisabled = () => {
        if (this.state.pullCredit === "true") {
            return false
        } else if (this.state.pullCredit === "false" && this.props.application.scre && this.props.application.scre !== 0) {
            return false
        }
        return true
    }
    showCredit = () => {
        this.setState({ showCreditModal: true })
    }
    softPullIcon = () => {
        return <div className="creditCheck-recommend">Recommend</div>
    }
    handleLoanBalance = e => {
        this.props.updateApplicationAttribute( e.target.value, "loanbalance")
    }

    render() {

        let getContactModal = () => {
            return (
             <Modal show={this.state.showContactModal} 
                onHide={() => this.setState({ showContactModal: false })}>
                <Modal.Header closeButton >
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    We've notified your loan officer about this issue, they will contact you soon.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.setState({ showContactModal: false })}>
                        OK
                    </Button>
    
                </Modal.Footer>
            </Modal>
        )}

        let displayEstimatedFicoAndDebt = () => {
            return <div className="mt-4">
            <Form.Group className="inputSection" style={{marginBottom: 26}}>
                <Form.Label>
                    <div className="creditCheck-label">What is your estimated FICO score?</div>
                </Form.Label>
                <div className="creditCheck-slider-wrap">
                    <InputNumber
                        className='creditCheck-input'
                        bordered={false}
                        controls={false}
                        value={this.state.scre}
                        min={500}
                        max={1000}
                        precision={0}
                        onChange={e=>{
                            this.setState({scre: e})
                        }}
                    />
                </div>
                {this.state.showCheckMsg && <div className="warning-msg">Please enter your FICO score</div>}
            </Form.Group>
            <Form.Group className="inputSection mt-4">
                    <Form.Label><div className="creditCheck-label">What is your estimated monthly debt payment?</div></Form.Label>
                    <div className="creditCheck-slider-wrap">
                        <InputNumber
                            className='creditCheck-input'
                            bordered={false}
                            controls={false}
                            formatter={value => {
                                if (value === '') {
                                    return '$ '
                                }
                                return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            parser={value => {
                                if (value === '$ ') {
                                    return 0
                                }
                                return value.replace(/\$\s?|(,*)/g, '')
                            }}
                            min={0}
                            max={1000000}
                            precision={0}
                            value={this.state.debt}
                            onChange={e=>{
                                this.setState({debt: e})
                            }}
                        />
                    </div>
                    {this.state.showCheckMsg && <div className="warning-msg">Please enter your monthly debt payment</div>}
            </Form.Group>
        </div>
        }
        return (
            <div className="text-left mb-3 newInterviewPaneEmployment">
                {this.state.showContactModal && getContactModal()}
                {
                    (this.checkStatus() === 'fail' && this.state.showStatusInfo) && (
                        <div className="report-error">
                            <img src="/images/warningSignYellow.png" style={{width: 28, height: 25, marginRight: 12}} />
                            <div style={{whiteSpace: 'initial', width: '100%'}}>
                                <div className="report-error-title"><div>Sorry, we were unable to pull your credit report.</div><div onClick={()=>this.contactLO()}>Contact my loan officer</div></div>
                                <div style={{fontSize: '16px', color: '#333', lineHeight: 'normal'}}>Your SSN: <span>{this.state.originalBorrowerSSN}</span>{this.state.hasCoborrower && <span>, {this.state.originalCoborrowerSSN}</span>}</div>
                                {this.state.errorMessages.length > 0 && <div style={{marginBottom: 2, display: 'flex'}} className="creditCheck-fail-msg"><span style={{marginRight: 6}}>Potential Error Cause:</span> <div style={{fontWeight: 500}}>{this.state.errorMessages[0]}</div></div>}
                                <div style={{marginBottom: 6, lineHeight: 'normal'}} className="creditCheck-fail-operate">Continue by entering your estimated FICO score, or Re-entering your SSN to redo the credit check</div>
                            </div>
                        </div>
                    )
                }
                <div className="credit-title">
                    <div style={{color: '#6E6E70', fontSize: '20px'}}>Your Credit</div>
                    <div className="text-wrap" style={{color: '#62707C', fontSize: '14px', fontWeight: 500}}>Please choose one of the options below to continue:</div>
                </div>
                {this.enterSSN()}
                {
                    !this.state.automatedCreditPull ? displayEstimatedFicoAndDebt() : 
                    <div className="credit-info-box">
                        <div className="text-wrap" style={{color: '#62707C', lineHeight: '20px', marginBottom: '8px'}}>Please cross check that the following information is correct</div>
                        <div className="credit-info">
                            <div><span className="credit-info-lable">Name:</span><span className="credit-info-value">{this.props.application["borrower"].firstname} {this.props.application["borrower"].lastname}</span></div>
                            <div><span className="credit-info-lable">Current address:</span><span className="credit-info-value text-wrap">{com.formatAddress(this.props.application.borrower)}</span></div>
                        </div>
                        {
                            (this.checkStatus() === 'success' && this.state.showStatusInfo) && (
                                <div className="creditCheck-success">
                                    <img src="/images/congratulation2.png" />
                                    <div className="creditCheck-success-title">Congratulations</div>
                                    <div className="creditCheck-success-subtitle">Your soft pull is successful!</div>
                                </div>
                            )
                        }
                        {
                            (this.props.progress.interview.max === this.props.progress.interview.step) && this.checkStatus() === 'none' && (
                                <Form.Group controlId="confirm">
                                    <Form.Check type="checkbox"
                                        className="credit-checkbox"
                                        defaultChecked={this.state.confirm}
                                        required
                                        key={Math.random()}
                                        name="confirm"
                                        onChange={(e) => this.setState({ confirm: e.target.checked })}
                                        label="*I confirm the above information is correct.">
                                    </Form.Check>
                                </Form.Group>
                            )
                        }
                        {
                            this.state.showCheckCredit && !this.state.showFico && (
                                <>
                                    {
                                        this.state.changeTips && this.checkStatus() !== 'none'  && (
                                            <div className="text-wrap" style={{color: '#62707C', fontWeight: 500, lineHeight: '20px', marginBottom: '26px'}}>We noticed that you have changed your SSN, Do you want to redo Soft Credit Check, or enter your estimated FICO score ?
            *** This may affect the relevance of your credit record in our system</div>
                                        )
                                    }
                                    <div className="creditCheck-select mt-4">
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            <div className="creditCheck-label">I would like to check my credit by</div>
                                        </Form.Label>
                                        <CheckGroup
                                            data={[
                                                {
                                                    value: "true",
                                                    label: "Soft pull",
                                                    tag: this.softPullIcon()
                                                },
                                                {
                                                    value: "false",
                                                    label: "FICO score",
                                                }, 
                                            ]} 
                                            onChangeSelect={e=>{
                                                if (e === "true") {
                                                    this.setState({scre: 0})
                                                    this.props.changeContinueButtonText("Check Credit")
                                                }
                                                if (e === "false") {
                                                    this.props.changeContinueButtonText("Next step")
                                                }
                                                this.setState({pullCredit: e, showCheckMsg: false})
                                            }} 
                                            value={this.state.pullCredit} 
                                            type='radio'
                                            direction="horizontal"
                                        />
                                        {this.state.pullCredit === 'true' && <div className="text-wrap mt-3" style={{color: '#6E6E70', fontSize: 14, lineHeight: '20px'}}>*By clicking continue, I understand {com.getCompanyName()} has the permission to use my SSN do a soft pull.</div>}
                                    </Form.Group>
                                        {
                                            this.state.pullCredit === "false" && displayEstimatedFicoAndDebt()
                                        }
                                    </div>
                                </>
                            )
                        }
                        {
                            !this.state.showCheckCredit && this.state.showFico && displayEstimatedFicoAndDebt()
                        }
                    </div>
                }
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SSN)
