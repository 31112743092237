

import "../Prequal.css";
import React, { useState, useEffect } from 'react';
import {
    Form,
    InputGroup,
} from "react-bootstrap";

export default function InputGroupComponent(props) {
    const { placeholderValue } = props;

    const [placeholder, setPlaceholder] = useState("")

    useEffect(() => {
        setPlaceholder(placeholderValue)
    }, [placeholderValue])

    return (
        <div style={props.style}>
            <InputGroup size="sm" className="mb-3 input-groups">
                <Form.Control
                    placeholder={placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    required={props.required ? props.required : false}
                    name={props.name}
                />
            </InputGroup>
        </div>
    )
}
