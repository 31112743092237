import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import NumericalInput, { CommaizedInput } from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common.js"
import { Tips, tooltip } from '../Common/Tooltip'
import CheckGroup, { CheckGroupWithImage } from "../Common/components/CheckGroup"
import ScrollBar from "../Common/components/ScrollBar"
import AddressAutocomplete from '../Common/AddressAutocomplete'
import InputGroup from 'react-bootstrap/InputGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Multiselect from '../Common/Multiselect'
import { Input, Space, InputNumber, Switch, Checkbox, Select, Table, Popconfirm } from 'antd'
import { OwnedPropertyMortgagesTable } from "./tables"
import EditableTable from "../Common/components/tables"

const customstyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)

        fontSize: '14px',
    },
    searchBox: { // To change search box element look
        fontSize: '.875rem',
        minHeight: 'calc(1.5em + .5rem + 2px)',
        padding: '.25rem .5rem',
        marginBottom: '0px !important',

    },
    inputField: { // To change input field position or margin
        margin: '0px'
    },
    chips: { // To change css chips(Selected options)
        background: 'rgba(0,48,116,1)',
        marginBottom: '2px !important',
        fontSize: '12px',
        padding: '2px 10px',
    },
    optionContainer: { // To change css for option container 
        fontSize: '12px',
    }
}

const mapStateToProps = (state) => {

    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {

        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },

    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },

    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMobileHomeInfo: (value, verb) => {
        dispatch(act.ChangeMobileHomeInfo(value, verb))
    },
    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event))
    },
    prependBorrowerOwnedProperty: () => {
        dispatch(act.PrependBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
})

class Property extends Component {

    constructor(props) {
        super(props)

        this.state = {
            validated: false,
        }
        this.isValidated = this.isValidated.bind(this)
        this.lastcompletion = 0
        this.countyref = React.createRef()
        this.stateref = React.createRef()
    }
    componentDidMount() {
        if (this.props.application.property.isManufacturedHome === null) {
            this.props.changeMainProperty(false, "isManufacturedHome")
        }
        if (this.props.application.property.isMixedUse === null) {
            this.props.changeMainProperty(false, "isMixedUse")
        }
        if (this.props.property.units === "")
            this.props.changeMainPropertyUnits({ target: { value: "2" } })
        if (this.props.property.salesprice === "") {
            this.props.changeMainPropertySalesPrice(500000)
            this.props.changeMainPropertyAppraisal(500000)
        }
        this.completion('property')
    }
    componentDidUpdate() {
        this.completion('property')
    }
    componentWillUnmount() { }
    completion = (stateKey) => {
        let keysToFilter = []
        keysToFilter = ['occupancy', 'propertytype']
        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})
        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }

    changeMainPropertyPropertyType = e => {
        com.touch()
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, com.borrower, "propertytype")
        this.props.changeMainPropertyPropertyType(e)
    }
    lookupProperty = (num, prefix, short, sfx, city, zip) => {
        let body = {
            number: num, prefix: prefix, street: short, suffix: sfx, city: city, zip: zip
        }

        let token = sessionStorage.getItem("ZeitroA")
        this.props.changeMainProperty(false, "lookupsuccessful")
        fetch('/borrower/findproperty', {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                "X-Borrower": this.props.borrowerid,
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (this.props.property.yearbuilt === "" && js.Yearbuilt !== "" && !this.props.property.lookupsuccessful) {
                        this.props.changeMainPropertyYearBuilt({ target: { value: js.Yearbuilt } })
                        this.props.changeMainPropertyUnits({ target: { value: js.Units } })
                        this.props.changeMainProperty(true, "lookupsuccessful")
                        if (this.props.application.property.purpose !== st.POL_Purchase) {
                            let dt = js.Lastsale
                            let dat = dt.split('T')[0].split('-')
                            let d = parseInt(dat[0])
                            this.props.changeMainPropertyRefinanceYearAcquired({ target: { value: d } })
                            let cost = js.Lastsaleprice.toString()
                            this.props.changeMainPropertyRefinanceOriginalCost({ target: { value: cost } })
                        }

                    }

                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }

    changeMainPropertyAddress = e => {
        //this.onStreetAddressCompletion(address)
        let address = e.target.value
        if (address === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }

        this.props.changeMainPropertyAddress(e)
    }

    propertyKnown = () => {
        return !(this.props.property.purpose === st.POL_Purchase && false === this.props.application.property.hascontract)
    }

    render = () => {

        let changeMainPropertyUnits = e => {
            com.touch()
            this.props.changeMainPropertyUnits(e)
        }
        let changeOccupancy = e => {
            com.touch()
            this.props.changeMainProperty(e, "occupancy")
        }
        let changePropertyType = e => {
            com.touch()
            if (e === 'twotofourfamily') {
                this.props.changeMainPropertyUnits({ target: { value: "2" } })
            }
            if (e === "mobilehome") {
                this.props.changeMainProperty(true, "isManufacturedHome")
            } else {
                this.props.changeMainProperty(false, "isManufacturedHome")
            }
            this.props.changeMainProperty(e, "propertytype")
        }
        let saveHomeLoanDebts = e => {
            this.props.changeMobileHomeInfo(e, "debtsToBePaid")
        }
        return (
            <div className="text-left   newInterviewPaneProperty">
                <div className="prequalInterviewTitle">Property</div>
                <div className="pt-4 " style={{ paddingRight: 10 }}>
                    <Form.Group className="inputSection">
                        <Form.Label>

                            How do you intend to use the subject property?
                        </Form.Label>
                        <CheckGroup
                            data={[{
                                value: 'principal',
                                label: 'Primary residence',
                            },
                            {
                                value: 'secondhome',
                                label: 'Secondary residence',
                            },
                            {
                                value: 'investment',
                                label: 'Investment',
                            }
                            ]}
                            onChangeSelect={changeOccupancy}
                            value={this.props.application.property.occupancy}
                            type='radio'
                            direction="horizontal" />
                    </Form.Group>
                </div>

                <div className="mt-4" style={{ paddingRight: 10 }}>
                    <Form.Group className="inputSection">
                        <Form.Label>
                            What type of the subject property are you seeking?
                            <Tips content={<div>
                                <div className="pb-3 check-group-wrap" style={{ display: "inline-flex", gap: "20px" }}>
                                    <img src="/images/singleFamily2.png" style={{ width: "52px", height: "38px" }} />
                                    <div>
                                        <div style={{ fontWeight: "600" }}>Single Family Residence:</div>
                                        <div>A single dwelling unit that has no shared property and is built on its own parcel of land.</div>
                                    </div>
                                </div>
                                <div className="pb-3" style={{ display: "inline-flex", gap: "20px" }}>
                                    <img src="/images/condo2.png" style={{ width: "52px", height: "38px" }} />
                                    <div>
                                        <div style={{ fontWeight: "600" }}>Condo</div>
                                        <div>A housing or residential complex in which there are separate units, with each unit being owned by an individual.</div>
                                    </div>
                                </div>
                                <div className="pb-3" style={{ display: "inline-flex", gap: "20px" }}>
                                    <img src="/images/multiFamily2.png" style={{ width: "52px", height: "38px" }} />
                                    <div>
                                        <div style={{ fontWeight: "600" }}>Multi-Family (2 to 4 Unit):</div>
                                        <div>A single building that's divided to accommodate more than one family living separately.</div>
                                    </div>
                                </div>
                                <div style={{ display: "inline-flex", gap: "20px" }}>
                                    <img src="/images/mobilehome.png" style={{ width: "52px", height: "38px" }} />
                                    <div>
                                        <div style={{ fontWeight: "600" }}>Mobile home:</div>
                                        <div>A mobile home is a prefabricated structure, built in a factory on a permanently attached chassis before being transported to site, used as a permanent or temporary residence.</div>
                                    </div>
                                </div>
                            </div>}
                                placement="bottom"
                                title="What are property types?" />
                        </Form.Label>
                        <CheckGroupWithImage
                            data={[
                                {
                                    value: 'singlefamilydetached',
                                    label: 'Single Family',
                                    image: '/images/singleFamily2.png',
                                    width: 38,
                                    height: 38,
                                },
                                {
                                    value: 'singlefamilyattached',
                                    label: 'Townhouse',
                                    image: "/images/townhouse2.png",
                                    width: 38,
                                    height: 38,
                                },
                                {
                                    value: 'twotofourfamily',
                                    label: 'Multi-family',
                                    image: "/images/multiFamily2.png",
                                    width: 47,
                                    height: 47,
                                },
                                {
                                    value: 'condominium',
                                    label: 'Condo',
                                    image: "/images/condo2.png",
                                    width: 38,
                                    height: 38,
                                },
                                {
                                    value: 'mobilehome',
                                    label: 'Mobile home',
                                    image: "/images/mobilehome.png",
                                    width: 38,
                                    height: 38,
                                }
                            ]}
                            onChangeSelect={changePropertyType}
                            value={this.props.application.property.propertytype}
                            type='radio'
                            hideCircle={true}
                            direction="horizontal" />
                    </Form.Group>
                </div>
                {this.props.application.property.propertytype === "twotofourfamily" &&
                    <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    What is the number of units?
                                </Form.Label>
                                <div>
                                    <Select
                                        onChange={(e) => changeMainPropertyUnits({ target: { value: e } })}
                                        value={this.props.property.units}
                                        className="w-100" style={{ height: 44 }}>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </Select>
                                </div>
                                {/* <Form.Control name="units" as="select" size="sm"
                                    value={this.props.property.units}
                                    onChange={changeMainPropertyUnits} >


                                </Form.Control> */}
                                <Form.Control.Feedback type="invalid" >
                                    Please provide number of units.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>}
                {this.props.application.property.propertytype === "mobilehome" &&
                    <div>
                        <Row className=" ">
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        What is the full name of the mobile home park?
                                    </Form.Label>
                                    <Form.Control name="units" as="input" size="sm"
                                        value={this.props.property.mobilehomeinfo.mobilehomeparkfullname}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomeparkfullname")} />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the full name of the mobile home park
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        What's the monthly rent for the mobile home's leased space?
                                    </Form.Label>
                                    <Input prefix="$" value={this.props.property.mobilehomeinfo.leasedSpaceRentAmount} onChange={e => this.props.changeMobileHomeInfo(e.target.value, "leasedSpaceRentAmount")} />
                                    {/* <CommaizedInput value={this.props.property.mobilehomeinfo.leasedSpaceRentAmount} onChange={e => this.props.changeMobileHomeInfo(e.target.value, "leasedSpaceRentAmount")} withDollarSign required />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the rent amount for the mobile home's leased space
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col className="pl-0 mt-4" md={6}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Width of the mobile home
                                    </Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={this.props.property.mobilehomeinfo.widthft}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthft")}
                                                suffix="ft"
                                                size="large"
                                            /></div>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={this.props.property.mobilehomeinfo.widthinch}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthinch")}
                                                suffix="inch"
                                                size="large"
                                            />
                                        </div>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Length of the mobile home
                                    </Form.Label>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={this.props.property.mobilehomeinfo.lengthft}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthft")}
                                                suffix="ft"
                                                size="large"
                                            />
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={this.props.property.mobilehomeinfo.lengthinch}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthinch")}
                                                suffix="inch"
                                                size="large"
                                            />
                                        </div>
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        What year of the mobile home built?
                                    </Form.Label>
                                    <div>
                                        <Input
                                            type="number"
                                            value={this.props.property.mobilehomeinfo.year}
                                            onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "year")}

                                            size="large"
                                        />
                                    </div>
                                    {/* <Form.Control name="unyearits" as="input" size="sm" type="number"
                                        value={this.props.property.mobilehomeinfo.year}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "year")} />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the year of the mobile home
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" >
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Space # of the mobile home in park
                                    </Form.Label>
                                    <Form.Control name="unyearits" as="input" size="sm"
                                        value={this.props.property.mobilehomeinfo.space}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "space")} />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the space # of the mobile home in park
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Condition of mobile home
                                    </Form.Label>
                                    <div>
                                        <Select
                                            className="w-100"
                                            style={{ height: 44 }}
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehomedescription")}

                                            value={this.props.property.mobilehomeinfo.mobilehomedescription}>
                                            <option value="New">New</option>
                                            <option value="Used">Used</option>
                                            <option value="Repo">Repo</option>
                                        </Select>
                                    </div>
                                    {/* <Form.Control name="units" as="select" size="sm"
                                        value={this.props.property.mobilehomeinfo.mobilehomedescription}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomedescription")} >
                                        <option value=""></option>
                                        <option value="New">New</option>
                                        <option value="Used">Used</option>
                                        <option value="Repo">Repo</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" >
                                        Please description of mobile home
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Type of mobile home
                                    </Form.Label>
                                    <div>
                                        <Select
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehometype")}
                                            className="w-100"
                                            style={{ height: 44 }} value={this.props.property.mobilehomeinfo.mobilehometype}>

                                            <option value="SingleWide">Single Wide</option>
                                            <option value="DoubleWide">Double Wide</option>
                                            <option value="TripleWide">Triple Wide</option>
                                        </Select>
                                    </div>
                                    {/* <Form.Control name="units" as="select" size="sm"
                                        value={this.props.property.mobilehomeinfo.mobilehometype}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehometype")} >
                                        <option value=""></option>
                                        <option value="SingleWide">Single Wide</option>
                                        <option value="DoubleWide">Double Wide</option>
                                        <option value="TripleWide">Triple Wide</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" >
                                        Please type of mobile home
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className=" mt-4 pl-0" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Location of mobile home
                                    </Form.Label>
                                    <div>
                                        <Select
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehomelocation")}
                                            className="w-100"
                                            style={{ height: 44 }} value={this.props.property.mobilehomeinfo.mobilehomelocation}>
                                            <option value="Park">Park</option>
                                            <option value="LandorHome">Land/home</option>
                                            <option value="CoopPark">Co-op park</option>
                                            <option value="SharedPark">Shared park</option>
                                        </Select>
                                    </div>
                                    {/* <Form.Control required name="units" as="select" size="sm"
                                        value={this.props.property.mobilehomeinfo.mobilehomelocation}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomelocation")} >
                                        <option value=""></option>
                                        <option value="Park">Park</option>
                                        <option value="LandorHome">Land/home</option>
                                        <option value="CoopPark">Co-op park</option>
                                        <option value="SharedPark">Shared park</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" >
                                        Please location of mobile home
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                            {this.props.property.purpose !== "purchase" && <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Original purchase price
                                    </Form.Label>
                                    <CommaizedInput value={this.props.property.mobilehomeinfo.originalpurchaseprice} onChange={e => this.props.changeMobileHomeInfo(e.target.value, "originalpurchaseprice")} withDollarSign />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the orinial purchase price
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>}
                        </Row>
                        {this.props.property.purpose === "cashoutrefinance" && <Row>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Cash out requested
                                    </Form.Label>
                                    <CommaizedInput value={this.props.property.mobilehomeinfo.cashOutRequested} onChange={e => this.props.changeMobileHomeInfo(e.target.value, "cashOutRequested")} withDollarSign />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the cash out requested
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>}
                        {this.props.property.purpose !== "purchase" && <Row className="">
                            <Col className="pl-0 mt-4" md={12} >
                                <EditableTable dataSource={this.props.property.mobilehomeinfo.debtsToBePaid}
                                    title="Add debts to be paid"
                                    columns={[
                                        {
                                            title: "Description of the debt",
                                            dataIndex: "description",
                                            type: "input",
                                        },
                                        {
                                            title: "Remaining debt amount",
                                            dataIndex: "amount",
                                            type: "inputdollar",
                                        },
                                    ]}
                                    newDataTemplate={{ amount: "", description: "" }}
                                    saveFunc={saveHomeLoanDebts}
                                />
                            </Col>
                        </Row>}
                    </div>}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Property)

const options = (() => {
    let out = []
    com.downpaymentsources.forEach(x => out.push(x.id))
    return out
})()

const mapStateToPropsMore = (state) => {

    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToPropsMore = (dispatch) => ({
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },

    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },
    changeMainPropertyRefinanceExplainOther: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExplainOther(event.target.value))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateTransaction: (event, verb) => {
        dispatch(act.UpdateTransaction(event.target.value, verb))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
})

export class MoreProperty extends Component {

    constructor(props) {
        super(props)

        this.state = {
            validated: false,
        }
        this.isValidated = this.isValidated.bind(this)
        this.inwhat = React.createRef()
        this.selected = this.parseBareSources(this.props.property.sources)
        this.other = this.getOther()
        if (this.props.property.inwhatname === "") {
            let name = this.props.application["borrower"].firstname + " " + this.props.application["borrower"].lastname
            if (this.props.application.hascoborrower === "withcoborrower" ||
                (this.props.application.hascoborrower === "multiple" && this.props.application.married)) {
                name += ", " + this.props.application["coborrower"].firstname + " " + this.props.application["coborrower"].lastname
            }
            this.props.changeMainPropertyInWhatName({ target: { value: name } })
        }
    }
    handleSubmit = (e) => {

        // TBD
        // let inw = this.props.property.inwhatname
        // if(inw.toUpperCase().indexOf(this.props.application["borrower"].firstname.toUpperCase()) < 0 || inw.toUpperCase().indexOf(this.props.application["borrower"].lastname.toUpperCase()) < 0  ) {
        //     this.inwhat.current.setCustomValidity("Borrower must be on the title!")
        //     this.setState({ validated: true })
        //     return false
        // }
        return true
    }
    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    changeMainPropertyPropertyType = e => {
        com.touch()
        if (this.props.property.purpose !== "purchase" && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, "borrower", "propertytype")
        this.props.changeMainPropertyPropertyType(e)
    }
    renderRefinance = () => {
        let changeMainPropertyRefinancePurpose = e => {
            com.touch()
            this.props.changeMainPropertyRefinancePurpose(e)
        }
        let changeMainPropertyRefinanceImprovementsStatus = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsStatus(e)
        }
        let changeMainPropertyRefinanceCost = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceCost(e)
        }
        let changeMainPropertyRefinanceExplainOther = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceExplainOther(e)
        }
        let changeMainPropertyRefinanceImprovementsDesc = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsDesc(e)
        }
        return (
            <div className="">
                <Form.Row>
                    <Col>
                        <h4 className="ml-0 heading"><div style={{ fontSize: '0.9em' }} >Cash Out Refinance Loan Details:</div></h4>
                    </Col>
                </Form.Row>
                <Form.Row>

                    <Col md="auto">
                        <Form.Group controlId="purpose" className="text-left" >
                            <Form.Label className="text-left" >Purpose of Refinance:</Form.Label>
                            <Form.Control name="purpose" as="select" size="sm"
                                defaultValue={this.props.property.refinance.purpose}
                                onChange={changeMainPropertyRefinancePurpose}
                            >
                                <option value="debtconsolidation">Debt consolidation by paying consumer debts such as credit cards or installment loans</option>
                                <option value="homeimprovement">Home Improvement</option>
                                <option value="paytorelatives">Paying off part of the equity to relatives</option>
                                <option value="cashforreserves">Obtain cash for reserves</option>
                                <option value="paidcashforsubjectproperty">Paid cash for subject property and want to replenish reserves</option>
                                <option value="payingoffheloc">Paying off second mortgage or a HELOC balance</option>
                                <option value="payingotherproperties">Paying off balances on other owned properties</option>
                                <option value="payingstudentloans">Paying off student loans only</option>
                                <option value="other">Other</option>

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Form.Row>
                <Form.Row>
                    <Col >
                        <Form.Group hidden={this.props.property.refinance.purpose !== "homeimprovement"} controlId="improv" className="text-left" >
                            <Form.Label className="text-left" >Improvements:</Form.Label>
                            <Form.Control name="improve" as="select" size="sm"
                                defaultValue={this.props.property.refinance.improvementsstatus}
                                onChange={changeMainPropertyRefinanceImprovementsStatus}
                            >
                                <option value="made">Made</option>
                                <option value="tobemade">To be made</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col >
                        {(this.props.property.refinance.improvementsstatus !== "noimprovements" && this.props.property.refinance.purpose === "homeimprovement") ?
                            <Form.Group controlId="refcost" className="text-left" hidden={this.props.property.refinance.improvementsstatus === "none"}>
                                <Form.Label className="text-left" >Their Cost:</Form.Label>

                                <NumericalInput
                                    required={this.props.property.refinance.improvementsstatus !== "noimprovements"}
                                    name="refcost"
                                    size="sm"
                                    defaultValue={this.props.property.refinance.cost}
                                    onChange={changeMainPropertyRefinanceCost}

                                    min={0}
                                />

                                <Form.Control.Feedback type="invalid" >
                                    Please provide cost of improvements.
                                </Form.Control.Feedback>
                            </Form.Group> : " "}
                    </Col>
                    <Col>
                    </Col>
                </Form.Row>
                {this.props.property.refinance.purpose === "other" ?
                    <Form.Row hidden={this.props.property.refinance.purpose !== "other"}>
                        <Col xs="auto">
                            <Form.Group controlId="explain" className="text-left" >
                                <Form.Label className="text-left" >Please write an explanation:</Form.Label>
                                <Form.Control
                                    required
                                    name="explain"
                                    type="text"
                                    as="textarea"
                                    defaultValue={this.props.property.refinance.explainotherpurpose}
                                    onChange={changeMainPropertyRefinanceExplainOther}

                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your estimated liabilities.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row> : ""}
                <Form.Row hidden={this.props.property.refinance.purpose !== "homeimprovement" || this.props.property.refinance.improvementsstatus === "noimprovements"}>
                    <Col xs="auto">
                        <Form.Group controlId="descimp" className="text-left" >
                            <Form.Label className="text-left" >Describe Improvements:</Form.Label>
                            <Form.Control

                                name="descimp"
                                type="text"
                                as="textarea"
                                defaultValue={this.props.property.refinance.improvementsdesc}
                                onChange={changeMainPropertyRefinanceImprovementsDesc}

                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            />
                            <Form.Control.Feedback type="invalid" >
                                Please provide your estimated liabilities.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </div>
        )
    }
    onSelect = e => {
        let ar = []
        let adj = []

        e.forEach(x => {
            if (x.id !== "Other") {
                ar.push(x.id)
                adj.push(x)
            } else {
                let other = " " + this.other
                ar.push(other)
                adj.push({ id: other, name: other })
            }

        })
        let out = ar.join(",")
        this.selected = adj
        com.touch()
        this.props.changeMainPropertySources({ target: { value: out } })
    }
    parseSources = v => {
        let s = []
        if (v === "") {
            return s
        }
        let ar = v.split(',')
        ar.forEach(x => {
            if (options.includes(x)) {
                s.push({ id: x, name: x })
            } else {

                if (x === '') return
                s.push({ id: "Other", name: "Other" })
            }
        })

        return s
    }
    parseBareSources = v => {
        let s = []
        if (v === "") return s
        let ar = v.split(',')
        ar.forEach(x => {

            s.push({ id: x, name: x })
        })
        return s
    }
    getOther = () => {
        let ar = this.props.property.sources.split(',')
        let o = ""
        ar.forEach(x => {
            if (x === "") return
            if (!options.includes(x)) {
                o = x.slice(1, x.length)
                o = o.replaceAll(';', ',')

            }
        })
        return o
    }
    render = () => {
        let aupdateTransaction = (verb) => {
            return e => {
                const event = {
                    target: {
                        value: e
                    }
                }
                com.touch()
                this.props.updateTransaction(event, verb)
            }
        }
        let updateTransaction = (verb) => {
            return e => {
                com.touch()
                this.props.updateTransaction(e, verb)
            }
        }

        let onSelect = e => {
            com.touch()
            this.props.changeMainPropertyInWhatManner({ target: { value: e } })
        }


        let changeMainPropertyInWhatName = e => {
            com.touch()
            this.props.changeMainPropertyInWhatName(e)
        }
        let changeMainPropertyInWhatManner = e => {
            com.touch()
            this.props.changeMainPropertyInWhatManner(e)
        }


        let hasOther = () => {
            let ar = this.props.property.sources.split(',')
            let has = false
            ar.forEach(x => {
                if (x === '') return

                if (!options.includes(x)) {
                    has = true
                }
            })

            return has
        }
        let changeOther = e => {
            let ar = []
            let adj = []

            this.selected.forEach(x => {
                if (!options.includes(x.id)) {
                    let r = " " + e.target.value.replaceAll(',', ';')
                    ar.push(r)
                    adj.push({ id: r, name: r })
                } else {
                    ar.push(x.id,)

                    adj.push({ id: x.id, name: x.name })
                }
            })
            let out = ar.join(",")
            this.selected = adj

            com.touch()
            this.other = e.target.value
            this.props.changeMainPropertySources({ target: { value: out } })

        }
        this.parseSources(this.props.property.sources)
        return (
            <div className="text-left  p-4">
                <div className=" prequalInterviewTitle">
                    Transactions
                </div>
                <div className="mt-4 ">

                    <Form.Row >
                        <Col xs="auto">
                            <Form.Group controlId="InName" className="text-left" >
                                <Form.Label className="text-left" >Title will be held in what name(s)<Tips
                                    content={
                                        <div>Specify the names of individual, or individuals, or legal entities that will hold the title. For example, husband and wife in case of joint tenancy.
                                            <div className="mt-2">Borrower <b>must</b> be on the title</div></div>
                                    }
                                    placement="bottom"
                                />
                                </Form.Label>

                                <Form.Control isValid={false} size="sm"
                                    ref={this.inwhat}
                                    required
                                    name="inname"
                                    defaultValue={this.props.property.inwhatname}
                                    onChange={changeMainPropertyInWhatName}

                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />

                                <Form.Control.Feedback type="invalid" >
                                    Please specify in what name the title will be held.
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="manner" className="text-left" >
                                <Form.Label className="text-left" >Manner in which title will be held <Tips
                                    content={<div>
                                        There are several ways that people can take title to a property. Some of the common forms are:
                                        <ul>
                                            <li><b>Joint Tenancy:</b> Each person has an undivided interest in the property.</li>
                                            <li><b>Tenants in Common:</b> Indicates the percentage of ownership in the property. It is not an undivided interest as in Joint Tenancy; for example, one party may own 30% and another party may own 70%.</li>
                                            <li><b>Sole and Separate:</b> Allows an individual to have 100% title to the property. A single or unmarried person may have this type of title.</li>
                                        </ul>
                                        It is recommended that borrowers seek legal advice before choosing an appropriate form of ownership.
                                    </div>}
                                    placement="bottom"
                                />

                                </Form.Label>
                                <div>
                                    <Select
                                        style={{ width: "100%", height: 44 }}
                                        value={this.props.property.inwhatmanner}
                                        onSelect={(e) => {

                                            onSelect(e)
                                        }}
                                    >

                                        {com.mannerthetitleheld.map((st) =>
                                        (
                                            <Select.Option value={st}>{st}</Select.Option>

                                        )
                                        )}
                                    </Select>

                                </div>
                                {/* <InputGroup>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="manner"
                                        value={this.props.property.inwhatmanner}
                                        onChange={changeMainPropertyInWhatManner}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <DropdownButton
                                        as={InputGroup.Append}
                                        variant="dropdown"
                                        className="incdropdown"
                                        title=""
                                        id="dropdown-income"
                                        size="sm"
                                        onSelect={onSelect}
                                    >   {com.mannerthetitleheld.map((st) =>
                                    (
                                        <Dropdown.Item key={st} eventKey={st} href="#">{st}</Dropdown.Item>

                                    )
                                    )}
                                    </DropdownButton>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the manner in which the title will be held.
                                    </Form.Control.Feedback>
                                </InputGroup> */}
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">

                        <Col   >
                            <Form.Group controlId="Sources" className="text-left" >
                                <Form.Label className="text-left" >Sources of Downpayment,  Settlement Charges, and/or Subordinate Financing (check all that apply)<Tips
                                    content={<div>If you are purchasing a property, there will generally be a down payment and various costs to close the loan. The funds may come from a savings account, sale of stocks or bonds, proceeds from the sale of another property, etc. Please indicate the source of the funds. If there will be other financing or subordinate financing on the property, indicate the source of funds. For example, subordinate financing may be a loan from a seller or from a 3rd party source such as a realtor.
                                    </div>}
                                    placement="bottom"
                                />


                                </Form.Label>
                                <Multiselect
                                    showCheckbox={true}
                                    required={this.parseSources(this.props.property.sources).length === 0}

                                    closeIcon="close"
                                    style={customstyle}
                                    options={com.downpaymentsources} // Options to display in the dropdown
                                    selectedValues={this.parseSources(this.props.property.sources)} // Preselected value to persist in dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    displayValue="name" // Property name to display in the dropdown options
                                />

                                {hasOther() ?
                                    <InputGroup className="pl-5">
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name="sources"
                                            value={this.getOther()}
                                            onChange={changeOther}
                                            placeholder="List other sources"

                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide other sources of downpayment, settlement charges, and/or subordinate financing.
                                        </Form.Control.Feedback>
                                    </InputGroup> : ""
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </div>
                <div className="mt-4">
                    {(this.props.property.purpose === "cashoutrefinance") ? this.renderRefinance() : ""}
                    {this.props.property.purpose === "purchase" ?
                        <div>
                            <Form.Row><Col><Form.Label>Cash deposit towards purchase</Form.Label></Col></Form.Row>
                            <Form.Row className="pt-1 mb-1 "
                            >
                                <Col xs="auto">
                                    <Form.Group controlId="Heldby" className="text-left" size="sm">
                                        <Form.Label className="text-left" >Held by<Tips
                                            content={<div>Write the name of the escrow or title company to whom you made the earnest money deposit check that was submitted along with your offer.<br /> If not sure, write "I don't know yet"
                                            </div>}
                                        />
                                        </Form.Label>
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name="heldby"
                                            type="text"
                                            defaultValue={this.props.application.transaction.cashdepositedby}
                                            onChange={updateTransaction("cashdepositedby")}

                                            pattern="^[a-zA-Z0-9 !-:&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Provide name of the escrow or title company.
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId="Amount" className="text-left" size="sm">
                                        <Form.Label className="text-left" >Amount</Form.Label>
                                        <div>
                                            <InputNumber
                                                prefix='$'
                                                min={1}
                                                size='large'
                                                style={{ width: 150 }}
                                                onChange={aupdateTransaction("depositamount")}
                                                value={this.props.application.transaction.depositamount}
                                            ></InputNumber>
                                        </div>
                                        {/* <NumericalInput isValid={false}
                                            required={(this.props.property.purpose === "purchase")}
                                            name="amount"
                                            defaultValue={this.props.application.transaction.depositamount}
                                            onChange={updateTransaction("depositamount")}
                                            size="sm"
                                            min={1}

                                        /> */}

                                        {/*  */}
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the deposit amount.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row></div> : ""}

                </div>

            </div>
        )
    }
}

MoreProperty = connect(mapStateToPropsMore, mapDispatchToPropsMore, null, { forwardRef: true })(MoreProperty)

export class DetailProperty extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            loanLimits: {}
        }
        this.lastcompletion = 0
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {

                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    }
    componentDidMount() {
        if (this.props.application.financingsameproperty === null) {
            this.props.updateSameProperty({ target: { checked: true } })
            this.updateRefinanceAddressWithResidencyAddress()
        }
        this.completion('property')
    }

    componentDidUpdate() {
        this.completion('property')
    }

    componentWillUnmount() { }
    completion = (stateKey) => {
        let keysToFilter = []
        keysToFilter = ['address', 'appraisal', 'salesprice', 'isManufacturedHome']
        if (this.props[stateKey].propertytype === "twotofourfamily") {
            keysToFilter.push('units')
        }
        if (this.props[stateKey].occupancy === "principal") {
            keysToFilter.push('isMixedUse')
        }
        if (this.props[stateKey].occupancy === "investment" || (this.props[stateKey].occupancy === "principal" && this.props.application[stateKey].propertytype === "twotofourfamily")) {
            keysToFilter.push('purchase.expectedgrossmonthlyrentalincome')
        }
        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})
        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }

    updateRefinanceAddressWithResidencyAddress = () => {
        let b = this.props.application.borrower
        let p = this.props.application.property
        if (p.address === "") {
            this.props.changeMainProperty(b.presentaddressstreet, "address")
            this.lookupAddress(b.presentaddressstreet)
        }
    }

    onAddressBlur = (e) => {
        this.timer = setTimeout(this.lookupAddress, 200)
    }

    isoccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }

    sameaddress = () => {
        let addr = com.formatAddress(this.props.application.borrower)


        return addr
    }

    changeMainPropertyAddress = e => {
        //this.onStreetAddressCompletion(address)
        let address = e.target.value
        if (address === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }

        this.props.changeMainPropertyAddress(e)
    }

    lookupAddress = (providedAddress) => {
        let now = Date.now()

        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
        if (typeof providedAddress !== "undefined") {
            addr = providedAddress
        }

        if (this.isoccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        }
        if ("" === addr)
            return
        let target = encodeURI("https://maps.googleapis.com/maps/api/geocode/json?address=" + addr + "&key=AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8")

        fetch(target, {
            method: 'GET',
        }).then(
            response => {

                if (response.status !== 200) {
                    //alert('Looks like there was a problem. Status Code: ' + response.status)
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.status === "OK") {

                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }

    onStreetAddressCompletion = address => {
        this.lastcompletion = Date.now()

        if ("undefined" !== typeof this.timer)
            clearTimeout(this.timer)
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let zip = com.getSubaddress(address, "postal_code", "long_name")
        let street = com.getSubaddress(address, "route", "short_name")

        let county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        let state = com.getSubaddress(address, "administrative_area_level_1", "short_name")
        let longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")
        if (county !== "")
            this.props.changeMainProperty(county, "county")
        this.props.changeMainProperty(longstate, "state")
        this.props.changeMainProperty(city, "city")
        this.props.changeMainProperty(zip, "zipcode")
        let addr = num + " " + street + ", " + city + ", " +
            com.getSubaddress(address, "administrative_area_level_1", "short_name") +
            " " + zip
        if (num !== "")
            this.props.changeMainPropertyAddress({ target: { value: addr } })
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")
        let shorts = short.split(' ')

        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(' ')
        }
        let prefix = ""
        let s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {

            prefix = shorts[0]
            shorts = short.split(' ')
            short = shorts.slice(1, shorts.length).join(' ')
        }

        this.props.changeMainPropertyAddress({ target: { value: addr } })

    }
    getStates = () => {
        let options = []
        let keys = Object.keys(this.state.loanLimits)
        options.push(<option value="">Select property state</option>)
        keys.forEach((x) => {
            options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
        })
        return options
    };

    getCounties = () => {
        let options = []
        options.push(<option value="">Select property county</option>)
        let st = this.props.property.state
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    };
    render() {
        let changeMainPropertyUnits = e => {
            com.touch()
            this.props.changeMainPropertyUnits(e)
        }
        let changeMainPropertyExpectedRentalIncome = e => {
            com.touch()
            this.props.changeMainProperty(e.target.value, "purchase|expectedgrossmonthlyrentalincome")
        }
        let onAppraisal = e => {
            com.touch()
            this.props.changeMainPropertyAppraisal(e)
        }
        let onSalesPrice = e => {
            com.touch()
            this.props.changeMainPropertySalesPrice(e)
        }
        let displayOwnedProperty = () => {
            return <div>
                <div className="mb-3">
                    <div className="formLabel">
                        Property status
                    </div>
                    <Select
                        className="w-100"
                        style={{ height: 44 }}
                        defaultValue={this.props.application.property.status}
                        options={[
                            { value: "sold", label: "Sold" },
                            { value: "pending", label: "Pending sale" },

                            { value: "second", label: "Second" },
                            { value: "rental", label: "Rental" },
                        ]}
                        onChange={e => this.props.changeMainProperty(e, "status")}
                    />

                </div>
                <div className="mb-3">
                    <div className="formLabel">
                        Monthly insurance, taxes, association dues, etc.
                    </div>
                    <InputNumber
                        prefix="$"
                        className="w-100"
                        defaultValue={this.props.application.property.insurancemaintenancetaxes}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={e => this.props.changeMainProperty(e, "insurancemaintenancetaxes")}
                        size="large"
                    />
                </div>

                {(
                    this.props.application.property.occupancy === "investment" ||
                    this.props.application.property.propertytype === "twotofourfamily"
                ) && <div className="mb-3">
                        <div className="formLabel">
                            Expected Monthly Rental Income
                        </div>
                        <InputNumber
                            prefix="$"
                            className="w-100"
                            defaultValue={this.props.application.property.netrentalincome}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={e => this.props.changeMainProperty(e, "netrentalincome")}
                            size="large"
                        />
                    </div>}

                <div>
                    <OwnedPropertyMortgagesTable isSubjectProperty={true} />
                </div>
            </div>

        }
        return (
            <div className="text-left newInterviewPaneProperty">
                {
                    (this.props.property.purpose === st.POL_Refinance || this.props.property.purpose === st.POL_Cashoutrefinance) ?
                        <Row className="pt-4">
                            <Col className="pl-0" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Are you refinancing your primary residence?
                                    </Form.Label>
                                    <CheckGroup
                                        data={[{
                                            value: 'no',
                                            label: "No",
                                        },
                                        {
                                            value: 'yes',
                                            label: 'Yes',
                                        },
                                        ]}
                                        onChangeSelect={e => {
                                            com.touch()
                                            let out = e === "yes"
                                            this.props.updateSameProperty({ target: { checked: out } })
                                            if (out) {
                                                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
                                                this.updateRefinanceAddressWithResidencyAddress()
                                            } else {

                                            }
                                        }}
                                        value={
                                            this.props.application.financingsameproperty === true ? "yes" : "no"
                                        }
                                        type='radio'
                                        direction="horizontal" />
                                </Form.Group>
                            </Col>
                        </Row> :
                        <div>
                            <Row className="pt-3">
                                <Col className="pl-0" >
                                    <Form.Group controlId="Adadaddress" className="text-left" >
                                        <Form.Label>What’s the address of the property you are going to purchase?</Form.Label>
                                        <AddressAutocomplete id="xyzuit" label=""
                                            autoComplete="off"
                                            onBlur={this.onAddressBlur}
                                            onCompletion={this.onStreetAddressCompletion}
                                            placeholder="Address starting with the house number"
                                            required={true} name={"xyzuit"}
                                            defaultValue={this.props.property.address}
                                            onChange={this.changeMainPropertyAddress}
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            goodfeedback=""
                                            badfeedback="Please provide address of the property"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="px-0">
                                <Col className="pl-0 mt-4" md={3} xs={12}>
                                    <Form.Group >
                                        <Select
                                            onChange={(e) => this.props.changeMainProperty(e, "state")}
                                            className="w-100"
                                            style={{ height: 44 }} value={this.props.property.state}>
                                            {this.getStates()}
                                        </Select>
                                        {/* <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            placeholder="State"
                                            onChange={(e) => {
                                                this.props.changeMainProperty(e.target.value, "state")
                                            }}
                                            value={this.props.property.state}
                                        >
                                            {this.getStates()}
                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={12} className="pl-0 mt-4">
                                    <Form.Group >
                                        <Select
                                            onChange={(e) => this.props.changeMainProperty(e, "county")}
                                            className="w-100"
                                            style={{ height: 44 }} value={this.props.property.county}>
                                            {this.getCounties()}
                                        </Select>

                                        {/* <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            onChange={(e) => {
                                                this.props.changeMainProperty(e.target.value, "county")
                                            }}
                                            value={this.props.property.county}
                                        >
                                            {this.getCounties()}
                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={12} className=" pl-0 mt-4">
                                    <Form.Group >
                                        <Form.Control
                                            required
                                            size="sm"
                                            onChange={(e) => {
                                                this.props.changeMainProperty(e.target.value, "city")
                                            }}
                                            value={this.props.property.city}
                                            placeholder="City"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="pl-0 mt-4 " md={3} xs={12} >
                                    <Form.Group >
                                        <Form.Control
                                            required
                                            size="sm"
                                            type="number"
                                            onChange={(e) => {
                                                this.props.changeMainProperty(e.target.value, "zipcode")
                                            }}
                                            value={this.props.property.zipcode}
                                            placeholder="Zip Code"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                }

                {
                    this.props.application.financingsameproperty === false && <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group controlId="Adadaddress" className="text-left" >
                                <Form.Label>What’s the address of the property you are going to refinance?</Form.Label>
                                <AddressAutocomplete id="xyzuit" label=""
                                    autoComplete="off"
                                    onBlur={this.onAddressBlur}
                                    onCompletion={this.onStreetAddressCompletion}
                                    placeholder="Address starting with the house number"
                                    required={true} name={"xyzuit"}
                                    defaultValue={this.props.property.address}
                                    onChange={this.changeMainPropertyAddress}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    goodfeedback=""
                                    badfeedback="Please provide address of the property"
                                />
                                {displayOwnedProperty()}
                            </Form.Group>
                        </Col>
                    </Row>

                }


                {this.props.application.property.propertytype === "twotofourfamily" &&
                    <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    What is the number of units?
                                </Form.Label>
                                <div>
                                    <Select
                                        onChange={(e) => changeMainPropertyUnits({ target: { value: e } })}
                                        value={this.props.property.units}
                                        className="w-100" style={{ height: 44 }}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </Select>
                                </div>
                                {/* <Form.Control name="units" as="select" size="sm"
                                    value={this.props.property.units}
                                    onChange={changeMainPropertyUnits} >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>

                                </Form.Control> */}
                                {/*  */}
                                <Form.Control.Feedback type="invalid" >
                                    Please provide number of units.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>}


                {this.props.property.occupancy === "principal" &&
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Will you use any part of this property for business?
                                </Form.Label>
                                <CheckGroup
                                    data={[{
                                        value: 'no',
                                        label: "No",
                                    },
                                    {
                                        value: 'yes',
                                        label: 'Yes',
                                    },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        let value = e === "yes"
                                        this.props.changeMainProperty(value, "isMixedUse")
                                    }}
                                    value={
                                        this.props.application.property.isMixedUse === true ? "yes" : "no"
                                    }
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {
                    this.props.property.occupancy === "secondhome" &&
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Do you intend to use the second home for work related purposes? <Tips
                                        content={<div>This is only important for <b>FHA loans</b> that have a separate category of FHA second home.
                                            If you apply for FHA loan, you'll have to provide documentation that proves that your workplace
                                            is within 100 miles from your second home. For applying non-FHA loans, please select No.</div>}
                                        placement="top"
                                        title="FHA loans only"
                                    />
                                </Form.Label>
                                <CheckGroup
                                    data={[{
                                        value: 'no',
                                        label: "No",
                                    },
                                    {
                                        value: 'yes',
                                        label: 'Yes',
                                    },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        let value = e === "yes"
                                        this.props.changeMainProperty(value, "qualifiesForFHAsecondary")
                                    }}
                                    value={
                                        this.props.application.property.qualifiesForFHAsecondary === true ? "yes" : "no"
                                    }
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                }

                <Row >
                    <Col className="pl-0 mt-4" md={6} xs={12}>
                        <Form.Label>What is the estimated home value?</Form.Label>
                        <div>
                            <Input prefix='$' value={this.props.property.appraisal} onChange={e => onAppraisal(e.target.value)} ></Input>
                        </div>
                        {/* <CommaizedInput value={this.props.property.appraisal} onChange={e => onAppraisal(e.target.value)} withDollarSign required /> */}
                        {/* <ScrollBar max={5000000} min={100000} step={10000} initVal={this.props.property.appraisal===""? 500000: parseInt(this.props.property.appraisal)}  scrollChangeValue={onAppraisal}/> */}
                    </Col>
                    {(this.props.property.purpose === st.POL_Purchase && true === this.props.application.property.hascontract) &&
                        <Col className="pl-0 mt-4" md={6} xs={12}>
                            <Form.Label>What is the sales price?</Form.Label>

                            <div>
                                <Input prefix='$' value={this.props.property.salesprice} onChange={e => onSalesPrice(e.target.value)} ></Input>
                            </div>
                            {/* <CommaizedInput value={this.props.property.salesprice} onChange={e => onSalesPrice(e.target.value)} withDollarSign required /> */}
                            {/* <ScrollBar max={5000000} min={100000} step={10000} initVal={this.props.property.salesprice===""? 500000: parseInt(this.props.property.salesprice)}  scrollChangeValue={onSalesPrice}/> */}
                        </Col>}
                </Row>
                <Row className=" mt-4">
                    <Col className="pl-0 " md={6} xs={12}>
                        <Form.Label>{this.props.application.property.purpose === st.POL_Purchase ? "What is the amount of your down payment?" : (this.props.application.property.purpose === st.POL_Refinance ? "What is the amount of your principal paydown?" : "What is your cashout amount?")}</Form.Label>
                        {/* <CommaizedInput
                            value={this.props.application.property.purpose === st.POL_Purchase ? this.props.application.selection.downpayment : (this.props.application.property.purpose === st.POL_Refinance ? this.props.application.selection.paydown : this.props.application.selection.cashout)}
                            onChange={e => {
                                this.props.updateSelectField(com.safeParseInt(e.target.value), this.props.application.property.purpose === st.POL_Purchase ? "downpayment" : (this.props.application.property.purpose === st.POL_Refinance ? "paydown" : "cashout"))
                                if (this.props.application.property.purpose === st.POL_Purchase) {
                                    let salesprice = com.safeParseInt(this.props.property.salesprice)
                                    let downpayment = com.safeParseInt(e.target.value)
                                    this.props.updateSelectField(salesprice - downpayment, "loansize")
                                }
                            }
                            }
                            withDollarSign
                            required
                        /> */}
                        <div>
                            <Input prefix='$' value={this.props.application.property.purpose === st.POL_Purchase ? this.props.application.selection.downpayment : (this.props.application.property.purpose === st.POL_Refinance ? this.props.application.selection.paydown : this.props.application.selection.cashout)} onChange={e => {
                                this.props.updateSelectField(com.safeParseInt(e.target.value), this.props.application.property.purpose === st.POL_Purchase ? "downpayment" : (this.props.application.property.purpose === st.POL_Refinance ? "paydown" : "cashout"))
                                if (this.props.application.property.purpose === st.POL_Purchase) {
                                    let salesprice = com.safeParseInt(this.props.property.salesprice)
                                    let downpayment = com.safeParseInt(e.target.value)
                                    this.props.updateSelectField(salesprice - downpayment, "loansize")
                                }
                            }
                            }></Input>
                        </div>
                    </Col>
                    <Col className="pl-0 " md={6} xs={12}>
                    </Col>
                </Row>
            </div>
        )
    }
}

DetailProperty = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DetailProperty)