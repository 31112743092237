import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Prequal.css';
import './PrequalInterview.css';

import Row from 'react-bootstrap/Col'
import { State } from '../State'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import CodeVerification from "../Common/components/CodeVerification";
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common"
import { getCurrentState, loadState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Spinner from 'react-bootstrap/Spinner'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({

    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },  
});

class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code : "",
            verified: false,
            showResult: false,
            verifingCode: false,
            sendingCode: false,
        }
    }

    sendcode = () => {
        this.setState({sendingCode: true})
        fetch("/auth/generateonetimepasscode", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email,
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid")===null? "":sessionStorage.getItem("loid"),
            }),            
            headers: {
                'Content-Type': 'application/json',
                Cache: "no-cache"                      
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log(response)
                
            } else {
                console.log(response)
                
            }
            this.setState({sendingCode: false})
        }).catch(error => {
            console.log(error)
        })
    }

    verifycode = (code) => {
        this.setState({verifingCode: true})
        fetch("/auth/verifycodeandsignup", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email,
                firstName: this.props.application.borrower.firstname,
                lastName: this.props.application.borrower.lastname,
                code: code,
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
                State: JSON.stringify(loadState()),
            }),            
            headers: {
                Cache: "no-cache"                      
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                this.setState({showResult: true, verified: false, verifingCode: false, code:""})
                return;
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({showResult: true, verified: false, verifingCode: false, code:""})
                } else {
                    sessionStorage.setItem("ZeitroA", js.Token)
                    let state = State.fromJson(JSON.parse(js.State))
                    let statejs = state.toJson()
                    sessionStorage.removeItem("state")
                    sessionStorage.setItem("state", statejs)
                    sessionStorage.setItem("originalstate", statejs)
                    sessionStorage.setItem("newcustomer", "false")
                    let path = "/prequal/interview/main/applicant"   
                    if (state.progress.interview.step) {
                        path += "/" + state.progress.interview.step
                    } 
                    window.location.href = path
                    this.setState({showResult: true, verified: true, verifingCode: false, code:""})
                }
            });
            
        }).catch(error => {
            console.log(error)
            this.setState({code: ""})
        })
    }

    componentDidMount() {
        this.sendcode()
    }


    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    handleSubmit = e => {
        if (!this.state.verified) {
            return false
        }
        return true

    }

    render() {
        const showErrorMeesage = () => {
            return (
                <div className="d-flex justify-content-center">

                    <div style={{backgroundColor:"#FFE6BF", borderRadius:"4px", padding:"10px",marginTop:"15px", maxWidth:"350px"}}>
                        <img src="/images/warningSignYellow.png" style={{width:"18px", height:"18px", marginRight:"10px"}}/>
                        <a>The code is incorrect or expired.</a>
                    </div>
                </div>
            )
        }


        if (this.state.showResult && this.state.verified ) {
            return (
                <div className="text-center mb-3">
                    <img src="/images/emailSuccess.png" style={{width:"80px", height:"78px"}}/>
                    <div className="prequalBigText">Succeed!</div>
                    <div className="prequalSmallText pt-2">
                        <div>Your account has been verified.</div>
                        <div>Continue to your application.</div>
                        
                    </div>

                </div>
            )
        }

        return (
            <div className="text-center mb-3">
                <div><img src="/images/checkEmail.png" style={{height:"71px"}}/></div>
                <div className="prequalBigText">Check your email</div>
                <div className="prequalSmallText pt-2 text-wrap">Please enter the 4 digit code sent to {this.props.application.borrower.email}</div>
                <div style={{display:"flex", justifyContent:"center", padding:"20px 0 0 10px"}}>
                {(this.state.verifingCode||this.state.sendingCode) ? <Spinner animation="border" size="lg"/> :<CodeVerification  value={this.state.code} onChange={(e)=>{
                        this.setState({code: e})
                        if (e.length === 4) {
                            this.verifycode(e)
                        }
                    }}/>}
                    
                </div>
                {(this.state.showResult && !this.state.verified ) && showErrorMeesage()}
                <div className="p-2">Didn't receive the code? <a style={{color:"blue"}} onClick={()=>{
                    this.sendcode()
                }}>Resend</a></div>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmailVerification)
