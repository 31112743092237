import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import {
  CaretUpFilled,
  CaretDownFilled
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import './SidebarHardMoneyLoan.css';
import { connect } from 'react-redux'
import { stepMap, stepNumMap } from './constants'

const mapStateToProps = (state) => {
  return {
    property: state.application.property,
    purpose: state.application.property.purpose,
    hardmoney: state.application.hardmoneyloan,
    progress: state.progress,
  }
}
export default function SidebarHardMoneyLoan(props) {
  const { section } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const renderExpandIcon = (props) => {
    if (props.isSubMenu && props.isOpen) {
      return <CaretUpFilled />;
    } else if (props.isSubMenu && !props.isOpen) {
      return <CaretDownFilled />;
    }
    return null;
  };

  const onClick = (e) => {
    props.menuClick(e);
  };

  useEffect(() => {
    if (section in stepMap) {
      let stepval = Math.min(props.progress.interview.step + 1, 4)
      stepval = Math.max(stepval, 1)
      setSelectedItem([stepNumMap[stepval]])
    } else {
      setSelectedItem([])
    }
  }, [props.progress.interview.step])

  function getItem(label, key, noIcon, children, type) {
    let icon = null
    if (!noIcon && key in stepMap) {
      if (props.progress.interview.step + 1 === stepMap[key]) icon = <img src="/images/navSignSelected.png" />
      else {
        icon = props.progress.interview.max >= stepMap[key] ? <img src="/images/navSignFinished.png" /> : <img src="/images/navSignUnselected.png" />
      }
    }
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled: stepMap[key] > props.progress.interview.max + 1
    };
  }

  const items = [
    getItem('Overview', 'overview', true),
    getItem('My Application', 'myApplication', true, [
      getItem('Borrower', 'borrower'),
      getItem('Property and Loan', 'property'),
      getItem('Experience', 'experience'),
      getItem('Declarations', 'declarations'),
    ]),
    getItem('My Tasks', 'myTasks', true),
    getItem('My Documents', 'myDocuments', true, [
      getItem('Uploaded Documents', 'documentsUploaded'),
      getItem('Received Documents', 'documentsReceived'),
    ]),
  ];


  return (
    <div className='sidenavPrequal'>
      <Menu
        className='hardMoneyLoan-menu'
        defaultSelectedKeys={['overview']}
        defaultOpenKeys={['myApplication', 'myDocuments']}
        selectedKeys={selectedItem}
        expandIcon={renderExpandIcon}
        onClick={onClick}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
      />
    </div>
  )
}

SidebarHardMoneyLoan = connect(mapStateToProps)(SidebarHardMoneyLoan);
