import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ScrollBar from "../Common/ScrollBar";
import HorizontalSliderV2 from "../Common/HorizontalSliderV2";
import "./PurchaseCalc.css";
import * as com from "../Common.js";
import NumericalInput, {CommaizedInput} from "../NumericalInput";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import RateReport from "./Preview";
import Spinner from 'react-bootstrap/Spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Helmet } from "react-helmet"
import { Tips } from "../Common/Tooltip";
import { Dropdown, Space, Input } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const PurchaseCalc = (props) => {
  window.drift.hide();
  let customerid = props.match.params["customerid"];
  if (typeof customerid === "undefined") {
    customerid = props.customerid
  }
  let loid = props.match.params["loid"];
  let forDisplayOnly = typeof props.forDisplayOnly === "undefined" ? false : true
  if (typeof loid === "undefined") {
    loid = props.loid
  }
  const [currentLoanLimit, setCurrentLoanLimit] = useState(1149825);
  const [loanLimits, setLoanLimits] = useState({});
  const [customerUrl, setCustomerUrl] = useState("https://app.zeitro.com");
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");

  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [monthlyIncome, setMonthlyIncome] = useState(30000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [escrowWaiver, setEscrowWaiver] = useState(true);

  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(8);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [currentMtype, setCurrentMtype] = useState("fixed30")

  const [message, setMessage] = useState("");
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showSubscribeResult, setShowSubscribeResult] = useState(false);
  const [subscribeResultMessage, setSubscribeResultMessage] = useState("");
  const [validatedSubscribe, setValidatedSubscribe] = useState(false);
  const subscribeFormRef = useRef()
  const [alreadysubscribe, setAlreadysubscribe] = useState(false);
  const [pastSetting, setPastSetting] = useState({});
  const [newSetting, setNewSetting] = useState({});
  const [lenderNotSet, setLendersNotSet] = useState(false)
  const [previewResult, setPreviewResult] = useState("");
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [loanType, setLoanType] = useState("Conventional");

  let termOptions = [
    { label: "30 Year", value: "fixed30" },
    { label: "25 Year", value: "fixed25" },
    { label: "20 Year", value: "fixed20" },
    { label: "15 Year", value: "fixed15" },
    { label: "10 Year", value: "fixed10" },
    { label: "ARM 5/6", value: "arm56" },
    { label: "ARM 7/6", value: "arm76" },
    { label: "ARM 10/6", value: "arm106" },
  ];
  const preselectedTermOptions = [
    { label: "30 Year",value: "fixed30" },
    { label: "15 Year",value: "fixed15" },
    { label: "ARM 7/6",value: "arm76" },
  ];

  const loanTypeMap = {
    "Conventional": ["CONFORMING", "SUPER_CONFORMING"],
    "FHA": ["FHA","FHA_HIGH_BALANCE"],
    "VA": ["VA", "VA_HIGH_BALANCE"],
    "Jumbo": ["JUMBO"],
  }

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, ltv, ltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let getEstimatedThirdPartyFee = () => {
    let lm = getLoanAmount()
    return com.commaize(3000 + 1000 * lm/1000000)
  }

  let getProducts = () => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: 3200,
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      escrowWaiver: escrowWaiver,
    };
    setLoading(true);

    fetch("/data/getrefinance/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
        "X-AGENT": (loid === null || customerid !== "individualcustomers") ? undefined : loid,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setMessage("An internal error has occured");
          return;
        }
        response.json().then((pr) => {
          if (pr.status_code === "12") {
            setLendersNotSet(true)
            setLoading(false);
            return
          }

          let products = pr.result;

          if (pr.status !== "Ok")
            setMessage(
              <div className="mt-5">
                <h4>{pr.status}</h4>
                {pr.message}
              </div>
            );
          if (products === undefined) {
            setLoading(false);
            return;
          }
          products.forEach((product) => {
            let lender = product.Lender;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let promotions = pr.promotions
              // if (commonproduct.Term !== loanTerm) return;
              let ausengine = pr.AUSEngine;
              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  promotions,
                };

                if (rate.lockDay !== 30) {
                  return;
                }
                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();

                const filterPdkt = () => {
                  if (!loanTypeMap[loanType].includes(pdkt.Mtype)) {
                    return false
                  }
                  return true
                }

                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });

          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            // keep only one negative points rate and set closing costs to zero
            // let filteredPrds = products.filter((p) => {
            //   return p.lender_credit > 0;
            // });
            // let otherPrds = products.filter((p)=>{
            //   return p.lender_credit<=0
            // })
            // otherPrds.sort(compareRate)
            // let anotherPrds = []
            // if (otherPrds.length > 0) {
            //   anotherPrds = [otherPrds[0]]
            //   anotherPrds[0].points = 0
            //   anotherPrds[0].lender_credit = 0
            // }
            // filteredPrds = [...filteredPrds, ...anotherPrds]
            let filteredPrds = products
            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              // console.log(filteredPrds[i]);
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0
            let bestPoints = 1000000
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500)
                bestIndex = i
              }
            }
            return bestIndex
          };

          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          allRates = {};
          allRates.fixed30 = fixed30;
          // allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          ;
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    }

  const getCustomerUrl = () => {
    fetch("/data/customerurls/" + customerid)
      .then((res) => {
        if (!res.ok) {
          ;
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setCustomerUrl(res.Prodapp);
      })
      .catch((err) => {
        console.error(err);
      });
    }

  const ref = useRef();
  useEffect(() => {
    window.drift.hide();
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });


  useEffect(() => {
    getProducts();
    getLoanLimits()
    getCustomerUrl()
  }, []);

  useEffect(() => {
    let currentLimit = 0
    if (Object.keys(loanLimits).length === 0){

    } else{
      if (propertyType !=="twotofourfamily"){
        currentLimit = loanLimits[state][county]["OneUnit"];
      } else{
        if (units === 2) {
          currentLimit = loanLimits[state][county]["TwoUnit"];
        }
        if (units === 3) {
          currentLimit = loanLimits[state][county]["ThreeUnit"];
        }
        if (units === 4) {
          currentLimit = loanLimits[state][county]["FourUnit"];
        }
      }
      setCurrentLoanLimit(currentLimit);
    }
  },[state, county, units, propertyType, loanLimits]);

  useEffect(() => {
    let loanamount = propertyValue - downpayment;
    if (loanamount > currentLoanLimit) {
      setLoanType("Jumbo");
    }
    if (loanamount < currentLoanLimit) {
      setLoanType("Conventional");
    }
  }, [propertyValue, downpayment, currentLoanLimit]);

  let showCounties = () => {
    let options = [<option value=""></option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value=""></option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Down payment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Downpayment can't be bigger than purchase price");
      return false;
    }
    if (fico<500 || fico > 1000) {
      setValidated(true);
      alert("Credit score should be in the range of 500-1000.");
      return false;
    }
    getProducts();
    return false;
  };

  let changeBalancefixed30 = (v) => {
    setIndexFixed30(v);
  };
  let changeBalancefixed25 = (v) => {
    setIndexFixed25(v);
  };
  let changeBalancefixed20 = (v) => {
    setIndexFixed20(v);
  };
  let changeBalancefixed15 = (v) => {
    setIndexFixed15(v);
  };
  let changeBalancefixed10 = (v) => {
    setIndexFixed10(v);
  };
  let changeBalancearm56 = (v) => {
    setIndexArm56(v);
  };
  let changeBalancearm76 = (v) => {
    setIndexArm76(v);
  };
  let changeBalancearm106 = (v) => {
    setIndexArm106(v);
  };
  function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {
    let monthlyrate = rate / 12.0;
    let expn = Math.pow(1 + monthlyrate, termmonths);
    let payment = (loansize * monthlyrate * expn) / (expn - 1);
    payment = Math.round(payment * 100) / 100;

    let accumulatedinterest =
      ((loansize * monthlyrate - payment) *
        (Math.pow(1 + monthlyrate, spanmonths) - 1)) /
        monthlyrate +
      spanmonths * payment;
    return accumulatedinterest;
  }

  let showCard = (type) => {
    if (loans.length === 0) return "";
    let cbfunc = changeBalancefixed30;
    let allloans = fixed30;
    let curindex = indexfixed30;

    if (type == "fixed25") {
      allloans = fixed25;
      cbfunc = changeBalancefixed25;
      curindex = indexfixed25;
    } else if (type == "fixed20") {
      allloans = fixed20;
      cbfunc = changeBalancefixed20;
      curindex = indexfixed20;
    } else if (type == "fixed15") {
      allloans = fixed15;
      cbfunc = changeBalancefixed15;
      curindex = indexfixed15;
    } else if (type == "fixed10") {
      allloans = fixed10;
      cbfunc = changeBalancefixed10;
      curindex = indexfixed10;
    } else if (type == "arm56") {
      allloans = arm56;
      cbfunc = changeBalancearm56;
      curindex = indexarm56;
    } else if (type == "arm76") {
      allloans = arm76;
      cbfunc = changeBalancearm76;
      curindex = indexarm76;
    } else if (type == "arm106") {
      allloans = arm106;
      cbfunc = changeBalancearm106;
      curindex = indexarm106;
    }

    let loan = allloans[curindex];
    if (loan == undefined) {
      return (
        <Container className="text-center p-3 unqualifiedTypeCard">
          <p>No qualified product for loan type {type}</p>
        </Container>
      );
    }

    const onClickMinusClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.max(0, curindex-1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.max(0, curindex-1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.max(0, curindex-1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.max(0, curindex-1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.max(0, curindex-1));
      } else if (type==="arm56") {
        setIndexArm56(Math.max(0, curindex-1));
      } else if (type==="arm76") {
        setIndexArm76(Math.max(0, curindex-1));
      } else if (type==="arm106") {
        setIndexArm106(Math.max(0, curindex-1));
      }
    }
    const onClickAddClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm56") {
        setIndexArm56(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm76") {
        setIndexArm76(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm106") {
        setIndexArm106(Math.min(allloans.length-1, curindex+1));
      }
    }
    
    return (
      <Container className="text-center p-3 ratesCard">
        <Row>
        <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Loan Type</div>
            {type.includes("fixed") ? (
              <div className="ratesCardText">{loan.Term} Year Fixed</div>
            ) : (
              <div className="ratesCardText">
                {loan.Arm.fixedperiod}/{loan.Arm.adjustmentperiod} ARM
              </div>
            )}
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Rate</div>
            <div className="ratesCardText">
              {loan.base_rate.toFixed(3)}%
            </div>
          </Col>

          <Col  className="ratesCardCol" md>
            <div className="ratesCardTitle">APR</div>
            <div className="ratesCardText">
              {loan.APR.toFixed(3)}%
            </div>
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Monthly Pay</div>
            <div className="ratesCardText">
              ${com.commaizeFloat(loan.monthly_payment.toFixed(2))}
            </div>
          </Col>

          <Col md={5} className="ratesCardClosingCostCol">
          <div className="ratesCardClosingCostRow">
            <div className="ratesCardClosingCostLabel">Points 
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip" className="text-left">
                      Mortgage points are essentially a form of prepaid interest you can choose to pay up front in exchange for a lower interest rate and monthly payments (a practice known as “buying down” your interest rate). The higher the points, the lower your monthly payment, and vice versa.
                    </Tooltip>
                  }
                >
                  <img src="/images/questionMark.png" style={{maxWidth:"15px", marginLeft:"5px"}}/>
                </OverlayTrigger>
              </div>
            <div className="ratesCardClosingCostAdjusterCol">
              <InputGroup className="ratesCardClosingCostAdjuster mt-1">
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickAddClosingCosts()}>—</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text style={{backgroundColor:"white"}}> {loan.points} % / ${com.commaizeFloat(loan.lender_credit.toFixed(0))}</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickMinusClosingCosts()}>+</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>
          </Col>
        </Row>
        {loan.promotions !== null &&
        <Row>
          <div className="promotionWrap">
            <b>Special promotion</b>: ·{loan.promotions.join(" · ")}
          </div>
        </Row>
        }
      </Container>
    );
  };

  let showCards = () => {
    let buttons = []
    let termMap = {
      fixed30:"30 Year",
      fixed25:"25 Year",
      fixed20:"20 Year",
      fixed15:"15 Year",
      fixed10:"10 Year",
      arm56:"ARM 5/6",
      arm76:"ARM 7/6",
      arm106:"ARM 10/6",
    }
    for (let mtype in allRates) {
      const buttonStyle = mtype === currentMtype ? "rate-quoter-mtype-selected" : "rate-quoter-mtype-unselected";
      buttons.push(<Button variant={buttonStyle} id={mtype} onClick={()=>setCurrentMtype(mtype)}>{termMap[mtype]}</Button>)
    }

    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date();
    const dayOfWeek = today.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // If today is Sunday (0), subtract 2 days to get Friday
      // If today is Saturday (6), subtract 1 day to get Friday
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek === 0 ? 2 : 1));
    }

    return (<div className="rate-quoter-term-selector pt-4 pb-4">
      <div className="rate-quoter-term-rates" >Rate as of {today.toLocaleDateString("en-US", options)}</div>
      <div className="rate-quoter-term-buttons">
        {buttons}
      </div>
    </div>)
  };

  let generateTableData = () => {

    if (typeof allRates[currentMtype] === "undefined") {
      return []
    }

    let point1Diff = 999
    let point1I = 0
    let point0Diff = 999
    let point0I = 0
    let indexes = new Set();


    for (var i = 0; i < allRates[currentMtype].length; i++){
      let diff0 = Math.abs(allRates[currentMtype][i].points )
      let diff1 = Math.abs(allRates[currentMtype][i].points -1 )
      if (diff0 < point0Diff) {
        point0I = i
        point0Diff = diff0
      }
      if (diff1 < point1Diff) {
        point1I = i
        point1Diff = diff1
      }
    }

    indexes.add(point0I)
    indexes.add(point1I)

    let indexdiff = 2
    if (customerid === "siliconcitymortgage"){
      indexdiff = 6
    } 

    let start = point1I+1
    while (start <= allRates[currentMtype].length-1 &&  start - point1I <=indexdiff ) {
      indexes.add(start)
      start++
    } 

    if (point0I+1 <= allRates[currentMtype].length-1) indexes.add(point0I+1)

 
    let data = []
    let indexesSorted = Array.from(indexes).sort((a, b) => a - b)
    for (let i of indexesSorted) {
      let d = allRates[currentMtype][i]
      if (typeof d !== "undefined") {
        data.push({
          rate: d.base_rate.toFixed(3) +"%",
          apr: d.APR.toFixed(3) + "%",
          monthlyPayment: "$"+com.commaizeFloat(d.monthly_payment),
          points: "$"+com.commaizeFloat(d.lender_credit),
        })
      }
    }

    return data

  }

  let showRatesForMtype = () => {
    if (typeof allRates[currentMtype] === "undefined") {
      return ""
    }
    let headerStyle = {
      borderBottom: "#D9D9D9 solid 1px",
      borderTop: "none",
    };
    const rowStyle = { border: "none", fontSize:"28px", padding: '25px 0' };
    let getStartedUrl = customerUrl + "/services/preapp?customerid=" + customerid
    if (typeof loid !== "undefined") {
      getStartedUrl = "https://app.zeitro.com/services/preapp?customerid=" + customerid + "&loid="+loid
    }
    if (customerid === "wamlending"){
      getStartedUrl = "https://wamlending.my1003app.com/326424/register"
    }
    let columns = [
      {
        dataField: "rate",
        text: "RATE",
        formatter: (cell, row) => {return <span><strong>{cell}</strong></span>}
      },
      {
        dataField: "apr",
        text: "APR",
      },
      {
        dataField: "monthlyPayment",
        text: "MON. PAY",
      },
      {
        dataField: "points",
        text: "POINTS/CREDIT",
        headerFormatter: (column, index) => {
          return <div className="d-flex justify-content-center">
          <div >{column.text}</div>
          <Tips
          placement="top"
          title=""
          content={
            <div>
              A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs.
            </div>
          }
        /></div>
        }
      },
    ]
    if (!forDisplayOnly) {
      columns.push(
        {
          dataField: "action",
          text: "",
          formatter: (cell, row) => {return <div><Button variant="rate-quoter-get-started" onClick={()=>window.open(getStartedUrl, "_blank")}>Get Started</Button></div>}
        }
      )
    }
    let data = generateTableData()
    return (
      <div className="rate-quoter-mtype-table">
        {data.length === 0 ? <div className="ratesWarning">
          <Row className="justify-content-md-center">
            <Col xs={1}>
              <img
                src="/images/warningSign.png"
                style={{ maxWidth: "20px" }}
              />
            </Col>
            <Col xs={11}>No qualified products for this mortgage type.</Col>
          </Row>
        </div> : <BootstrapTable
          bootstrap4
          keyField="rate"
          columns={columns}
          data={data}
          bordered={false}
          striped={false}
          // rowStyle={rowStyle}
          headerClasses="rate-quoter-header"
          rowClasses='rate-quoter-row'
          hover
          condensed
        />}
        
      </div>
    )
  }

  let multiSelectStyle = {
    chips: {
      background: "#505050",
    },
    multiselectContainer: {
      color: "black",
    },
    searchBox: {
      border: "none",
      "border-bottom": "2px solid black",
      "border-radius": "0px",
    },
    option: {
      background: "white",
      color: "black",
    },
  };

  let onSelectTerms = (selectedList, selectedItem) => {
    setSelectedTerms(selectedList);
  };

  let onRemoveTerms = (selectedList, removedItem) => {
    setSelectedTerms(selectedList);
  };


  let onSubscribe = (e) => {
    setShowSubscribe(true);
    setShowSubscribeResult(false);
    setSubscribeResultMessage("");
  };

  let getSubscribeModal = () => {
    const handleCloseModal = () => setShowSubscribe(false);
    const handleReset = () => {
      subscribeFormRef.current.reset();
      setValidatedSubscribe(false);
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        return
      }
      setValidatedSubscribe(true);

      let stname = [];
      selectedTerms.forEach((t) => {
        stname.push(t.label);
      });
      let [ltv, cltv, hcltv] = getLtv();
      let body = {
        Email: event.target.elements.email.value,
        Fname: event.target.elements.fname.value,
        Lname: event.target.elements.lname.value,
        Frequency: event.target.elements.frequency.value,
        SelectedTerms: stname,
        Fixed30lc:
          fixed30[indexfixed30] !== undefined
            ? fixed30[indexfixed30].lender_credit
            : 3000,
        Fixed25lc:
          fixed25[indexfixed25] !== undefined
            ? fixed25[indexfixed25].lender_credit
            : 3000,
        Fixed20lc:
          fixed20[indexfixed20] !== undefined
            ? fixed20[indexfixed20].lender_credit
            : 3000,
        Fixed15lc:
          fixed15[indexfixed15] !== undefined
            ? fixed15[indexfixed15].lender_credit
            : 3000,
        Fixed10lc:
          fixed10[indexfixed10] !== undefined
            ? fixed10[indexfixed10].lender_credit
            : 3000,
        Arm56lc:
          arm56[indexarm56] !== undefined ? arm56[indexarm56].lender_credit : 3000,
        Arm76lc:
          arm76[indexarm76] !== undefined ? arm76[indexarm76].lender_credit : 3000,
        Arm106lc:
          arm106[indexarm106] !== undefined
            ? arm106[indexarm106].lender_credit
            : 3000,
        PropertyType: com.getPropertyType(propertyType),
        LTV: ltv,
        LoanAmount: getLoanAmount(),
        Occupancy: com.getOccupancy(occupancy),
        Purpose: purpose,
        State: state,
        County: county,
        MonthlyIncome: monthlyIncome,
        MonthlyDebt: monthlyDebt,
        Fico: fico,
      };
      // console.log(body);
      setNewSetting(body);
      let token = sessionStorage.getItem("ZeitroA");
      fetch("/data/subscriberates/" + customerid, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "Content-Type": "application/json",
          "X-AGENT": loid,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Looks like there was a problem: " + response);
          return;
        }
        response.json().then((r) => {
          if (r.Status == "OK") {
            setAlreadysubscribe(false);
            setNewSetting(body);
            setShowSubscribeResult(true);
          } else {
            console.log(r);
            setPastSetting(r.Settings);
            setAlreadysubscribe(true);
            setShowSubscribeResult(true);
          }
        });
      });
      handleReset()
      setShowSubscribe(false);
    };

    let [ltv, cltv, hcltv] = getLtv();
    let ratesPreviewData = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[indexfixed30],
      fixed25: fixed25[indexfixed25],
      fixed20: fixed20[indexfixed20],
      fixed15: fixed15[indexfixed15],
      fixed10: fixed10[indexfixed10],
      arm56: arm56[indexarm56],
      arm76: arm76[indexarm76],
      arm106: arm106[indexarm106],
    }

    return (
      <Modal
        show={showSubscribe}
        onHide={handleCloseModal}
        size="lg"
        className="ratesReportModal"
        centered
      >
          <Modal.Body className="ratesReportModalBody">
            <div className="ratesReportModalDiv">
                <Form ref={subscribeFormRef} validated={validatedSubscribe} onSubmit={handleSubmit}>
              <div className="ratesReportForm">
                <div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="fname">
                      <Form.Label className="RateQuoterformLabel">First Name</Form.Label>
                      <Form.Control placeholder="Your first name" size="sm"/>
                    </Form.Group>
                  </div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="lname">
                      <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text"  placeholder="Your last name" size="sm"/>
                  </Form.Group>
                  </div>
                </div>
                <div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="email">
                    <Form.Label className="RateQuoterformLabel">Email</Form.Label>
                    <Form.Control placeholder="example@email.com" type="email" required size="sm"/>
                  </Form.Group>
                </div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="frequency">
                    <Form.Label>Report Frequency</Form.Label>
                  <Form.Control as="select" size="sm">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Control>
                </Form.Group>
                </div>
                </div>
              </div>
                <div className="ratesReportFormButton">
                  <Button variant="saveSubscribe" type="submit">
                    Save
                  </Button>
                </div>
                </Form>
              {!props.isMobile && <div className="ratesReportPreview">
                <RateReport data={ratesPreviewData} isMobile={props.isMobile}/>
              </div>}
            </div>
        {/* <br/>
            <h3>Or, send the rate report one time and right away:</h3>
            <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" required onChange={(e)=>setPreviewEmail(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
                <Button variant="primary" size="md" style={{marginTop: "32px", width: "80%"}} onClick={handleShowRatePreview}>
                 Preview
                </Button>
              </Col>

            </Form.Row>
         </Form> */}
          </Modal.Body>
          {!props.isMobile && <Modal.Footer>
            {showRatePreview && <RateReport data={previewData}/>}
            {previewResult !== "" && <Container><Row><b style={{size:"120%"}}>{previewResult}</b></Row></Container>}
            {showRatePreview && <Button variant="light" size="md" onClick={()=>setShowRatePreview(false)}>
              Hide Preview
            </Button>}
            <Button variant="light" size="md" onClick={handleCloseModal}>
              {showRatePreview? "Re-config" : "Close"}
            </Button>

          </Modal.Footer>}
      </Modal>
    );
  };

  let getSubscribeResultModal = () => {
    const handleCloseModal = () => setShowSubscribeResult(false);
    let s = (string) => {
      return string.toString().replaceAll("_", " ").toLowerCase();
    };
    let showSubscribeMessageExisted = () => {
      let data = [
        {
          field: "Loan Purpose",
          past: s(pastSetting.Purpose),
          new: s(newSetting.Purpose),
        },
        {
          field: "Loan amount",
          past: pastSetting.LoanAmount,
          new: newSetting.LoanAmount,
        },
        {
          field: "FICO Score",
          past: pastSetting.Fico,
          new: newSetting.Fico,
        },
        {
          field: "Occupancy Type",
          past: s(pastSetting.Occupancy),
          new: s(newSetting.Occupancy),
        },
        {
          field: "Property Type",
          past: s(pastSetting.PropertyType),
          new: s(newSetting.PropertyType),
        },
        {
          field: "Monthly Income",
          past: s(pastSetting.MonthlyIncome),
          new: s(newSetting.MonthlyIncome),
        },
        {
          field: "Monthly Debt",
          past: s(pastSetting.MonthlyDebt),
          new: s(newSetting.MonthlyDebt),
        },
        {
          field: "Email Frequency",
          past: s(pastSetting.Frequency),
          new: s(newSetting.Frequency),
        },
        {
          field: "Preferred Products",
          past: s(pastSetting.SelectedTerms),
          new: s(newSetting.SelectedTerms),
        },
      ];
      let columns = [
        {
          dataField: "field",
          text: "",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "past",
          text: "Past Settings",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "new",
          text: "New Settings",
          headerStyle: { backgroundColor: "white" },
        },
      ];

      return (
        <BootstrapTable
          bootstrap4
          condensed={true}
          bordered={false}
          striped={false}
          keyField="lock"
          data={data}
          columns={columns}
        />
      );
    };

    let handleUpdateSubscribe = () => {
      fetch("/data/updatesubscribe/" + customerid, {
        method: "POST",
        headers: {
          Cache: "no-cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSetting),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Error in /data/updatesubscribe:", response)
        }
      });

      setShowSubscribe(false);
      setShowSubscribeResult(false);
    };

    return (
      <Modal
        show={showSubscribeResult}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="ratesReportModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {alreadysubscribe
              ? "This email account has subscribed before"
              : "Done!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alreadySubscribedModalBody">
          {!alreadysubscribe ? (
            <div>
              <p></p>
              <p style={{ fontSize: "130%" }}>
                {newSetting.Email} will receive rates report on a{" "}
                {newSetting.Frequency} basis.
              </p>
              <p></p>
            </div>
          ) : (
            showSubscribeMessageExisted()
          )}
        </Modal.Body>

        {alreadysubscribe ? (
          <Modal.Footer>
            {!props.isMobile && <Button variant="light" size="md" onClick={handleCloseModal}>
              Close
            </Button>}
            <Button variant="update" onClick={handleCloseModal}>
              Keep Past Setting
            </Button>
            <Button variant="update" onClick={handleUpdateSubscribe}>
              Use New Setting
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="light" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  let handleShowRatePreview = () => {
    if (showRatePreview === true) {
      setShowRatePreview(false)
      return
    }
    
    let [ltv, cltv, hcltv] = getLtv();
    let data = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[Math.max(indexfixed30-1, 0)],
      fixed25: fixed25[Math.max(indexfixed25-1,0)],
      fixed20: fixed20[Math.max(indexfixed20-1,0)],
      fixed15: fixed15[Math.max(indexfixed15-1,0)],
      fixed10: fixed10[Math.max(indexfixed10-1,0)],
      arm56: arm56[Math.max(indexarm56-1,0)],
      arm76: arm76[Math.max(indexarm76-1,0)],
      arm106: arm106[Math.max(indexarm106-1,0)],
    }
  
    setPreviewData(data)
    setShowRatePreview(true)
  }

  const handleChangeSelectedTerms = (e)=>{
    setSelectedTerms(e)
  }

  const getLenderNotSetMessage = () => {

    return  <div className="ratesWarning">
    <Row className="justify-content-md-center">
      <Col xs={1}>
        <img
          src="/images/warningSign.png"
          style={{ maxWidth: "20px" }}
        />
      </Col>
      <Col xs={11}>This functionality is not ready yet. Please check later</Col>
    </Row>
  </div>
  }

  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue(100000)
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      setDownpayment(parseInt(val))
    }
  }

  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      let p = parseInt(val)
      setDownpayment(Math.round(propertyValue * 0.01 * p))
    }
  }

  const smartlook = `window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document); 
  smartlook('init', '61a897d096d8942996d3e8462ec6b167a7c6eff9', { region: 'eu' });
  smartlook('record', { forms: true, numbers: true, emails: true, ips: true });`

  const items = [];

  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue*0.6]
    } else {
      return [0, propertyValue*0.97]
    }
  }

  return (
    <div
      id="contain"
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center rateQuoteWidget"
    >
      {!window.location.hostname.includes("localhost") &&<Helmet><script type='text/javascript'>{smartlook}</script></Helmet>}
      <Container style={{background:"#F7F7F7"}} className="py-4 px-3">
        <Col className="text-center" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md={4} lg={4} xs={12}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Loan purpose
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                    <option value="CashOutRefi">Cash Out Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} lg={4} xs={12}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md={4} lg={4} xs={12}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      value={county}
                      onChange={(e) => setCounty(e.target.value)}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              </Form.Row >
              <Form.Row className="mt-3">
                <Col md={4} lg={4} xs={12}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Credit score
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="input"
                      onChange={(e) =>
                        setFico(
                          parseInt(e.target.value === "" ? 0 : e.target.value)
                        )
                      }
                      value={fico}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      {purpose === "Purchase"
                        ? "Est. purchase price"
                        : "Home value"}
                    </Form.Label>
                    <div className="rateQuoterFormSliderOutline">
                      <div className="dollarSign">$</div>
                      <CommaizedInput style={{border:"none", maxWidth:"130px", fontSize:"16px"}} value={propertyValue} onChange={updateProperValue} />
  
                      {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                    </div>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} lg={4} style={{display:"-webkit-inline-box"}}>
                  <Form.Row>
                    <Col md={8} xs={8}>
                      <Form.Group className="text-left rate-quoter-downpay">
                        <Form.Label
                          className="text-left "
                          style={{ marginBottom: "0" }}
                        >
                          {purpose === "Purchase"
                            ? "Down payment"
                            : "Mortgage balance"}
                        </Form.Label>
                        <div className="rateQuoterFormSliderOutline">
                          <div className="dollarSign">$</div>
                          {/* <div className="rateQuoterFormText">{"$ " + com.commaize(downpayment)}</div> */}
                          <CommaizedInput style={{border:"none", width:"100%", fontSize:"16px",marginTop:"2px", maxHeight:"40px"}} value={downpayment} onChange={updateDownpayment} />
                          {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={getDownpaymentDomain()} defaultValues={[downpayment]} onChange={setDownpayment} onUpdate={setDownpayment} step={1000}/></div> */}
                        </div>
                      </Form.Group>
                    </Col>
                    {purpose === "Purchase" ? <Col md={4} xs={4} className="d-flex align-items-end" style={{paddingBottom:"2px"}}><Input className="rate-quoter-ltv" suffix="%" defaultValue={100-getLtv()[0]} value={100-getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{borderRadius: "4px"}}/></Col> :
                      <Col md={4} xs={4} style={{paddingBottom:"2px"}} className="d-flex align-items-end pb-1"><Input className="rate-quoter-ltv" suffix="%" defaultValue={getLtv()[0]} value={getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{borderRadius: "4px"}}/></Col>
                    }
                  </Form.Row>
                </Col>
              </Form.Row>  

              <Form.Row className="mt-3">
                <Col xs={12} md={4} lg={4}>
                    <Form.Group controlId="loantype" className="text-left ">
                      <Form.Label style={{ marginBottom: "0" }}>
                        Loan type
                      </Form.Label>
                      <Form.Control
                        name="loantype"
                        as="select"
                        size="sm"
                        type="text"
                        required
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      >
                        <option value="Conventional">Conventional</option>
                        <option value="FHA">FHA</option>
                        <option value="VA">VA</option>
                        <option value="Jumbo">Jumbo</option>
                      </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4} lg={4}>
                  <Form.Group className="text-left monthlyIncomeWrap">
                      <Form.Label style={{ marginBottom: "0" }}>
                        Monthly income
                      </Form.Label>
                      <NumericalInput
                        size="sm"
                        value={monthlyIncome}
                        required
                        onChange={(e) => setMonthlyIncome(e.target.value)}
                        min={0}
                      />
                  </Form.Group>
                </Col>
                <Col xs={6} md={4} lg={4}>
                  <Form.Group className="text-left monthlyDebtWrap">
                    <Form.Label style={{ marginBottom: "0" }}>
                    Monthly liability
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={monthlyDebt}
                      required
                      onChange={(e) => setMonthlyDebt(e.target.value)}
                      min={0}
                    />
                  </Form.Group>   
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} lg={4} className="rate-quoter-filters-wrap">
                
                <Dropdown
                menu={{
                  items,
                }}
                  dropdownRender={(menu) => (
                          <div >
                            {React.cloneElement(menu, {
                    style: {
                      boxShadow: 'none',
                    },
                  })}
                      <Space
                        style={{
                          padding: 30,
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
                          backgroundColor:"white",
                        }}
                        className="d-block"
                      >
                        
                        <div>
                    <Form.Group controlId="propertytype" className="text-left ">
                      <Form.Label style={{ marginBottom: "0" }}>
                        Property Type
                      </Form.Label>
                      <Form.Control
                        name="propertytype"
                        as="select"
                        size="sm"
                        type="text"
                        required
                        value={propertyType}
                        onChange={(e) => setPropertyType(e.target.value)}
                      >
                        <option value="singlefamilydetached">
                          Single Family Home, Detached
                        </option>
                        <option value="singlefamilyattached">
                          Single Family Home, Attached
                        </option>
                        <option value="condominiumdetached">
                          Condominium, Detached
                        </option>
                        <option value="condominium">Condominium, Attached</option>
                        <option value="pudattached">
                          Planned Unit Development, attached
                        </option>
                        <option value="puddetached">
                          Planned Unit Development, detached
                        </option>
                        <option value="twotofourfamily">
                          2 to 4 Unit Multifamily
                        </option>
                        <option value="manufacturedhome">
                          Manufactured Home
                        </option>
                      </Form.Control>
                    </Form.Group>
                    </div>
                  {propertyType === "twotofourfamily" ? (
                    <div className="mt-3" >
                      <Form.Group controlId="propertytype" className="text-left ">
                        <Form.Label style={{ marginBottom: "0" }}>
                          Number of Units
                        </Form.Label>
                        <Form.Control
                          name="propertytype"
                          as="select"
                          size="sm"
                          type="text"
                          required
                          value={units}
                          onChange={(e) => setUnits(e.target.value)}
                        >
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-3">
                    <Form.Group controlId="occupancy" className="text-left ">
                      <Form.Label
                        className="text-left "
                        style={{ marginBottom: "0" }}
                      >
                        Occupancy
                      </Form.Label>
                      <Form.Control
                        name="occupancy"
                        as="select"
                        size="sm"
                        defaultValue={occupancy}
                        onChange={(e) => {
                          onOccupancy(e.target.value);
                        }}
                      >
                        <option value="principal">Primary Residence</option>
                        <option value="secondhome">Second Home</option>
                        <option value="investment">Investment</option>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className="mt-3" >
                      <Form.Group controlId="impound" className="text-left ">
                        <Form.Label style={{ marginBottom: "0" }}>
                          Impound/Escrow
                        </Form.Label>
                        <Form.Control
                          name="propertytype"
                          as="select"
                          size="sm"
                          type="text"
                          required
                          value={escrowWaiver}
                          onChange={(e) => setEscrowWaiver(e.target.value === "true" ? true : false)}
                        >
                          <option value={true}>No</option>
                          <option value={false}>Yes</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                      </Space>
                    </div>
                  )}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space className="dropdownFilter">
                      <img src="/images/moreFilters.png" className="dropdownFilterImage" />
                      More filters
                    </Space>
                  </a>
                </Dropdown>

                <Button type="submit" variant="rate-quoter-update">
                    Check rates
                </Button>
                </Col>
              </Form.Row>

          </Form>
        </Col>
      </Container>

      <Container>

        <Row>
        {showRatePreview && <RateReport data={previewData} show={showRatePreview} onHide={()=>setShowRatePreview(false)}/>}
        </Row>
      </Container>

      {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <Spinner animation="border" size="lg" />
            </div>
          </div>
        </Row>
      ) : (
        <Container>
          {lenderNotSet && getLenderNotSetMessage()}
          {(!lenderNotSet && message === "") ? (
            <div>
              <div>{showCards()}</div>
              <div>{showRatesForMtype()}</div>
              {/* <div className="ratesDisclaimerDiv">
                <div>Estimated third party fee: {getEstimatedThirdPartyFee()}</div>
              </div> */}
              <div className="rate-quoter-disclaimer"><InfoCircleOutlined className="mr-1 d-inline-flex"/>Please note that interest rates are subject to change based on market conditions and other factors outside of our control. The displayed rates are intended for a 30-day lock period and may not reflect the current rates at the time of your application. We recommend that consumers contact our loan officers to confirm the rate lock and discuss any questions or concerns they may have.</div>
              {!forDisplayOnly && <div className="getRatesReportDiv mb-3">
                <Container className="getRatesReport">
                <div className="getRatesReportCol1"><img className="getRatesReportImg" src="/images/getRatesReport.png" style={{maxWidth:"60px"}} /></div>
                <div className="getRatesReportCol2">
                  <div className="getRatesReportTitle">Get your personalized rates report</div>
                  <div className="getRatesReportText">Enter your email address and delivery frequency, you will receive a personalized rates report based on your inputs. </div>
                </div>
                <div className="getRatesReportCol3">
                  <Button
                    variant="getRatesReportImgButton"
                    onClick={onSubscribe}
                    >
                    Get Rates Report
                  </Button>
                  </div>
                </Container>
                  <div className="getRatesReportButton2">
                    <Button
                      variant="getRatesReportImgButton"
                      onClick={onSubscribe}
                      >
                      Get Rates Report
                    </Button>
                  </div>
              </div>}
            </div>
            

          ) : (
            <div className="text-wrap w-100">{message}</div>
          )}
        </Container>
      )}
      
      {showSubscribeResult ? getSubscribeResultModal() : ""}
      {showSubscribe && !showSubscribeResult ? getSubscribeModal() : ""}
    </div>
  );
};
export default withRouter(PurchaseCalc);
