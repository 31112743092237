import * as interview from '../Defs/interviewdefs'
import * as loinput from '../Defs/loinput'


export function updateApplication(t) {
    return { type: interview.CHANGE_APPLICATION, payload: t };
}
// interview functions
export function UpdateHasCoborrower(t) {
    return { type: interview.CHANGE_HAS_COBORROWER, payload: t };
}
export function UpdateApplicationAttribute(t, verb) {
    return { type: interview.CHANGE_APPLICATION_ATTRIBUTE, payload: t,  verb: verb  };
}
export  function UpdateHardMoneyLoanAttribute(t, verb) {
    return { type: interview.CHANGE_HARDMONEYLOAN_ATTRIBUTE, payload: t, verb: verb}
    
}
export function UpdateBorrowerAttribute(who, t, verb) {
    return { type: interview.CHANGE_BORROWER_ATTRIBUTE, who, payload: t,  verb  };
}
export function UpdateSelectedLoanAttribute(t, verb) {
    return { type: interview.CHANGE_CHOSENLOAN_ATTRIBUTE, payload: t,  verb: verb  };
}

export function UpdateCohabiting(t) {
    return { type: interview.CHANGE_COHABITING, payload: t };
}
export function UpdateCurrentResidence(t) {
    return {type: interview.CHANGE_RENTOROWN_CURRENT_RESIDENCE, payload: t}
}
export function UpdateSameProperty(t) {
    return {type: interview.CHANGE_FINANCING_SAMEPROPERTY, payload: t}
}
export function AddAdditionaOccupation(t, who) {
    return { type: interview.ADD_ADDITIONAL_OCCUPATION, payload: t, who: who };
}
export function RemoveAdditionalOccupation(t, who, i) {
    return { type: interview.REMOVE_ADDITIONAL_OCCUPATION, payload: t, who: who, index: i };
}
export function ClearAdditionalOccupations(t, who) {
    return { type: interview.CLEAR_ADDITIONAL_OCCUPATIONS, payload: t, who: who };
}
export function UpdateBorrowerHasOccupation(t) {
    return { type: interview.CHANGE_BORROWER_HASOCCUPATION, payload: t };
}
export function UpdateIncomeMonthly(t, who, verb) {
    return { type: interview.CHANGE_INCOME_MONTHLY, payload: t, who: who, verb: verb  };
}
export function UpdateIncomeAtttribute(t, who, verb) {
    return { type: interview.CHANGE_INCOME_ATTRIBUTE, payload: t, who: who, verb: verb  };
}
export function UpdateIncomeAttribute(t, who, verb) {
    return { type: interview.CHANGE_INCOME_ATTRIBUTE, payload: t, who: who, verb: verb  };

}
export function SetBorrowerHasAdditionalIncome(t, who) {
    return { type: interview.HAS_ADDITIONAL_INCOME, payload: t, who: who };
}

export function AddBorrowerAdditionalIncome(t, who) {
    return { type: interview.ADD_ADDITIONAL_INCOME, payload: t, who: who };
}
export function RemoveBorrowerAdditionalIncome(t, who) {
    return { type: interview.REMOVE_ADDITIONAL_INCOME, payload: t, who: who };
}
export function ClearBorrowerAdditionalIncome(who) {
    return { type: interview.CLEAR_ADDITIONAL_INCOME, who: who };
}

export function UpdateBorrowerAdditionalIncome(t, who, verb, n) {
    return { type: interview.CHANGE_ADDITIONAL_INCOME, payload: t, who:who, verb:verb, index: n };
}
export function UpdateExpenses(t, who, verb) {
    return { type: interview.CHANGE_EXPENSES, payload: t, who:who, verb:verb };
}
export function UpdateJointly(t) {
    return { type: interview.CHANGE_JOINTLY, payload: t };
}
export function UpdateFinances(t, who, verb) {
    return { type: interview.CHANGE_FINANCES, payload: t, who: who, verb: verb };
}
export function AddBorrowerBankAccount(t, who) {
    return { type: interview.ADD_BORROWER_BANKACCOUNT, payload: t, who:who };
}
export function BulkAddBorrowerBankAccounts(t, who) {
    return { type: interview.BULKADD_BORROWER_BANKACCOUNTS, payload: t, who:who };
}
export function RemoveBorrowerBankAccount(t, who) {
    return { type: interview.REMOVE_BORROWER_BANKACCOUNT, payload: t, who:who };
}
export function ClearBorrowerBankAccounts(t, who) {
    return { type: interview.CLEAR_BORROWER_BANKACCOUNTS, payload: t, who:who };
}
export function ChangeBorrowerBankName(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BANKNAME, payload: t, index: i, who:who };
}
export function ChangeBorrowerBankAccount(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BANKACCOUNT, payload: t, index: i, who: who };
}
export function ChangeBorrowerBankBalance(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BANKBALANCE, payload: t, index: i, who: who };
}
export function ChangeBorrowerBankAccountType(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BANKACCOUNTTYPE, payload: t, index: i, who: who };
}
export function ChangeBorrowerBankAttribution(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BANKATTRIBUTION, payload: t, index: i, who: who };
}

export function AddBorrowerBrokerAccount(t, who) {
    return { type: interview.ADD_BORROWER_BROKERACCOUNT, payload: t, who:who };
}
export function RemoveBorrowerBrokerAccount(t, who) {
    return { type: interview.REMOVE_BORROWER_BROKERACCOUNT, payload: t, who:who };
}
export function ClearBorrowerBrokerAccounts(t, who) {
    return { type: interview.CLEAR_BORROWER_BROKERACCOUNTS, payload: t, who:who };
}
export function ChangeBorrowerBrokerName(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERNAME, payload: t, index: i, who:who };
}
export function ChangeBorrowerBrokerAccount(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERACCOUNT, payload: t, index: i, who: who };
}
export function ChangeBorrowerBrokerBalance(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERBALANCE, payload: t, index: i, who: who };
}
export function ChangeBorrowerBrokerAttribution(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERATTRIBUTION, payload: t, index: i, who: who };
}
export function ChangeBorrowerBrokerAllocation(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERALLOCATION, payload: t, index: i, who: who };
}

export function ChangeBorrowerInsuranceValue(t, i, verb) {
    return { type: interview.CHANGE_BORROWER_CHANGE_INSURANCE, payload: t, index: i, verb: verb };
}
export function AddBorrowerInsurance(t) {
    return { type: interview.CHANGE_BORROWER_ADD_INSURANCE, payload: t };
}
export function ClearBorrowerInsurance(t) {
    return { type: interview.CHANGE_BORROWER_CLEAR_INSURANCE, payload: t };
}
export function RemoveBorrowerInsurance(t, i) {
    return { type: interview.CHANGE_BORROWER_DELETE_INSURANCE, payload: t, index: i };
}


export function ChangeBorrowerBrokerTaxStatus(t, i, who) {
    return { type: interview.CHANGE_BORROWER_BROKERTAXSTATUS, payload: t, index: i, who: who };
}


export function AddBorrowerAuto(t, who) {
    return { type: interview.ADD_BORROWER_AUTO, payload: t, who:who };
}
export function RemoveBorrowerAuto(t, who) {
    return { type: interview.REMOVE_BORROWER_AUTO, payload: t , who:who};
}
export function ClearBorrowerAutos(t, who) {
    return { type: interview.CLEAR_BORROWER_AUTOS, payload: t, who:who };
}
export function ChangeBorrowerAutoMake(t, i, who) {
    return { type: interview.CHANGE_BORROWER_AUTO_MAKE, payload: t, index: i, who:who };
}
export function ChangeBorrowerAutoYear(t, i, who) {
    return { type: interview.CHANGE_BORROWER_AUTO_YEAR, payload: t, index: i, who:who };
}
export function ChangeBorrowerAutoValue(t, i, who) {
    return { type: interview.CHANGE_BORROWER_AUTO_VALUE, payload: t, index: i, who:who };
}
export function AddBorrowerOtherAsset(t, who) {
    return { type: interview.ADD_BORROWER_OTHER_ASSET, payload: t, who:who };
}
export function BulkAddBorrowerOtherAssets(t, who) {
    return { type: interview.BULKADD_BORROWER_OTHER_ASSETS, payload: t, who:who };
}
export function RemoveBorrowerOtherAsset(index, who) {
    return { type: interview.REMOVE_BORROWER_OTHER_ASSET, payload:index, who:who };
}
export function ClearBorrowerOtherAssets(t, who) {
    return { type: interview.CLEAR_BORROWER_OTHER_ASSETS, payload: t, who:who };
}
export function ChangeBorrowerOtherAssetName(t, i, who) {
    return { type: interview.CHANGE_BORROWER_OTHER_ASSET_NAME, payload: t, index: i, who:who };
}
export function ChangeBorrowerOtherAssetValue(t, i,  who) {
    return { type: interview.CHANGE_BORROWER_OTHER_ASSET_VALUE, payload: t, index: i,  who:who };
}

export function ChangeBorrowerOtherAssetAttribution(t, i){
    return { type: interview.CHANGE_BORROWER_OTHER_ASSET_ATTRIBUTION, payload: t, index: i };
}
export function ChangeBorrowerOtherAsset(t, verb, i){
    return { type: interview.CHANGE_BORROWER_OTHER_ASSET, payload: t, verb, index: i };
}

export function AddBorrowerLoan(t, who) {
    return { type: interview.ADD_BORROWER_LOAN, payload: t, who: who };
}
export function BulkAddBorrowerLoans(t, who) {
    return { type: interview.BULKADD_BORROWER_LOANS, payload: t, who: who };
}
export function CopyBorrowerLoan(t, who) {
    return { type: interview.COPY_BORROWER_LOAN, payload: t, who: who };
}

export function RemoveBorrowerLoan(t, i, who) {
    return { type: interview.REMOVE_BORROWER_LOAN, payload: t, index: i, who: who };
}
export function ClearBorrowerLoans(t, who) {
    return { type: interview.CLEAR_BORROWER_LOANS, payload: t, who: who };
}
export function ChangeBorrowerLoanCompany(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_COMPANY, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanAddress(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_ADDRESS, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanAccount(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_ACCOUNT, payload: t, index: i , who: who};
}
export function ChangeBorrowerLoanMonthly(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_MONTHLY, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanRemaining(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_REMAINING, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoan(payload, index, verb) {
    return { type: interview.CHANGE_BORROWER_LOAN, payload, index, verb };
}
export function ChangeBorrowerLoanSatisfiedUpon(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_SATISFIEDUPON, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanRate(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_RATE, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanForSubject(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_FORSUBJECT, payload: t, index: i, who: who };
}

export function ChangeBorrowerLoanCorrection(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_CORRECTION, payload: t, index: i, who: who };
}
export function ChangeBorrowerLoanExplanation(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_EXPLANATION, payload: t, index: i, who: who };
}
export function ChangeBorrowerMonthsLeftToPay(t, i, who) {
    return { type: interview.CHANGE_BORROWER_LOAN_MONTHSLEFTTOPAY, payload: t, index: i, who: who };
}

export function BulkAddBorrowerOwnedProperties(t) {
    return { type: interview.BULKADD_BORROWER_OWNED_PROPERTIES, payload: t };
}
export function AddBorrowerOwnedProperty(t) {
    return { type: interview.ADD_BORROWER_OWNED_PROPERTY, payload: t };
}
export function PrependBorrowerOwnedProperty() {
    return { type: interview.PREPEND_BORROWER_OWNED_PROPERTY };
}
export function RemoveBorrowerOwnedProperty(t) {
    return { type: interview.REMOVE_BORROWER_OWNED_PROPERTY, payload: t };
}
export function ClearBorrowerOwnedProperty(t) {
    return { type: interview.CLEAR_BORROWER_OWNED_PROPERTY, payload: t };
}
export function ChangeOwnedProperty(t, i, verb) {
    return { type: interview.CHANGE_OWNED_PROPERTY, payload: t, index: i, verb: verb };
}

export function AddBorrowerAdditionalProperty(t, who) {
    return { type: interview.ADD_BORROWER_ADDITIONAL_PROPERTY, payload: t, who: who };
}
export function RemoveBorrowerAdditionalProperty(t, who) {
    return { type: interview.REMOVE_BORROWER_ADDITIONAL_PROPERTY, payload: t, who: who };
}
export function ClearBorrowerAdditionalProperty(t, who) {
    return { type: interview.CLEAR_BORROWER_ADDITIONAL_PROPERTY, payload: t, who: who };
}
export function ChangeAdditionalProperty(t, i, verb, who) {
    return { type: interview.CHANGE_ADDITIONAL_PROPERTY, payload: t, index: i, verb: verb, who: who };
}
export function ChangeBorrowerAdditionalPropertyAddress(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_ADDRESS, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyStatus(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_STATUS, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyType(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_TYPE, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyValue(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_VALUE, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyLiens(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_LIENS, payload: t, index: i , who: who};
}
export function ChangeBorrowerAdditionalPropertyGrossIncome(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_GROSSINCOME, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyPayment(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_PAYMENT, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyExpenses(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_EXPENSES, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalPropertyNetIncome(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_NETINCOME, t, i, who };
}
export function ChangeBorrowerAdditionalPropertyAttribute(value, index, verb) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTE, value, index, verb};
}

export function ChangeBorrowerAdditionalPropertyAttribution(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTION, payload: t, index: i, who: who };
}

export function ChangeBorrowerFreeAndClear(t, i, who) {
    return { type: interview.CHANGE_BORROWER_FREEANDCLEAR, payload: t, who: who };
}
export function ChangeReviewedLoans(t) {
    return { type: interview.CHANGE_REVIEWED_LOANS, payload: t };
}



export function AddBorrowerAdditionaCredits(t, who) {
    return { type: interview.ADD_BORROWER_ADDITIONAL_CREDITS, payload: t, who: who };
}
export function RemoveBorrowerAdditionalCredits(t, who) {
    return { type: interview.REMOVE_BORROWER_ADDITIONAL_CREDITS, payload: t, who: who };
}
export function ClearBorrowerAdditionalCredits(t, who) {
    return { type: interview.CLEAR_BORROWER_ADDITIONAL_CREDITS, payload: t, who: who };
}
export function ChangeBorrowerAdditionalCreditAltName(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_CREDIT_ALTNAME, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalCreditCreditor(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_CREDIT_CREDITOR, payload: t, index: i, who: who };
}
export function ChangeBorrowerAdditionalCreditAccount(t, i, who) {
    return { type: interview.CHANGE_BORROWER_ADDITIONAL_CREDIT_ACCOUNT, payload: t, index: i, who: who };
}
export function ChangeMainPropertyDescription(t) {
    return { type: interview.CHANGE_PROPERTY_DESCRIPTION, payload: t };
}

export function ChangeMainPropertyAddress(t) {
    return { type: interview.CHANGE_PROPERTY_ADDRESS, payload: t };
}
export function ChangeMainPropertyOccupancy(t) {
    return { type: interview.CHANGE_PROPERTY_OCCUPANCY, payload: t };
}
export function ChangeMainPropertyPurpose(t) {
    return { type: interview.CHANGE_PROPERTY_PURPOSE, payload: t };
}
export function ChangeMainPropertyAppraisal(t) {
    return { type: interview.CHANGE_PROPERTY_APPRAISAL, payload: t };
}
export function ChangeMainPropertySalesPrice(t) {
    return { type: interview.CHANGE_PROPERTY_SALESPRICE, payload: t };
}
export function ChangeMainPropertyAttribute(t, verb) {
    return { type: interview.CHANGE_PROPERTY_ATTRIBUTE, payload: t, verb };
}
export function ChangeMainPropertyUnits(t) {
    return { type: interview.CHANGE_PROPERTY_UNITS, payload: t };
}
export function ChangeMainPropertyYearBuilt(t) {
    return { type: interview.CHANGE_PROPERTY_YEARBUILT, payload: t };
}
export function ChangeMainPropertyInWhatName(t) {
    return { type: interview.CHANGE_PROPERTY_INWHATNAME, payload: t };
}
export function ChangeMainPropertyEstateHeldIn(t) {
    return { type: interview.CHANGE_PROPERTY_ESTATEHELDIN, payload: t };
}
export function ChangeMainPropertyInWhatManner(t) {
    return { type: interview.CHANGE_PROPERTY_INWHATMANNER, payload: t };
}
export function ChangeMainPropertySources(t) {
    return { type: interview.CHANGE_PROPERTY_SOURCES, payload: t };
}
export function ChangeMainPropertyRefinanceYearAcquired(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_YEARACQUIRED, payload: t };
}
export function ChangeMainPropertyRefinanceOriginalCost(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_ORIGINALCOST, payload: t };
}
export function ChangeMainPropertyRefinancePurpose(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_PURPOSE, payload: t };
}
export function ChangeMainPropertyRefinanceImprovementsDesc(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSDESC, payload: t };
}
export function ChangeMainPropertyRefinanceImprovementsStatus(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSSTATUS, payload: t };
}
export function ChangeMainPropertyRefinanceCost(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_COST, payload: t };
}
export function ChangeMainPropertyRefinanceExplainOther(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_EXPLAINOTHER, payload: t };
}
export function ChangeMainPropertyRefinanceAttribute(t, verb) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_ATTRIBUTE, payload: t, verb };
}

export function ChangeMainPropertyConstructionYearAcquired(t) {
    return { type: interview.CHANGE_PROPERTY_CONSTRUCTION_YEARACQUIRED, payload: t };
}
export function ChangeMainPropertyConstructionOriginalCost(t) {
    return { type: interview.CHANGE_PROPERTY_CONSTRUCTION_ORIGINALCOST, payload: t };
}
export function ChangeMainPropertyConstructionExistingLiens(t) {
    return { type: interview.CHANGE_PROPERTY_CONSTRUCTION_EXISTINGLIENS, payload: t };
}
export function ChangeMainPropertyRefinanceExistingLiens(t) {
    return { type: interview.CHANGE_PROPERTY_REFINANCE_EXISTINGLIENS, payload: t };
}
export function ChangeMainPropertyConstructionPresentValue(t) {
    return { type: interview.CHANGE_PROPERTY_CONSTRUCTION_PRESENTVALUE, payload: t };
}
export function ChangeMainPropertyConstructionCostOfImprovements(t) {
    return { type: interview.CHANGE_PROPERTY_CONSTRUCTION_COSTOFIMPROVEMENTS, payload: t };
}
export function ChangeDeclaration(t, who, v) {
    return { type: interview.CHANGE_DECLARATIONS, payload: t, who: who, verb: v };
}
export function ChangeBorrowerInfo(t, who, v) {
    return { type: interview.CHANGE_BORROWER_INFO, payload: t, who: who, verb: v };
}
export function ChangeOccupationInfo(t, who, v) {
    return { type: interview.CHANGE_OCCUPATION_INFO, payload: t, who: who, verb: v };
}
export function ChangePastOccupationInfo(t, who, v, i) {
    return { type: interview.CHANGE_PAST_OCCUPATION_INFO, payload: t, who: who, verb: v, index: i };
}
export function ChangeMainPropertyExpirationDate(t) {
    return { type: interview.CHANGE_MAINPROPERTY_EXPIRATION, payload: t };
}

export function ChangeMainPropertyPropertyType(t) {
    return { type: interview.CHANGE_MAINPROPERTY_PROPERTYTYPE, payload: t };
}
export function ChangeMainProperty(t, verb) {
    return { type: interview.CHANGE_MAINPROPERTY, payload: t, verb: verb };
}
export function ChangeMobileHomeInfo(t, verb) {
    return { type: interview.CHANGE_MOBILE_HOME_INFO, payload: t, verb: verb };
}

export function UpdateEqualOpportunity(t, who, verb) {
    return { type: interview.CHANGE_EQUAL_OPPORTUNITY, payload: t, who: who, verb: verb  };
}
export function UpdateEqualOpportunitySub(t, who, section, verb) {
    return { type: interview.CHANGE_EQUAL_OPPORTUNITY_SUB, payload: t, who: who, section: section, verb: verb  };
}
export function UpdateSelectField(t, verb) {
    return { type: interview.CHANGE_SELECTION_FIELD, payload: t, verb: verb  };
}

export function UpdateSelectedLoanField(t, verb) {
    return { type: interview.CHANGE_SELECTEDLOAN_FIELD, payload: t, verb: verb  };
}
export function UpdateProduct(t, verb) {
    return { type: loinput.CHANGE_PRODUCT, payload: t,  verb: verb  };
}
export function UpdateTransaction(t, verb) {
    return { type: loinput.CHANGE_TRANSACTION, payload: t,  verb: verb  };
}

export function UpdateSelectedLoanProductField(t, index, verb) {
    return { type: interview.CHANGE_SELECTEDLOAN_PRODUCT_FIELD, payload: t, index, verb: verb  };
}


export function UpdatePreviousAddress(t, who, verb, n) {
    return { type: interview.CHANGE_PREVIOUS_ADDRESS, payload: t, who:who, verb:verb, index: n };
}

export function AddPreviousAddress(t, who) {
    return { type: interview.ADD_PREVIOUS_ADDRESS, payload: t, who:who };
}
export function RemovePreviousAddress(t, who, i) {
    return { type: interview.REMOVE_PREVIOUS_ADDRESS, payload: t, who:who, index:i };
}
export function ClearPreviousAddress(t, who) {
    return { type: interview.CLEAR_PREVIOUS_ADDRESS, payload: t, who:who };
}

export function BulkAddAlimonys(t, who) {
    return { type: interview.BULKADD_BORROWER_ALIMONYS, payload: t, who: who };
}
export function AddAlimony(t, who) {
    return { type: interview.ADD_BORROWER_ALIMONY, payload: t, who: who };
}
export function RemoveAlimony(t, who, i) {
    return { type: interview.REMOVE_BORROWER_ALIMONY, payload: t, index: i, who: who };
}
export function ClearAlimonies(t, who) {
    return { type: interview.CLEAR_BORROWER_ALIMONY, payload: t, who: who };
}
export function ChangeOwedTo(t, who, i) {
    return { type: interview.CHANGE_BORROWER_ALIMONY_OWEDTO, payload: t, index: i, who: who };
}
export function ChangeAlimony(t, who, i) {
    return { type: interview.CHANGE_BORROWER_ALIMONY_AMOUNT, payload: t, index: i, who: who };
}
export function ChangeAlimonyField(t, verb, who, i) {
    return { type: interview.CHANGE_BORROWER_ALIMONY, payload: t, verb, index: i, who: who };
}
export function ChangePaymentType(t, who, i) {
    return { type: interview.CHANGE_BORROWER_ALIMONY_TYPE, payload: t, index: i, who: who };
}


export function AddRemoteCoborrower(t) {
    return { type: interview.ADD_REMOTE_COBORROWER, payload: t };
}
export function RemoveRemoteCoborrower(t) {
    return { type: interview.REMOVE_REMOTE_COBORROWER, payload: t };
}
export function ClearRemoteCoborrower(t) {
    return { type: interview.CLEAR_REMOTE_COBORROWER, payload: t };
}
export function ChangeRemoteCoborrowerFirstName(t, i) {
    return { type: interview.CHANGE_REMOTE_COBORROWER_FIRSTNAME, payload: t, index: i };
}
export function ChangeRemoteCoborrowerLastName(t, i) {
    return { type: interview.CHANGE_REMOTE_COBORROWER_LASTNAME, payload: t, index: i };
}
export function ChangeRemoteCoborrowerEmail(t, i) {
    return { type: interview.CHANGE_REMOTE_COBORROWER_EMAIL, payload: t, index: i };
}
export function ChangeRemoteCoborrowerStatus(t, i) {
    return { type: interview.CHANGE_REMOTE_COBORROWER_STATUS, payload: t, index: i };
}
export function ChangeRemoteCoborrowerUse(t, i) {
    return { type: interview.CHANGE_REMOTE_COBORROWER_USE, payload: t, index: i };
}

export function UpdateSolarPanels(t, verb) {
    return { type: interview.CHANGE_SOLAR_PANELS, payload: t, verb:verb};
}


export function AddOtherLien() {
    return { type: interview.ADD_BORROWER_OTHER_LIEN, };
}
export function RemoveOtherLien(index) {
    return { type: interview.REMOVE_BORROWER_OTHER_LIEN, payload:index };
}
export function ClearBorrowerOtherLiens() {
    return { type: interview.CLEAR_BORROWER_OTHER_LIENS};
}
export function ChangeBorrowerOtherLienValue(t, i, verb) {
    return { type: interview.CHANGE_BORROWER_OTHER_LIEN_VALUE, payload: t, index: i, verb:verb };
}

export function AddBorrowerOtherOccupation( who) {
    return { type: interview.ADD_BORROWER_OTHER_OCCUPATION,  who:who};
}
export function RemoveBorrowerOtherOccupation(who, index) {
    return { type: interview.REMOVE_BORROWER_OTHER_OCCUPATION, payload:index, who:who };
}
export function ClearBorrowerOtherOccupations(who) {
    return { type: interview.CLEAR_BORROWER_OTHER_OCCUPATIONS, who:who};
}
export function ChangeBorrowerOtherOccupationValue(t, i, who, verb) {
    return { type: interview.CHANGE_BORROWER_OTHER_OCCUPATION_VALUE, payload: t, index: i, who:who, verb:verb };
}

export function ChangeBorrowerOtherOccupationIncome(t, i, who, verb) {
    return { type: interview.CHANGE_BORROWER_OTHER_OCCUPATION_INCOME, payload: t, index: i, who:who, verb:verb };
}

export function AddBorrowerPreviousOccupation( who) {
    return { type: interview.ADD_BORROWER_PREVIOUS_OCCUPATION,  who:who};
}
export function RemoveBorrowerPreviousOccupation(who, index) {
    return { type: interview.REMOVE_BORROWER_PREVIOUS_OCCUPATION, payload:index, who:who };
}
export function ClearBorrowerPreviousOccupations(who) {
    return { type: interview.CLEAR_BORROWER_PREVIOUS_OCCUPATIONS, who:who};
}
export function ChangeBorrowerPreviousOccupationValue(t, i, who, verb) {
    return { type: interview.CHANGE_BORROWER_PREVIOUS_OCCUPATION_VALUE, payload: t, index: i, who:who, verb:verb };
}

export function ChangeBorrowerPreviousOccupationIncome(t, i, who, verb) {
    return { type: interview.CHANGE_BORROWER_PREVIOUS_OCCUPATION_INCOME, payload: t, index: i, who:who, verb:verb };
}

export function UpdateFico(score) {
    return { type: interview.UPDATE_FICO, payload: score};
}

export function UpdateCRErrors(ar) {
    return { type: interview.UPDATE_CR_ERRORS, payload: ar};
}
export function ClearCRErrors(ar) {
    return { type: interview.CLEAR_CR_ERRORS, payload: ar};
}

export function UpdateCannotShopServices(name, amount) {
    return { type: interview.UPDATE_CANNOTSHOPSERVICES, servicename: name, estimateamount: amount};
}

export function UpdateCanShopServices(name, amount) {
    return { type: interview.UPDATE_CANSHOPSERVICES, servicename: name, estimateamount: amount};
}

export function UpdateFees(name, amount) {
    return { type: interview.UPDATE_FEES, feename: name, estimateamount: amount};
}

export function UpdateOriginalCosts(name, amount) {
    return { type: interview.UPDATE_ORIGINALCOSTS, chargename: name, estimateamount: amount};
}
export function UpdateOtherCosts(name, amount) {
    return { type: interview.UPDATE_OTHERCOSTS, chargename: name, estimateamount: amount};
}
export function UpdatePaymentPeriod(name, service, period) {
    return { type: interview.UPDATE_PAYMENT_PERIOD, name, service, period};
}
export function UpdatePaymentAmount(name, service, amount) {
    return { type: interview.UPDATE_PAYMENT_AMOUNT, name, service, amount};
}
export function UpdatePrepaidDefault(name, service, period, prepaidperiods, amount) {
    return { type: interview.UPDATE_PREPAID_DEFAULT, name, service, period, prepaidperiods, amount};
}
export function AddSelectedLoanService(service, chargename) {
    return { type: interview.ADD_SELECTEDLOAN_SERVICE, service, chargename};
}
export function AddSelectedLoanEstimatedClosingCosts(payload) {
    return { type: interview.ADD_SELECTEDLOAN_ESTIMATEDCLOSING_COSTS, payload};
}
export function RemoveSelectedLoanService(service, chargename) {
    return { type: interview.REMOVE_SELECTEDLOAN_SERVICE, service, chargename};
}
export function AddSelectedLoanCharge(service, chargename) {
    return { type: interview.ADD_SELECTEDLOAN_CHARGE, service, chargename};
}
export function RemoveSelectedLoanCharge(service, chargename) {
    return { type: interview.REMOVE_SELECTEDLOAN_CHARGE, service, chargename};
}
export function AddSelectedLoanPrepaid(service, chargename, period) {
    return { type: interview.ADD_SELECTEDLOAN_PREPAID, service, chargename, period};
}
export function RemoveSelectedLoanPrepaid(service, chargename, period) {
    return { type: interview.REMOVE_SELECTEDLOAN_PREPAID, service, chargename, period};
}



export function AddBorrowerGift() {
    return { type: interview.ADD_BORROWER_GIFT };
}
export function BulkAddBorrowerGifts(payload) {
    return { type: interview.BULKADD_BORROWER_GIFTS, payload: payload };
}
export function RemoveBorrowerGift(index) {
    return { type: interview.REMOVE_BORROWER_GIFT, index };
}
export function ClearBorrowerGift() {
    return { type: interview.CLEAR_BORROWER_GIFT };
}
export function UpdateBorrowerGift(value, verb, index) {
    return { type: interview.CHANGE_BORROWER_GIFT, value, verb, index };
}


export function AddBorrowerAdditionalPropertiesFromCredit(payload) {
    return { type: interview.ADD_BORROWER_ADDITIONAL_PROPERTIES_CREDIT, payload };
}
export function ChangeBorrowerAdditionalPropertiesFromCreditAttr(payload, index, verb) {
    return { type: interview.ADD_BORROWER_ADDITIONAL_PROPERTIES_ATTRIBUTES, payload, index, verb };
}


export function BulkAddNewMortgageLoan(payload) {
    return { type: interview.BULKADD_NEW_MORTGAGE_LOAN, payload: payload };
}
export function AddNewMortgageLoan() {
    return { type: interview.ADD_NEW_MORTGAGE_LOAN };
}
export function RemoveNewMortgageLoan(index) {
    return { type: interview.REMOVE_NEW_MORTGAGE_LOAN, payload:index };
}
export function ClearNewMortgageLoans() {
    return { type: interview.CLEAR_NEW_MORTGAGE_LOANS  };
}
export function UpdateNewMortgageLoanAttribute(value, index, verb) {
    return { type: interview.UPDATE_NEW_MORTGAGE_ATTRIBUTE, value, index, verb  };
}

