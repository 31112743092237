import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import Login from './Login'
import {CustomerHeader} from './Landing/LandingMenu'
import LandingCopyright from './Landing/LandingCopyright'
import { Link } from 'react-router-dom'

function LoginPage(props) {
    let login;
    window.scrollTo(0,0);

    if(props.signup)
        login =  <Login view={props.view} key={"signup"+Math.random()}/>
    else
        login = <Login view={props.view} key={"login"+Math.random()}/>

    let searchparams = new URLSearchParams(window.location.search);
    let customerid = searchparams.get("customerid");

    let url = window.location.href

    return (
        <div>
            <CustomerHeader />
           
            <div id="landing1" className="text-center my-4 py-3" >
                <div className="text-center ">
                    
                        {login}
                    
                </div>
            </div>
        {url.includes("signin") ? <>
            <Row className="text-center">
            <Col>
            <Link to= {"/services/forgot-password?customerid=" + customerid} >Forgot your password?</Link>
            </Col>
            </Row>
            <Row className="text-center pt-1 pb-4">
            <Col>
            <Link to= {"/services/preapp?customerid=" + customerid}>Sign up for a new account</Link>
            </Col>
            </Row>
          </> :
            <Row className="text-center pt-1 pb-4">
            <Col>
            <Link to= {"/services/signin?customerid=" + customerid}>Already have an account? Login here</Link>
            </Col>
            </Row>
        }
        </div>
    )
}

export default LoginPage;


