import { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import LoanRateTitle from './LoanRateTitle.js';
import LoanRateCalculator from './LoanRateCalculator';
import LoanRateResult from './LoanRateResult';
import { useParams } from 'react-router-dom';

export default function LoanRate() {

  const { customerid } = useParams();
  useEffect(()=>{
    sessionStorage.setItem("cid", customerid)
  }, [])

  return (
    <div className='container' style={{maxWidth: '1274px', overflow: 'hidden'}}>
        <LoanRateTitle title='Hard Money Loan Rate Calculator' />
        <Row gutter={24}>
            <Col span={24} lg={14}>
                <LoanRateCalculator />
            </Col>
            <Col span={24} lg={10}>
                <LoanRateResult customerid={customerid}/>  
            </Col>
        </Row>
    </div>
  );
}