
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from "react-router-dom";
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Button, Col, Row, Tooltip, Input } from 'antd';
import EmailVerification from '../../Application/EmailVerification';
import "./loanRateResult.css";
import {
  QuestionCircleFilled
} from '@ant-design/icons';

const mapStateToProps = (state) => {
  return {
      application: state.application,
      progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb))
  },
  changeBorrowerInfo: (event, verb) => {
      dispatch(act.ChangeBorrowerInfo(event, "borrower", verb))
  },
  updateChangeApplication: (application) => {
      dispatch(act.updateApplication(application))
  },

});

export function CalcHardMoneyLoanRate(props)  {
// to get max loan amount:
// if it's a purchase loan (+rehab)，the max loan amount is the lesser of 85% LTV or 70% LTARV. 
// if it's a rate-term refi loan, the max loan amount is the lesser of 70% LTV or 70% LTARV. 
// If it's a cash-out refi loan, the max is the lesser of 65% LTV or 65% LTARV.

  let result = {
    unqualified: false,
    unqualifiedReason: "", 
    floorRate: 0,
    point: 0,
    monthlyPayment: 0,
    closingCost: 0,
    maxLoanAmount: 0,

  }
  let propertyType = props.application.property.propertytype
  let scre = props.application.hardmoneyloan.estimatedfico
  let borrowerExperience = props.application.hardmoneyloan.timesofflippingaproperty + props.application.hardmoneyloan.timesofpurchasingarentalproperty
  let loanamount = ""
  let maxLoanAmount = ""
  let ltv = 0
  if (props.application.property.salesprice==="") {
    ltv = 0
  } else {
    switch (props.application.property.purpose) {
      case "purchase":
        loanamount = props.application.hardmoneyloan.initialloanamount
        maxLoanAmount = com.safeParseInt(loanamount)  * 0.85
        break;
      case "refinance":
        loanamount = props.application.hardmoneyloan.refinanceloanamount
        maxLoanAmount = com.safeParseInt(loanamount)  * 0.7
        break;
      case "construction":
        loanamount = props.application.hardmoneyloan.constructionloanamount
        maxLoanAmount = com.safeParseInt(loanamount)  * 0.7
        break;
    }
    ltv = 100 * com.safeParseInt(loanamount) / com.safeParseInt(props.application.property.salesprice)
  }

  let floorRate = 8
  let point = 1

  if (ltv>60 && ltv <=70) {
    floorRate += 0.5
  } else if (ltv>70 && ltv <=80) {
    floorRate += 1
  } else if (ltv > 80) {
    result.unqualified = true
    result.unqualifiedReason = "The maximum LTV is 80%, while current LTV is "+ Math.round(ltv) + "%."
  }

  if (scre >= 650) {
    if (scre >= 700 && scre < 730) {
      floorRate += 0.5
    }
    if (scre >= 650 && scre < 700) {
      floorRate += 0.5
      point += 0.5
    }
  } else {
    result.unqualified = true
    result.unqualifiedReason = "The minimum FICO score is 650, whereas the FICO score you have provided is " + scre + "."
  }

  if (borrowerExperience>=10 && borrowerExperience <= 20) {
    floorRate += 0.5
  } else if (borrowerExperience>=3 && borrowerExperience <10) {
    floorRate += 1
  } else if (borrowerExperience < 3) {
    floorRate += 1.5
    point += 0.5
  }

  if (propertyType === "commercial" || propertyType === "land") {
    floorRate += 3
    point += 1
  }
  if (propertyType === "condominium" || propertyType === "mixeduse") { 
    floorRate += 2
    point += 1
  }

  
  let mp = Math.round(com.MonthlyPayment(0.01 * floorRate/12, com.safeParseInt(loanamount), props.application.hardmoneyloan.mortgagetermmonths))
  let cc = Math.round(com.safeParseInt(loanamount) * point * 0.01)
  
  result.floorRate = floorRate
  result.point = point
  result.monthlyPayment = mp
  result.closingCost = cc
  result.maxLoanAmount = maxLoanAmount
  return result
}

const LoanRateResult = (props) => {
  const [showSignup, setShowSignup] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [rate, setRate] = useState("")
  const [maxLoanAmount, setMaxLoanAmount] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState("")
  const [closingCost, setClosingCost] = useState("")
  const [unqualified, setUnqualified] = useState(false)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")

  const cardList = [
    {
        title: 'Interest Rate',
        value: rate
    },
    {
        title: 'Maximum Loan amount',
        value: maxLoanAmount
    },
    {
        title: 'Monthly Payment',
        value: monthlyPayment
    },
    {
        title: 'Closing Cost',
        value: closingCost
    }
  ]

  useEffect(()=>{
    let result = CalcHardMoneyLoanRate(props)
    setRate(result.floorRate.toFixed(3) + "%")
    setMonthlyPayment("$" + com.commaize(result.monthlyPayment))
    setClosingCost("$" + com.commaize(result.closingCost))
    setUnqualified(result.unqualified)
    setUnqualifiedReason(result.unqualifiedReason)
    setMaxLoanAmount("$"+ com.commaize(result.maxLoanAmount))
  }, [props.application])

  const signup = () => {
    if (showEmailVerification) {
      return (
        <div style={{padding: '50px 0'}}>
          <EmailVerification purpose="hardmoneyloan"  />
        </div>
      )
    }
    return (
      <div className='creat-account'>
        <div className='creat-title'>Create Account</div>
        <div className='creat-subtitle'>To explore your best loan option and get connected with our experienced loan officers </div>
        <Row gutter={20}>
          <Col style={{marginBottom: '20px'}} sm={12} span={24}><Input size="large" placeholder="First name" onChange={(e)=> props.changeBorrowerInfo(e.target.value, "firstname")}/></Col>
          <Col style={{marginBottom: '20px'}} sm={12} span={24}><Input size="large" placeholder="Last name" onChange={(e)=> props.changeBorrowerInfo(e.target.value, "lastname")}/></Col>
        </Row>
        <Row>
          <Col span={24}><Input size="large" placeholder='Email'onChange={(e)=> props.changeBorrowerInfo(e.target.value, "email")}/></Col>
        </Row>
        <div><Button className='continue-btn' type="primary" onClick={()=>setShowEmailVerification(true)}>Continue</Button></div>
        <div className='to-login'><span>Already have an account?</span> <Link to={"/services/signin?customerid=" + props.customerid}>Log in</Link></div>
        <div className='to-login mt-3'> <a className='text-blue ' onClick={()=>setShowSignup(false)}>Go back</a></div>
      </div>
    )
  }

  const showUnqualified = () => {
    return <div className='mb-5'>
      <div className='bold mb-2'>Sorry, we are unable to offer a loan based on the input provided. This is due to:</div>
      <div style={{fontSize: 20, fontWeight: 500}}>{unqualifiedReason}</div>
    </div>
  }

  return (
    <div className='loan-rate-result center'>
      {showSignup ? signup() : <div>
      <div className='title center'>Fill in the table on the left and click <span className='bold'>Calculate</span> to see your rates</div>
      <Row className='result-card'>
        {
          unqualified ? showUnqualified() :
          cardList.map((item, index) => 
            <Col span={24} sm={12} className='card-item center' key={index}>
              <div className='result-title'>{item.title}</div>
              <div className='result-value'>{item.value}</div>
            </Col>
          )
        }
      </Row>
      <div className='describe bold info-first center'>To obtain an accurate rate tailored to your specific situation and explore rates for alternative mortgage terms of <span className='text-blue'>6 to 24 months</span>, simply sign up below. </div>
      <div className='describe info-second center'>Our experienced loan officers will promptly reach out to assist you in finding the best rate available.</div>
      <div className='btn-wrap center'><Button className="sign-btn" type="primary" onClick={()=>setShowSignup(true)}>Sign Up</Button></div>
      <div className='login center'>Already have an account? <span className='text-blue'><Link to={"/services/signin?customerid=" + props.customerid}>Login</Link></span></div>
      {/* <div className='privacy center'>Privacy Statement</div> */}
    </div>}
    
    </div>
  );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoanRateResult))
