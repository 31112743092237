import React, { useState } from 'react';
import {
  message
} from 'antd';
import { Link } from 'react-router-dom'
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import './Submitted.css'


const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
});

export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [contactMessage, setContactMessage] = useState("")

    const statusList = [
        {
            name: 'Application Draft Started',
            done: true,
            operate: [<Link to="/application/hardMoneyLoan/interview/main/borrower">Edit application draft</Link>]
        },
        {
            name: 'Initial Application Submitted',
            done: true,
            operate: [<Link to="/application/hardMoneyLoan/interview/main/myTasks">Gather documents requested in My Tasks</Link>]
        },
        {
            name: 'Pre-Approval Letter Received',
            operate: []
        },
    ]
    return <>
        <div className='submitted-banner'>
            <div className='bannerleft'>
                <img src="/images/trophy.png" alt="" className="trophy-icon" />
            </div>
            <div className='bannerright'>
                <div className='title'>Congratulation!<br />You have submitted the initial application.</div>
                <div className="subtitle">
                    Our loan officer will review your application and get back to you soon.<br />
                    You will find any updates in the loan <a href="/application/hardMoneyLoan/interview/main/overview">Overview.</a><br />
                    Prepare to pay the processing fee and submit requested supporting documents in <a href="/application/hardMoneyLoan/interview/main/myTasks">My Tasks</a>.
                </div>
            </div>
        </div>

        <div className="over-view text-left pt-5">
            <div className='over-view-content'>
                <div className='application-status'>
                    <div className='status-title' onClick={() => setShowContact(true)}>Application Status </div>
                    <div className='status-list'>
                        {
                            statusList.map((status, statusIndex) =>
                                <div className='status-item' key={statusIndex}>
                                    <div style={{ color: status.done ? '#1F3988' : '#8895BC' }}>{status.name}</div>
                                    {
                                        status.operate.length > 0 && (
                                            <div className='operate' style={{ color: status.done ? '#0F62FE' : '' }}>
                                                {
                                                    status.operate.map((o, index) => o)
                                                }
                                            </div>
                                        )
                                    }
                                    <div className='status-tag' style={{ backgroundColor: status.done ? '#0F62FE' : '#DDE1E6' }}></div>
                                </div>
                            )
                        }

                        <div className='status-item'></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
