import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import * as com from "../Common.js"
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import InputGroup from 'react-bootstrap/InputGroup'
import { tooltip } from "../Common/Tooltip"
import Steps from 'awesome-steps'
import { countSubjectMortgages } from './AlimonySolar'
import 'awesome-steps/dist/style.css'
import { Tips } from "../Common/Tooltip"
import { Input, Space, InputNumber, Switch, Checkbox, Select, Table, Popconfirm, Skeleton } from 'antd'
import { OwnedPropertyMortgagesTable } from "../Application/tables"
import CheckGroup from "../Common/components/CheckGroup"

import { getCurrentState } from '../Store'

var parser = require('parse-address')

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSolarPanels: (payload, verb) => {
        dispatch(act.UpdateSolarPanels(payload, verb))
    },
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    changeBorrowerLoanMonthly: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanMonthly(event.target.value, i, who))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who))
    },
    changeBorrowerLoanRate: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRate(event.target.value, i, who))
    },
    changeBorrowerLoanForSubject: (val, i, who) => {
        dispatch(act.ChangeBorrowerLoanForSubject(val, i, who))
    },
    changeBorrowerLoanCorrection: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCorrection(event.target.value, i, who))
    },
    changeBorrowerLoanExplanation: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanExplanation(event.target.value, i, who))
    },
    updateFinances: (t, who, verb) => {
        dispatch(act.UpdateFinances(t, who, verb))
    },
    addBorrowerAdditionaCredits: (event, who) => {
        dispatch(act.AddBorrowerAdditionaCredits(event, who))
    },
    clearBorrowerAdditionalCredits: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalCredits(event, who))
    },
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeBorrowerFreeAndClear: (payload, who) => {
        dispatch(act.ChangeBorrowerFreeAndClear(payload, who))
    },
    changePaymentType: (event, i, who) => {
        dispatch(act.ChangePaymentType(event.target.value, i, who))
    },
    addBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.AddBorrowerAdditionalProperty(event, who))
    },
    addBorrowerOwnedProperty: (event) => {
        dispatch(act.AddBorrowerOwnedProperty(event))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    clearBorrowerOwnedProperty: () => {
        dispatch(act.ClearBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, verb) => {
        dispatch(act.ChangeOwnedProperty(t, i, verb))
    },
    clearBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalProperty(event, who))
    },

    addNewMortgageLoan: () => {
        dispatch(act.AddNewMortgageLoan())
    },
    removeNewMortgageLoan: (index) => {
        dispatch(act.RemoveNewMortgageLoan(index))
    },
    clearNewMortgageLoans: () => {
        dispatch(act.ClearNewMortgageLoans())
    },
    updateNewMortgageLoanAttribute: (value, index, verb) => {
        dispatch(act.UpdateNewMortgageLoanAttribute(value, index, verb))
    },
})

export function calculateBalancesAndPayments() {
    let firstmortgage = 0
    let firstmortgagebalance = 0
    let othermortgage = 0
    let otherbalance = 0
    let cashout = 0
    let loanmount = 0
    let subjectmortgages = countSubjectMortgages(this.props.application.property.purpose,
        this.props.application.assetsandliabilities.loans,
        this.props.application.otherliens)
    let loans = this.props.application.assetsandliabilities.loans
    let len = loans.length
    let otherliens = this.props.application.otherliens

    if (subjectmortgages === 1 && this.props.application.property.purpose !== st.POL_Purchase) {
        // mark the only mortgage as primary
        for (let index = 0; index < len; index++) {
            let loan = loans[index]
            if (loan.forsubjectproperty === true) {
                loan.isprimary = true
            }
        }
        if (otherliens != null) {
            for (let index = 0; index < otherliens.length; index++) {
                let loan = otherliens[index]
                loan.isprimary = true
                loan.satisfiedupon = "withproceeds"
            }
        }
    }
    if (null !== loans) {

        for (let index = 0; index < len; index++) {
            let loan = loans[index]
            if (com.isHomeloan(loan)) {
                if (loan.correction !== "none"
                    && loan.correction !== "")
                    continue
                let mopayment = parseInt(loan.monthlypayment)
                let rembalance = parseInt(loan.remainingbalance)
                if (this.props.application.property.purpose !== st.POL_Purchase && loan.forsubjectproperty
                ) {
                    if (loan.satisfiedupon === "withproceeds") {
                        if (loan.isprimary) {
                            firstmortgage += mopayment
                            firstmortgagebalance += rembalance
                        } else {
                            if (this.props.application.property.purpose === st.POL_Refinance &&
                                loan.partoforiginalpurchase === true) {
                                othermortgage += mopayment
                                otherbalance += rembalance
                            } else {
                                othermortgage += mopayment
                                otherbalance += rembalance
                                cashout += rembalance
                            }
                        }
                    }
                }

                if (this.props.application.property.purpose === st.POL_Purchase &&
                    loan.forprimaryresidence) {
                    if (firstmortgagebalance === 0) {
                        firstmortgage += mopayment
                        firstmortgagebalance += rembalance
                    } else {
                        othermortgage += mopayment
                        otherbalance += rembalance
                        cashout += rembalance
                    }
                }
            } else {
                if (loan.correction !== "none" && loan.correction !== "")
                    continue
                // add for cashout
                if (this.props.application.property.purpose === st.POL_Cashoutrefinance &&
                    loan.satisfiedupon === "withproceeds")
                    cashout += parseInt(loan.remainingbalance)
            }
        }
    }

    if (otherliens != null) {
        for (let i = 0; i < otherliens.length; i++) {
            let lien = otherliens[i]
            if ("" !== lien.balance) {

                if (lien.isHELOC) {
                    if (lien.satisfiedupon !== "withproceeds")
                        continue
                }
                firstmortgagebalance += parseInt(lien.balance)
                firstmortgage += parseInt(lien.monthly)

            }
        }
    }
    switch (this.props.application.property.purpose) {
        case st.POL_Refinance:
            loanmount = firstmortgagebalance + otherbalance
            break
        case st.POL_Cashoutrefinance:
            loanmount = firstmortgagebalance + cashout
            break

        default:
            break
    }

    return [firstmortgage, firstmortgagebalance, othermortgage, otherbalance, cashout, loanmount]
}
export function filterMortgages(loan) {
    if (!loan.creditloantype) {
        return false
    }

    return loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
        loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
        loan.creditloantype.toLowerCase().indexOf("realestate") >= 0
}
export class Liabilities extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingcr: false,
            show: {},
            usestepper: true,
            status: "process",
            expand: [],
            stepsandsubsteps: [],
            observedIndex: 0,
            showWarning: false,
            message: "",
            monthlyIncome: 0,
            monthlyDebt: 0,
            preDTI: 0,
        }
        // if this is the last page before rates, change the "Continue" button to "Submit" && this.props.application.status === "interviewstarted"

        if (this.props.application.status === "interviewstarted") {
            this.props.changeContinueButtonText()
        }
        if (null === this.props.application.assetsandliabilities.loansreviewed) {
            this.props.application.assetsandliabilities.loansreviewed = 0
            this.props.changeReviewedLoans(0)
        }
        this.getDTI()

        this.isPresentAddrOwned = this.props.application.borrower.presentaddressownership === st.O_Own

        props.updateApplicationAttribute({ target: { value: "0" } }, "loanbalance")

        if (null != this.props.application.assetsandliabilities.loans) {

            this.state.stepsandsubsteps = this.recalcStepper()
        }
        if (this.props.application.assetsandliabilities.ownedproperties.length > 0 &&
            this.props.application.borrower.presentaddressownership === st.O_Own &&
            this.props.application.assetsandliabilities.ownedproperties[0].loans.length === 0 &&
            this.props.application.assetsandliabilities.loans !== null) {
            let loansForCurrentResidence = []
            for (let loan of this.props.application.assetsandliabilities.loans) {
                if (loan.forprimaryresidence) {
                    loansForCurrentResidence.push(loan)
                }
            }
            this.props.changeOwnedProperty(loansForCurrentResidence, 0, "loans")
        }
    }
    recalcStepper = () => {
        let loans = this.getFilteredLoans()


        let steps = -1
        let substeps = 0
        let dupresolved = null
        let stepsandsubsteps = []
        for (let i = 0; i < loans.length; i++) {
            let remapped = loans[i]
            let st
            if (remapped.correction === "duplicate") {
                substeps += 1
                st = { steps, substeps, dupresolved }
            } else {
                steps += 1
                substeps = 0
                st = { steps, substeps, dupresolved }
            }
            stepsandsubsteps.push(st)
        }
        return stepsandsubsteps
    }
    reconcileDOBs = (js, borrowers) => {

        for (let i = 0; i < borrowers.length; i++) {
            let outperson = js.personswithresidencies[i].person
            let inperson = borrowers[i].person
            let indateofbirth = inperson.dateofbirth.replace(/-/g, '')

            let outdateofbirth = outperson.dateofbirth

            if (outdateofbirth !== "") {
                this.props.changeBorrowerInfo(outdateofbirth, (i === 0) ? com.borrower : com.coborrower, "dateofbirth")
            }
        }

    }
    fillPerson = who => {
        let a = this.props.application[who]
        return {
            firstname: a.firstname, middlename: a.middlename, lastname: a.lastname,
            suffix: a.suffix, email: a.email,
            socialsecurity: a.socialsecurity,
            dateofbirth: a.dateofbirth
        }
    }
    fillAddress = who => {
        let a = this.props.application[who]

        return {
            street: a.presentaddressstreet, city: a.presentaddresscity, state: a.presentaddressstate,
            zip: a.presentaddresszip, years: a.presentaddressyears,
            months: a.presentaddressmonths, ownership: a.presentaddressownership
        }
    }
    fillPreviouswAddress = (index, who) => {
        let a = this.props.application[who].previousaddresses[index]

        return {
            street: a.street, city: a.city, state: a.state,
            zip: a.zip, years: a.years,
            months: a.months, ownership: a.ownership
        }
    }
    fillApplicant = who => {
        let addresses = [this.fillAddress(who)]
        let a = this.props.application[who].previousaddresses

        for (let i = 0; i < a.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }
        if (addresses.length > 0 && addresses[0].street === "") {
            addresses = [this.fillAddress("borrower")]
        }
        return { person: this.fillPerson(who), addresses: addresses }
    }
    fillRequestBody = () => {
        let borrowers = [this.fillApplicant("borrower")]
        if ((this.props.application.hascoborrower === "withcoborrower" ||
            this.props.application.hascoborrower === "multiple"
            || (this.props.application.hascoborrower === "alone" && this.props.application.aloneMarriedWantsFHA)
        ) && this.props.application.married
        ) {
            borrowers.push(this.fillApplicant("coborrower"))
        }
        return {
            // true for LO, false for borrower
            force_ucs: false,
            debug: "just_request",
            app_data: borrowers,
            request: "credit_report"
        }
    }
    load = () => {
        this.props.changeReviewedLoans(0)
        if (false && "undefined" === typeof this.props.borrowerid && null !== this.props.application.assetsandliabilities.loans) {
            return
        }
        this["state"].loadingcr = true
        this.setState({ loadingcr: true })
        let token = window.sessionStorage.getItem("ZeitroA")
        let id = com.getUserId()

        if ("undefined" === typeof id) {
            id = this.props.borrowerid
        }
        let body = this.fillRequestBody()
        let borrowers = body.app_data
        fetch(window.location.origin + "/borrower/creditreport/" + id, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {

            response.text().then(text => {
                try {

                    let js = JSON.parse(text)
                    this.props.clearBorrowerLoans(null, this.props.who)
                    this.props.updateFico(js.creditscore)
                    if (js.creditscore == "0") {
                        this.props.updateFico("750")
                    }
                    // this.reconcileDOBs(js, borrowers)
                    let remap = o => {
                        let l = { ...o }

                        //l.correction = "none"
                        //l.attribution = attr

                        // let isstudent = liability.creditliabilityaccounttype === "Educational"
                        let islease = l.creditliabilityaccounttype === "AutoLease" || l.creditliabilityaccounttype === "Lease"
                        let isinstallment = l.creditliabilityaccounttype === "Installment"

                        if (l.creditloantype.toLowerCase().indexOf("education") >= 0) {
                            if (l.monthlypayment === "")
                                l.monthlypayment = 0
                        } else {
                            if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === "")) {
                                l.monthlydefinedbycustomer = true
                            }
                        }
                        return l
                    }
                    let opened = js.liabilities
                    let dupresolved = null
                    let expand = []
                    let steps = -1
                    let substeps = 0
                    let stepsandsubsteps = []
                    for (let i = 0; i < opened.length; i++) {
                        let remapped = remap(opened[i])
                        if (opened[i].remainingbalance === "" || parseInt(opened[i].remainingbalance) === 0)
                            continue
                        this.props.copyBorrowerLoan(remapped, this.props.who)

                        expand.push(false)
                        let st
                        if (remapped.correction === "duplicate") {
                            substeps += 1
                            st = { steps, substeps, dupresolved }
                        } else {
                            steps += 1
                            substeps = 0
                            st = { steps, substeps, dupresolved }
                        }
                        stepsandsubsteps.push(st)
                    }

                    this.props.addBorrowerAdditionalPropertiesFromCredit(null)
                    getCurrentState()

                    this.setState({ loadingcr: false, expand, stepsandsubsteps })
                    if (typeof js.errorMessages !== "undefined") {
                        this.props.updateCRErrors(js.errorMessages)
                    }

                } catch (xx) {
                    console.log(text)
                    console.log(xx)
                    this.setState({ loadingcr: false })
                }
            })

        }).catch(error => {

            this.setState({ loadingcr: false })

        })

    }

    getDTI = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ loading: true })
        fetch('/data/getPreDTI', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrowerid,
            },

        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Bad response, should not happen")
                    return
                }
                response.json().then(pr => {
                    if (typeof pr["Error"] !== "undefined") {
                        console.log(pr.Error)
                    } else {
                        let income = pr.dtiData[0].monthly_income
                        let debt = pr.dtiData[0].monthly_payment
                        let dti = income === 0 ? 0 : Math.round(debt / income * 100)
                        this.setState({ monthlyIncome: income, monthlyDebt: debt, preDTI: dti })
                    }
                })
            }

        ).catch((err) => {

            // this.setState({ loading: false })
            console.log("Network error")
        })
    }

    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }

    updateJointly = (e) => {
        com.touch()
        let f = e.target.value === "true"
        this.props.updateJointly({ target: { checked: f } })
    }

    processOtherCredits = (val, index) => {
        if (null === val)
            return ""
        let { altname, creditor, account, key } = val

        return this.renderRow(index, altname, creditor, account, key)
    }

    addOtherCredits = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerAdditionalCredits(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionaCredits(0, this.props.who)
        }
    }

    updateFinancesCheck = (verb) => {
        return e => {
            let val = e.target.value === "true"
            this.props.updateFinances(val, this.props.who, verb)
        }
    }
    updateFinances = (verb) => {
        return e => this.props.updateFinances(e.target.value, this.props.who, verb)
    }
    freeAndClear = (e) => {
        com.touch()
        let t = e === "true"
        this.props.changeBorrowerFreeAndClear(t)
    }

    filterNonDuplicates = (loan) => {
        return loan.correction !== "duplicate"
    }
    getFilteredLoans = () => {
        return this.props.application.assetsandliabilities.loans == null ? null :
            this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(filterMortgages)
    }
    getUnfilteredIndex = (index) => {
        let loans = this.props.application.assetsandliabilities.loans

        let acc = 0
        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (filterMortgages(loan) && this.filterNonDuplicates(loan)) {
                if (acc === index)
                    return i
                acc += 1
            }
        }
        alert("should not be here")
        return -1
    }

    processSteps = (val, index) => {
        if (null === val)
            return ""
        let name = val.name
        let address = val.address
        let accountnumber = val.accountnumber
        let monthlypayment = val.monthlypayment
        let remainingbalance = val.remainingbalance
        let satisfiedupon = val.satisfiedupon
        let monthslefttopay = val.monthslefttopay
        let key = val.key

        return this.renderLoanStep(index, name, address, accountnumber,
            monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key)
    }

    process = (val, index) => {
        if (null === val)
            return ""
        let name = val.name
        let address = val.address
        let accountnumber = val.accountnumber
        let monthlypayment = val.monthlypayment
        let remainingbalance = val.remainingbalance
        let satisfiedupon = val.satisfiedupon
        let monthslefttopay = val.monthslefttopay
        let key = val.key

        return this.renderLoan(index, name, address, accountnumber,
            monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, true)
    }
    updateApplicationAttribute = (value) => {
        this.props.updateApplicationAttribute({ target: { value } }, "loanbalance")
    }

    getBalancePayment = calculateBalancesAndPayments.bind(this)


    presentFreeAndClear = () => {
        let loans = this.getFilteredLoans()

        if (loans == null) return ""

        if (this.props.application.assetsandliabilities.loansreviewed < loans.length)
            return ""

        if (this.props.application.property.purpose === st.POL_Purchase
            && this.props.application[this.props.who].presentaddressownership !== st.O_Own) {
            return ""
        }
        let iwe = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
            `we` :
            `I`

        //let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()

        let hasMortgages = false
        let hassubject = false
        let hasprimary = false
        //let hasprimary = false
        if (null !== loans) {
            let len = loans.length
            for (let index = 0; index < len; index++) {
                if (loans[index].creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                    loans[index].creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                    loans[index].creditloantype.toLowerCase().indexOf("realestate") >= 0
                ) {
                    hasMortgages = true
                    if (loans[index].forsubjectproperty) {
                        if ("" !== loans[index].remainingbalance)
                            hassubject = true
                    }
                    if (loans[index].forprimaryresidence) {
                        if ("" !== loans[index].remainingbalance)
                            hasprimary = true
                    }
                }
            }
        }
        let needsToAsk = (this.props.application.property.purpose === st.POL_Purchase && !hasprimary) ||
            (this.props.application.property.purpose !== st.POL_Purchase && !hassubject)
        if (needsToAsk) {
            return (
                <div className="pt-4 w-600px" >
                    <div className="text-wrap">
                        <Form.Group>
                            <div>
                                <div className="d-flex ">
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            Do you own your primary residence free and clear? <Tips
                                                content="When an asset is owned completely without debt or liens against it, it is called free and clear."
                                                placement="bottom"
                                                title=""
                                            />
                                        </Form.Label>
                                        <CheckGroup
                                            data={[{
                                                value: "false",
                                                label: "No",
                                            },
                                            {
                                                value: "true",
                                                label: "Yes" ,
                                            },
                                            ]}
                                            onChangeSelect={this.freeAndClear}
                                            value={
                                                true === this.props.application.assetsandliabilities.propertyownedfreeandclear ? "true" : "false"
                                            }
                                            type='radio'
                                            direction="horizontal" />
                                    </Form.Group>
                                </div>
                            </div>

                            <Form.Control.Feedback type="invalid" >
                                Please specify if you own the property free and clear.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {
                            hasMortgages && !hasprimary && !this.props.application.assetsandliabilities.propertyownedfreeandclear &&
                            <div style={{ color: '#600' }} >Important! Your credit report contains home loans, but none of them are checked to be for your current residence.
                                Double check the loans above. If your mortgages for current residence are missing in the credit report, please specify them.
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }

    handleSubmit = (event) => {
        if (this.props.application.scre === 0) {
            return false
        }
        /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        if (this.props.application.property.purpose === st.POL_Cashoutrefinance) {
            this.props.updateSelectField(cashout, "mincashout")
        }
        if (this.props.application.property.purpose === st.POL_Refinance &&
            0 === totalmortgage && true === this.props.application.assetsandliabilities.propertyownedfreeandclear) {
            alert("You can't refinance the property that you own free and clear!")
            return false
        }
        let loans = this.getFilteredLoans()
        if (this.props.application.assetsandliabilities.loansreviewed == null ||
            this.props.application.assetsandliabilities.loansreviewed < loans.length) {
            if (this.props.application.assetsandliabilities.loansreviewed != null) {
                this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed + 1)
            }
            return false
        }
        this.props.updateApplicationAttribute({ target: { value: mortgagebalance.toString() } }, "loanbalance")
        return true
    }
    getIcon = (index) => {
        let loans = this.getFilteredLoans()


        let a = loans[index]
        let mapit = a => (a.creditloantype.toLowerCase().indexOf("mortgage") >= 0) ? "1" :
            ((a.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                a.creditloantype.toLowerCase().indexOf("realestate") >= 0
            ) ? "2" :
                ((a.creditloantype.toLowerCase().indexOf("education") >= 0 ? "3" :
                    (a.creditloantype.toLowerCase().indexOf("auto") >= 0 ? "4" :

                        (a.creditloantype.toLowerCase().indexOf("card") >= 0 || a.creditloantype.toLowerCase().indexOf("charge") >= 0 ? "5" :
                            (a.creditloantype.toLowerCase().indexOf("medical") >= 0 ? "6" :

                                (a.creditloantype.toLowerCase().indexOf("collection") >= 0 ? "7" : "8")
                            ))))))
        switch (mapit(a)) {
            case "1":
                return <i className="fas fa-home mr-1 blue"></i>
            case "2":
                return <i className="fas fa-house-damage mr-1 blue"></i>
            case "3":
                return <i className="fas fa-graduation-cap mr-1 blue"></i>
            case "4":
                return <i className="fas fa-car-alt mr-1 blue"></i>
            case "5":
                return <i className="fas fa-credit-card mr-1 blue"></i>
            case "6":
                return <i className="fas fa-first-aid mr-1 blue"></i>
            case "7":
                return <i className="fas fa-hand-holding-usd mr-1 blue"></i>
            default:
                return ""
        }

    }
    getSubjAddress = () => {
        if (this.props.application.property.purpose !== st.POL_Purchase)
            return this.props.application.property.address
        let addr = com.formatAddress(this.props.application.borrower)

        return addr
    }
    getFlag = (correction) => {
        if (correction === "paidbybusiness")
            return <i className="fas fa-user-tie ml-2 darkgreen"></i>

        if (correction === "alreadyclosed")
            return <i className="fa fa-times ml-2 darkgreen"></i>
        if (correction === "wrongperson")
            return <i className="far fa-hand-paper ml-2 darkred"></i>
        if (correction === "other")
            return <i className="far fa-flag ml-2 darkred"></i>
        if (correction === "duplicate")
            return <i className="far fa-clone ml-2 darkred"></i>
        return ""
    }
    renderLoan = (index, name, address, accountnumber, monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, margin) => {
        index = parseInt(index)
        let loans = this.getFilteredLoans()
        let isHELOC = () => {

            let loan = loans[index]
            return com.isHeloc(loan)
        }
        let handleClose = e => {
            let newshow = { ...this.state.show }
            newshow[index] = false
            this.setState({ show: newshow })
        }
        let showCorrect = e => {
            let newshow = { ...this.state.show }
            newshow[index] = true
            this.setState({ show: newshow })
        }
        let isPrimary = (trr) => {

            if (this.props.application.property.purpose !== st.POL_Purchase &&
                (loans[index].forsubjectproperty === true ||
                    (trr && loans[index].forsubjectproperty == null)
                )
            ) {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    let loan = loans[i]

                    if (loan.forsubjectproperty &&
                        ((loan.satisfiedupon === "withproceeds") || (trr && loan.satisfiedupon == null))) {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    return false
                }
                return true
            }
            return false
        }
        let changeBorrowerLoanSatisfiedUpon = (event) => {
            com.touch()

            if (isMortgage() && this.props.application.property.purpose === st.POL_Refinance && event.target.value === "withproceeds") {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    let loan = loans[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    this.setState({ observedIndex: index, showWarning: true })
                    return
                }
            }
            this.props.changeBorrowerLoanSatisfiedUpon(event, this.getUnfilteredIndex(index), this.props.who)
        }
        let changeExplanation = e => {
            com.touch()
            this.props.changeBorrowerLoanExplanation(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let correctionChecked = e => {
            com.touch()
            this.props.changeBorrowerLoanCorrection(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let changeBorrowerLoanForSubject = e => {
            com.touch()
            let val = e.target.value === "true"

            this.props.changeBorrowerLoanForSubject(val, this.getUnfilteredIndex(index), this.props.who)
            if (isPrimary(val)) {
                this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.getUnfilteredIndex(index), this.props.who)
            }
            this.getBalancePayment()
        }
        let changeBorrowerLoanForPrimary = e => {
            com.touch()
            let val = e.target.value === "true"

            this.props.changeBorrowerLoan(val, this.getUnfilteredIndex(index), "forprimaryresidence")

        }
        let displayOwnership = () => {

            switch (loans[index].attribution) {
                case 1:
                    return `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
                case 2:
                    return `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`
                case 3:
                    return "Joint"
                default:
                    return "Unknown"
            }
        }
        let onMonthlyChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanMonthly(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let displayMonthly = () => {
            let loans = this.getFilteredLoans()

            let liability = loans[index]
            let userinput = liability.monthlydefinedbycustomer

            if (userinput) {
                return (
                    <Form.Group className="text-left pr-3">
                        <NumericalInput
                            isValid={false}
                            size="sm"
                            id={"loan" + index}
                            required
                            type="number"
                            name={"loan" + index}
                            defaultValue={liability.monthlypayment}
                            onChange={onMonthlyChange}

                            min={1}
                        />

                        <Form.Control.Feedback type="invalid" >
                            Enter the monthly payment
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            return "$" + com.commaize(loans[index].monthlypayment)
        }
        let askHowToPay = () => {


            if (parseInt(loans[index].remainingbalance) > 0) {
                if (loans[index].name === "AMEX") {
                    if (loans[index].satisfiedupon) {

                        changeBorrowerLoanSatisfiedUpon({ target: { value: 'withownfunds' } })
                    }
                    return (
                        <div>
                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance before this transaction?
                            </div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mb-0">

                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={loans[index].satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfieddownlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfiedown" + index}>Yes, I will</label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={loans[index].satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfiedlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfied" + index}>No, I won't</label>
                                        </div>
                                    </div>
                                </fieldset>

                                <Form.Control.Feedback type="invalid" >
                                    Please make your choice.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    )
                }
                let canPayoffWithLoanProceeds = () => {
                    let loans = this.getFilteredLoans()

                    if (this.props.application.property.purpose === st.POL_Cashoutrefinance)
                        return true
                    if (this.props.application.property.purpose === st.POL_Purchase)
                        return false

                    if (isHELOC()) {
                        if (this.props.application.property.purpose === st.POL_Refinance && false === loans[index].partoforiginalpurchase)
                            return false
                    }
                    // refinance /cashout left
                    if (loans[index].forsubjectproperty === true)
                        return true
                    if (loans[index].partoforiginalpurchase)
                        return true
                    return false
                }
                let paidOff = () => {

                    return tooltip(<i className="text-nowrap" style={{ fontWeight: 'bold', fontStyle: 'normal' }}><i className="fas fa-angle-right"></i>Will this balance be paid off at the time of (or before) this transaction?</i>,
                        <div>
                            This question should be answered having several things in mind:
                            <ul>
                                <li>
                                    Are you refinancing this loan? Answer "Yes, I'm refinancing this loan"
                                </li>
                                <li>
                                    Are you paying the loan back? For example, you're selling your previous residence. Answer: "Yes, I'm it paying it off myself
                                    ".
                                </li><li>
                                    Closing some credit card balances can also be helpful in lowering your debt payments, and improving your odds of getting qualified for a new loan.
                                </li>
                                <li>
                                    Answer No, if you're keeping the loan as is
                                </li>
                            </ul>
                        </div>, "auto", "", "")
                }
                if (!showBalancePaidOff())
                    return ""
                return (
                    <div>
                        <div className="sectionquestion d-inline-block">{paidOff()}</div>
                        <Form.Group>
                            <fieldset>
                                <div className="d-flex mb-0">
                                    {canPayoffWithLoanProceeds() ?
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={loans[index].satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfiedlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfied" + index}>{isPrimary() ? "Yes, I'm refinancing this loan" : "Yes, I'm paying it off with the loan proceeds"}</label>
                                        </div> : ""
                                    }
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={loans[index].satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + index} name={"satisfied" + index}></input>
                                        <label id={"satisfiedownlabel" + index} className="zeitro-radio zeitro-radio divlink" htmlFor={"satisfiedown" + index}>Yes, I'm paying it off myself</label>
                                    </div>
                                    <div>
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={loans[index].satisfiedupon === "not"} type="radio" id={"not" + index} name={"satisfied" + index}></input>
                                        <label id={"notlabel" + index} className="zeitro-radio ivlink" htmlFor={"not" + index}>No, I won't</label>
                                    </div>
                                </div>
                            </fieldset>

                            <Form.Control.Feedback type="invalid" >
                                Please make your choice.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                )
            }
            return ""
        }

        let changePropertyAddress = event => {
            com.touch()
            this.props.changeBorrowerLoan(event.target.value, this.getUnfilteredIndex(index), "propertyaddress")
        }


        let isMortgage = () => {

            return loans[index].creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                loans[index].creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                loans[index].creditloantype.toLowerCase().indexOf("realestate") >= 0
        }

        let correction = loans[index].correction
        let crossed = ""

        if (["wrongperson", "alreadyclosed", "other", "duplicate"].includes(correction))
            crossed = " crisscrossed"
        if (correction === "paidbybusiness" || correction === "alreadyclosed")
            crossed = " greencrisscrossed"
        let mt5 = " mt-4"
        if (index === 0)
            mt5 = ""
        let onRateChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanRate(e, this.getUnfilteredIndex(index), this.props.who)
        }

        let changePartOfOriginal = e => {
            let r = e.target.value === "true"
            this.props.changeBorrowerLoan(r, this.getUnfilteredIndex(index), "partoforiginalpurchase")
        }
        let showAddress = () => {
            if (!isMortgage())
                return false

            if (this.props.application.property.purpose !== st.POL_Purchase && false === loans[index].forsubjectproperty &&
                this.props.application.financingsameproperty)
                return true

            if (this.props.application.property.purpose === st.POL_Purchase && false === loans[index].forprimaryresidence)
                return true

            if (this.props.application.property.purpose !== st.POL_Purchase &&
                false === this.props.application.financingsameproperty && false === loans[index].forprimaryresidence)
                return true
            return false
        }

        let showBalancePaidOff = () => {
            if (isMortgage()) {

                if (loans[index].forsubjectproperty == null &&
                    loans[index].forprimaryresidence == null)
                    return false
                if (this.props.application.property.purpose !== st.POL_Purchase && loans[index].forsubjectproperty)
                    return false
                /*
                if(isPrimary()) {  
                    return false
                }
                */
                return true
            }
            return true
        }
        let style = {}
        if (loans[index].forsubjectproperty === true && crossed === "") {
            style = {
                backgroundImage: 'url("/housemoney.png")',
                position: 'relative',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom 0px right 50px',
                backgroundSize: 'auto 100%',

            }

        }
        let theRest = () => {
            let ital = "ml-3 font-weight-light"
            switch (loans[index].correction) {
                case "duplicate":
                    return <div className={ital}>
                        This entry is a duplicate, and won't be counted against your debt
                    </div>
                case "wrongperson":
                    return <div className={ital}>
                        This entry does not belong to you, and won't be counted against your debt
                    </div>
                case "alreadyclosed":
                    return <div className={ital}>
                        This loan is already closed, and won't be counted against your debt
                    </div>
                case "paidbybusiness":
                    return <div className={ital}>
                        This loan is paid by business, and won't be counted against your debt
                    </div>
                case "other":
                    return <div className={ital}>
                        This loan won't be counted against your debt
                    </div>
                default:
                    break
            }


            if (crossed !== "")
                return ""

            let showQuestionForPrimary = () => {

                if (this.props.application.property.purpose === st.POL_Purchase)
                    return ""
                // only refinance
                if (false !== loans[index].forsubjectproperty)
                    return ""
                if (false !== this.props.application.financingsameproperty)
                    return ""
                return askPrimary()
            }

            let askPrimary = () => {
                return <div>
                    <Row className="">
                        <Col>
                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for {com.getPrimaryAddress("borrower", this.props.application)}?</div>
                            <div className="d-flex mb-0">

                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanForPrimary} value="true" checked={true === loans[index].forprimaryresidence} type="radio" id={"forprimary" + index} name={"forsforprimaryubject" + index}></input>
                                    <label id={"forprimarylabel" + index} className="zeitro-radio divlink" htmlFor={"forprimary" + index}>Yes, it is</label>
                                </div>
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanForPrimary} value="false" checked={false === loans[index].forprimaryresidence} type="radio" id={"forprimarynot" + index} name={"forsforprimaryubject" + index}></input>
                                    <label id={"forprimarynotlabel" + index} className="zeitro-radio divlink" htmlFor={"forprimarynot" + index}>No, it isn't</label>
                                </div>

                            </div>

                        </Col>
                    </Row>

                </div>
            }

            return (
                <div>
                    {(this.props.application.property.purpose !== st.POL_Purchase && isMortgage())
                        ?
                        <Row className="">
                            <Col>
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for {this.getSubjAddress()}?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="true" checked={true === loans[index].forsubjectproperty} type="radio" id={"forsubject" + index} name={"forsubject" + index}></input>
                                        <label id={"forsubjectlabel" + index} className="zeitro-radio divlink" htmlFor={"forsubject" + index}>Yes, it is</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="false" checked={false === loans[index].forsubjectproperty} type="radio" id={"forsubjectnot" + index} name={"forsubject" + index}></input>
                                        <label id={"forsubjectnotlabel" + index} className="zeitro-radio divlink" htmlFor={"forsubjectnot" + index}>No, it isn't</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        : ""}
                    {showQuestionForPrimary()
                    }

                    {loans[index].forsubjectproperty === true && this.props.application.property.purpose !== st.POL_Purchase &&
                        !isHELOC() && isPrimary()
                        ?

                        <Form.Group controlId={"liability" + index} className="text-left d-flex text-nowrap  m-0  p-0 pb-2 " >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '1.1em' }} className="text-left pt-1 pl-3" > &gt;Your current rate? </Form.Label>
                            <fieldset>
                                <InputGroup style={{ width: '8em' }} >
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        className="ml-1"
                                        required
                                        name={"liability" + index}
                                        type="text"
                                        defaultValue={loans[index].rate}
                                        onChange={onRateChange}
                                        style={{ width: '4em' }}
                                        pattern="^[0-9]+\.[0-9]+$"
                                        placeholder="XX.XX"
                                    />
                                    <InputGroup.Append  >
                                        <InputGroup.Text style={{ fontSize: '1.1em' }} >%
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <div>

                                    <Form.Control.Feedback type="invalid" >
                                        Please provide description.
                                    </Form.Control.Feedback>
                                </div>
                            </fieldset>
                        </Form.Group>

                        : ""

                    }

                    <div className="mx-3">{showAddress() ?

                        <AddressAutocomplete isValid={false} size="sm"
                            required
                            label="Provide Property address"
                            id={"propertyaddress" + index}
                            type="text"
                            defaultValue={loans[index].propertyaddress}
                            onChange={changePropertyAddress}
                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            goodfeedback=""
                            badfeedback="Please provide property raddress."
                        />

                        : " "}</div>


                    {loans[index].forsubjectproperty === true && isHELOC() &&
                        this.props.application.property.purpose === st.POL_Refinance ?
                        <Row className="">
                            <Col >
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Was this HELOC a part of the original purchase?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="true" checked={true === loans[index].partoforiginalpurchase} type="radio" id={"partoforiginalpurchase" + index} name={"partoforiginalpurchase" + index}></input>
                                        <label id={"partoforiginalpurchaselabel" + index} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchase" + index}>Yes, it was</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="false" checked={false === loans[index].partoforiginalpurchase} type="radio" id={"partoforiginalpurchasenot" + index} name={"partoforiginalpurchase" + index}></input>
                                        <label id={"partoforiginalpurchasenotlabel" + index} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchasenot" + index}>No, it wasn't</label>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                        : ""}


                    <Row className="">
                        <Col >

                            {askHowToPay()}
                        </Col>

                    </Row>
                </div>
            )
        }

        let m3 = "m-3 "
        if (!margin) {
            mt5 = ""
            m3 = "mb-3 "
        }
        let toggleShow = () => {
            let expand = this.state.expand
            expand[index] = !expand[index]
            this.setState({ expand })

            var content = document.getElementById("card" + index)
            if (!expand[index]) {
                content.style.maxHeight = "0px"
            } else {
                content.style.maxHeight = 2 * content.scrollHeight + "px"
            }
        }
        let askAboutDuplicate = !margin &&
            loans[index].correction === "duplicate" &&
            this.state.stepsandsubsteps[index].dupresolved == null

        let askDupe = () => {
            if (index === 0)
                return "should not see this"
            let onDupe = e => {
                let val = e.target.value === "true"
                let stepsandsubsteps = this.state.stepsandsubsteps
                stepsandsubsteps[index].dupresolved = val
                this.setState({ stepsandsubsteps })

                if (!val) {
                    com.touch()
                    this.props.changeBorrowerLoanCorrection({ target: { value: "none" } }, this.getUnfilteredIndex(index), this.props.who)
                    setTimeout(() => {
                        let stepsandsubsteps = this.recalcStepper()
                        this.setState({ stepsandsubsteps })
                    }, 1000)
                }
            }
            return <div className="ml-2">
                <div className="_sectionquestion">This entry looks like a duplicate of the previous one: {loans[index - 1].name}, acc. {loans[index - 1].accountnumber},
                    balance: ${com.commaizeFloat(loans[index - 1].remainingbalance)}
                    <br />Could you please confirm?</div>
                <Form.Group>
                    <fieldset>
                        <div className="d-flex  mt-2">
                            <div className="mr-3">
                                <input required onChange={onDupe} value={true} checked={true === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupe" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupe">Yes, this is a duplicate</label></div>
                            <div><input required onChange={onDupe} value={false} checked={false === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupenot" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupenot">No, it is not</label></div>
                        </div>
                    </fieldset>
                </Form.Group>
            </div>
        }
        let switchCashout = () => {
            this.setState({ showWarning: false })
            this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.state.observedIndex, this.props.who)
            this.props.changeMainPropertyPurpose({ target: { value: st.POL_Cashoutrefinance } })
        }
        let originalPurchase = () => {
            this.setState({ showWarning: false })

            this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.state.observedIndex, this.props.who)
            this.props.changeBorrowerLoan(true, this.getUnfilteredIndex(index), "partoforiginalpurchase")
        }
        return (
            <div key={key} className={loans[index].mannerofpaymentcode !== "C" ?
                " red-shadow  text-wrap" + crossed + mt5
                :
                "  drop-shadow  text-wrap" + crossed + mt5
            } style={style}>
                <Modal show={this.state.showWarning} onHide={() => { this.setState({ showWarning: false }) }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Tell us more about these loans</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <div style={{ minWidth: '400px' }}>
                            You indicated that you'd like to refinance more than one loan.
                        </div>
                        <div className="mt-4">
                            This is possible within the limits of rate/term refinance only if these loans were obtained together as a part of a purchase transaction.

                        </div>
                        <div className="mt-4">
                            Otherwise, we can switch to a cash out refinance. Your rates might be higher. Note, that you can always go back and change your choice.
                        </div>
                        <form name={"doo" + index}>
                        </form>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button onClick={switchCashout}>Switch to cash out refinance</Button>
                            </Col>
                            <Col className="text-center">
                                <Button onClick={originalPurchase}>The loans were part of original purchase</Button>
                            </Col>
                        </Row>

                    </Modal.Body>

                </Modal>

                <Modal show={this.state.show[index]} onHide={handleClose}
                    size="sm"
                // aria-labelledby="contained-modal-title-vcenter"
                // centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <h4>Does this liability need a correction?</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <form name={"corr" + index}>
                            <div className="mt-2 ">
                                <Form.Control name="purpose" as="select"
                                    value={correction}
                                    onChange={correctionChecked} >

                                    <option value="none">No correction needed</option>
                                    <option value="wrongperson">This is not my account!</option>
                                    <option value="alreadyclosed">This account has been paid in full and closed</option>
                                    <option value="paidbybusiness">Debt paid by business</option>
                                    <option value="duplicate">Duplicate entry</option>

                                    <option value="other">Other</option>
                                </Form.Control>

                            </div>
                            {correction !== "none" && correction !== "duplicate" ?
                                <div className="mt-3">
                                    <div>Please enter an explanation:</div>
                                    <textarea rows="5" onChange={changeExplanation} className="w-100" value={loans[index].explanation}>
                                    </textarea>
                                    <div style={{ fontStyle: 'italic', fontSize: '0.8em' }}>Documentation is required to support this explanation</div>

                                </div>
                                : ""
                            }
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button variant="success" onClick={handleClose}>Close</Button>
                                </Col>
                            </Row>
                        </form>

                    </Modal.Body>
                </Modal>
                <Row className="mt-0 pt-2 bluishbackground">
                    <Col xs>

                        <div style={{ fontSize: "1.2em" }} className="sectionquestion">
                            {this.getIcon(index)}

                            {com.unCamelCase(loans[index].creditloantype)}{this.getFlag(correction)}</div>
                    </Col>
                    {margin ?
                        <Col xs="auto">
                            <i className={this.state.expand[index] ? "fa fa-arrow-circle-up downarrow" : "fa fa-arrow-circle-down downarrow"} onClick={toggleShow} aria-hidden="true" ></i>
                        </Col> : ""}
                </Row>
                <div className="">
                    <Row className="bluishbackground" >
                        <Col xs="auto">
                            <div>Lender:</div>
                            <div >{loans[index].name}</div>
                        </Col>
                        <Col xs="auto">
                            <div>Account number:</div>
                            <div>{loans[index].accountnumber}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Balance:</div>
                            <div>${com.commaize(loans[index].remainingbalance)}</div>
                        </Col>
                        <Col xs="auto" >
                            <div>Mo. Payment:</div>
                            <div>{displayMonthly()}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Mo. left:</div>
                            <div>{
                                Number.isInteger(parseInt(loans[index].monthslefttopay)) ?
                                    loans[index].monthslefttopay : "N/A"
                            }</div>
                        </Col>

                        <Col xs="auto" className="">
                            Ownership<br />
                            {displayOwnership()}
                        </Col>

                        {loans[index].currentratingtype !== "AsAgreed" ?
                            <Col xs="auto" className="text-wrap">
                                <div style={{ fontWeight: '700', color: '#660000' }} className="text-wrap">{com.unCamelCase(loans[index].currentratingtype)}</div>
                            </Col>
                            : ""}

                    </Row>
                </div>
                <div className={!margin ? "" : "cardbody"} id={"card" + index}>
                    {askAboutDuplicate ? askDupe() :
                        <div className="">
                            <Row className="">
                                <Col sm="auto" className="d-flex pl-2 py-0 text-nowrap" >
                                    <Button onClick={showCorrect} style={{ color: '#b91c1c' }} className="text-wrap mt-1 p-1" variant="link" >This record needs correction<Tips
                                        content={<div>Click on this link if
                                            <ul>
                                                <li>
                                                    This loan does not belong to you
                                                </li>
                                                <li>
                                                    It has been paid in full
                                                </li>
                                                <li>
                                                    The debt has been paid by business
                                                </li>
                                                <li>
                                                    This is a duplicate entry (happens quite often!)
                                                </li>                                </ul>
                                            In this case the liability won't be counted, but you will have to supply supporting documents.

                                        </div>}
                                        placement="right"
                                        title="Correct my mortgage loans"
                                    /></Button>
                                </Col>

                            </Row>
                            {askAboutDuplicate ? askDupe() : theRest()}
                        </div>}
                </div>

            </div>
        )
    }
    checkForPrimary = (index) => {
        if (this.props.application.property.purpose !== st.POL_Purchase)
            return true
        let loans = this.getFilteredLoans()

        if (loans[index].forprimaryresidence === true)
            return true

        let adr = loans[index].propertyaddress

        let parsed = parser.parseLocation(adr)
        let subj = parser.parseLocation(this.getSubjAddress())
        if (adr === "")
            return true

        let ret = parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }

    checkForSubject = (index) => {
        if (this.props.application.property.purpose === st.POL_Purchase)
            return true
        let loans = this.getFilteredLoans()

        if (loans[index].forsubjectproperty === true)
            return true

        let adr = loans[index].propertyaddress

        let parsed = parser.parseLocation(adr)
        let subj = parser.parseLocation(this.getSubjAddress())
        if (adr === "")
            return true

        let ret = parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }
    renderLoanStep = (index, name, address, accountnumber,
        monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key) => {
        index = parseInt(index)
        let loans = this.getFilteredLoans()

        let click = () => {
            if (index >= this.props.application.assetsandliabilities.loansreviewed)
                return
            this.props.changeReviewedLoans(index)
        }
        let onBack = e => {
            this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed - 1)
            this.setState({ status: "process" })
            this.props.clearValidate()
            document.getElementById("card" + (index - 1)).scrollIntoView({ behavior: "smooth" })
        }
        let onDone = e => {

            // if (this.props.checkValidate(e)) {
            if (!this.checkForSubject(index)) {
                alert("The address provided is for the property that you're refinancing.")
                this.setState({ status: "error" })
                return false
            }
            if (!this.checkForPrimary(index)) {
                alert("The address provided is for the property that you're purchasing.")
                this.setState({ status: "error" })
                return false
            }
            this.setState({ status: "process" })
            this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed + 1)
            //document.getElementById("card" + (index)).scrollIntoView({ behavior: "smooth" });
            // } else {
            //     this.setState({ status: "error" })
            //     this.props.clearValidate()
            // }
        }

        let grayifneeded = ""
        if (index > this.props.application.assetsandliabilities.loansreviewed)
            grayifneeded = "graytext"
        let correction = loans[index].correction
        let dd = <div className="mt-0  w-100">
            {(index === this.props.application.assetsandliabilities.loansreviewed) ?
                <div id={"card" + index}>

                    <Row >
                        <Col xs>
                            {this.renderLoan(index, name, address, accountnumber,
                                monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, false)}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="auto"><Button variant="zeitro-outline" onClick={onDone} >This record looks good to me</Button></Col>
                        <Col xs>{this.props.application.assetsandliabilities.loansreviewed > 0 ?
                            <Button variant="zeitro-outline" onClick={onBack}>Back</Button> : ""}</Col>
                    </Row>

                </div>
                :
                <Row id={"card" + index} className={grayifneeded}>
                    <Col xs="auto">

                        <div >
                            {this.getIcon(index)}

                            {com.unCamelCase(loans[index].creditloantype)}{this.getFlag(correction)}
                        </div>

                    </Col>
                    <Col xs>
                        {loans[index].name}
                    </Col>
                </Row>
            }
        </div>


        let tt = ""
        let dup = (loans[index].correction === "duplicate")

        return (
            <Steps.Step sm title={dd} onClick={click} description={tt} subStep={dup} secondaryCurrent={dup} />
        )

    }
    nbsp = () => {
        return (<div className="mt-4">&nbsp;<br />&nbsp;</div>)
    }
    renderStepper = () => {
        if (this.state.stepsandsubsteps.length === 0)
            return ""

        let current = this.props.application.assetsandliabilities.loansreviewed == null ? 0 : this.props.application.assetsandliabilities.loansreviewed

        let inx = this.state.stepsandsubsteps[current].steps
        let ss = this.state.stepsandsubsteps[current].substeps

        return (
            <div id="liabilitystepper">
                <div className="prequalInterviewH3 text-wrap mt-3">Please review your existing mortgages and mark the ones that are already paid off or invalid by clicking on the Needs Correction link</div>
                <Steps status={this.state.status} direction="vertical" secondaryCurrent={ss} current={inx} className="text-left">
                    {
                        this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(filterMortgages).map(this.processSteps)
                    }

                </Steps>
            </div>
        )
    }
    renderCore = () => {
        let loans = this.getFilteredLoans()
        if (!loans) return ""

        if (this.props.application.assetsandliabilities.loans !== null && this.props.application.assetsandliabilities.loans.length === 1 && this.props.application.assetsandliabilities.loans[0].name === "Estimated") {
            if (this.props.application.assetsandliabilities.loansreviewed === 0) {
                this.props.changeReviewedLoans(1)
            }
            return ""
        }


        if (this.props.application.assetsandliabilities.loansreviewed == null || this.props.application.assetsandliabilities.loansreviewed < loans.length) {
            return this.renderStepper()
        } else {
            return (
                <div className="pt-2">
                    {this.props.application.assetsandliabilities.loans === null ? "" :
                        this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(filterMortgages).map(this.process)}
                </div>
            )
        }
    }
    addOtherProperties = (e) => {
        com.touch()
        let val = e === "true"
        if (!val) {
            if (this.props.application.borrower.presentaddressownership === st.O_Own) {
                for (let i = 1; i < this.props.application.assetsandliabilities.ownedproperties.length; i++) {

                    this.props.removeBorrowerOwnedProperty(i)
                }
            } else {
                this.props.clearBorrowerOwnedProperty()
            }
        } else {
            this.props.addBorrowerOwnedProperty()
        }
    }
    processAdditionalRealEstate = (val, index) => {
        if (null === val)
            return ""
        let { key } = val

        // if the residential property is owned, show only addtionals
        if (this.isPresentAddrOwned &&
            this.props.application.assetsandliabilities.ownedproperties.length > 0) {

            if (index !== 0) {
                return this.renderAdditional(index, key)
            } else {
                return ""
            }
        }

        return this.renderAdditional(index, key)
    }

    renderAdditional = (index, key) => {
        index = parseInt(index)
        return (
            <div key={key} className="incard ">
                <Row>
                    <Col className="p-0 ">
                        <Row className="p-0 ">
                            <Col >
                                <AddressAutocomplete id={"Address" + index} label="Property address"
                                    name={"address" + index}
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].address}
                                    onChange={e => this.props.changeOwnedProperty(e.target.value, index, "address")}
                                    onCompletion={(address, who) => {
                                        let num = com.getSubaddress(address, "street_number", "long_name")

                                        let city = com.getSubaddress(address, "locality", "long_name")
                                        let zip = com.getSubaddress(address, "postal_code", "long_name")
                                        let county = com.getSubaddress(
                                            address,
                                            "administrative_area_level_2",
                                            "long_name"
                                        )
                                        let longstate = com.getSubaddress(
                                            address,
                                            "administrative_area_level_1",
                                            "long_name"
                                        )
                                        let short = com.getSubaddress(address, "route", "short_name")
                                        let stradd = num + " " + short
                                        this.props.changeOwnedProperty(stradd, index, "address")
                                        this.props.changeOwnedProperty(longstate, index, "state")
                                        this.props.changeOwnedProperty(county, index, "county")
                                        this.props.changeOwnedProperty(city, index, "city")
                                        this.props.changeOwnedProperty(zip, index, "zipcode")
                                    }}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                            </Col>
                        </Row>
                        <Row className="p-0 mt-4">
                            <Col >
                                <div className="formLabel">
                                    Property value
                                </div>
                                <InputNumber
                                    prefix="$"
                                    className="w-100"
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].marketvalue}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={e => this.props.changeOwnedProperty(e, index, "marketvalue")}
                                    size="large"
                                />
                            </Col>
                            <Col>
                                <div className="formLabel">
                                    Property status
                                </div>
                                <Select
                                    className="w-100"
                                    style={{ height: 44 }}
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].status}
                                    options={[
                                        { value: "sold", label: "Sold" },
                                        { value: "pending", label: "Pending sale" },
                                        
                                        { value: "second", label: "Second" },
                                        { value: "rental", label: "Rental" },
                                    ]}
                                    onChange={e => this.props.changeOwnedProperty(e, index, "status")}
                                />

                            </Col>
                            <Col>
                                <div className="formLabel">
                                    Property intended occupancy
                                </div>
                                <Select
                                    className="w-100"
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].occupancy}
                                    options={[
                                        { value: "principal", label: "Primary Residence" },
                                        { value: "secondhome", label: "Second Home" },
                                        { value: "investment", label: "Investment" },
                                    ]}
                                    onChange={e => this.props.changeOwnedProperty(e, index, "occupancy")}
                                />

                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={8} xs={12}>
                                <div className="formLabel">
                                    Monthly insurance, taxes, association dues, etc.
                                </div>
                                <InputNumber
                                    prefix="$"
                                    className="w-100"
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].insurancemaintenancetaxes}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={e => this.props.changeOwnedProperty(e, index, "insurancemaintenancetaxes")}
                                    size="large"
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3 mt-4">
                            <Col>
                                <Checkbox
                                    checked={
                                        this.props.application.assetsandliabilities.ownedproperties[index].propertytype === "twotofour"
                                    }
                                    className="formLabel"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.props.changeOwnedProperty("twotofour", index, "propertytype")
                                        } else {
                                            this.props.changeOwnedProperty(null, index, "propertytype")
                                        }
                                    }}
                                >
                                    This is a 2-4 unit property
                                </Checkbox>
                            </Col>
                        </Row>
                        {(
                            this.props.application.assetsandliabilities.ownedproperties[index].occupancy === "investment" ||
                            this.props.application.assetsandliabilities.ownedproperties[index].propertytype === "twotofour"
                        ) && <Row className="mb-4">
                                <Col md={8} xs={12}>
                                    <div className="formLabel">
                                        Expected Monthly Rental Income
                                    </div>
                                    <InputNumber
                                        prefix="$"
                                        className="w-100"
                                        defaultValue={this.props.application.assetsandliabilities.ownedproperties[index].netrentalincome}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={e => this.props.changeOwnedProperty(String(e), index, "netrentalincome")}
                                        size="large"
                                    />
                                </Col>
                            </Row>}
                        <Row>
                            <Col>
                                <OwnedPropertyMortgagesTable i={index} />
                            </Col>
                        </Row>

                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application.assetsandliabilities.ownedproperties.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={() => this.props.addBorrowerOwnedProperty()} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.ownedproperties.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={() => this.props.removeBorrowerOwnedProperty(index)}></i>

                    </Col>
                </Row>
            </div>
        )
    }

    getExpensesQuestions = (iwe, you, who) => {
        let addtionalPropertyTitle = you + " currently own any additional properties"
        if (this.props.application.property.purpose === "purchase" &&
            this.props.application.borrower.presentaddressownership === "own" &&
            this.props.application.assetsandliabilities.ownedproperties.length > 0) {
            addtionalPropertyTitle += ", besides " + this.props.application.assetsandliabilities.ownedproperties[0].address + "?"
        } else {
            addtionalPropertyTitle += "?"
        }
        return (
            <div>
                <div className="pt-5 " >
                    {this.props.application.assetsandliabilities.ownedproperties.length > 0 &&
                        this.props.application.borrower.presentaddressownership === st.O_Own &&
                        <Form.Group className="inputSection">
                            <Form.Label className="d-flex justify-content-between text-wrap">
                                Please provide the loans list of your owned property: {this.props.application.assetsandliabilities.ownedproperties[0].address}:
                            </Form.Label>
                            <div>
                                <OwnedPropertyMortgagesTable i={0} />
                            </div>

                        </Form.Group>}

                    <Form.Group className="inputSection">
                        <Form.Label className="d-flex justify-content-between text-wrap">
                            <a>{addtionalPropertyTitle}</a>
                        </Form.Label>
                        <CheckGroup
                            required
                            data={[{
                                value: 'false',
                                label: 'No',
                            },
                            {
                                value: 'true',
                                label: 'Yes ',
                            },
                            ]}
                            onChangeSelect={e => this.addOtherProperties(e)}
                            value={
                                (null != this.props.application.assetsandliabilities.ownedproperties && (
                                    (this.props.application.borrower.presentaddressownership === st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length > 1) ||
                                    (this.props.application.borrower.presentaddressownership !== st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length > 0)
                                )) ? "true" : "false"
                            }
                            type='radio'
                            direction="horizontal" />
                    </Form.Group>
                </div>

                <div className="pt-3">
                    {null != this.props.application.assetsandliabilities.ownedproperties ?
                        this.props.application.assetsandliabilities.ownedproperties.map(this.processAdditionalRealEstate) : ""}
                </div>
            </div>
        )
    }

    renderAdditionalExpenses = () => {
        let iwe = "I"
        let you = "Do you"
        let who = "borrower"
        return this.getExpensesQuestions(iwe, you, who)
    }

    render = () => {
        let loans = this.getFilteredLoans()

        let showWarnings = x => {
            return <Alert className="mx-3" variant="info"> {x}</Alert>
        }

        return (
            <div
                className=" mb-4 text-left newInterviewPaneEmployment" >
                <div className="prequalInterviewTitle pt-3">Liabilities</div>

                {this.props.application.assetsandliabilities.warnings != null && this.props.borrowerid ?
                    this.props.application.assetsandliabilities.warnings.map(showWarnings)
                    : ""
                }
                {this.state.loadingcr ?
                    <div className="w-100 mt-4 text-center">
                        <Skeleton active />
                    </div>
                    :

                    <div className="pt-3">
                        {this.renderCore()}

                        {this.renderAdditionalExpenses()}
                    </div>

                }

                {this.presentFreeAndClear()}
                {this.state.message}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Liabilities)

