import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { Row } from "react-bootstrap"
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import CheckGroup from "../Common/components/CheckGroup"
import { isEmpty } from 'lodash'
import { Select, Input } from "antd"

export function getTotalExpenses(who) {
    let total = 0
    let getExpence = (value, monthly) => {
        value = parseFloat(value)
        if (monthly === "monthly")
            return value
        else

            return value / 12
    }

    if (this.props.application.property.purpose === "purchase" &&
        this.props.application[who].presentaddressownership === "rent") {
        total += getExpence(this.props.application[who].expenses.currentrent, this.props.application[who].expenses.currentrentperiod)
    }

    if ((this.props.application[who].presentaddressownership === "own" &&
        this.props.application.property.purpose === "purchase") ||
        this.props.application.property.purpose !== "purchase") {

        total += getExpence(this.props.application[who].expenses.currentfirstmortgage,
            this.props.application[who].expenses.currentfirstmortgageperiod)
        total += getExpence(this.props.application[who].expenses.currentotherfinancing,
            this.props.application[who].expenses.currentotherfinancingperiod)

        total += getExpence(this.props.application[who].expenses.currenthazardinsurance,
            this.props.application[who].expenses.currenthazardinsuranceperiod)

        total += getExpence(this.props.application[who].expenses.currentmortgageinsurance,
            this.props.application[who].expenses.currentmortgageinsuranceperiod)

        total += getExpence(this.props.application[who].expenses.currentrealestatetaxes,
            this.props.application[who].expenses.currentrealestatetaxesperiod)

        total += getExpence(this.props.application[who].expenses.currentdues,
            this.props.application[who].expenses.currentduesperiod)

        total += getExpence(this.props.application[who].expenses.currentother,
            this.props.application[who].expenses.currentotherperiod)

    }

    return com.commaizeFloat(total.toFixed(2))
}
const mapStateToProps = (state) => {
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    addAlimony: (event, who) => {
        dispatch(act.AddAlimony(event, who))
    },
    removeAlimony: (i, who) => {
        dispatch(act.RemoveAlimony(i, who))
    },
    clearAlimonies: (event, who) => {
        dispatch(act.ClearAlimonies(event, who))
    },
    changeAlimony: (event, i, who) => {
        dispatch(act.ChangeAlimony(event, i, who))
    },
    changeAlimonyField: (value, verb, who, i) => {
        dispatch(act.ChangeAlimonyField(value, verb, who, i))
    },
    changePaymentType: (event, i, who) => {
        dispatch(act.ChangePaymentType(event.target.value, i, who))
    },
})

class Expenses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
        }
        this.isValidated = this.isValidated.bind(this)
        // see if you need to seed the defaults for 
        let firstmortgage = 0
        let otherfinancing = 0

        // if this is the last page before rates, change the "Continue" button to "Submit" && this.props.application.status === "interviewstarted"
        if (this.props.application.status === "interviewstarted" &&
            (!this.props.checkCoExpenses() || (this.props.checkCoExpenses() && this.props.who === "coborrower"))) {
            this.props.changeContinueButtonText()
        }

        if (this.props.who === com.borrower) {
            if (this.props.application.assetsandliabilities.loans !== null) {
                for (let index = 0; index < this.props.application.assetsandliabilities.loans.length; index++) {
                    let loan = this.props.application.assetsandliabilities.loans[index]
                    // don't want to do this for a coborrower until we see it
                    //if( (loan.attribution & 1) === 0)
                    //    continue
                    if (!loan.creditloantype) continue
                    if (loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0) {
                        if (loan.forsubjectproperty === true) {
                            if (firstmortgage === 0)
                                firstmortgage += parseInt(loan.monthlypayment)
                            else
                                otherfinancing += parseInt(loan.monthlypayment)
                        }
                    } else {

                        if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0) {
                            if (loan.forsubjectproperty === true)
                                otherfinancing += parseInt(loan.monthlypayment)
                        }

                    }
                }
            }

            for (let i = 0; (this.props.application.otherloans != null) && (i < this.props.application.otherloans.length); i++) {
                let loan = this.props.application.otherliens[i]
                if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                    if (firstmortgage === 0)
                        firstmortgage += parseInt(loan.monthlypayment)
                    else
                        otherfinancing += parseInt(loan.monthlypayment)
                }
            }


            setTimeout(() => {
                if (this.props.application[this.props.who].expenses.currentfirstmortgage === "") {

                    this.props.updateExpenses({ target: { value: firstmortgage.toString() } }, com.borrower, "currentfirstmortgage")
                    this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentfirstmortgageperiod")
                    if (this.props.application[this.props.who].expenses.currentotherfinancing === "") {
                        this.props.updateExpenses({ target: { value: otherfinancing.toString() } }, com.borrower, "currentotherfinancing")
                        this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentotherfinancingperiod")
                    }
                }
            }, 100)


        }
        if (null === this.props.application.borrower.finances.alimony) {
            this.props.clearAlimonies("", "borrower")
        }
        if (null === this.props.application.coborrower.finances.alimony) {
            this.props.clearAlimonies("", "coborrower")
        }
    }
    componentDidMount() {
        this.completion()
    }
    componentDidUpdate() {
        this.completion()
    }
    componentWillUnmount() { }
    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length === 0) {
            sessionStorage.setItem("progressValue", 100)
            return
        }
        if (formElements && formElements.length !== 0) {
            let totalCount = 0
            let fillNum = 0
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (element.type !== 'button' && element.type !== 'fieldset' && element.type !== 'submit') {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem("progressValue", progressValue)
        }
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }

    showCoborrower = () => {
        if (this.props.hascoborrower === "withcoborrower" && this.props.application.married)
            return true
        if (this.props.hascoborrower === "multiple" && this.props.application.married)
            return true
        return false
    }

    totalExpenses = getTotalExpenses.bind(this)

    getHeader = () => {
        if (this.props.application.hascoborrower === "alone") {
            return "Expenses"
        }
        if (this.props.application.hascoborrower === "withcoborrower" && !this.props.application.married) {
            return <div><a style={{ color: "#325CEB" }}>{this.props.application[this.props.who].firstname + " " + this.props.application[this.props.who].lastname}</a>'s Expenses</div>
        }
        return "Expenses"

    }

    handleSubmit = e => {
        if (this.props.application[this.props.who].presentaddressownership == null) return false
        return true
    }
    processAlimonies = (val, index) => {
        if (null === val)
            return ""

        let amount = val.amount
        let owedto = val.owedto
        let whatisit = val.alimonyorsupport
        let lessthan10 = val.lessthan10
        let monthslefttopay = val.monthstopay
        let key = val.key
        return this.generateAlimony(index, amount, owedto, lessthan10, whatisit, monthslefttopay, key)
    }
    generateAlimony = (index, amount, owedto, lessthan10, whatisit, monthslefttopay, key) => {
        index = parseInt(index)
        let removeAlimony = (event) => {
            com.touch()
            this.props.removeAlimony(index, this.props.who)
        }
        let changeAlimony = (event) => {
            com.touch()
            this.props.changeAlimony(event, this.props.who, index)
        }
        let addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        let updateWhat = (event) => {
            com.touch()
            this.props.changePaymentType(event, this.props.who, index)
        }
        return (
            <div key={key} className="incard mt-2">
                <Row>
                    <Col xs={12} as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAlimony} ></i>
                        <i hidden={index === this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAlimony} ></i>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col className="p-0 px-1">
                        <Row >
                            <Col md={6} xs={12} className="mt-4">
                                <Form.Group controlId="type" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Payment type</Form.Label>
                                    <div>
                                        <Select
                                            style={{ width: "100%", height: 44 }}
                                            value={whatisit}
                                            onChange={(e) => { updateWhat({ target: { value: e } }) }}
                                        >
                                            <option value="alimony">Alimony</option>
                                            <option value="childsupport">Child Support</option>
                                            <option value="maintenancepayments">Separate Maintenance</option>
                                            <option value="jobrelatedexpenses">Job Related Expenses</option>
                                            <option value="other">Other</option>


                                        </Select>

                                    </div>
                                    {/* <Form.Control name="purpose" as="select"
                                        value={whatisit}
                                        onChange={updateWhat}
                                        size="sm">

                                        <option value="alimony">Alimony</option>
                                        <option value="childsupport">Child Support</option>
                                        <option value="maintenancepayments">Separate Maintenance</option>
                                        <option value="jobrelatedexpenses">Job Related Expenses</option>
                                        <option value="other">Other</option>

                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12} className="mt-4" style={{ size: "4em" }}>
                                <Form.Group controlId="Amount" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Monthly amount</Form.Label>
                                    <div>

                                        <Input
                                            prefix='$'
                                            style={{ height: 44 }}
                                            value={amount}
                                            onChange={(e) => {
                                                changeAlimony(e.target.value)
                                            }}
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        ></Input>
                                    </div>

                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </div >
        )
    }
    render() {
        let who = this.props.who
        let updateExpenses = (who, verb) => {

            return (event) => {
                com.touch()
                return this.props.updateExpenses(event, who, verb)
            }
        }
        let clearAlimony = (event) => {
            com.touch()
            this.props.clearAlimonies(event, who)
        }

        let addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, who)
        }

        return (
            <div className=" mb-4 text-left newInterviewPaneEmployment ">
                <div className="prequalInterviewTitle">{this.getHeader()}</div>
                <div className="pt-4  w-600px">
                    <Form.Group className="inputSection">
                        <Form.Label className="d-flex text-wrap">Do you have any additional expenses, including alimony, child support, separate maintenance, and job-related expenses?</Form.Label>
                        <CheckGroup
                            data={[{
                                value: 'false',
                                label: 'No',
                            },
                            {
                                value: 'true',
                                label: 'Yes',
                            },
                            ]}
                            onChangeSelect={e => {
                                if (e === "true") {
                                    addAlimony()
                                } else {
                                    clearAlimony()
                                }
                            }}
                            value={
                                (null != this.props.application[who].finances.alimony && this.props.application[who].finances.alimony.length > 0) ? "true" : "false"
                            }
                            type='radio'
                            direction="horizontal" />

                    </Form.Group>
                </div>
                <div>
                    {this.props.application[who].finances.alimony != null ? this.props.application[who].finances.alimony.map(this.processAlimonies) : ""}
                </div>
                <div className="text-left mt-3" >

                </div>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Expenses)

