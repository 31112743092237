import applicationReducer from './application'
import progressReducer from './progress'

import { combineReducers } from 'redux';

let rootReducer =  combineReducers({
    application: applicationReducer,
    progress: progressReducer,
  });

export default rootReducer;