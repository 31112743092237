import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Spinner } from "react-bootstrap";
import { connect } from 'react-redux'
import { Divider, Radio, Skeleton, Input, Dropdown, Space, Modal } from 'antd';
import { SearchOutlined } from "@ant-design/icons"
import * as act from "./Store/actions"
import * as prgr from "./Store/progress"
import * as com from "./Common.js"
import { State } from './State.js'
import './HardMoneyLoanDashboard.css'


import { UpdateChangeProgressType } from "./Store/progress"
import { getCurrentState } from "./Store";
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const filterOptions = [{ label: 'All Loans', value: 'all' }, { label: 'Draft', value: 'draft' }, { label: 'Submitted', value: 'submitted' },]

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressStep: (step) => {
      dispatch(prgr.UpdateChangeProgressStep(step))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
});
/* not sure if we will need this

function DeleteProfile(props) {
    const nukeTheProfile = a => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/auth/deleteprofile', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: ''
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        window.location.href="/"
                    }
                });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    return (
        <Modal      {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Do you really want to delete your account?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5">
                <p>
                    We are sad to see you go! We hope you will find the right loan for yourself.
                </p>
                <p>
                    Upon the deletion of your profile all the data will be lost, including the 1003 form, and all the supplemental documentation.
          </p>
                <p>
                    This operation is irreversible. Are you sure you want to do this?
                </p>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Button variant="success" onClick={props.handleClose}>No, I'll keep working on my loan</Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="danger" onClick={nukeTheProfile}>Yes, delete my data, and the account</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
*/
class HardMoneyLoanDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loans: [],
            loading: false,
            loadingOpenNew: false,
            customerid: com.getCustomerId(),
            loanFilterValue: 'all',
            searchValue: '',
            deleteModalOpen: false,
            targetLoanID: '',
        }
        this.reload()
    }

    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ loading: true })
        fetch('/borrower/gethardmoneyloans', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                    } else {
                        js.loans.forEach(loan => {
                            loan.state = JSON.parse(loan.state)
                            loan.lostate = JSON.parse(loan.lostate)
                        })
                        js.loans.sort((a, b) => {
                            return new Date(b.state.progress.lasttouch) - new Date(a.state.progress.lasttouch)
                        })
                        this.setState({ loans: js.loans, loanid: js.loanid })

                    }
                    this.setState({ loading: false })
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            this.setState({ loading: false })
        });
    }

    componentDidMount() {
        this.props.updateChangeProgressType("yourloans")
    }
    newApplication(loan) {
        let token = sessionStorage.getItem("ZeitroA")
        let js = { LoanID: loan.loanid }
        let isHardMoneyLoan = loan.state.application.product.mortgageapplied === "hardmoneyloan"
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        let state = JSON.parse(js.State)

                        state.progress.stage = "interview"
                        state.progress.interviewfinished = false
                        state.progress.interviewstarted = false
                        state.progress.productselectionstarted = false
                        state.progress.interview.step = 0
                        state.progress.interview.max = 0
                        state.application.status = 'interviewstarted'
                        state.application.selection.selectedloan = null
                        state.application.property.hascontract = true
                        if (isHardMoneyLoan) {
                            state.application.product.mortgageapplied = "hardmoneyloan"
                        }
                        this.props.updateChangeApplication(state.application)
                        this.props.updateChangeVisitedStage("interviewstarted")
                        this.props.updateChangeProgress(0, "max")
                        this.props.updateChangeProgress(0, "interview")

                        getCurrentState()

                        //  redirect
                        if (isHardMoneyLoan) {
                            window.location.href = "/application/hardMoneyLoan/interview/main/borrower"
                            return
                        }
                        window.location.href = "/services/preapp?customerid=" + this.state.customerid
                    }

                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    openNewApplication() {
        this.setState({ loadingOpenNew: true })
        let token = sessionStorage.getItem("ZeitroA")
        const newS = JSON.parse((() => { let a = new State(); return a.toJson() })())

        newS.progress.stage = "interview"
        newS.progress.interviewfinished = false
        newS.progress.interviewstarted = false
        newS.progress.productselectionstarted = false
        newS.progress.interview.step = 0
        newS.progress.interview.max = 0
        newS.application.status = 'interviewstarted'
        newS.application.selection.selectedloan = null
        newS.application.property.hascontract = true
        newS.application.product.mortgageapplied = "hardmoneyloan"

        let js = { LoanID: this.state.loanid, NewState: com.ascii2hex(JSON.stringify(newS)) }
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ loadingOpenNew: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log(js.Text)
                        alert("Looks like there was a problem. Please contact your loan officer for help.")
                    } else {
                        let st = JSON.parse(js.State)

                        // st.progress.stage = "interview"
                        // st.progress.interviewfinished = false
                        // st.progress.interviewstarted = false
                        // st.progress.productselectionstarted = false
                        // st.progress.interview.step = 0
                        // st.progress.interview.max = 0
                        // st.application.status = 'interviewstarted'
                        // st.application.selection.selectedloan = null

                        this.props.updateChangeApplication(newS.application)
                        this.props.updateChangeVisitedStage("interviewstarted")
                        this.props.updateChangeProgress(0, "max")
                        this.props.updateChangeProgress(0 , "interview")
                        getCurrentState()
                        this.setState({ loading: false })
                        window.location.href = "/application/hardMoneyLoan/interview/main/borrower"
                    }

                });
            }
        ).catch((err) => {
            this.setState({ loadingOpenNew: false })
            console.log('Fetch Error :', err);
        });
    }
    onSearch = (e) => {
        this.setState({
            searchValue: e.target.value,
        })
    }
    onSwitch(loan) {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let js = { LoanID: loan.loanid }
        fetch('/auth/switch', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ loading: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status === "OK") {
                        // sessionStorage.setItem("state", js.State);
                        // sessionStorage.setItem("originalstate", js.State);
                        let st = JSON.parse(js.State)
                        this.props.updateChangeApplication(st.application)
                        this.props.updateChangeProgress(st.progress.interview.max, "max")
                        this.props.updateChangeProgressStep(st.progress.interview.step)
                        this.props.updateChangeProgress(0, "interview")
                        getCurrentState()

                        let u = "/application/interview/main"
                        if (st.application.property.purpose === "purchase" && !st.application.property.hascontract) {
                            u = "/prequal/interview/main"
                        }
                        if (st.application.product.mortgageapplied === "hardmoneyloan") {
                            u = "/application/hardMoneyLoan/interview/main/borrower"
                        }
                        this.setState({ loading: false })
                        window.location.href = u

                    } else {
                        this.setState({ loading: false })
                        alert("Error switchin the app")
                    }

                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    onChangeFilter = (e) => {
        let val = e.target.value

        this.setState({
            loanFilterValue: val
        })
    }
    confirmDelete = () => {
        let LoanID = this.state.targetLoanID
        this.setState({
            confirmLoading: true
        })
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/hideapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                LoanID
            })
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                } else {
                    this.setState({ confirmLoading: false, deleteModalOpen: false, targetLoanID: ''})
                    this.reload()
                }
                return response.json()
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            this.setState({ confirmLoading: false, targetLoanID: '' })
        });
    }
    displayLoan = (l) => {
        let myclass = ""
        if (['fundsreleased', 'applicationwithdrawn', 'applicationabandoned',
            'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(l.state.application.status)) {

            if (['applicationwithdrawn', 'applicationabandoned',
                'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(l.state.application.status)) {
                myclass = " crisscrossed"
            } else {
                myclass = " greencrisscrossed"
            }


        }

        let onNewApplication = (l) => {
            this.newApplication(l)
        }
        let onSwitch = (l) => {
            if (this.state.loanid === l.loanid) {
                window.location.href = '/application/hardMoneyLoan/interview/main/borrower'
                return
            }
            else {
                this.onSwitch(l)
            }
        }
        let cls = "text-left drop-shadow mt-2 mr-5  p-2 d-inline-block"
        if (this.state.loanid === l.loanid)
            cls = "text-left drop-big-shadow mt-2 mr-5  p-2 d-inline-block"
        cls = cls + myclass
        let lasttouch = new Date(l.state.progress.lasttouch)
        let isPrequal = (l.state.application.property.purpose === "purchase" && l.state.application.property.hascontract === false) ? true : false
        let isHardMoneyLoan = l.state.application.product.mortgageapplied === "hardmoneyloan"
        let items = [
            {
                key: '1',
                label: (
                    <Button variant="plainText" onClick={e => { e.stopPropagation(); e.preventDefault(); onNewApplication(l) }} >
                        Copy this application
                    </Button>
                ),
            }
        ]
        if (l.state.application.status === 'interviewstarted' || l.state.application.status === 'loinitialized' ) {
            items.push(
                {
                    key: '2',
                    label: (
                        <Button variant="plainText" className="delete-menu-btn" onClick={
                            (e) => {
                                e.stopPropagation(); e.preventDefault();
                                this.setState({ targetLoanID: l.loanid, deleteModalOpen: true })
                            }
                        }>
                            Delete
                        </Button>
                    ),
                }
            )
        }

        const getLoanStatus = () => {
            let { status } = l.state.application
            if (status === "interviewstarted") return 'Draft'
            if (status === "interviewfinished") return 'Submitted'
        }
        const getFullAddress = () => {
            if (isPrequal) return "-"
            const {address, zipcode, state, city } = l.state.application.property
            if (!address) return '-'
            let fulladdr = address
            if (city) fulladdr += ', ' + city
            if (state) fulladdr += ', ' + state
            if (zipcode) fulladdr += ' ' + zipcode
            return fulladdr
        }
        const getLoanTitle = (l) => {
            if (isPrequal) return "Pre-qualification"
            if (isHardMoneyLoan) {
                const { serialnumber } = l.state.application.hardmoneyloan
                return com.capitalize(l.state.application.property.purpose) + (serialnumber ? ' | ' + serialnumber : "")
            }
            return "Application"

        }
        let loanAmount = l.state.application.hardmoneyloan.initialloanamount
        loanAmount = `${loanAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (loanAmount !== '' && !String(loanAmount).startsWith('$')) loanAmount = '$' + loanAmount
        return <Col md={3} xs={12} key={l.loanid} className="myhardmoneyloan text-left p-0 mr-3 mb-3" onClick={() => onSwitch(l)}>
        {this.state.loanid === l.loanid && <div className="view-current-label">View Current application</div>}
            {this.state.loanid === l.loanid && <div className="view-current-label-triangle"></div>}
            <div style={{ padding: "15px 18px 15px 18px" }}>

            <div className='d-flex justify-content-between'>
                <div style={{ marginLeft: 20, fontSize: 16, fontWeight: 500 }} className="mt-2">
                    {getLoanTitle(l)}
                </div>
                    <div>
                        <Dropdown
                            onClick={(e) => { e.stopPropagation(); e.preventDefault()}}
                            menu={{
                                items,
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <div style={{ width: 30, paddingLeft: 20, paddingRight: 5 }} >
                                        <img src="/images/threedots.png" style={{ height: 20 }} />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                    </div>
                <div style={{ marginLeft: 20, color: '#1F3988', fontWeight: 600, fontSize: 14 }}>{getLoanStatus()}</div>
                <div style={{ marginLeft: 20 }} className="mt-2">
                    {getFullAddress()}
                </div>
                <div style={{ marginLeft: 20, color: "#6E6E70" }} className="mt-2">
                    Last edit at {lasttouch.toLocaleDateString()}
                </div>
                <Divider style={{ marginLeft: 20, width: 'auto' }} className="mt-2" />
                <div style={{ marginLeft: 20, marginRight: 20 }} className="mt-2 loan-amount d-flex justify-content-between">
                    <span>Loan amount:</span>
                    <span className="loan-amount-value">{loanAmount}</span>
                </div>
            </div>
        </Col>

    }

    searchFilter = (loan) => {
        let val = this.state.searchValue.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        val = val.trim().toLowerCase()
        if (val === '') return true
        const { address, city, state, county, zipcode } = loan.state.application.property
        return [address, city, state, county, zipcode].some(x => x.toLowerCase().includes(val))
    }

    statusFilter = (loan) => {
        let { status } = loan.state.application
        if (this.state.loanFilterValue === 'all') return true
        if (this.state.loanFilterValue === 'submitted' && status === "interviewfinished") return true
        if (this.state.loanFilterValue === 'draft' && status === "interviewstarted" || status === "loinitialized") return true
        return false
    }

    handleDelete = () => {
        this.setState({

        })
    }

    render() {
        let borrowerName = this.props.application.borrower.firstname
        let welcomeMessage = borrowerName === "" ? "Welcome!" : "Welcome back, " + borrowerName + "."

        return (
            <div className="mt-0 px-5 py-4 h-100">
                <Row className="welcomeMessage">{welcomeMessage}</Row>
                <Row className="mt-3" style={{ fontSize: 20, fontWeight: 500 }}>My Loans</Row>
                <Row style={{ fontSize: 16, color: "#6E6E70" }}>Select a loan to continue</Row>
                <div className="wrapper">
                    <Row className="mt-3">
                        <Col md={3} xs={12} className="text-left p-0 mr-3 mb-3">
                            <Radio.Group
                                options={filterOptions}
                                onChange={this.onChangeFilter}
                                value={this.state.loanFilterValue}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Col>
                        <Col md={3} xs={12} className="text-left p-0 mr-3 mb-3">
                        </Col>
                        <Col md={3} xs={12} className="text-right p-0 mr-3 mb-3">
                            <Input
                                placeholder="Search Address"
                                onChange={this.onSearch}
                                allowClear
                                prefix={<SearchOutlined />}
                                size="large"
                                style={{ width: 250, marginRight: 20, borderRadius: 0 }}
                            />
                        </Col>
                    </Row>

                    <div className="child">
                        {this.state.loading ? <Skeleton active className="mt-4" /> :

                            <Row className="mt-3">
                                <Col md={3} xs={12} className="myloans text-center p-0 mr-3 mb-3" style={{ cursor: "pointer" }}>

                                    <div className="vertical-center px-5" style={{ color: "#325CEB", fontSize: 16, fontWeight: 500 }} onClick={() => this.openNewApplication()}>
                                        <img src="/images/bluePlusInCircleMark.png" style={{ width: 15, height: 15, marginRight: 5 }} />
                                        {this.state.loadingOpenNew ? <Spinner animation='border' size="sm" /> : "Open a new application"}
                                    </div>

                                </Col>
                                {this.state.loans.filter(this.statusFilter).filter(this.searchFilter).sort((a, b) => (this.state.loanid === a.loanid ? -1 : 1)).map(this.displayLoan)}
                            </Row>
                        }
                    </div>
                </div>
                <Modal
                    open={this.state.deleteModalOpen}
                    onOk={this.confirmDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({
                            deleteModalOpen: false,
                            targetLoanID: '',
                        })
                    }}
                >Are you sure you want to delete this loan?</Modal>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HardMoneyLoanDashboard)
