import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/gilroy-regular-3-webfont.woff2'
import './fonts/gilroy-medium-2-webfont.woff2'
import './fonts/gilroy-bold-4-webfont.woff2'

import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Provider } from "react-redux";
import store from "./Store/index";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
