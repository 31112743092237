import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Prequal.css';
import '../BorrowerApplication.css'
import * as com from "../Common.js"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { withRouter } from "react-router-dom";
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
        progress: state.progress,
    }
}
const mapDispatchToProps = (dispatch) => ({
    
});
class Review extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: true
        }
        this.prequal = React.createRef()
    }

    componentDidMount() {
        this.props.changeContinueButtonText("My rates")
    }

    componentWillUnmount() { }


    handleSubmit = e => {
        if(this.props.property.purpose === "purchase" && false === this.props.application.property.hascontract) {            
            return this.prequal.current.handleSubmit(e)
        }
        return true
    }

    render() {
        let reviewList = [
            {
                key: 'applicant',
                label: 'Personal information'
            },
            {
                key: 'property',
                label: 'Property information'
            },
            {
                key: 'income',
                label: 'Income '
            },
            {
                key: 'finances',
                label: 'Finance information'
            },
            {
                key: 'liabilities',
                label: 'Liabilities'
            }
        ]
        let statusList = [
            {
                name: 'Application',
                status: 1,
                desc: 'Submit your application to your loan officer.'
            },
            {
                name: 'Review',
                status: 0,
                desc: 'Your Loan Officer will review your documents and notify you by email when this page is updated with your rate.'
            }
        ]
        const isPrequal = this.props.application.property.purpose==="purchase" && !this.props.application.property.hascontract
        let review = (key) => {
            console.log(key)
            this.props.history.push('/application/interview/main/' + key)
        }
        let isHardMoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
        const showPurpose = () => {
            if (this.props.application.property.purpose === null) return "Loan Application"
            if (this.props.application.property.purpose==="cashoutrefinance") return "Cashout"
            if (isHardMoneyLoan) return "Hard Money Loan"
            return "Home " + this.props.application.property.purpose.charAt(0).toUpperCase() + this.props.application.property.purpose.slice(1)
        }
        let lasttouch = new Date(this.props.progress.lasttouch)
        return (
            <div className="application-review text-left text-wrap newInterviewPaneEmployment">
               <div className="review-status">
                    <img src="/images/congratulation.png"/>
                    <span>Your application has sent to your loan officer.</span>
               </div>
               <div className="review-collapse">
                    <div className="review-collapse-header" onClick={() => this.setState({expand: !this.state.expand})}>
                        <div className="header-logo"><img style={{width: 21, height: 21}} src="/images/reviewHotel.png"/></div>
                        <div className={this.state.expand ? 'header-content expand' : 'header-content'}>
                            <div>
                                <div className="review-type">{showPurpose()}<span style={{fontWeight: 400}}> |</span> { isPrequal? 'Pre-Qualification' : 'Application' }</div>
                                <div className="review-addr">{com.formatAddress(this.props.application.borrower)}</div>
                            </div>
                            <div className="header-content-r">
                                <div>
                                    <div className="review-application-status">Status:<span style={{color: '#333', fontWeight: 600, marginLeft: 8}}>Submitted</span></div>
                                    <div className="review-editTime">Single family · Last edit at {lasttouch.toLocaleDateString()}</div>
                                </div>
                                <img className={!this.state.expand ? 'iconExpand' : ''} src="/images/arrow-black.svg"/>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.expand ? 'review-collapse-content expand' : 'review-collapse-content'}>
                        <div className="review-list">
                            {
                                reviewList.map((item, index) =>(
                                    <div className="review-list-item" key={index}>
                                        <div><img src="/images/navSignFinished.png" /><span className="review-name">{ item.label }</span></div>
                                        <span className="review-btn" onClick={() => review(item.key)}>Review</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
               </div>
               <div className="status-list">
                    <div className="status-list-title">Here is what to expect next</div>
                    {
                        statusList.map((item, index) =>(
                            <div className="status-list-item">
                                <div className="status-list-item-top">
                                    <div className={item.status === 0 ? 'status-order cur' : 'status-order'}>{ index + 1}</div>
                                    <div className="status-info">
                                        <div style={{marginBottom: '4px',lineHeight: '20px'}}><span className="name">{ item.name }</span><span className="status">{ item.status === 0 ? 'Current step' : 'Finished'}</span></div>
                                        <div className="desc">{ item.desc }</div>
                                    </div>
                                </div>
                                {index !== statusList.length - 1 && <div className="status-line"></div>}
                            </div>
                        ))
                    }
               </div>
            </div >
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Review))