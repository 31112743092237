import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DatePicker,
    Button,
    Select,
    Divider,
    Form,
    Input,
    InputNumber,
    Col,
    Row
} from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';
import "./Common.css";
import "./PropertyAndLoan.css";
import * as com from "../../Common"
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'
import {
    PropertyTypeOptions,
    loanTypeOptions,
    loanPurposeOptions,
    loanTermOptions,
    exitStrategyOptions,
    YesNoOptions,
    validateMessages,
} from './constants'

const mapStateToProps = (state) => {
    return {
        status: state.application.status,
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
});

export default function PropertyAndLoan(props) {
    const interviewfinished = props.status === 'interviewfinished'
    let disabledOnclick = interviewfinished ? props.onSubmittedDisableClick : null
    const [form] = Form.useForm();
    const [loanPurpose, setLoanPurpose] = useState(props.property.purpose);
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [countyOptions, setCountyOptions] = useState([])
    const onFinish = (values) => {
        props.finish(values)
    }
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    const tcdDisabledDate = (current) => {
        return current && current < dayjs().endOf('day').add(10, 'day');
    }
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const onChangeLoanPurpose = (v) => {
        setLoanPurpose(v)
        props.updateHardMoneyLoanAttribute(v === "purchaserehab", "rehabloan")
        props.changeMainPropertyAttribute(v, "purpose")
    }
    const getAddress = (val) => {
        let num = com.getSubaddress(val.address_components, "street_number", "long_name");
        let street = com.getSubaddress(val.address_components, "route", "long_name")
        let city = com.getSubaddress(val.address_components, "locality", "long_name")
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        let streetaddr = street;
        if (num) {
            streetaddr = num + " " + streetaddr;
        }
        form.setFieldsValue({
            zipCode: zipCode,
            streetAddress: streetaddr,
            city: city,
            state: state,
            county: county
        })
        props.changeMainPropertyAttribute(streetaddr, "address")
        props.changeMainPropertyAttribute(zipCode, "zipcode")
        props.changeMainPropertyAttribute(city, "city")
        props.changeMainPropertyAttribute(state, "state")
        props.changeMainPropertyAttribute(county, "county")
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    ;
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then((res) => {
                setLoanLimits(res)
                setStateOptions(Object.keys(res).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })))
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const onChangeState = (val) => {
        props.changeMainPropertyAttribute(val, "state")
        form.setFieldsValue({
            county: undefined,
        })
        setCountyOptions(val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : [])
    }
    const onChangeZip = (e) => {
        let formatVal = e.target.value.replace(/\-/g, "");
        form.setFieldsValue({
            zipCode: formatVal,
        })
        props.changeMainPropertyAttribute(String(formatVal), "zipcode")
    }
    useEffect(() => {
        fetchLoanLimits()
    }, []);
    const onChangePurchaseLoanAmountRequested = (val) => {
        props.updateHardMoneyLoanAttribute(String(val), "initialloanamount")
    }
    const onChangeRehabLoanAmountRequested = (val) => {
        if (!val) val = 0
        props.updateHardMoneyLoanAttribute(String(val), "rehabloanamount")
    }

    const onChangeRehabDetails = (val, key) => {
        let r = { ...props.application.hardmoneyloan.rehabdetails }
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "rehabdetails")
    }
    const onChangeRefinanceDetails = (val, key) => {
        let r = { ...props.application.hardmoneyloan.refinancedetails }
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "refinancedetails")
    }
    const cd = props.application.hardmoneyloan.targetclosingdate ? dayjs(props.application.hardmoneyloan.targetclosingdate, "MM/DD/YYYY") : ''
    const formInitial = {
        streetAddress: props.property.address,
        state: props.property.state,
        city: props.property.city,
        county: props.property.county,
        zipCode: props.property.zipcode,
        propertyType: props.property.propertytype,
        loanType: props.application.hardmoneyloan.loantype,
        loanPurpose: props.property.purpose,
        purchaseprice: props.property.salesprice,
        escrowDate: cd,
        initialAdvanceRequested: props.application.hardmoneyloan.initialadvancerequested,
        rehabRequested: props.application.hardmoneyloan.rehabloanamount || 0,
        totalLoanAmountRequested: props.application.hardmoneyloan.initialloanamount,
        loanTermRequested: props.application.hardmoneyloan.mortgagetermmonths || "",
        ARV: props.application.hardmoneyloan.projectedafterrepairvalue,
        assignmentFee: props.application.hardmoneyloan.assignmentfee,
        liquidity: props.application.hardmoneyloan.liquidity,
        exitStrategy: props.application.hardmoneyloan.exitstrategy,
        rehabRemaining: props.application.hardmoneyloan.rehabdetails.rehabremaining,
        rehabCostSpentToDate: props.application.hardmoneyloan.rehabdetails.rehabcostspenttodate,
        addingSqft: props.application.hardmoneyloan.rehabdetails.addingsqft,
        sqftAdded: props.application.hardmoneyloan.rehabdetails.sqftadded,
        addingUnits: props.application.hardmoneyloan.rehabdetails.addingunits,
        originalPurchasePrice: props.application.hardmoneyloan.refinancedetails.originalPurchasePrice,
        dateOfPurchase: props.application.hardmoneyloan.refinancedetails.dateOfPurchase ? dayjs(props.application.hardmoneyloan.refinancedetails.dateOfPurchase, "MM/DD/YYYY") : '',
        estimatedCurrentValue: props.application.hardmoneyloan.refinancedetails.estimatedCurrentValue,
        existingLoanAmount: props.application.hardmoneyloan.refinancedetails.existingLoanAmount,
        existingLender: props.application.hardmoneyloan.refinancedetails.existingLender,
        existingLoanRate: props.application.hardmoneyloan.refinancedetails.existingLoanRate,
        existingLoanMaturityDate: props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate ? dayjs(props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate, "MM/DD/YYYY") : '',
        subordinateDebt: props.application.hardmoneyloan.refinancedetails.subordinateDebt,
        midConstruction: props.application.hardmoneyloan.refinancedetails.midConstruction,
    }

    const renderPurchaseLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Purchase Loan Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="purchaseprice"
                    label="Purchase price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        min={0}
                        disabled={interviewfinished}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.changeMainPropertyAttribute(e, "salesprice")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="escrowDate"
                    label="Scheduled close of escrow date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        className='closing-date'
                        disabledDate={tcdDisabledDate}
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        onChange={(e) => {
                            let dat = new Date(e)
                            props.updateHardMoneyLoanAttribute(e ? dayjs(dat).format('MM/DD/YYYY') : '', "targetclosingdate")
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="initialAdvanceRequested"
                    label="Initial advance requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabRequested"
                    label="Rehab requested"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangeRehabLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="totalLoanAmountRequested"
                    label="Total loan amount requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangePurchaseLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="loanTermRequested"
                    label="Loan term requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={loanTermOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="ARV"
                    label="Estimated after repair value (ARV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="assignmentFee"
                    label="Assignment fee (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "assignmentfee")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="liquidity"
                    label="Liquidity"
                    onClick={disabledOnclick}
                >
                    <Input placeholder="Optional" onChange={e => props.updateHardMoneyLoanAttribute(e.target.value, "liquidity")} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="exitStrategy"
                    label="Exit strategy"
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={exitStrategyOptions}
                        placeholder="Optional"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "exitstrategy")}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderRehabLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Rehab Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="rehabRemaining"
                    label="Rehab remaining"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRehabDetails(String(e), "rehabremaining")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabCostSpentToDate"
                    label="Rehab cost spent to date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        min={0}
                        disabled={interviewfinished}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRehabDetails(String(e), "rehabcostspenttodate")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="addingSqft"
                    label="Adding sqft (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRehabDetails(e, "addingsqft")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="sqftAdded"
                    label="Sqft added"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="N/A" onChange={e => onChangeRehabDetails(e.target.value, "sqftadded")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="addingUnits"
                    label="Adding units (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRehabDetails(e, "addingunits")}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderRefinanceLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Refinance Loan Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="originalPurchasePrice"
                    label="Original purchase price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "originalPurchasePrice")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="dateOfPurchase"
                    label="Date of purchase"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e => {
                        let dat = new Date(e)
                        onChangeRefinanceDetails(e ? dayjs(dat).format('MM/DD/YYYY') : '', "dateOfPurchase")
                    }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="initialAdvanceRequested"
                    label="Initial advance requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabRequested"
                    label="Rehab requested"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        max={10000000}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangeRehabLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="totalLoanAmountRequested"
                    label="Total loan amount requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "refinanceloanamount")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="loanTermRequested"
                    label="Loan term requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={loanTermOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="estimatedCurrentValue"
                    label="Estimated current value (LTV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "estimatedCurrentValue")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="ARV"
                    label="Estimated after repair value (ARV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="existingLoanAmount"
                    label="Existing loan amount (Payoff)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "existingLoanAmount")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="existingLender"
                    label="Existing lender"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => onChangeRefinanceDetails(e.target.value, "existingLender")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="existingLoanRate"
                    label="Existing loan rate"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        addonAfter="%"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        max={100}
                        min={0}
                        onChange={e => onChangeRefinanceDetails(String(e), "existingLoanRate")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="existingLoanMaturityDate"
                    label="Existing loan maturity date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e => {
                        let dat = new Date(e)
                        onChangeRefinanceDetails(e ? dayjs(dat).format('MM/DD/YYYY') : '', "existingLoanMaturityDate")
                    }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="subordinateDebt"
                    label="Subordinate debt (Y/N-$)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder="Select Loan term"
                        onChange={e => onChangeRefinanceDetails(e, "subordinateDebt")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="liquidity"
                    label="Liquidity"
                    onClick={disabledOnclick}
                >
                    <Input placeholder="Optional" onChange={e => props.updateHardMoneyLoanAttribute(e, "liquidity")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="exitStrategy"
                    label="Exit strategy"
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={exitStrategyOptions}
                        placeholder="Optional"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "exitstrategy")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="midConstruction"
                    label="Mid construction (Y/N)"
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRefinanceDetails(e, "midConstruction")}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderActionButtons = () => <Row gutter={10}>
        <Col>
            <Form.Item>
                <Button
                    disabled={false}
                    type="default"
                    className='secondary-btn'
                    onClick={props.back}
                >
                    Back
                </Button>
            </Form.Item>
        </Col>
        <Col>
            <Form.Item>
                <Button
                    type="primary"
                    className='common-btn'
                    htmlType="submit"
                    disabled={false}
                    onClick={() => {
                        // Scroll to top after click continue
                        window.document.body.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                    }}
                >
                    Continue
                </Button>
            </Form.Item>
        </Col>
    </Row>

    const onFieldsChanged = (changedValues, allValues) => {
        // console.log(changedValues, allValues)
        const hasError = form.getFieldsError().some(x => x.errors.length > 0)
        if (hasError) props.resetProgress()
    }

    return (
        <div className="borrower-form text-left">
            <Form
                className='application-form'
                layout='vertical'
                form={form}
                disabled={interviewfinished}
                onFieldsChange={onFieldsChanged}
                scrollToFirstError={{
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                    block: 'center',
                }}
                onFinish={onFinish}
                initialValues={formInitial}
                validateMessages={validateMessages}
                size="large"
            >
                <Row>
                    <span className='form-title'>Property Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={24}>
                        <Form.Item
                            name="streetAddress"
                            label="Property address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <GoogleAutocomplete
                                disabled={interviewfinished}
                                placeholder="Street address"
                                getPlace={getAddress}
                                onChange={(e) => { props.changeMainPropertyAttribute(e, "address") }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="state"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={stateOptions}
                                onChange={onChangeState}
                                placeholder="Select your state"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="county"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={countyOptions}
                                placeholder="Select your county"
                                onChange={(e) => props.changeMainPropertyAttribute(e, "county")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="zipCode"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                type='number'
                                style={{ width: '100%' }}
                                placeholder="Zip Code"
                                min={0}
                                onChange={onChangeZip}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <Form.Item
                            name="propertyType"
                            label="Property type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            tooltip={{
                                title: 'Property Type',
                                icon: <QuestionCircleOutlined className="tips-icon" />,
                            }}
                        >
                            <Select
                                allowClear
                                options={PropertyTypeOptions}
                                placeholder="Select Property type"
                                onChange={(e) => props.changeMainPropertyAttribute(e, "propertytype")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <span className='form-title'>Loan Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <Form.Item
                            name="loanPurpose"
                            label="Loan purpose" // change to "loan type" per RateDNA's request
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={loanPurposeOptions}
                                placeholder="Select Loan purpose"
                                onChange={onChangeLoanPurpose}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="loanType"
                            label="Loan type" // change to "loan purpose" per RateDNA's request
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={loanTypeOptions}
                                placeholder="Select Loan type"
                                onChange={e => props.updateHardMoneyLoanAttribute(e, "loantype")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {loanPurpose === 'purchaserehab' || loanPurpose === 'purchase' ? renderPurchaseLoanDetails() : null}
                {loanPurpose === 'purchaserehab' ? renderRehabLoanDetails() : null}
                {/* Refinance Loan Details */}
                {loanPurpose === 'refinance' || loanPurpose === 'cashoutrefinance' ? renderRefinanceLoanDetails() : null}
                {renderActionButtons()}
            </Form>
        </div>
    )
}

PropertyAndLoan = connect(mapStateToProps, mapDispatchToProps)(PropertyAndLoan);
