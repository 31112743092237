import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Prequal.css';
import './PrequalInterview.css';
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common"
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"

const mapStateToProps = (state) => {
    let getOccupation = (employed, selfemployed) => {
        if (employed === "employed" && !selfemployed) return "employed"
        if (employed === "employed" && selfemployed) return "selfemployed"
        return "unemployed"

    }
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
        property: state.application.property,
        cohabiting: state.application.cohabiting,
        financingsameproperty: state.application.financingsameproperty,
        borroweroccupation: getOccupation(state.application.borrower.occupation.hasoccupation,
            state.application.borrower.occupation.selfemployed),
        coborroweroccupation: "co" + getOccupation(state.application.coborrower.occupation.hasoccupation,
            state.application.coborrower.occupation.selfemployed),
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb));
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb));
      },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    updateCurrentResidence: (event) => {
        dispatch(act.UpdateCurrentResidence(event.target.value))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    updateCohabiting: (event) => {
        dispatch(act.UpdateCohabiting(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb));
      },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },

    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },

    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event.target.value))
    },

    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationCheck: (check, who, verb) => {
        dispatch(act.ChangeOccupationInfo(check, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },

    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },      
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },    
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },  
});

class Applicant extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            validated: false,
        }
        this.radiocoborrower = React.createRef()
    }

    componentDidMount() { 
    }



    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    handleSubmit = e => {
        if (this.form.current.reportValidity() === false) {

            e.preventDefault();
            this.setState({ validated: true })
            return false
        }

        // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.props.application.borrower.email)) {
        //     return true
        // }
        return true
    }
    
    render() {
        let changeBorrowerInfo = (who, verb) => {
            return (event) => {
              com.touch();
              this.props.changeBorrowerInfo(event, who, verb);
            };
        };
        return (
            <div className=" text-left mb-3">
                <div className="pt-4">
                    <Form ref={this.form}>
                        <Form.Group >
                            <Form.Label className="interviewEmailLabel">What's your email address?</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholderValue='Enter your email address'
                                value={this.props.application.borrower.email} 
                                onChange={changeBorrowerInfo("borrower", "email")}/>
                        </Form.Group>
                    </Form>
                </div>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Applicant)
