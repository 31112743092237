import { useState } from 'react';
import {
    Modal,
    Input,
    message,
    Button,
} from 'antd';
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Link } from 'react-router-dom'
import { CalcHardMoneyLoanRate } from '../../Calculator/HardMoneyLoanCalculator/LoanRateResult';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
});


export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [contactMessage, setContactMessage] = useState("")
    const { TextArea } = Input;

    const statusList = [
        {
            name: 'Application Draft Started',
            done: true,
            operate: []
        },
        {
            name: 'Initial Application Submitted',
            done: true,
            operate: []
        },
        {
            name: 'Processing',
            operate: []
        },
        {
            name: 'Underwriting',
            operate: []
        },
        {
            name: 'Approved',
            operate: []
        },
        {
            name: 'Closing',
            operate: []
        },
        {
            name: 'Funded',
            operate: []
        },
    ]
    const calcLoanAmount = () => {
        let lm = ""
        if (props.property.purpose === "purchase") {
            lm = props.hardmoney.initialloanamount
        }
        if (props.property.purpose === "refinance") {
            lm = props.hardmoney.refinanceloanamount
        }
        if (props.property.purpose === "construction") {
            lm = props.hardmoney.constructionloanamount
        }
        return lm
    }
    const getLoanAmount = () => {
        let lm = calcLoanAmount()
        if (lm !== "") {
            return "$" + com.commaize(lm)
        }
        return "-"
    }
    const getAsIsValue = () => {
        if (props.property.salesprice !== "") {
            return "$" + com.commaize(props.property.salesprice)
        }
        return "-"
    }
    const getLTV = () => {
        if (props.property.salesprice !== "") {
            let sp = com.safeParseInt(props.property.salesprice)
            let lm = com.safeParseInt(calcLoanAmount())
            return Math.round(100 * lm / sp) + "%"
        }
        return "-"
    }

    let rateResult = CalcHardMoneyLoanRate(props)

    const sendMail = () => {
        setLoading(true)
        let id = com.getUserId()
        let token = com.getUserToken()

        fetch('/borrower/sendmail', {
            method: 'POST',
            body: JSON.stringify({ id: parseInt(id), body: "<html><body>" + contactMessage + "</body></html>" }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    setLoading(false)
                    setShowContact(false)
                    messageApi.open({
                        type: 'error',
                        content: 'Looks like there was a problem sending email. Please try later.',
                    });
                    return;
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log(js.Error)
                        setLoading(false)
                        setShowContact(false)
                        messageApi.open({
                            type: 'error',
                            content: 'Looks like there was a problem sending email. Please try later.',
                        });
                    } else {
                        setLoading(false)
                        setShowContact(false)
                        messageApi.open({
                            type: 'success',
                            content: 'Your message has been sent.',
                        });
                    }

                });
            }
        )
            .catch(function (err) {
                setLoading(false)
                console.log('Fetch Error :', err);
            });
    }

    return (
        <div className="over-view text-left">
            {contextHolder}
            <Modal centered title="Contact my loan officer"
                open={showContact}
                onCancel={() => setShowContact(false)}
                footer={[
                    <Button key="back" onClick={() => setShowContact(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={sendMail}>
                        Send
                    </Button>,
                ]} >
                <div>
                    <TextArea rows={4} placeholder="Leave your message here" onChange={e => setContactMessage(e.target.value)} />
                </div>
            </Modal>
            <div className='over-view-content'>
                <div className='application-status'>
                    <div className='status-title' onClick={() => setShowContact(true)}>Application Status <a>Contact my loan officer</a></div>
                    <div className='status-list'>
                        {
                            statusList.map((status, statusIndex) =>
                                <div className='status-item' key={statusIndex}>
                                    <div style={{ color: props.step > statusIndex ? '#1F3988' : '#8895BC' }}>{status.name}</div>
                                    {
                                        status.operate.length > 0 && (
                                            <div className='operate' style={{ color: props.step > statusIndex ? '#0F62FE' : '' }}>
                                                {
                                                    status.operate.map((o, index) => o)
                                                }
                                            </div>
                                        )
                                    }
                                    <div className='status-tag' style={{ backgroundColor: props.step > statusIndex ? '#0F62FE' : '#DDE1E6' }}></div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='my-loan-terms'>
                    <div className='loan-terms-title'><span>My Loan Terms</span><span>Estimated {props.hardmoney.mortgagetermmonths} months</span></div>
                    <div className='loan-terms'>
                        <div className='loan-terms-item'><span>Loan amount</span><span>{getLoanAmount()}</span></div>
                        <div className='loan-terms-item'><span>As-Is-Value</span><span>{getAsIsValue()}</span></div>
                        <div className='loan-terms-item'><span>Loan-to-Value</span><span>{getLTV()}</span></div>
                    </div>
                    <div className='loan-terms'>
                        <div className='loan-terms-item'><span>Estimated Interest Rate</span><span>{rateResult.floorRate}%</span></div>
                        <div className='loan-terms-item'><span>Estimated Monthly Payment</span><span>${com.commaize(rateResult.monthlyPayment)}</span></div>
                        <div className='loan-terms-item'><span>Estimated Closing Costs</span><span>${com.commaize(rateResult.closingCost)}</span></div>
                    </div>
                    <div className='loan-terms'>
                        <div className='loan-terms-item'><span>Target Closing Date</span><span>{props.hardmoney.targetclosingdate !== "" ? props.hardmoney.targetclosingdate : "-"}</span></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
