import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import './ApplicationInterview.css'
import './EmailVerification.css'
import { Modal } from 'antd'
import Row from 'react-bootstrap/Col'
import { State } from '../State'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Button } from "react-bootstrap"
import CodeVerification from "../Common/components/CodeVerification"
import CheckGroup from "../Common/components/CheckGroup"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as prgr from "../Store/progress"
import * as st from "../State"
import * as com from "../Common"
import { getCurrentState, loadState, updateState, mergeState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Spinner from 'react-bootstrap/Spinner'
import dayjs from 'dayjs'
const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({

    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
})

class EmailVerification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            code: "",
            verified: false,
            showResult: false,
            verifingCode: false,
            sendingCode: false,
            showPastLoansModal: false,
            selectedLoanId: "",
            pastLoans: [],
            currentLoanId: "",
            loading: false,
        }
        this.isPrequal = this.props.application.property.purpose === "purchase" && !this.props.application.property.hascontract
        this.isHardMoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
    }

    sendcode = () => {
        this.setState({ sendingCode: true })
        fetch("/auth/generateonetimepasscode", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email.toLowerCase(),
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
            }),
            headers: {
                'Content-Type': 'application/json',
                Cache: "no-cache"
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log(response)

            } else {
                // console.log(response)

            }
            this.setState({ sendingCode: false })
        }).catch(error => {
            console.log(error)
        })
    }

    verifycodeAndCheckPastLoans = (code) => {
        this.setState({ verifingCode: true })
        fetch("/auth/verifycodeandcheckpastloans", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email.toLowerCase(),
                firstName: this.props.application.borrower.firstname,
                lastName: this.props.application.borrower.lastname,
                code: code,
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
                State: JSON.stringify(loadState()), // change to com.ascii2hex(sessionStorage.getItem("state")) ?
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                return
            }
            response.json().then(js => {
                if (js.AlreadySignedUp === false) {
                    this.signup()
                } else {
                    js.LoanStates.loans.forEach(loan => {
                        loan.state = JSON.parse(loan.state)
                        loan.lostate = JSON.parse(loan.lostate)
                    })
                    this.setState({ showPastLoansModal: true, pastLoans: js.LoanStates.loans, currentLoanId: js.LoanStates.loanid, verifingCode: false, code: "", showResult: false, verified: true, })
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ code: "" })
        })
    }

    signup = () => {
        this.setState({ verifingCode: true })
        fetch("/auth/signupfrompreapp", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email.toLowerCase(),
                firstName: this.props.application.borrower.firstname,
                lastName: this.props.application.borrower.lastname,
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
                State: JSON.stringify(loadState()),
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                return
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                } else {
                    sessionStorage.setItem("ZeitroA", js.Token)
                    let state = State.fromJson(JSON.parse(js.State))
                    let statejs = state.toJson()
                    sessionStorage.removeItem("state")
                    sessionStorage.setItem("state", statejs)
                    sessionStorage.setItem("originalstate", statejs)
                    sessionStorage.setItem("newcustomer", "false")
                    let path = "/application/interview/main/applicant"
                    if (this.isPrequal) {
                        path = "/prequal/interview/main/applicant"
                    }
                    if (this.isHardMoneyLoan) {
                        path = "/application/hardMoneyLoan/interview/main/borrower"
                    }
                    window.location.href = path
                    this.setState({ showResult: true, verified: true, verifingCode: false, code: "" })
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ code: "" })
        })
    }

    verifycode = (code) => {
        this.setState({ verifingCode: true })
        fetch("/auth/verifycodeandsignup", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.application.borrower.email.toLowerCase(),
                firstName: this.props.application.borrower.firstname,
                lastName: this.props.application.borrower.lastname,
                code: code,
                customerID: sessionStorage.getItem("cid"),
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
                State: JSON.stringify(loadState()),
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                return
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({ showResult: true, verified: false, verifingCode: false, code: "" })
                } else {
                    sessionStorage.setItem("ZeitroA", js.Token)
                    let state = State.fromJson(JSON.parse(js.State))
                    let statejs = state.toJson()
                    sessionStorage.removeItem("state")
                    sessionStorage.setItem("state", statejs)
                    sessionStorage.setItem("originalstate", statejs)
                    sessionStorage.setItem("newcustomer", "false")
                    let path = "/application/interview/main/applicant"
                    if (this.isPrequal) {
                        path = "/prequal/interview/main/applicant"
                    }
                    if (this.isHardMoneyLoan) {
                        path = "/application/hardMoneyLoan/interview/main/borrower"
                    }
                    window.location.href = path
                    this.setState({ showResult: true, verified: true, verifingCode: false, code: "" })
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ code: "" })
        })
    }

    componentDidMount() {
        this.sendcode()
    }


    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    handleSubmit = e => {
        if (!this.state.verified) {
            return false
        }
        return true

    }

    createNew(withRecord) {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let js = {
            Email: this.props.application.borrower.email.toLowerCase(),
            CustomerID: sessionStorage.getItem("cid"),
            LoanID: this.state.selectedLoanId !== "" ? this.state.selectedLoanId : "",
            NewState: withRecord ? "" : com.ascii2hex(sessionStorage.getItem("state")),
            LoID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
        }
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    this.setState({ loading: false })
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log(js.Text)
                        alert("Looks like there was a problem, please try later.")
                    } else {
                        sessionStorage.setItem("ZeitroA", js.Token)
                        if (withRecord) {
                            let st = JSON.parse(js.State)
                            st.progress.stage = "interview"
                            st.progress.interviewfinished = false
                            st.progress.interviewstarted = false
                            st.progress.productselectionstarted = false
                            st.progress.interview.step = 0
                            st.progress.interview.max = 0
                            st.application.status = 'interviewstarted'
                            st.application.selection.selectedloan = null
                            st.application.property.purpose = this.props.application.property.purpose
                            st.application.property.hascontract = false
                            st.application.borrower.firstname = this.props.application.borrower.firstname
                            st.application.borrower.lastname = this.props.application.borrower.lastname
                            this.props.updateChangeApplication(st.application)
                            this.props.updateChangeVisitedStage("interviewstarted")
                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                            getCurrentState()
                            com.touch()
                        } else {

                        }

                        this.setState({ loading: false })
                        let path = "/application/interview/main/applicant"
                        if (this.isPrequal) {
                            path = "/prequal/interview/main/applicant"
                        }
                        if (this.isHardMoneyLoan) {
                            path = "/application/hardMoneyLoan/interview/main/borrower"
                        }
                        window.location.href = path
                    }

                })
            }
        ).catch((err) => {
            this.setState({ loading: false })
            console.log('Fetch Error :', err)
        })
    }
    editEmail = (type) => {
        this.props.back(type)
    }
    render() {
        const showErrorMeesage = () => {
            return (
                <div className="d-flex justify-content-center">

                    <div style={{ backgroundColor: "#FFE6BF", borderRadius: "4px", padding: "10px", marginTop: "15px", maxWidth: "350px" }}>
                        <img src="/images/warningSignYellow.png" style={{ width: "18px", height: "18px", marginRight: "10px" }} />
                        <a>The code is incorrect or expired.</a>
                    </div>
                </div>
            )
        }

        const showPastLoans = () => {
            if (!this.state.showPastLoansModal) {
                return ""
            }
            let propertyType = {
                'singlefamilydetached': 'Single Family',
                'singlefamilyattached': 'Townhouse',
                'twotofourfamily': 'Multi-family',
                'condominium': 'Condo'
            }
            let filterValue = (val) => {
                return val && val !== '' ? val : '--'
            }
            const data = []
            for (let loan of this.state.pastLoans) {
                let lasttouch = new Date(loan.state.progress.lasttouch)
                let isPrequal = loan.state.application.property.purpose === "purchase" && !loan.state.application.property.hascontract
                if (loan.state.application.property.purpose === null) {
                    data.push({
                        value: loan.loanid,
                        label: <div>
                            <div>Loan purpose unselected</div>
                        </div>
                    })
                } else {
                    data.push({
                        value: loan.loanid,
                        label: (
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <div><b>{com.capitalize(loan.state.application.property.purpose)}</b> {!isPrequal ? " | Application" : " | Pre-qualification"}</div>
                                    <div className="mt-2" style={{ fontSize: '14px' }}>{filterValue(propertyType[loan.state.application.property.propertytype]) + ", " + filterValue(loan.state.application.property.county) + ", " + filterValue(loan.state.application.property.state)}</div>
                                </div>
                                <div style={{ color: "#6E6E70", fontSize: '14px', marginTop: '6px' }}>Last edit at {lasttouch.toLocaleDateString()}</div>
                            </div>
                        )
                    })
                }
            }


            return (
                <Modal
                    style={{
                        top: 0
                    }}
      wrapClassName='full-height-modal'
                    className="pastLoansModal"
                    open={this.state.showPastLoansModal}
                    title="Use a previous application"
                    onOk={() => this.setState({ showPastLoans: false })}
                    onCancel={() => this.setState({ showPastLoansModal: false })}
                    width="768px"
                    maskClosable={false}
                    closable={false}
                    footer={[
                        <Button style={{ marginRight: 18 }} variant="zeitro-primary"
                            disabled={this.state.selectedLoanId === "" ? true : false}
                            onClick={() => this.createNew(true)}>
                            Auto fill my information
                        </Button>,
                        <Button style={{ color: '#325CEB', fontWeight: 700 }} variant="link" onClick={() => this.createNew(false)}>
                            Skip
                        </Button>,
                    ]}
                >
                    <div className="pastLoansModal-title">We noticed that you have {this.state.pastLoans.length} existing applications. Would you like to use your previous information for this application?</div>
                    <div className="subheading">You can edit your information any time during the application process.</div>
                    <div style={{ margin: '26px 0', maxHeight: '400px', overflow: 'auto' }}>
                        <CheckGroup
                            data={data}
                            onChangeSelect={e => {
                                this.setState({ selectedLoanId: e })
                            }}
                            value={this.state.selectedLoanId}
                            mode="flex"
                            type='radio'
                            direction="horizontal"
                            fixedWidth="280px" />
                    </div>
                </Modal>
            )
        }

        if (this.state.showResult && this.state.verified) {
            return (
                <div className="text-center mb-3">
                    <img src="/images/emailSuccess.png" style={{ width: "80px", height: "78px" }} />
                    <div className="prequalBigText">Succeed!</div>
                    <div className="prequalSmallText pt-2">
                        <div>Your account has been verified.</div>
                        <div>Continue to your application.</div>

                    </div>

                </div>
            )
        }
        return (
            <div className="text-center mb-3">


                <div style={{ marginBottom: 23 }}><img src="/images/saveProgress.png" style={{ height: "71px" }} /></div>
                <div className="prequalBigText prequalCheckText">Check your email</div>
                {/* <div className="prequalSmallText pt-2 text-wrap">Please enter the 4 digit code sent to {this.props.application.borrower.email}</div> */}
                {
                    this.props.application.borrower.email && (
                        <div style={{ margin: '6px auto 18px', color: '#6E6E70', lineHeight: '20px' }}>
                            <div>We sent a code to <span style={{ fontWeight: 600, color: '#222' }}>{this.props.application.borrower.email}</span><a style={{ color: '#325CEB', marginLeft: 8, textDecorationLine: 'underline' }} className="prequalEditText" onClick={() => { this.editEmail(1) }}>Edit</a></div>
                            <div>Enter the code to log in your account</div>
                        </div>
                    )
                }
                <div style={{ display: "flex", justifyContent: "center", padding: "20px 0 0 10px" }}>
                    {(this.state.verifingCode || this.state.sendingCode) ? <Spinner animation="border" size="lg" /> : <CodeVerification value={this.state.code} onChange={(e) => {
                        this.setState({ code: e })
                        if (e.length === 4) {
                            this.verifycodeAndCheckPastLoans(e)
                        }
                    }} />}

                </div>
                {(this.state.showResult && !this.state.verified) && showErrorMeesage()}
                <div style={{ fontSize: 16 }} className="p-2">Didn’t receive the email? <a style={{ color: "#325CEB", textDecorationLine: "underline" }} onClick={() => {
                    this.sendcode()
                }}>Resend</a></div>
                {showPastLoans()}
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmailVerification)
