import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import * as act from "./Store/actions"
import {
    withRouter
} from "react-router-dom";
import { application, State } from './State'
const merge = require('deepmerge')
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
});


export default function BorrowerInterviewForLO(props) {   
     
    const customerid = props.match.params["customerid"];
    const loanid = props.match.params["loanid"];
    
    const signin = () => {

        let tosend = JSON.stringify({
                CustomerID: customerid,
                LoanID: loanid,
            })
            
        fetch('/borrower/borrowerviewforlogetstate', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        sessionStorage.setItem("edit", "false")
                        sessionStorage.setItem("state", "{}");
                        sessionStorage.setItem("originalstate", "{}");
                        return
                    } else {
                        sessionStorage.setItem("ZeitroA", js.Token)
                        let state = JSON.parse(js.State)

                        let serstate
                        if ("{}" === js.State) {
                            state = JSON.parse((() => { let l = new State(); return l.toJson() })())
                        }
                        try {
                            let applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            let app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        sessionStorage.removeItem("state")
                        sessionStorage.removeItem("originalstate")
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        props.updateChangeApplication({ ...state.application })
                        

                        redirectCustomer(js, state)
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }

    const redirectCustomer = (js, state) => {

        const isPrequal = state.application.property.purpose === "purchase" && !state.application.property.hascontract
        const isHardMoneyLoan = state.application.product.mortgageapplied === "hardmoneyloan"
        sessionStorage.setItem("fromiframe", true)
        let path = ""

        if ("undefined" === typeof state.progress || "undefined" === typeof state.progress.stage || 
        "undefined" === typeof state.application.property.purpose || 
        state.application.property.purpose === null) {
            path = "/services/preapp?customerid=" + customerid
        } else {

            path = "/application/interview/main?fromiframe=true"

        }

        if (isPrequal) path = path.replace("/application/", "/prequal/")
        if (isHardMoneyLoan) path = "/application/hardMoneyLoan/interview/main/borrower?fromiframe=true"

        if (props.view === "bare") {
            window.parent.postMessage({ source: "redirect", payload: { path, token: js.Token } }, '*')
        } else {
            window.location.href = path
        }
    }


    useEffect(()=>{
        sessionStorage.setItem("edit", "false")
        sessionStorage.setItem("state", "{}");
        sessionStorage.setItem("originalstate", "{}");

        signin()
    }, [])

    return (
        <div></div>
    )
}

BorrowerInterviewForLO = withRouter(connect(mapStateToProps, mapDispatchToProps)(BorrowerInterviewForLO))
