
import Auth from "./Auth";
import React, {useEffect} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './Prequal.css';
import Error404 from "./Error404.js"
import {
    Switch,
    Route
} from "react-router-dom";
import FaqPage from './FaqPage'
import Notifications from './Notifications'
import Glossary from './Glossary'
import LogoutPage from './LogoutPage'
import HomePageApplication from './HomePageApplication';

const AuthenticatedApplication = () => {    


    return (
        <div className="mx-0 px-0 h-100">
            {sessionStorage.getItem("newcustomer") !== "true" && <Auth />}
                <Switch>

                    <Route path="/application/">
                        <HomePageApplication />
                    </Route>

                    <Route exact path="/resources/faq">
                        <FaqPage />
                    </Route>
                    <Route exact path="/resources/glossary">
                        <Glossary />
                    </Route>
                    <Route exact path="/services/logout">
                        <LogoutPage />
                    </Route>
                    <Route >
                        <Error404 />
                    </Route>
                </Switch>
            <Notifications />
        </div>
    )
}

export default AuthenticatedApplication;