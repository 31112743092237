import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import * as com from "../Common.js"
import { tooltip, Tips } from "../Common/Tooltip"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import NumericalInput from '../NumericalInput'
import { InputNumber, Select } from "antd"
const judgements = "judgements"
const bancrupt = "bancrupt"
const propertyforeclosure = "propertyforeclosure"
const lawsuit = "lawsuit"
const loanforeclosure = "loanforeclosure"
const delinquentondebt = "delinquentondebt"
const downpaymentborrowed = "downpaymentborrowed"
const comakeronnote = "comakeronnote"
const occupyasprimary = "occupyasprimary"
const ownershipinterest = "ownershipinterest"
const propertyowned = "propertyowned"
const titleheld = "titleheld"
const loansinforebearance = "loansinforebearance"

const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.application.borrower.declarations,
        coborrower: state.application.coborrower.declarations,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event.target.value, who, verb))
    },
})

class Declarations extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            validated: false
        }

        this.isValidated = this.isValidated.bind(this)
        this.form = React.createRef()
    }
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }

    render = () => {
        let achangeDeclaration = (verb, who) => {
            return ((event) => {
                const e = {
                    target: {
                        value: event
                    }
                }
                com.touch()
                this.props.changeDeclaration(e, who, verb)
            }
            )
        }
        let changeDeclaration = (verb, who) => {
            return ((event) => {
                com.touch()
                this.props.changeDeclaration(event, who, verb)
            }
            )
        }
        let updateBankrupcy = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeDeclaration({ target: { value: event.target.checked } }, who, verb)
            }
        }
        return (
            <div className="mx-2 p-4 text-left text-wrap">
                <div className=" prequalInterviewTitle">
                    Property Declarations
                </div>
                <div className="subheading mt-1">
                    About this property and your money for this loan
                </div>
                <div className="text-left mt-4 mb-3">
                    <Row>
                        <Col className=""></Col></Row>
                    <Form.Row>
                        <Col>
                            <Table className="text-left m-1 p-1" >
                                {this.props.hascoborrower !== "alone" ? (

                                    <thead>
                                        <tr className="m-1 p-1" >
                                            <th className=" m-1 p-1" >&nbsp;</th>
                                            <th className=" m-1 p-1" >Borrower</th>
                                            {this.props.hascoborrower !== "alone" ? (
                                                <th className=" m-1 p-1" >Co-applicant</th>
                                            ) : ""
                                            }
                                        </tr>
                                    </thead>
                                ) : ""
                                }
                                <tbody>
                                    <tr>
                                        <td className="m-1 p-1" >Will you occupy the property as your primary residence?  </td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="occupyasprimary" className="m-0 p-0 text-left" >
                                                <div >
                                                    <Select style={{ width: 200, height: 44 }} value={this.props.borrower.occupyasprimary}

                                                        onChange={achangeDeclaration(occupyasprimary, com.borrower)}
                                                    >

                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Select>
                                                </div>
                                                {/* <Form.Control required name="occupyasprimary" as="select" size="sm"
                                                    defaultValue={this.props.borrower.occupyasprimary}
                                                    onChange={changeDeclaration(occupyasprimary, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control> */}
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1">
                                                <Form.Group controlId="cooccupyasprimary" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select name="cooccupyasprimary" style={{ width: 200, height: 44 }} value={this.props.coborrower.occupyasprimary}

                                                            onChange={achangeDeclaration(occupyasprimary, com.coborrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="cooccupyasprimary" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.occupyasprimary}
                                                        onChange={changeDeclaration(occupyasprimary, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    {this.props.borrower.occupyasprimary !== "yes" && this.props.coborrower.occupyasprimary !== "yes" ? "" :
                                        <tr>
                                            <td className="m-1 p-1" >Have you had an ownership interest in another property in the last three years?  </td>
                                            <td className="m-1 p-1">
                                                <Form.Group controlId="ownershipinterest" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: 200, height: 44 }} value={this.props.borrower.ownershipinterest}

                                                            onChange={achangeDeclaration(ownershipinterest, com.borrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="ownershipinterest" as="select" size="sm"
                                                        defaultValue={this.props.borrower.ownershipinterest}
                                                        onChange={changeDeclaration(ownershipinterest, com.borrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                            {this.props.hascoborrower !== "alone" ? (
                                                <td className="m-1 p-1 yesno">
                                                    <Form.Group controlId="coownershipinterest" className="m-0 p-0 text-left" >
                                                        <div>
                                                            <Select style={{ width: 200, height: 44 }} value={this.props.coborrower.ownershipinterest}

                                                                onChange={achangeDeclaration(ownershipinterest, com.coborrower)}
                                                            >

                                                                <option value="no">No</option>
                                                                <option value="yes">Yes</option>
                                                            </Select>
                                                        </div>
                                                        {/* <Form.Control required name="coownershipinterest" as="select" size="sm"
                                                            defaultValue={this.props.coborrower.ownershipinterest}
                                                            onChange={changeDeclaration(ownershipinterest, com.coborrower)}

                                                        >
                                                            <option value="">...</option>
                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                </td>
                                            ) : ""
                                            }
                                        </tr>

                                    }

                                    {false === (this.props.borrower.occupyasprimary !== "yes" && this.props.coborrower.occupyasprimary !== "yes") ?
                                        <tr><td className="m-0 p-0"><Form.Row className="m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <Table variant="info" className="m-0 p-0" hidden={!(this.props.borrower.ownershipinterest !== "no" || (this.props.hascoborrower !== "alone" && this.props.coborrower.ownershipinterest !== "no"))}>
                                                    <thead>
                                                        <tr className="m-1 p-1" >
                                                            <th className=" m-1 p-1 borrowercolumn" ></th>
                                                            <th className=" m-1 p-1" >What type of property did you own? <Tips
                                                                content={<div>
                                                                    Select FHA Second Home only if your mortgage is an FHA one, and your property was qualified as FHA secondary for this mortgage
                                                                </div>}
                                                            /></th>
                                                            <th className=" m-1 p-1" >How did you hold title to the home?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  >
                                                        <tr hidden={this.props.borrower.ownershipinterest === "no"}>
                                                            <td className="borrowercolumn" >Borrower </td>
                                                            <td>
                                                                <Form.Group controlId="propertyowned" className="m-0 p-0 text-left" >
                                                                    <div>
                                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.propertyowned}

                                                                            onChange={achangeDeclaration(propertyowned, com.borrower)}
                                                                        >

                                                                            <option value="principal">Principal Residence</option>
                                                                            <option value="secondhome">Second Home</option>
                                                                            <option value="fhasecond">FHA Second Home</option>
                                                                            <option value="investment">Investment Home</option>
                                                                        </Select>
                                                                    </div>
                                                                    {/* <Form.Control name="propertyowned" as="select" size="sm"
                                                                        defaultValue={this.props.borrower.propertyowned}
                                                                        onChange={changeDeclaration(propertyowned, com.borrower)}

                                                                    >
                                                                        <option value="principal">Principal Residence</option>
                                                                        <option value="secondhome">Second Home</option>
                                                                        <option value="fhasecond">FHA Second Home</option>
                                                                        <option value="investment">Investment Home</option>
                                                                    </Form.Control> */}
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group controlId="titleheld" className="m-0 p-0 text-left" >
                                                                    <div>
                                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.titleheld}

                                                                            onChange={achangeDeclaration(titleheld, com.borrower)}
                                                                        >

                                                                            <option value="yourself">By Yourself</option>
                                                                            <option value="jointwithspouse">Jointly with the Spouse or Legal Life Partner</option>
                                                                            <option value="jointwithother">Jointly with Another Person</option>
                                                                        </Select>
                                                                    </div>
                                                                    {/* <Form.Control name="titleheld" as="select" size="sm"
                                                                        defaultValue={this.props.borrower.titleheld}
                                                                        onChange={changeDeclaration(titleheld, com.borrower)}

                                                                    >
                                                                        <option value="yourself">By Yourself</option>
                                                                        <option value="jointwithspouse">Jointly with the Spouse or Legal Life Partner</option>
                                                                        <option value="jointwithother">Jointly with Another Person</option>

                                                                    </Form.Control> */}
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        {this.props.hascoborrower !== "alone" ? (
                                                            <tr hidden={this.props.coborrower.ownershipinterest === "no"}>
                                                                <td className="borrowercolumn"> Coborrower </td>
                                                                <td>
                                                                    <Form.Group controlId="copropertyowned" className="m-0 p-0 text-left" >
                                                                        <div>
                                                                            <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.propertyowned}

                                                                                onChange={achangeDeclaration(propertyowned, com.coborrower)}
                                                                            >

                                                                                <option value="principal">Principal Residence</option>
                                                                                <option value="secondhome">Second Home</option>
                                                                                <option value="investment">Investment Home</option>
                                                                            </Select>
                                                                        </div>
                                                                        {/* <Form.Control name="copropertyowned" as="select" size="sm"
                                                                            defaultValue={this.props.coborrower.propertyowned}
                                                                            onChange={changeDeclaration(propertyowned, com.coborrower)}

                                                                        >
                                                                            <option value="principal">Principal Residence</option>
                                                                            <option value="secondhome">Second Home</option>
                                                                            <option value="investment">Investment Home</option>
                                                                        </Form.Control>
                                                                        
                                                                        <Form.Control.Feedback type="invalid" >
                                                                            Please provide your citizenship.
                                                                        </Form.Control.Feedback> */}
                                                                    </Form.Group>
                                                                </td>
                                                                <td>
                                                                    <Form.Group controlId="cotitleheld" className="m-0 p-0 text-left" >
                                                                        <div>
                                                                            <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.titleheld}

                                                                                onChange={achangeDeclaration(titleheld, com.coborrower)}
                                                                            >

                                                                                <option value="yourself">By Yourself</option>
                                                                                <option value="jointwithspouse">Jointly with the Spouse</option>
                                                                                <option value="jointwithother">Jointly with Another Person</option>
                                                                            </Select>
                                                                        </div>
                                                                        {/* <Form.Control name="cotitleheld" as="select" size="sm"
                                                                            defaultValue={this.props.coborrower.titleheld}
                                                                            onChange={changeDeclaration(titleheld, com.coborrower)}

                                                                        >
                                                                            <option value="yourself">By Yourself</option>
                                                                            <option value="jointwithspouse">Jointly with the Spouse</option>
                                                                            <option value="jointwithother">Jointly with Another Person</option>
                                                                        </Form.Control> */}
                                                                    </Form.Group>
                                                                </td>
                                                            </tr>
                                                        ) : ""
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Form.Row>
                                        </td>
                                        </tr>
                                        : ""}
                                    {this.props.application.property.purpose === "purchase" ?
                                        <tr>
                                            <td className="m-1 p-1 text-wrap" >Do you have a family relationship or business affiliation with the seller of the property?
                                            </td>
                                            <td className="m-1 p-1  yesno">
                                                <Form.Group controlId="familyrelationship" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.familyrelationship}

                                                            onChange={achangeDeclaration('familyrelationship', com.borrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="familyrelationship" as="select" size="sm"
                                                        defaultValue={this.props.borrower.familyrelationship}
                                                        onChange={changeDeclaration("familyrelationship", com.borrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                            {this.props.hascoborrower !== "alone" ? (
                                                <td className="m-1 p-1  yesno">
                                                    <Form.Group controlId="cofamilyrelationship" className="m-0 p-0 text-left" >
                                                        <div>
                                                            <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.familyrelationship}

                                                                onChange={achangeDeclaration('familyrelationship', com.coborrower)}
                                                            >

                                                                <option value="no">No</option>
                                                                <option value="yes">Yes</option>
                                                            </Select>
                                                        </div>
                                                        {/* <Form.Control required name="cofamilyrelationship" as="select" size="sm"
                                                            defaultValue={this.props.coborrower.familyrelationship}
                                                            onChange={changeDeclaration("familyrelationship", com.coborrower)}

                                                        >
                                                            <option value="">...</option>
                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                </td>
                                            ) : ""
                                            }
                                        </tr> : ""}
                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Are you borrowing any money for this real estate transaction? <Tips
                                            content={<div>(e.g., money for your closing costs or down payment) or
                                                obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?
                                            </div>}
                                        />
                                        </td>
                                        <td className="m-1 p-1  yesno">
                                            <Form.Group controlId="downpaymentborrowed" className="m-0 p-0 text-left" >
                                                <div>
                                                    <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.downpaymentborrowed}

                                                        onChange={achangeDeclaration(downpaymentborrowed, com.borrower)}
                                                    >

                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Select>
                                                </div>
                                                {/* <Form.Control required name="downpaymentborrowed" as="select" size="sm"
                                                    defaultValue={this.props.borrower.downpaymentborrowed}
                                                    onChange={changeDeclaration(downpaymentborrowed, com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control> */}
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1  yesno">
                                                <Form.Group controlId="codownpaymentborrowed" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.downpaymentborrowed}

                                                            onChange={achangeDeclaration(downpaymentborrowed, com.coborrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="codownpaymentborrowed" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.downpaymentborrowed}
                                                        onChange={changeDeclaration(downpaymentborrowed, com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    {false === (this.props.borrower.downpaymentborrowed !== "yes" && this.props.coborrower.downpaymentborrowed !== "yes") ?
                                        <tr><td className="m-0 p-0"><Form.Row className="m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <Table variant="info" className="m-0 p-0" hidden={!(this.props.borrower.ownershipinterest !== "no" || (this.props.hascoborrower !== "alone" && this.props.coborrower.ownershipinterest !== "no"))}>
                                                    <thead>
                                                        <tr className="m-1 p-1" >
                                                            <th className=" m-1 p-1 borrowercolumn" ></th>
                                                            <th className=" m-1 p-1" >Amount:</th>
                                                            <th className=" m-1 p-1" >&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  >
                                                        <tr hidden={this.props.borrower.ownershipinterest === "no"} className="m-1 p-1">
                                                            <td className="borrowercolumn m-1 p-1 "> Borrower </td>
                                                            <td style={{ width: '14em' }} className=" m-1 p-1" >
                                                                <Form.Group controlId="moneyborrowed" className="m-0 p-0 text-left" >
                                                                    <div>
                                                                        <InputNumber
                                                                            prefix="$"
                                                                            size='large'
                                                                            style={{ width: "100%" }}
                                                                            value={this.props.borrower.moneyborrowed}
                                                                            onChange={achangeDeclaration("moneyborrowed", com.borrower)}
                                                                        ></InputNumber>
                                                                    </div>
                                                                    {/* <NumericalInput name="moneyborrowed" size="sm"
                                                                        defaultValue={this.props.borrower.moneyborrowed}
                                                                        onChange={changeDeclaration("moneyborrowed", com.borrower)}

                                                                    /> */}

                                                                </Form.Group>
                                                            </td>
                                                            <td className=" m-1 p-1" >
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                        {this.props.hascoborrower !== "alone" ? (
                                                            <tr hidden={this.props.coborrower.ownershipinterest === "no"}>
                                                                <td className="borrowercolumn"> Coborrower </td>
                                                                <td style={{ width: '14em' }}>
                                                                    <Form.Group controlId="comoneyborrowed" className="m-0 p-0 text-left" >
                                                                        <div>
                                                                            <InputNumber
                                                                                prefix="$"
                                                                                size='large'
                                                                                style={{ width: "100%" }}
                                                                                value={this.props.coborrower.moneyborrowed}
                                                                                onChange={achangeDeclaration("moneyborrowed", com.coborrower)}
                                                                            ></InputNumber>
                                                                        </div>
                                                                        {/* <NumericalInput name="comoneyborrowed" size="sm"
                                                                            defaultValue={this.props.coborrower.moneyborrowed}
                                                                            onChange={changeDeclaration("moneyborrowed", com.coborrower)}

                                                                        />


                                                                        
                                                                        <Form.Control.Feedback type="invalid" >
                                                                            Please provide your citizenship.
                                                                        </Form.Control.Feedback> */}
                                                                    </Form.Group>
                                                                </td>
                                                                <td>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        ) : ""
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Form.Row>
                                        </td>
                                        </tr> : ""

                                    }
                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or
                                            before closing this transaction that is not disclosed on this loan application?   </td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="applyingonanother" className="m-0 p-0 text-left" >
                                                <div>
                                                    <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.applyingonanother}

                                                        onChange={achangeDeclaration("applyingonanother", com.borrower)}
                                                    >

                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Select>
                                                </div>
                                                {/* <Form.Control required name="applyingonanother" as="select" size="sm"
                                                    defaultValue={this.props.borrower.applyingonanother}
                                                    onChange={changeDeclaration("applyingonanother", com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control> */}
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1">
                                                <Form.Group controlId="coapplyingonanother" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.applyingonanother}

                                                            onChange={achangeDeclaration("applyingonanother", com.coborrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="coapplyingonanother" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.applyingonanother}
                                                        onChange={changeDeclaration("applyingonanother", com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Have you or will you be applying for any new credit on or before closing this loan that is not disclosed on this application?<Tips
                                            content={"(E.g., installmentloan, credit card, etc.)"}
                                        /></td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="applyingnewcredit" className="m-0 p-0 text-left" >
                                                <div>
                                                    <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.applyingnewcredit}

                                                        onChange={achangeDeclaration("applyingnewcredit", com.borrower)}
                                                    >

                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Select>
                                                </div>
                                                {/* <Form.Control required name="applyingnewcredit" as="select" size="sm"
                                                    defaultValue={this.props.borrower.applyingnewcredit}
                                                    onChange={changeDeclaration("applyingnewcredit", com.borrower)}
                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control> */}
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1">
                                                <Form.Group controlId="coapplyingnewcredit" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.applyingnewcredit}

                                                            onChange={achangeDeclaration("applyingnewcredit", com.coborrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="coapplyingnewcredit" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.applyingnewcredit}
                                                        onChange={changeDeclaration("applyingnewcredit", com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                    <tr>
                                        <td className="m-1 p-1 text-wrap" >Will this property be subject to a lien that could take priority over the first mortgage lien?<Tips
                                            content={"Such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)"}
                                        /> </td>
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="cleanenergylien" className="m-0 p-0 text-left" >
                                                <div>
                                                    <Select style={{ width: '100%', height: 44 }} value={this.props.borrower.cleanenergylien}

                                                        onChange={achangeDeclaration("cleanenergylien", com.borrower)}
                                                    >

                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Select>
                                                </div>
                                                {/* <Form.Control required name="cleanenergylien" as="select" size="sm"
                                                    defaultValue={this.props.borrower.applyingnewcredit}
                                                    onChange={changeDeclaration("cleanenergylien", com.borrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control> */}
                                            </Form.Group>
                                        </td>
                                        {this.props.hascoborrower !== "alone" ? (
                                            <td className="m-1 p-1">
                                                <Form.Group controlId="cocleanenergylien" className="m-0 p-0 text-left" >
                                                    <div>
                                                        <Select style={{ width: '100%', height: 44 }} value={this.props.coborrower.cleanenergylien}

                                                            onChange={achangeDeclaration("cleanenergylien", com.coborrower)}
                                                        >

                                                            <option value="no">No</option>
                                                            <option value="yes">Yes</option>
                                                        </Select>
                                                    </div>
                                                    {/* <Form.Control required name="cocleanenergylien" as="select" size="sm"
                                                        defaultValue={this.props.coborrower.applyingnewcredit}
                                                        onChange={changeDeclaration("cleanenergylien", com.coborrower)}

                                                    >
                                                        <option value="">...</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </Form.Control> */}
                                                </Form.Group>
                                            </td>
                                        ) : ""
                                        }
                                    </tr>
                                </tbody></Table>
                        </Col>
                    </Form.Row>
                </div>
                {/* <div className="text-left mt-4 mb-3 viewport">
                <Row><Col md="auto" className="sectionquestion ml-3 roundedtab">About your finances</Col>
                    <Col className="tabajacent"></Col></Row>                    

                    <Form.Row>
                        <Col>
                            <Table className="text-left m-1 p-1"><tbody>

                                <tr>
                                    <td className="m-1 p-1" >Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="comakeronnote" className="m-0 p-0 text-left" >
                                            <Form.Control required name="comakeronnote" as="select" size="sm"
                                                defaultValue={this.props.borrower.comakeronnote}
                                                onChange={changeDeclaration(comakeronnote, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="cocomakeronnote" className="m-0 p-0 text-left" >
                                                <Form.Control required name="cocomakeronnote" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.comakeronnote}
                                                    onChange={changeDeclaration(comakeronnote, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>

                                <tr>
                                    <td className="m-1 p-1" > Are there any outstanding judgments against you?</td>
                                    <td className="m-1 p-1" yesno>
                                        <Form.Group controlId="judgement" className="text-left m-0 p-0" >
                                            <Form.Control required name="judgement" as="select" size="sm"
                                                defaultValue={this.props.borrower.judgements}
                                                onChange={changeDeclaration(judgements, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group required controlId="cojudgement" className="text-left m-0 p-0" >
                                                <Form.Control name="judgement" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.judgements}
                                                    onChange={changeDeclaration(judgements, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>
                                <tr>
                                    <td className="m-1 p-1 text-wrap" >Are you currently delinquent or in default on a Federal debt? </td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="delinquentondebt" className=" m-0 p-0 text-left" >
                                            <Form.Control required name="delinquentondebt" as="select" size="sm"
                                                defaultValue={this.props.borrower.delinquentondebt}
                                                onChange={changeDeclaration(delinquentondebt, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="codelinquentondebt" className="m-0 p-0 text-left" >
                                                <Form.Control required name="codelinquentondebt" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.delinquentondebt}
                                                    onChange={changeDeclaration(delinquentondebt, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>
                                <tr>
                                    <td className="m-1 p-1" >Are you a party to a lawsuit in which you potentially have any personal financial liability?</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="lawsuit" className="text-left m-0 p-0" >
                                            <Form.Control required name="lawsuit" as="select" size="sm"
                                                defaultValue={this.props.borrower.lawsuit}
                                                onChange={changeDeclaration(lawsuit, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="colawsuit" className="text-left m-0 p-0" >
                                                <Form.Control required name="colawsuit" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.lawsuit}
                                                    onChange={changeDeclaration(lawsuit, com.coborrower)}
                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>


                                <tr>
                                    <td className="m-1 p-1 text-wrap" >Have you conveyed title to any property in lieu of foreclosure in the past 7 years?</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="conveyedtitle" className="text-left m-0 p-0" >
                                            <Form.Control required name="conveyedtitle" as="select" size="sm"
                                                defaultValue={this.props.borrower.conveyedtitle}
                                                onChange={changeDeclaration("conveyedtitle", com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="coconveyedtitle" className="text-left m-0 p-0" >
                                                <Form.Control name="coconveyedtitle" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.conveyedtitle}
                                                    onChange={changeDeclaration("conveyedtitle", com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>


                                <tr>
                                    <td className="m-1 p-1 text-wrap" >{tooltip("Within the past 7 years, have you completed a pre-foreclosure sale or short sale",
                                        <div>whereby the property was sold to a
                                third party and the Lender agreed to accept less than the outstanding mortgage balance due?</div>,
                                        "auto", "", "")}</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="loanforeclosure" className="text-left m-0 p-0" >
                                            <Form.Control required name="loanforeclosure" as="select" size="sm"
                                                defaultValue={this.props.borrower.loanforeclosure}
                                                onChange={changeDeclaration(loanforeclosure, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="coloanforeclosure" className="text-left m-0 p-0" >
                                                <Form.Control name="coloanforeclosure" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.loanforeclosure}
                                                    onChange={changeDeclaration(loanforeclosure, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>


                                <tr>
                                    <td className="m-1 p-1" >Have you had property foreclosed upon in the last 7 years?</td>
                                    <td className="m-1 p-1">
                                        <Form.Group controlId="propertyforeclosure" className="text-left m-0 p-0" >
                                            <Form.Control required name="propertyforeclosure" as="select" size="sm"
                                                defaultValue={this.props.borrower.propertyforeclosure}
                                                onChange={changeDeclaration(propertyforeclosure, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="copropertyforeclosure" className="text-left m-0 p-0" >
                                                <Form.Control required name="copropertyforeclosure" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.propertyforeclosure}
                                                    onChange={changeDeclaration(propertyforeclosure, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }

                                </tr>
                                <tr>
                                    <td className="m-1 p-1" >{tooltip("Do you currently have any loans in forebearance",
                                    <div>Forbearance is when your mortgage servicer or lender allows you to pause or reduce your mortgage payments for a limited time while you build back your finances.</div>
                                    , "auto" )}</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="loansinforebearance" className="text-left m-0 p-0" >
                                            <Form.Control required name="loansinforebearance" as="select" size="sm"
                                                defaultValue={this.props.borrower.loansinforebearance}
                                                onChange={changeDeclaration(loansinforebearance, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="coloansinforebearance" className="text-left m-0 p-0" >
                                                <Form.Control required name="coloansinforebearance" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.loansinforebearance}
                                                    onChange={changeDeclaration(loansinforebearance, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>                                
                                <tr>
                                    <td className="m-1 p-1" >Have you been declared bankrupt within the past 7 years?</td>
                                    <td className="m-1 p-1 yesno">
                                        <Form.Group controlId="bancrupt" className="text-left m-0 p-0" >
                                            <Form.Control required name="bancrupt" as="select" size="sm"
                                                defaultValue={this.props.borrower.bancrupt}
                                                onChange={changeDeclaration(bancrupt, com.borrower)}

                                            >
                                                <option value="">...</option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    {this.props.hascoborrower !== "alone" ? (
                                        <td className="m-1 p-1 yesno">
                                            <Form.Group controlId="cobancrupt" className="text-left m-0 p-0" >
                                                <Form.Control required name="cobancrupt" as="select" size="sm"
                                                    defaultValue={this.props.coborrower.bancrupt}
                                                    onChange={changeDeclaration(bancrupt, com.coborrower)}

                                                >
                                                    <option value="">...</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </td>
                                    ) : ""
                                    }
                                </tr>

                                {false === (this.props.borrower.bancrupt !== "yes" && this.props.coborrower.bancrupt !== "yes") ?
                                    <tr><td className="m-0 p-0"><Form.Row className="m-0 p-0">
                                        <Col className="m-0 p-0">
                                            <Table variant="info" className="m-0 p-0" hidden={!(this.props.borrower.ownershipinterest !== "no" || (this.props.hascoborrower !== "alone" && this.props.coborrower.ownershipinterest !== "no"))}>
                                                <thead>
                                                    <tr className="m-1 p-1" >
                                                        <th className=" m-1 p-1 borrowercolumn" ></th>
                                                        <th className=" m-1 p-1" > Identify the type(s) of bankruptcy::</th>
                                                        <th className=" m-1 p-1" >&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody  >
                                                    <tr hidden={this.props.borrower.ownershipinterest === "no"}>
                                                        <td className="borrowercolumn"> Borrower </td>
                                                        <td  >
                                                            <Row>
                                                                <Col md="auto">
                                                                    <input type="checkbox" id="chapter7" name="chapter7" defaultChecked={this.props.borrower.chapter7} onChange={updateBankrupcy(com.borrower, "chapter7")} ></input>
                                                                    <label id="chapter7label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter7">Chapter 7</label>
                                                                </Col>
                                                                <Col md="auto">
                                                                    <input type="checkbox" id="chapter11" name="chapter11" defaultChecked={this.props.borrower.chapter11} onChange={updateBankrupcy(com.borrower, "chapter11")} ></input>
                                                                    <label id="chapter11label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter11">Chapter 11</label>
                                                                </Col>
                                                                <Col md="auto">
                                                                    <input type="checkbox" id="chapter12" name="chapter12" defaultChecked={this.props.borrower.chapter12} onChange={updateBankrupcy(com.borrower, "chapter12")} ></input>
                                                                    <label id="chapter12label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter12">Chapter 12</label>
                                                                </Col>
                                                                <Col md="auto">
                                                                    <input type="checkbox" id="chapter13" name="chapter13" defaultChecked={this.props.borrower.chapter13} onChange={updateBankrupcy(com.borrower, "chapter13")} ></input>
                                                                    <label id="chapter13label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="chapter13">Chapter 13</label>
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>

                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                    {this.props.hascoborrower !== "alone" ? (
                                                        <tr hidden={this.props.coborrower.ownershipinterest === "no"}>
                                                            <td className="borrowercolumn"> Coborrower </td>
                                                            <td >
                                                                <Row>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="cochapter7" name="cochapter7" defaultChecked={this.props.coborrower.chapter7} onChange={updateBankrupcy(com.coborrower, "chapter7")} ></input>
                                                                        <label id="cochapter7label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter7">Chapter 7</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="cochapter11" name="cochapter11" defaultChecked={this.props.coborrower.chapter11} onChange={updateBankrupcy(com.coborrower, "chapter11")} ></input>
                                                                        <label id="cochapter11label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter11">Chapter 11</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="cochapter12" name="cochapter12" defaultChecked={this.props.coborrower.chapter12} onChange={updateBankrupcy(com.coborrower, "chapter12")} ></input>
                                                                        <label id="cochapter12label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter12">Chapter 12</label>
                                                                    </Col>
                                                                    <Col md="auto">
                                                                        <input type="checkbox" id="cochapter13" name="cochapter13" defaultChecked={this.props.coborrower.chapter13} onChange={updateBankrupcy(com.coborrower, "chapter13")} ></input>
                                                                        <label id="cochapter13label" style={{ textDecoration: "underline" }} className="divlink" htmlFor="cochapter13">Chapter 13</label>
                                                                    </Col>
                                                                    <Col></Col>
                                                                </Row>
                                                            </td>
                                                            <td>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    ) : ""
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Form.Row>
                                    </td>
                                    </tr> : ""

                                }
                            </tbody>
                            </Table>
                        </Col>
                    </Form.Row>

                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Declarations)
