import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import * as st from "../State"
import ScrollBar from "../Common/components/ScrollBar"
import { InputNumber, Select } from "antd"


const mapStateToProps = (state) => {

    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertySalesPrice: (event) => {
        console.log(event)
        dispatch(act.ChangeMainPropertySalesPrice(event))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event))
    },
})


export class DetailProperty extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            loanLimits: {},
        }
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {

                }
                return res.json()
            })
            .then((res) => {

                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
        this.lastcompletion = 0
    }
    isoccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }
    lookupAddress = (providedAddress) => {
        let now = Date.now()

        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
        if (typeof providedAddress !== "undefined") {
            addr = providedAddress
        }

        if (this.isoccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        }
        if ("" === addr)
            return
        let target = encodeURI("https://maps.googleapis.com/maps/api/geocode/json?address=" + addr + "&key=AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8")

        fetch(target, {
            method: 'GET',
        }).then(
            response => {

                if (response.status !== 200) {
                    //alert('Looks like there was a problem. Status Code: ' + response.status)
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.status === "OK") {

                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }
    onStreetAddressCompletion = address => {
        this.lastcompletion = Date.now()

        if ("undefined" !== typeof this.timer)
            clearTimeout(this.timer)
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let zip = com.getSubaddress(address, "postal_code", "long_name")
        let street = com.getSubaddress(address, "route", "short_name")

        let county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        let state = com.getSubaddress(address, "administrative_area_level_1", "short_name")
        let longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")
        if (county !== "")
            this.props.changeMainProperty(county, "county")
        this.props.changeMainProperty(longstate, "state")
        this.props.changeMainProperty(city, "city")
        this.props.changeMainProperty(zip, "zipcode")
        let addr = num + " " + street + ", " + city + ", " +
            com.getSubaddress(address, "administrative_area_level_1", "short_name") +
            " " + zip
        if (num !== "")
            this.props.changeMainPropertyAddress({ target: { value: addr } })
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")
        let shorts = short.split(' ')

        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(' ')
        }
        let prefix = ""
        let s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {

            prefix = shorts[0]
            shorts = short.split(' ')
            short = shorts.slice(1, shorts.length).join(' ')
        }

        this.props.changeMainPropertyAddress({ target: { value: addr } })

    }
    updateRefinanceAddressWithResidencyAddress = () => {
        let b = this.props.application.borrower
        let p = this.props.application.property
        if (p.address === "") {
            this.props.changeMainProperty(b.presentaddressstreet, "address")
            this.lookupAddress(b.presentaddressstreet)
        }
    }
    lookupProperty = (num, prefix, short, sfx, city, zip) => {
        let body = {
            number: num, prefix: prefix, street: short, suffix: sfx, city: city, zip: zip
        }

        let token = sessionStorage.getItem("ZeitroA")
        this.props.changeMainProperty(false, "lookupsuccessful")
        fetch('/borrower/findproperty', {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                "X-Borrower": this.props.borrowerid,
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (this.props.property.yearbuilt === "" && js.Yearbuilt !== "" && !this.props.property.lookupsuccessful) {
                        this.props.changeMainPropertyYearBuilt({ target: { value: js.Yearbuilt } })
                        this.props.changeMainPropertyUnits({ target: { value: js.Units } })
                        this.props.changeMainProperty(true, "lookupsuccessful")
                        if (this.props.application.property.purpose !== st.POL_Purchase) {
                            let dt = js.Lastsale
                            let dat = dt.split('T')[0].split('-')
                            let d = parseInt(dat[0])
                            this.props.changeMainPropertyRefinanceYearAcquired({ target: { value: d } })
                            let cost = js.Lastsaleprice.toString()
                            this.props.changeMainPropertyRefinanceOriginalCost({ target: { value: cost } })
                        }

                    }

                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }
    componentDidMount() {
        if (this.props.application.financingsameproperty === null) {
            this.props.updateSameProperty({ target: { checked: true } })
            this.updateRefinanceAddressWithResidencyAddress()
        }
        this.completion('property')
    }

    componentDidUpdate() {
        this.completion('property')
    }

    componentWillUnmount() { }
    completion = (stateKey) => {
        let keysToFilter = []
        keysToFilter = ['state', 'county']
        if (this.props[stateKey].propertytype === "twotofourfamily") {
            keysToFilter.push('units')
        }
        if (this.props[stateKey].occupancy === "investment" || (this.props[stateKey].occupancy === "principal" && this.props.application[stateKey].propertytype === "twotofourfamily")) {
            keysToFilter.push('purchase.expectedgrossmonthlyrentalincome')
        }

        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})
        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    render() {
        let changeMainPropertyExpectedRentalIncome = e => {
            com.touch()
            this.props.changeMainProperty(e, "purchase|expectedgrossmonthlyrentalincome")
        }
        let achangeMainPropertyState = e => {
            // this.stateref.current.setCustomValidity("")
            this.props.changeMainProperty(e, "state")
        }

        let changeMainPropertyState = e => {
            // this.stateref.current.setCustomValidity("")
            this.props.changeMainProperty(e.target.value, "state")
        }
        let changeMainPropertyCounty = e => {
            // this.countyref.current.setCustomValidity("")
            this.props.changeMainProperty(e, "county")
        }
        let getStates = () => {
            let options = []
            let keys = Object.keys(this.state.loanLimits)
            options.push(<option value=""></option>)
            keys.forEach((x) => {
                options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
            })
            return options
        }
        let getCounties = () => {
            let options = []
            options.push(<option value=""></option>)
            if (this.state.loanLimits[this.props.property.state.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[this.props.property.state.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
            return options
        }
        let aonBudget = e => {
            const event = {
                target: {
                    value: e
                }
            }
            console.log(event, this.props.property.salesprice)
            com.touch()
            this.props.changeMainPropertySalesPrice(event)
            this.props.changeMainPropertyAppraisal(event)
        }
        let onBudget = e => {
            com.touch()
            this.props.changeMainPropertySalesPrice(e)
            this.props.changeMainPropertyAppraisal(e)
        }
        return (
            <div className="text-left newInterviewPaneEmployment">
                <Row className="pt-3">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label className="text-left" >State</Form.Label>
                            <div>
                                <Select
                                    style={{
                                        width: "100%",
                                        height: 44
                                    }}
                                    onChange={(e) => achangeMainPropertyState(e)}
                                    value={this.props.property.state}>
                                    {getStates()}

                                </Select>
                            </div>
                            {/* <Form.Control name="units" as="select" size="md"
                                required
                                value={this.props.property.state}
                                onChange={changeMainPropertyState}

                            >
                                {getStates()}
                            </Form.Control> */}
                        </Form.Group>
                    </Col>
                    {/* <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label className="text-left" >County</Form.Label>
                            <div>
                                <Select
                                    style={{
                                        width: "100%",
                                        height: 44
                                    }}
                                    onChange={(e) => changeMainPropertyCounty(e)}
                                    value={this.props.property.county}>
                                    {getCounties()}

                                </Select>
                            </div>
                            <Form.Control
                                required
                                name="units"
                                as="select"
                                size="md"
                                value={this.props.property.county}
                                onChange={changeMainPropertyCounty}
                            >
                                {getCounties()}

                            </Form.Control>
                        </Form.Group>
                    </Col> */}
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label className="text-left" >County</Form.Label>
                            <div>
                                <Select
                                    style={{
                                        width: "100%",
                                        height: 44
                                    }}
                                    onChange={(e) => changeMainPropertyCounty(e)}
                                    value={this.props.property.county}>
                                    {getCounties()}

                                </Select>
                            </div>
                            {/* <Form.Control
                                required
                                name="units"
                                as="select"
                                size="md"
                                value={this.props.property.county}
                                onChange={changeMainPropertyCounty}
                            >
                                {getCounties()}

                            </Form.Control> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label>What is your maximum purchase price?</Form.Label>
                            <div>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    prefix='$'
                                    size="large"
                                    value={this.props.property.salesprice === "" ? 500000 : parseInt(this.props.property.salesprice)}
                                    onChange={(e) => {

                                        onBudget(e)
                                    }}
                                    max={5000000} min={100000} step={10000}
                                ></InputNumber>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>





                <div className="text-center mt-4 p-0">

                    {(this.props.property.occupancy === "investment" || (this.props.property.occupancy === "principal" &&
                        this.props.application.property.propertytype === "twotofourfamily")) ?
                        <div style={{ maxWidth: "318px" }}>
                            <Form.Group controlId="expectedgrossmonthlyrentalincome" className="text-left" >
                                <Form.Label className="text-left" >Expected monthly gross rental income/month</Form.Label>
                                <div>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        size="large"
                                        prefix='$'
                                        onChange={(e) => changeMainPropertyExpectedRentalIncome(e)}
                                        value={this.props.property.purchase.expectedgrossmonthlyrentalincome}
                                    ></InputNumber>


                                </div>
                                {/* <NumericalInput
                                    size="md"
                                    name="expectedgrossmonthlyrentalincome"
                                    type="text"
                                    value={this.props.property.purchase.expectedgrossmonthlyrentalincome}
                                    onChange={changeMainPropertyExpectedRentalIncome}
                                    required
                                    pattern="^(|[12][0-9]{3})$"
                                /> */}

                                <Form.Control.Feedback type="invalid" >
                                    Please provide monthly gross rental income.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        : ""}

                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DetailProperty)
