import React, { Component, forwardRef, useImperativeHandle, useRef } from "react"
import smartlookClient from 'smartlook-client'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Prequal.css'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Finances, { MoreFinances } from './Finances'
import Expenses from './Expenses'
import Income, { OtherIncome } from './Income'
import Property, { MoreProperty, DetailProperty } from './Property'
import Email from "./Email"
import Applicant, { Residence } from './Applicant'
import EmailVerification from "./EmailVerification"
import SSN from "./SSN"
import Liabilities, { calculateBalancesAndPayments } from './Liabilities'
import Race from './Race'
import Declarations from './Declarations'
import { withRouter, useLocation } from "react-router-dom"
import { OtherInfoPersonal2 } from "./AllOtherInfo"
import { Modal, message, Spin } from 'antd'
import { Spinner } from "react-bootstrap"
import ProductPicker from './ProductPicker'
import * as act from "../Store/actions"
import { connect } from 'react-redux'
import { UpdateChangeProgress, UpdateChangeVisitedStage, UpdateChangeLastTouch } from "../Store/progress"
import { getCurrentState } from '../Store'
import * as com from "../Common.js"
import SidebarApplication from "../SidebarApplication/SidebarApplication"
import { updateTaskStatus } from "../AppEvents"
import Review from "./Review"
import MyRates from "./MyRates/MyRates"

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        hascoborrower: state.application.hascoborrower,
        borrower: state.borrower
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(UpdateChangeVisitedStage(step, stage))
    },
})
class SubInterview extends Component {
    returnTrue = () => true
    returnFalse = () => false
    personalCoborrower = () => {
        return this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)

    }
    returnTruePurchase = () => {
        return this.props.application.property.purpose === "purchase"
    }

    newCustomer = () => {
        return sessionStorage.getItem("newcustomer") === "true"
    }

    getBalancePayment = calculateBalancesAndPayments.bind(this)

    creditNotPulled = () => {
        if (this.props.application.scre === 0) {
            return true
        }
        // if using estimated FICO, return true
        if (this.props.application.assetsandliabilities !== null &&
            this.props.application.assetsandliabilities.loans.length === 1 &&
            this.props.application.assetsandliabilities.loans[0].name === "Estimated") {
            return true
        }

        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        if (this.props.application.property.purpose === "refinance" &&
            0 === totalmortgage && true === this.props.application.assetsandliabilities.propertyownedfreeandclear) {
            // can't refinance the property that you own free and clear
            return true
        }
        return false
    }

    notRemote = () => {
        return this.props.application.hascoborrower !== "remote"
    }

    coborrowerNotCohabiting = () => {

        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.cohabiting
    }
    coborrowerNotJoint = () => {
        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.married
    }
    coborrowerNotJointPurchase = () => {
        if (this.props.application.property.purpose !== "purchase")
            return false
        return this.props.application.hascoborrower === "withcoborrower" && !this.props.application.married
    }

    checkExpenses = () => {

        return !(this.props.application.property.purpose === "purchase" && this.props.application.borrower.presentaddressownership === "rentfree")
    }
    checkPreChoice = () => {

        if (this.props.application.hascoborrower === "remote")
            return false
        return true
    }


    checkCoExpenses = () => {

        if (this.props.application.hascoborrower === "alone")
            return false
        if (this.props.application.hascoborrower === "remote")
            return false
        if (this.props.application.hascoborrower === "multiple" && this.props.application.married)
            return false
        if (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married)
            return false

        return !(this.props.application.property.purpose === "purchase" &&
            this.props.application.coborrower.presentaddressownership === "rentfree")
    }
    remoteCoborrowers = () => {
        return this.props.hascoborrower === "multiple"
    }
    returnProperty = () => {
        let ret = this.props.application.hascoborrower !== "remote"
        return ret
    }
    returnProductPicker = () => {
        return this.props.hascoborrower !== "remote"
    }
    returnMoreProperty = () => {
        return this.props.hascoborrower !== "remote"
    }

    getURLIndex = (section) => {
        let newIndex = 0
        if ("undefined" != typeof com.stageToNumberApplication[section]) {
            newIndex = com.stageToNumberApplication[section]
        } else {
            try {
                newIndex = parseInt(section)
            } catch (x) {
                newIndex = 0
            }
        }
        return Math.min(this.wizard.length - 1, newIndex)
    }


    checkAuthorizationValidity = v => {
        try {
            v = v.replace('null', '{}') // temp hack

            let js = JSON.parse(v)

            if (this.props.application.hascoborrower === "alone" || this.props.application.hascoborrower === "remote") {
                return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity
            }
            if (this.props.application.hascoborrower === "withcoborrower") {
                return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity &&
                    this.props.application.coborrower.socialsecurity === js.application.coborrower.socialsecurity
            }

            if (this.props.application.hascoborrower === "multiple") {
                if (this.props.application.married) {
                    return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity &&
                        this.props.application.coborrower.socialsecurity === js.application.coborrower.socialsecurity
                } else {
                    return this.props.application.borrower.socialsecurity === js.application.borrower.socialsecurity
                }
            }

            return false

        } catch (x) {
            return false
        }
    }

    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.wizard = []
        {
            let r = React.createRef() // 0 start
            this.wizard.push([<Email ref={r} self={this.form} />, this.newCustomer, r])
        }
        {
            let r = React.createRef() // 1
            this.wizard.push([<EmailVerification ref={r} self={this.form} />, this.newCustomer, r])
        }
        {
            let r = React.createRef() // 2 applicant
            this.wizard.push([<Applicant ref={r} self={this.form} who="borrower" />, this.notRemote, r])
        }
        {
            let r = React.createRef() // 3 residence
            this.wizard.push([<Residence ref={r} self={this.form} who="borrower" />, this.notRemote, r])
        }
        {
            let r = React.createRef()  // 4 property
            this.wizard.push([<Property ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnProperty, r])
        }
        {
            let r = React.createRef()  // 5 property
            this.wizard.push([<DetailProperty ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnProperty, r])
        }
        {
            let r = React.createRef() // 6 income
            this.wizard.push([<Income borrowerid={this.props.borrowerid} who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        // {
        //     let r = React.createRef() //5
        //     this.wizard.push([<Income who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        // }
        {
            let r = React.createRef() // 7 OtherIncome
            this.wizard.push([<OtherIncome borrowerid={this.props.borrowerid} who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef() // 8 finance
            this.wizard.push([<Finances who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef() // 9
            this.wizard.push([<MoreFinances who={com.borrower} ref={r} self={this.form} />, this.returnTruePurchase, r])
        }
        {
            let r = React.createRef() // 10
            this.wizard.push([<SSN ref={r} self={this.form} who={com.borrower} changeContinueButtonText={this.changeNextButtonText} changeLoading={this.changeLoading} />, this.returnTrue, r]) //this.creditNotPulled
        }
        {
            let r = React.createRef() //11 liabilities
            this.wizard.push([<Liabilities clearValidate={this.clearValidate} changeContinueButtonText={this.changeContinueButtonText} isSubmitPage={!this.checkExpenses() && !this.coborrowerNotJoint()} checkValidate={this.checkValidate} who={com.borrower} ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnTrue, r])
        }
        // {
        //     let r = React.createRef() // 10
        //     this.wizard.push([<Liabilities clearValidate={this.clearValidate} changeContinueButtonText={this.changeContinueButtonText}  isSubmitPage={!this.checkExpenses() && this.coborrowerNotJoint()}  checkValidate={this.checkValidate} who={com.coborrower} ref={r} self={this.form} />, this.coborrowerNotJoint, r])
        // }
        {
            let r = React.createRef() //  12 expenses
            this.wizard.push([<Expenses who={com.borrower} ref={r} self={this.form} changeContinueButtonText={this.changeContinueButtonText} checkCoExpenses={this.checkCoExpenses} />, this.checkExpenses, r])
        }
        {
            let r = React.createRef() // 13
            this.wizard.push([<Expenses who={com.coborrower} ref={r} self={this.form} changeContinueButtonText={this.changeContinueButtonText} checkCoExpenses={this.checkCoExpenses} />, this.checkCoExpenses, r])
        }
        {
            let r = React.createRef() // 14 Review
            this.wizard.push([<Review who={com.borrower} changeContinueButtonText={this.changeNextButtonText} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef() // 15 choice
            // this.wizard.push([<ProductPicker ref={r} self={this.form} borrowerid={this.props.borrowerid} />, this.returnProductPicker, r])
            this.wizard.push([<MyRates ref={r} self={this.form} borrowerid={this.props.borrowerid} changeContinueButtonText={this.changeNextButtonText} />, this.returnProductPicker, r])
        }

        {
            let r = React.createRef() // 16 Declarations - transactions
            this.wizard.push([<MoreProperty who={com.borrower} ref={r} self={this.form} />, this.returnMoreProperty, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<OtherInfoPersonal2 who={com.borrower} ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<OtherInfoPersonal2 who={com.coborrower} ref={r} self={this.form} />, this.personalCoborrower, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Declarations ref={r} self={this.form} />, this.returnTrue, r])
        }
        {
            let r = React.createRef()
            this.wizard.push([<Race ref={r} self={this.form} />, this.returnTrue, r])
        }
        // {
        // let r = React.createRef(); 
        // this.wizard.push([
        //     <Loan borrowerid={this.props.borrowerid} ref={r} self={this.form} />,
        //     this.returnTrue,
        //     r,
        // ]);
        // }        


        let index = 0
        if (this.props.progress.stage === "interview") {
            index = Math.min(this.wizard.length - 1, parseInt(this.props.progress.interview.step))
        }

        let section = this.props.match.params["section"]
        this.state = {
            section: section,
            handingoff: false,
        }

        if (null == section) {

        } else {
            index = this.getURLIndex(section)
        }

        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1

            if (index === this.wizard.length - 1) //end of line
                break
        }

        this.state = {
            currentIndex: index,
            currentPane: this.wizard[index][0],

            hiddenNext: (index === this.wizard.length - 1),
            validated: false,
            continueButtonText: "Next step",
            showSubmitModal: false,
            notifyingLO: false,
            whoValue: com.borrower,
            loading: false
        }
        this.timer = 0
        this.props.updateChangeProgress(index, "interview")
        if (index > this.props.progress.interview.max && index <= this.wizard.length - 1) {
            console.log(index, this.props.progress.interview.max)
            this.props.updateChangeProgress(index, "max")
        }

    }

    componentDidMount = () => {
        window.document.addEventListener('checkwalkback', this.onTouch)
        if (!window.location.hostname.includes("localhost")) {
            let token = sessionStorage.getItem("ZeitroA")
            fetch("/auth/getprofile", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrowerid,
                },
            })
                .then((response) => {
                    if (response.status !== 200) {
                        console.log(
                            "Looks like there was a problem. Status Code: " + response.status
                        )
                    }
                    response.json().then((js) => {
                        if (js.Status !== "OK") {
                            console.log("Error: " + js.Text)
                        } else {
                            smartlookClient.init('d837cbb6bdd09c2c425261ab6ab338c05aac8ef0')
                            smartlookClient.record({
                                emails: true,
                                forms: true,
                                numbers: true,
                                ips: true,
                            })
                            smartlookClient.identify(this.props.borrowerid, { name: js.FirstName + " " + js.LastName, email: js.Email })
                        }
                    })
                })
                .catch((err) => {
                    console.log("Fetch Error :", err)
                })

        }
    }
    componentWillUnmount = () => {
        window.document.removeEventListener('checkwalkback', this.onTouch)
    }

    changeContinueButtonText = () => {
        this.setState({ continueButtonText: "Submit" })
    }
    changeNextButtonText = (text) => {
        this.setState({ continueButtonText: text })
    }
    restoreContinueButtonText = () => {
        this.setState({ continueButtonText: "Next step" })
    }
    changeLoading = (state) => {
        this.setState({ loading: state })
    }
    onTouch = () => {
        let d = new Date()

        this.props.updateChangeLastTouch(d.toUTCString())

        if (this.state.currentIndex < this.props.progress.interview.max && this.state.currentIndex <= this.wizard.length - 1) {
            this.props.updateChangeProgress(this.state.currentIndex, "max")
        }
    }
    oninit = (callback) => {
        this.submit = callback
    }
    update = newindex => {
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break
            newindex += 1
        }

        this.setState({ currentIndex: newindex })
        this.setState({ validated: false })
        this.setState({ currentPane: this.wizard[newindex][0], hiddenNext: (newindex === this.wizard.length - 1) })
        this.props.updateChangeProgress(newindex, "interview")

        if (typeof this.props.progress.interview.max === "undefined" || this.props.progress.interview.max < newindex)
            this.props.updateChangeProgress(newindex, "max")
    }
    previous = () => {
        let child = this.wizard[this.state.currentIndex][2].current
        if (child && child.handlePrevious) {
            if (!child.handlePrevious()) {
                return false
            }
        }
        let newindex = Math.max(this.state.currentIndex - 1, 0)
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break
            newindex -= 1
            if (newindex === 0) //end of line
                break
        }

        if (!this.wizard[newindex][1]())
            newindex = this.state.currentIndex

        this.update(newindex)
        window.scrollTo(0, 0)
        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/application/interview/main/' + newindex)
    }
    next = () => {
        let newindex = Math.min(this.state.currentIndex + 1, this.wizard.length - 1)
        for (; ;) {
            let check = this.wizard[newindex][1]
            if (check())
                break
            newindex += 1
            if (newindex === this.wizard.length - 1) //end of line
                break
        }

        this.setState({ currentIndex: newindex, hiddenNext: (newindex === this.wizard.length - 1) })
        this.setState({ currentPane: this.wizard[newindex][0] })
        this.props.updateChangeProgress(newindex, "interview")

        if (typeof this.props.progress.interview.max === "undefined" || this.props.progress.interview.max < newindex)
            this.props.updateChangeProgress(newindex, "max")

        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/application/interview/main/' + newindex)

        switch (newindex) {
            case com.stageToNumberApplication.start + 2: {
                if (!this.props.progress.interview.interviewstarted) {
                    this.props.updateChangeVisitedStage("interviewstarted")
                    this.notifyLO("interviewstarted")
                }
                break
            }
            case com.stageToNumberApplication.choice: {
                if (!this.props.progress.interview.productselectionstarted) {
                    this.props.updateChangeVisitedStage("productselectionstarted")
                    this.notifyLO("productselectionstarted")
                }
                break
            }
            case com.stageToNumberApplication.end: {
                if (!this.props.progress.interview.interviewfinished) {
                    this.props.updateChangeVisitedStage("interviewfinished")
                    this.notifyLO("interviewfinished")
                }
                break
            }
            default:
                break
        }
        window.scrollTo(0, 0)
        console.log(this.props.progress)
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    clearValidate = () => {
        this.setState({ validated: false })
    }

    checkValidate = (event) => {

        if (this.form.current.reportValidity() === false) {
            event.preventDefault()
            event.stopPropagation()

            let els = document.getElementsByClassName("zeitro-radio")
            for (let i = 0; i < els.length; i++) {
                let inp = els[i].previousSibling

                if (!inp.validity.valid)
                    els[i].classList.add("in-valid")
                else
                    els[i].classList.replace("in-valid", "totally-valid")
            }

            els = document.getElementsByClassName("zeitro-checkbox")
            for (let i = 0; i < els.length; i++) {

                let inp = els[i].previousSibling

                if (!inp.validity.valid)
                    els[i].classList.add("in-valid")
                else
                    els[i].classList.replace("in-valid", "totally-valid")

            }
            this.setState({ validated: true })
            return false
        }
        return true
    }
    handleSubmit = (event) => {
        if (!this.checkValidate(event)) {
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        if (this.state.continueButtonText === "Submit") {
            event.preventDefault()
            event.stopPropagation()
            this.setState({ showSubmitModal: true })
            return
        }

        let child = this.wizard[this.state.currentIndex][2].current
        if (child && child.handleSubmit) {
            if (!child.handleSubmit(event)) {
                event.preventDefault()
                event.stopPropagation()
                this.setState({ validated: true })
                return false
            }
        }
        getCurrentState({ application: this.props.application, borrower: this.props.borrower }, sessionStorage.getItem("originalstate"))

        event.preventDefault()
        this.setState({ validated: false })
        event.stopPropagation()
        this.next()
        return true
    }
    rollback = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }

        this.setState({ currentIndex: index })
        this.setState({ currentPane: this.wizard[index][0], validated: false, hiddenNext: false, })
        this.props.updateChangeProgress(0, "interview")
        window.scrollTo(0, 0)


        if ("undefined" === typeof this.props.borrowerid)
            this.props.history.push('/application/interview/main/' + 0)
    }
    isRollbackHidden = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }
        return this.state.currentIndex === index
    }
    isPreviousHidden = () => {
        let index = 0
        for (; ;) {
            let check = this.wizard[index][1]
            if (check())
                break
            index += 1
        }
        return this.state.currentIndex === index
    }
    componentDidMount() {
    }
    toolbar = () => {
        let showButton = (amin, amax, text) => {
            let limit = this.props.progress.interview.max
            let disabled = false

            if (amin > limit) {
                disabled = true
                //bg="dis"
            }
            let setPane = () => {
                this.setState({ currentIndex: amin, currentPane: this.wizard[amin][0], hiddenNext: (amin === this.wizard.length - 1) })
            }


            return <Button disabled={disabled} variant="light" onClick={setPane}>{text}</Button>
        }
        let isPreapproval = false === this.props.application.property.hascontract &&
            this.props.application.property.purpose === "purchase"

        if (this.props.borrowerid)
            return (

                <ButtonToolbar className="ml-3 pt-2 pb-4" aria-label="Toolbar with button groups">
                    <ButtonGroup>
                        {showButton(0, com.stageToNumberApplication.personal, "Loan Purpose")}
                        {showButton(com.stageToNumberApplication.personal, com.stageToNumberApplication.property, "Personal")}
                        {showButton(com.stageToNumberApplication.property, com.stageToNumberApplication.income, "Property")}
                        {showButton(com.stageToNumberApplication.income, com.stageToNumberApplication.assets, "Income")}
                        {showButton(com.stageToNumberApplication.assets, com.stageToNumberApplication.liabilities, "Finance")}
                        {showButton(com.stageToNumberApplication.liabilities, com.stageToNumberApplication.choice, "Liabilities")}
                        {(this.props.application.hascoborrower !== "remote") ? showButton(com.stageToNumberApplication.choice, com.stageToNumberApplication.transaction, "Explore Rates") : ""}

                        {(this.props.application.hascoborrower !== "remote") ? showButton(com.stageToNumberApplication.transaction, com.stageToNumberApplication.declarations, "Transaction") : ""}
                        {showButton(com.stageToNumberApplication.declarations, com.stageToNumberApplication.end, "Declarations")}

                    </ButtonGroup>
                </ButtonToolbar>
            )
        return ""
    }
    interviewDisabled = () => {
        if ("undefined" !== typeof this.props.borrowerid) {
            return sessionStorage.getItem("edit") !== "true"
        }
        let current = com.getNumericalStatus(this.props.application.status)
        if (current < 3)
            return false
        if (this.state.currentIndex === this.wizard.length - 1)
            return false
        return true

    }
    handoff = e => {
        let token = sessionStorage.getItem("ZeitroA")
        let id = this.props.borrowerid
        this.setState({ handingoff: true })
        let body = { token: id }
        fetch('/borrower/handoff', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                    this.setState({ handingoff: false })
                    return
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ handingoff: false })
                    alert(js.Text)
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }

    autoCreateTasksAndNotifyLO = () => {
        this.setState({ notifyingLO: true })
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/autocreatetasksandnotifylo', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return
                }
                this.setState({ notifyingLO: false, showSubmitModal: false })
                updateTaskStatus()
            }
        ).catch((err) => {
            this.setState({ notifyingLO: false, showSubmitModal: false })
            console.log('Fetch Error :', err)
        })
    }

    showSubmitModal = () => {
        const handleOk = () => {
            this.restoreContinueButtonText()
            this.props.updateApplicationAttribute("interviewfinished", "status")
            this.autoCreateTasksAndNotifyLO()
        }
        return <Modal title="Congratulations on getting ready to submit your loan application!"
            open={this.state.showSubmitModal}
            onOk={handleOk}
            onCancel={() => this.setState({ showSubmitModal: false })}
            okText={this.state.notifyingLO ? <Spinner size="sm" animation="border" /> : "Submit"}
            centered>
            <p>What's the next step?</p>
            <p>Your loan officer will review your application and keep you updated on any progress or any further tasks. Once your submission is complete, feel free to: </p>
            <ul>
                <li>Check out your personalized rates by selecting "Continue"</li>
                <li>Fill out declarations</li>
                <li>Move ahead with uploading the required documents </li>
            </ul>
            <p>Don't hesitate to reach out if you have any questions or concerns!</p>
        </Modal>
    }

    render() {
        let isChecked = sessionStorage.getItem("edit") === "true"
        let onEdit = (e) => {
            sessionStorage.setItem("edit", e.target.checked)
            this.forceUpdate()
        }
        if (this.props.application.status === "loinitialized")
            this.props.updateApplicationAttribute("interviewstarted", "status")
        const success = () => {
            if (this.state.continueButtonText !== "Submit") {
                message.success('Your changes have been saved successfully.', 1)
            }
        }
        return (
            <Spin wrapperClassName={this.props.fromiframe === "true" ? "antSpinWrapper" : null} className="application-loding" spinning={this.state.loading}>
                <div className="d-flex" style={this.props.fromiframe === "true" ? { border: "2px solid #ECECEC", height: "100%" } : null}>
                    {this.state.showSubmitModal && this.showSubmitModal()}
                    {!this.props.smallScreen && <SidebarApplication />}
                    <div className="prequalInterviewPane newInterviewPane" style={{ margin: 0, borderRadius: 0, border: 'none', position: 'relative' }}>
                        <div key={this.props.match.params["section"]} id="envelope" className={this.props.fromiframe === "true" ? "text-center mt-0 text-nowrap iframeHeightAdjuster" : "text-center mt-0 text-nowrap full-height"} style={{ padding: 0 }} >
                            {this.toolbar()}

                            <Form className={this.props.fromiframe === "true" ? "iframeHeightAdjuster" : "full-height"} key={this.props.location} autoComplete="off" ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                {/* <fieldset disabled={this.interviewDisabled()}> */}
                                <fieldset className={this.props.fromiframe === "true" ? "iframeFieldset" : "full-height"} id="scrollBox" style={{ overflow: 'auto' }} disabled={false}>
                                    <Row className="ml-0  padding"  style={{
                                     
                                    }}>
                                        <Col className={this.props.fromiframe === "true" ? "text-center m-0 px-0 interviewpanes" : "text-center m-0 px-0"}>
                                            {this.state.currentPane}
                                        </Col>
                                    </Row>
                                </fieldset>
                                <Row className="text-left application-bottom-bar">
                                    <Button variant="zeitro-primary" id="next" type="submit" onClick={success} hidden={this.state.hiddenNext} className="text-center operate-btn btn">{this.state.continueButtonText}</Button>
                                    <div className="d-flex align-items-center">
                                        <div hidden={!this.state.hiddenNext} style={{ color: "#62707C", maxWidth: 480, marginRight: 10, textWrap: "wrap" }}>This concludes the application process. Your loan officer has been alerted and will be in touch shortly to guide you through the next steps.</div>
                                        <Button variant="zeitro-primary" id="previous" hidden={this.isPreviousHidden()} className="operate-btn back" onClick={this.previous} >Go back</Button>
                                    </div>
                                </Row>
                                {("undefined" !== typeof this.props.borrowerid) ?
                                    <div className="viewport  mt-5">
                                        <Row className="text-left" style={{ position: 'relative' }}>
                                            <Col xs="auto" style={{ marginTop: '11px' }}>
                                                Last change: {this.props.progress.lasttouch != null ? this.props.progress.lasttouch : "unknown"}
                                            </Col>
                                            <Col style={{ marginTop: '11px' }}>

                                                <Form.Group controlId="enableedit" className="text-left d-block text-nowrap " >
                                                    <Form.Check type="checkbox" className="mx-1 px-0"
                                                        defaultChecked={isChecked}

                                                        onChange={onEdit}
                                                        label="Enable editing"></Form.Check>
                                                </Form.Group>
                                            </Col>
                                            <Col hidden={this.props.application.status !== 'loinitialized'} style={{ position: 'relative' }} className="text-right">
                                                <Button onClick={this.handoff} style={{ right: '0px', marginTop: '5px' }}>
                                                    {
                                                        this.state.handingoff ? <img alt="wait" className="loader" style={{ width: '24px', opacity: '1.0', marginRight: '12px' }} src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img> : <span>&nbsp;</span>
                                                    } Hand off to borrower</Button>
                                            </Col>
                                        </Row> </div> : ""}
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }

    notifyLO = what => {
        this.setState({ notifyingLO: true })
        let token = sessionStorage.getItem("ZeitroA")
        let tosend =
            JSON.stringify({
                notification: what,
            })
        fetch('/borrower/notifylo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return
                }
                this.setState({ notifyingLO: false, showSubmitModal: false })
            }
        ).catch((err) => {
            this.setState({ notifyingLO: false, showSubmitModal: false })
            console.log('Fetch Error :', err)
        })
    }
}
SubInterview = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubInterview))

class ApplicationInterview extends Component {

    shouldComponentUpdate = () => {

        return true
    }
    render = () => <SubInterview {...this.props} key={this.props.match.params["section"]} />
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationInterview))