import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { Tabs } from "antd";
import DocumentManager from "./DocumentManager";
import Upload from "./Upload";
import {
  UpdateChangeProgress,
  UpdateChangeProgressType,
} from "../Store/progress";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    hascoborrower: state.application.hascoborrower,
    borrower: state.borrower,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage));
  },
  updateChangeProgressType: (step, stage) => {
    dispatch(UpdateChangeProgressType(step, stage));
  },
});
class Documents extends Component {
  componentDidMount() {
    this.props.updateChangeProgressType("documents");
  }

  render() {
    const items = [
      {
        key: "1",
        label: `My Documents`,
        children: <DocumentManager />,
      },
      {
        key: "2",
        label: `Upload New Documents`,
        children: <Upload />,
      },
    ];
    const onChange = (key) => {
      // console.log(key);
    };
    return <Tabs defaultActiveKey="1" destroyInactiveTabPane items={items} onChange={onChange} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
